import React from "react";
import { useGetInitializeMetaEntityCustomFieldsQuery } from "../../services/customFields/metaEntity.service";
import { User } from "../../entities/user";

interface InitMetaEntityCustomFieldsProps {
  isCustomFields: boolean;
  user: User;
}

export const InitMetaEntityCustomFields: React.FC<InitMetaEntityCustomFieldsProps> = ({
  isCustomFields,
  user
}) => {
  useGetInitializeMetaEntityCustomFieldsQuery(undefined, {
    skip: !isCustomFields || !user?.token
  });

  return null;
};
