import { FormLabel, Switch, Typography } from "@material-ui/core";
import FormControlLabel, {
  FormControlLabelProps
} from "@material-ui/core/FormControlLabel";
import { SwitchProps } from "@material-ui/core/Switch";
import { Field, FieldProps } from "formik";
import { grey } from "@material-ui/core/colors";
import { DebouncedFunc, get } from "lodash";
import React from "react";
import {
  FormProps,
  MyFormControl,
  MyFormControlProps
} from "./common/MyFormControl";
import { MyInputBox, MyInputBoxProps } from "./MyInputBox";

export interface MySwitchFieldProps extends SwitchProps, FormProps {
  label: string;
  formControlLabelProps?: FormControlLabelProps;
  inputBoxProps?: MyInputBoxProps;
  nodeChildren?: any;
  currentPath?: string;
  setFieldValue?: any;
  onDebounceChange?: DebouncedFunc<
    (
      values: {
        [key: string]: string | string[];
      },
      name: string,
      currentValue: boolean
    ) => void
  >;
}

export const MySwitchField: React.FC<MySwitchFieldProps> = ({
  name,
  label,
  helperText,
  formControlProps,
  formLabelProps,
  formHelperTextProps,
  formControlLabelProps,
  inputBoxProps,
  nodeChildren,
  currentPath,
  setFieldValue,
  onDebounceChange,
  ...switchProps
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<any>) => {
        const errorField = get(form.errors, field.name);
        const touchField = get(form.touched, field.name);
        const myFormControlProps: MyFormControlProps = {
          label,
          errorField,
          touchField,
          helperText,
          formHelperTextProps,
          formLabelProps: {
            ...formLabelProps,
            required: true
          }
        };

        const labelProps = {
          ...formLabelProps,
          required: switchProps.required || false
        };

        return (
          <>
            <MyInputBox {...inputBoxProps}>
              <MyFormControl
                showComponentLabel={false}
                {...formControlProps}
                {...myFormControlProps}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="medium"
                      color="primary"
                      {...field}
                      value={field.value || false}
                      {...switchProps}
                      checked={field.value || false}
                      onChange={(e: React.ChangeEvent<any>) => {
                        if (switchProps.readOnly) return;
                        field.onChange(e);
                        if (!touchField) {
                          form.setFieldTouched(field.name, true);
                        }
                        if (onDebounceChange) {
                          onDebounceChange(
                            form.values,
                            name as string,
                            e.currentTarget.value !== "true" ? true : false
                          );
                        }
                      }}
                    />
                  }
                  label={
                    <Typography style={{ color: "#222a41" }}>
                      {
                        <FormLabel
                          {...labelProps}
                          error={!!errorField && !!touchField}
                          style={{
                            color: myFormControlProps.disabled
                              ? grey[600]
                              : "black",
                            background: "white"
                          }}
                        >
                          {label}
                        </FormLabel>
                      }
                    </Typography>
                  }
                  {...formControlLabelProps}
                />
              </MyFormControl>
            </MyInputBox>
            {/*  {field.value && nodeChildren?.length > 0 && (
              <Paper>
                <Box p={1} pb={2}>
                  <MemoizedFormArrayList
                    nodeChildren={nodeChildren}
                    currentPath={currentPath}
                  />
                </Box>
              </Paper>
            )} */}
          </>
        );
      }}
    </Field>
  );
};
