import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ConnectSettingActionsTypes } from "./actions";
import * as Api from "./api";
import {
  ConnectSetting,
  ConnectSettingNames,
  ConnectSettingUpdateDTO
} from "./entity";

export interface ConnectSettingState extends EntityState<ConnectSetting> {
  readStatus: FetchingStatus;
  readAvailableStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  available: Record<ConnectSettingNames, Partial<ConnectSetting>>;
}

export const ConnectSettingAdapter = createEntityAdapter<ConnectSetting>();

export const ConnectSettingInitialState: ConnectSettingState = {
  ids: [],
  entities: {},
  available: {
    [ConnectSettingNames.MAGENTO]: { enabled: false, env: {} },
    [ConnectSettingNames.WOOCOMMERCE]: { enabled: false, env: {} },
    [ConnectSettingNames.MICROSOFT]: { enabled: false, env: {} }
  },
  readStatus: FetchingStatus.NULL,
  readAvailableStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const read = createMyAsyncThunk<ConnectSetting[]>(
  ConnectSettingActionsTypes.READ,
  Api.read
);
export const readAvailable = createMyAsyncThunk(
  ConnectSettingActionsTypes.READ_AVAILABLE,
  Api.readPublic
);

export const patch = createMyAsyncThunk<
  ConnectSetting,
  ConnectSettingUpdateDTO
>(ConnectSettingActionsTypes.PATCH, Api.patchOne, {
  onSuccessMessage: "saga:update-success"
});

const ConnectSettingSlice = createMySlice({
  name: "connect-settings",
  adapter: ConnectSettingAdapter,
  initialState: ConnectSettingAdapter.getInitialState(
    ConnectSettingInitialState
  ),
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ConnectSettingAdapter.setAll
    },
    {
      action: patch,
      statusName: "patchStatus",
      onSuccess: ConnectSettingAdapter.upsertOne
    },
    {
      action: readAvailable,
      statusName: "readAvailableStatus",
      onSuccess: (state, action) => {
        state.available = action.payload;
      }
    }
  ],
  reducers: {}
});

export const ConnectSettingReducer = ConnectSettingSlice.reducer;
export const ConnectSettingActions = {
  ...ConnectSettingSlice.actions,
  async: { read, readAvailable, patch }
};
