import i18n from "i18next";
import intersection from "lodash/intersection";
import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { useLocation } from "react-router-dom";
import { RoleStatus } from "../entities/role";
import { Reconnection } from "../pages/Reconnection";
import { RootState } from "../reducers";
import {
  AppAddSnackbar,
  AppIsOnBackOffice,
  AppRedirectAfterSubmit
} from "../reducers/app/action";
import { getAppState } from "../reducers/app/selector";
import { RetryConnectionAction } from "../reducers/authentication/action";
import { AuthenticationState } from "../reducers/authentication/reducer";
import {
  getCurrentUser,
  getUserRoleStatus
} from "../reducers/authentication/selector";
import { history, store } from "../reducers/store";
import { getToken } from "../utils/reconnection";
import { MyRouteProps, RouteOptions } from "./";
import { BackOffice } from "../pages/BackOffice";

interface RouterManagerProps {
  routes: MyRouteProps[];
}

interface SecureRouteProps extends Partial<MyRouteProps> {
  options?: RouteOptions;
  user: AuthenticationState["user"];
  component: any;
}

const ReturnNotAuthenticated: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    store.dispatch(
      new AppRedirectAfterSubmit(location.pathname + location.search)
    );
    store.dispatch(
      new AppAddSnackbar(i18n.t("routes:notAuthenticated"), "warning")
    );
  }, [location.pathname, location.search]);
  return <Redirect to="/" />;
};

export const safeRedirection = (roleStatus: RoleStatus) => {
  return {
    [RoleStatus.IS_ADMIN]: "/back-office/projects",
    [RoleStatus.IS_GUEST]: "/back-office/my-projects",
    [RoleStatus.IS_EXTERNAL_SALES]: "/back-office/my-projects"
  }[roleStatus];
};

const ReturnNoPermission: React.FC<{ isFeatureContext: boolean }> = ({
  isFeatureContext
}) => {
  const roleStatus = useSelector(getUserRoleStatus);
  const { t: tCommon } = useTranslation("common");
  /*  const safeRedirection = {
    [RoleStatus.IS_ADMIN]: "/back-office/projects",
    [RoleStatus.IS_GUEST]: "/back-office/my-projects",
    [RoleStatus.IS_EXTERNAL_SALES]: "/back-office/my-projects"
  }[roleStatus]; */

  useEffect(() => {
    history.push(safeRedirection(roleStatus));
    store.dispatch(
      new AppAddSnackbar(
        tCommon(
          isFeatureContext
            ? "unauthorized-feature-access"
            : "unauthorized-access"
        ),
        "warning"
      )
    );
  }, [isFeatureContext, tCommon, roleStatus]);
  return null;
};

const SecureRoute: React.FC<SecureRouteProps> = ({
  options,
  component: Component,
  ...rest
}) => {
  const user = useSelector(getCurrentUser);
  const featureSettings = useSelector(
    (state: RootState) => state.appSettings.setting.features
  );
  let isAuthenticated = true;
  let hasRole = true;
  let customValidation = true;
  let features = true;
  if (options) {
    if (options.authenticated) isAuthenticated = user.isActive && !!user.token;

    if (options.roles)
      hasRole =
        isAuthenticated &&
        user.roles.length > 0 &&
        intersection(
          options.roles,
          user.roles.map((r) => r.name)
        ).length > 0;
    if (options.customValidation)
      customValidation = options.customValidation({
        user,
        isAuthenticated,
        hasRole
      });

    if (options.features) {
      features = options.features.every((f) => featureSettings?.[f]);
    }
  }

  const needAuthentication =
    options?.authenticated === true ||
    (options?.roles !== undefined && options.roles.length > 0) ||
    !features;

  return (
    <Reconnection
      userIsAuthenticated={!!user.token}
      needAuthentication={needAuthentication}
    >
      <Route
        {...rest}
        render={(props) => {
          if (!needAuthentication) {
            return <Component {...props} />;
          }
          if (!isAuthenticated) {
            return <ReturnNotAuthenticated />;
          } else if (
            ![isAuthenticated, hasRole, customValidation, features].every(
              Boolean
            )
          ) {
            return <ReturnNoPermission isFeatureContext={!features} />;
          } else {
            return <Component {...props} />;
          }
        }}
      />
    </Reconnection>
  );
};

const createRoute = (
  allRoutes: MyRouteProps[],
  path?: string
): MyRouteProps[] => {
  return allRoutes.reduce((prevRoutes: any, route: any) => {
    const { options, childRoutes, name, ...routeProps } = route;
    const routePath = path ? path + route.path : route.path;
    routeProps.path = routePath;
    if (childRoutes) {
      prevRoutes = [...prevRoutes, ...createRoute(childRoutes, routePath)];
    }
    return [
      <SecureRoute key={name} options={options} {...routeProps} />,
      ...prevRoutes
    ];
  }, []);
};

export const RoutesManager: React.FC<RouterManagerProps> = ({ routes }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isOnBackOffice: appIsOnBackOffice } = useSelector(getAppState);
  const user = useSelector(getCurrentUser);
  const allRoutes = useMemo(() => createRoute(routes), [routes]);
  const token = getToken();
  const isOnBackOffice = location.pathname.includes("back-office");

  useEffect(() => {
    if (isOnBackOffice && !appIsOnBackOffice) {
      dispatch(new AppIsOnBackOffice(true));
    }
    if (!isOnBackOffice && appIsOnBackOffice) {
      dispatch(new AppIsOnBackOffice(false));
    }
  }, [appIsOnBackOffice, dispatch, isOnBackOffice]);

  useEffect(() => {
    // Do not retry connection when accessing link from product url
    if (!user.token && token) {
      const locations = location.pathname.split("/");
      if (locations[1] === "configurator" && locations[2] === "product") {
        return;
      }
      dispatch(
        new RetryConnectionAction(
          token,
          location.pathname === "/"
            ? undefined
            : location.pathname + location.search
        )
      );
    }
  }, [dispatch, location.pathname, location.search, token, user.token]);

  // reconnect if token changes from any browser tabs or using SSO
  const listenStorage = useCallback(
    (e: StorageEvent) => {
      if (e.key !== "token" || !!user.token || !!e.oldValue || !e.newValue)
        return;
      dispatch(new RetryConnectionAction(e.newValue));
    },
    [dispatch, user.token]
  );

  /*
   * Add event if token appear during authentication process from any browser tabs or using SSO
   * Required for user to not loose his brief configuration during an authentication process
   */
  useEffect(() => {
    window.addEventListener("storage", listenStorage);
    return () => {
      window.removeEventListener("storage", listenStorage);
    };
  }, [listenStorage]);

  return (
    <Suspense fallback={isOnBackOffice ? <BackOffice /> : <></>}>
      {allRoutes as any}
    </Suspense>
  );
};
