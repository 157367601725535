import { object } from "yup";
import { DecorationSchema } from "../../../common/BriefElementForm/schema/decoration.schema";
import { FinishSchema } from "../../../common/BriefElementForm/schema/finish.schema";
import { PurchaseConditionSchema } from "../../../common/BriefElementForm/schema/purchaseCondition.schema";
import { MaterialSchemaCorrugated } from "./material.schema";
import { PackagingSchemaCorrugated } from "./packaging.schema";

export const BriefElementSchemaCorrugated = object().shape(
  {
    ...PackagingSchemaCorrugated,
    ...DecorationSchema,
    ...MaterialSchemaCorrugated,
    ...FinishSchema,
    ...PurchaseConditionSchema
  },
  [
    ["versoColorTypeCMYK", "versoDirectToneColorType"],
    ["rectoColorTypeCMYK", "rectoDirectToneColorType"]
  ]
);
