import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import {
  SheetSizeActionsTypes,
  SheetSizeReadStatus,
  SheetSizeReadOneStatus,
  SheetSizeCreate,
  SheetSizeCreateStatus,
  SheetSizeUpdate,
  SheetSizeUpdateStatus,
  SheetSizeReadOne,
  SheetSizeDelete,
  SheetSizeDeleteStatus
} from "./action";
import * as Api from "./api";
import { AppAddSnackbar } from "../app/action";

export function* readSheetSizes() {
  yield put(new SheetSizeReadStatus(FetchingStatus.PENDING));
  try {
    const { data: sheetsizes } = yield call(Api.readSheetSizes);
    yield put(new SheetSizeReadStatus(FetchingStatus.SUCCESS, sheetsizes));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("sheetSizes:read-failed");
      Sentry.captureException(error);
    });
    yield put(new SheetSizeReadStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-failed")));
  }
}

export function* readOneSheetSize(action: SheetSizeReadOne) {
  yield put(new SheetSizeReadOneStatus(FetchingStatus.PENDING));
  try {
    const { data: sheetsize } = yield call(Api.readOneSheetSize, action.id);
    yield put(new SheetSizeReadOneStatus(FetchingStatus.SUCCESS, sheetsize));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("sheetSizes:read-one-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new SheetSizeReadOneStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-one-failed")));
  }
}

export function* createSheetSize(action: SheetSizeCreate) {
  yield put(new SheetSizeCreateStatus(FetchingStatus.PENDING));
  try {
    const { data: sheetsize } = yield call(
      Api.createSheetSize,
      action.sheetsize
    );
    yield put(new SheetSizeCreateStatus(FetchingStatus.SUCCESS, sheetsize));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("sheetSizes:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new SheetSizeCreateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* updateSheetSize(action: SheetSizeUpdate) {
  yield put(new SheetSizeUpdateStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.updateSheetSize, action.sheetsize);
    yield put(new SheetSizeUpdateStatus(FetchingStatus.SUCCESS));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("sheetSizes:update-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new SheetSizeUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* deleteSheetSize(action: SheetSizeDelete) {
  yield put(new SheetSizeDeleteStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.deleteSheetSize, action.sheetsize.id);
    yield put(new SheetSizeDeleteStatus(FetchingStatus.SUCCESS));
    yield put(new AppAddSnackbar(i18n.t("saga:delete-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("sheetSizes:delete-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new SheetSizeDeleteStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:delete-failed")));
  }
}

export const SheetSizeSaga = [
  takeLatest(SheetSizeActionsTypes.SHEETSIZE_CREATE, createSheetSize),
  takeLatest(SheetSizeActionsTypes.SHEETSIZE_READ, readSheetSizes),
  takeLatest(SheetSizeActionsTypes.SHEETSIZE_READ_ONE, readOneSheetSize),
  takeLatest(SheetSizeActionsTypes.SHEETSIZE_UPDATE, updateSheetSize),
  takeLatest(SheetSizeActionsTypes.SHEETSIZE_DELETE, deleteSheetSize)
];
