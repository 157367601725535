import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import { dynamicQueryCursorUrlClean } from "../../utils/function/dynamicUrlCursor";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { CursorBasedMetaDto, CursorPagination } from "../common/types";
import { Event } from "./type";

export const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Events",
    "TaskEvents",
    "CompanyEvents",
    "CompanyEventMonths",
    "ProjectEvents",
    "MessageEvents"
  ]
});

export const extendedApiSliceEvents = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<Event[], void>({
      query: () => "/events",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Events" as const,
                id
              })),
              { type: "Events", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Events", id: "PARTIAL-LIST" }]
    }),
    getTaskEvents: build.query<
      CursorPagination<Event>,
      Partial<CursorBasedMetaDto>
    >({
      query: ({ id, limit = 10, cursor }) =>
        dynamicQueryCursorUrlClean(`/events/${id}/task`, {
          limit,
          cursor
        }),

      // a voir
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}("${queryArgs.id}")`;
      },
      providesTags: (_, __, args) => [{ type: "TaskEvents", id: args?.id }],
      merge: (currentCache, newItems, args) => {
        if (!args?.arg.cursor) {
          return currentCache;
        }
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 30
    }),
    // Have to separate the query because of the tab parameter in the url, to not override the cache
    getTaskEventsByComments: build.query<
      CursorPagination<Event>,
      Partial<CursorBasedMetaDto>
    >({
      query: ({ id, limit = 10, cursor, tab, comment }) =>
        dynamicQueryCursorUrlClean(`/events/${id}/task`, {
          limit,
          cursor,
          tab,
          comment
        }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}("${queryArgs.id}")`;
      },
      providesTags: (_, __, args) => [{ type: "TaskEvents", id: args?.id }],
      merge: (currentCache, newItems, args) => {
        if (!args?.arg.cursor) {
          return currentCache;
        }
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 30
    }),
    getCompanyEventsByComments: build.query<
      CursorPagination<Event>,
      Partial<CursorBasedMetaDto>
    >({
      query: ({ id, limit = 10, cursor, tab, event }) =>
        dynamicQueryCursorUrlClean(`/events/${id}/company`, {
          limit,
          cursor,
          tab,
          event
        }),
      /* TODO need to add date to serialize and provideTags date of the currentMonth */
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}("${queryArgs.id}")`;
      },
      providesTags: (_, __, args) => [
        { type: "CompanyEvents", id: `${args?.id}` }
      ],
      merge: (currentCache, newItems, args) => {
        if (!args?.arg.cursor) {
          return currentCache;
        }
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 30
    }),
    getCompanyEvents: build.query<
      CursorPagination<Event>,
      Partial<CursorBasedMetaDto>
    >({
      query: ({ id, limit = 10, cursor, tab, type, start, end, date, event }) =>
        dynamicQueryCursorUrlClean(`/events/${id}/company`, {
          limit,
          cursor,
          tab,
          type,
          event,
          start,
          end,
          date
        }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return queryArgs.type
          ? `${endpointName}("${queryArgs.id}_${queryArgs.type}")`
          : queryArgs.date
          ? `${endpointName}("${queryArgs.id}_${queryArgs.date}")`
          : `${endpointName}("${queryArgs.id}_${queryArgs.start}_${queryArgs.end}")`;
      },
      providesTags: (_, __, args) => [
        {
          type: "CompanyEvents",
          id: args.type
            ? `${args.id}("${args.type}")`
            : args.date
            ? `${args.id}_${args.date}`
            : `${args.id}_${args.start}_${args.end}`
        }
      ],
      merge: (currentCache, newItems, args) => {
        if (!args?.arg.cursor) {
          return currentCache;
        }
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
      },
      /* 
      TODO improve as it's always fetching even when switching languages
      */
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 0
    }),
    getCompanyEventMonths: build.query<string[], Partial<CursorBasedMetaDto>>({
      query: ({ id, limit = 10, cursor, tab, type, start, end, date, event }) =>
        dynamicQueryCursorUrlClean(`/events/${id}/company/months`, {
          limit,
          cursor,
          tab,
          type,
          event,
          start,
          end,
          date
        }),
      providesTags: (_, __, args) => [
        {
          type: "CompanyEventMonths",
          id: args.id
        }
      ],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}("${queryArgs.id}")`;
      }
    }),
    getProjectEventsByComments: build.query<
      CursorPagination<Event>,
      Partial<CursorBasedMetaDto>
    >({
      keepUnusedDataFor: 0,
      query: ({ id, limit = 10, cursor, tab, comment }) =>
        dynamicQueryCursorUrlClean(`/events/${id}/project`, {
          limit,
          cursor,
          tab,
          comment
        }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}("${queryArgs.id}")`;
      },
      providesTags: (_, __, args) => [{ type: "ProjectEvents", id: args?.id }],
      merge: (currentCache, newItems, args) => {
        if (!args?.arg.cursor) {
          return currentCache;
        }
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      }
    }),
    createEvents: build.mutation<Event, Partial<Event>>({
      query(dto) {
        return {
          url: "/events",
          method: "POST",
          body: dto
        };
      },
      invalidatesTags: () => [{ type: "Events", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    updateEvents: build.mutation<Event, Partial<Event>>({
      query(dto) {
        const { id, ...patch } = dto;
        return {
          url: `/events/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      invalidatesTags: () => [{ type: "Events", id: "PARTIAL-LIST" }],
      async onQueryStarted(__, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    getEventMessages: build.query<
      CursorPagination<Event>,
      Partial<CursorBasedMetaDto> & { context: "user" | "company" | "project" }
    >({
      query: ({ id, limit = 10, cursor, tab, context }) =>
        dynamicQueryCursorUrlClean(`/events/${context}/${id}/messages`, {
          limit,
          cursor,
          tab
        }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}("${queryArgs.id}")`;
      },
      providesTags: (_, __, args) => [
        { type: "MessageEvents", id: args?.id },
        { type: "Events", id: "PARTIAL-LIST" }
      ],
      merge: (currentCache, newItems, args) => {
        if (!args?.arg.cursor) {
          return currentCache;
        }
        currentCache.data.push(...newItems.data);
        currentCache.meta = newItems.meta;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: 30
    }),
    deleteEvent: build.mutation<
      void,
      { id: string; requestArgs?: Partial<CursorBasedMetaDto> }
    >({
      query({ id }) {
        return {
          url: `/events/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: (_r, _e, { id }) => [
        { type: "Events", id },
        { type: "MessageEvents", id }
      ],
      async onQueryStarted(
        { id: idDeleted, requestArgs },
        { dispatch, queryFulfilled }
      ) {
        let patch;
        try {
          patch = dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              "getCompanyEvents",
              requestArgs ?? {},
              (draft: CursorPagination<Event>) => {
                const update = draft.data.filter(
                  (event) => event.id !== idDeleted
                );
                draft.data = update;
              }
            )
          );
          await queryFulfilled;
        } catch (error) {
          patch?.undo();
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:delete-failed")
            )
          );
        }
      }
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetEventsQuery,
  useCreateEventsMutation,
  useGetTaskEventsByCommentsQuery,
  useGetCompanyEventsByCommentsQuery,
  useGetProjectEventsByCommentsQuery,
  useGetTaskEventsQuery,
  useUpdateEventsMutation,
  useGetCompanyEventsQuery,
  useGetCompanyEventMonthsQuery,
  useGetEventMessagesQuery,
  useDeleteEventMutation
} = extendedApiSliceEvents;
