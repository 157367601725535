import React, { useState } from "react";
import {
  Notification,
  NotificationFilter
} from "../../../../services/notifications/notifications.type";
import { User } from "../../../../entities/user";
import { NotificationHeader } from "./NotificationHeader";
import {
  NotificationCategoryDisplay,
  getInAppCategoryLinkHref
} from "./NotificationCategoryDisplay";
import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import { Event } from "../../../../services/events/type";
import { useUpdateNotificationMutation } from "../../../../services/notifications/notifications.service";

interface NotificationUserCategoryProps {
  notification: Notification;
  archivingView?: boolean;
  actionNotfication: NotificationFilter;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& :hover": {
        background: "#F7F8FF"
      },
      cursor: "pointer"
    }
  })
);

export const NotificationUserCategory: React.FC<NotificationUserCategoryProps> = ({
  notification,
  archivingView = false,
  actionNotfication
}) => {
  const classes = useStyle();
  const [isHovered, setIsHovered] = useState(false);
  const event = notification?.events?.[0];

  const [updateNotification] = useUpdateNotificationMutation();

  const handleMarkAsRead = async () => {
    await updateNotification({
      id: notification.id,
      isRead: true,
      category: notification.category
    });
  };

  return (
    <Box
      className={classes.container}
      onClick={async (e) => {
        const redirectUrl = getInAppCategoryLinkHref(event as Event);
        handleMarkAsRead();
        window.open(redirectUrl, "_blank");
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NotificationHeader
        user={notification?.events?.[0]?.user as User}
        date={notification?.createdAt}
        notification={notification}
        isHovered={isHovered}
        archivingView={archivingView}
        actionNotfication={actionNotfication}
      >
        <Box style={{ maxWidth: "93%" }}>
          <NotificationCategoryDisplay notification={notification} />
        </Box>
      </NotificationHeader>
    </Box>
  );
};
