import { AxiosResponse } from "axios";
import { EditNameValues } from "../../components/common/form/action/EditNamedEntityForm";
import { Brief, BriefWithRelations } from "../../entities/brief";
import { Request } from "../../utils/request";
import { BriefConfiguratorValues } from "../briefConfigurator/reducer";
import { BriefElementConfiguratorValues } from "../briefElementConfigurator/reducer";

export const fetchBriefs = () => Request({ withToken: true }).get("/briefs");

export const createBrief = (
  configuratorValues: BriefConfiguratorValues & {
    briefElements: Array<Partial<BriefElementConfiguratorValues>>;
  }
) => Request({ withToken: true }).post("/briefs", configuratorValues);

export const getBriefByIdWithPrediction = (
  id: string
): Promise<AxiosResponse<BriefWithRelations>> => {
  return Request({ withToken: true }).get<BriefWithRelations>(`/briefs/${id}`, {
    transformResponse: (data) => JSON.parse(data)
  });
};

export const getBriefByIdTimeout = (
  id: string
): Promise<AxiosResponse<BriefWithRelations>> => {
  return Request({ withToken: true }).get<BriefWithRelations>(
    `/briefs/${id}/timeout`,
    {
      transformResponse: (data) => JSON.parse(data)
    }
  );
};

export const getBriefDetailsById = (
  id: string
): Promise<AxiosResponse<BriefWithRelations>> => {
  return Request({ withToken: true }).get<BriefWithRelations>(
    `/briefs/${id}/details`,
    {
      transformResponse: (data) => JSON.parse(data)
    }
  );
};

export const editBriefName = ({ id, name }: EditNameValues) =>
  Request({ withToken: true }).patch(`/briefs/${id}`, { name });

export const update = (id: string, updateBrief: Partial<Brief>) =>
  Request({ withToken: true }).patch(`/briefs/${id}`, updateBrief);

export const assignProject = (id: string, projectId?: string) =>
  Request({ withToken: true }).patch(`/briefs/${id}/project`, {
    projectId: projectId || null
  });

export const readForUser = (userId: string) =>
  Request({ withToken: true }).get(`/users/${userId}/briefs`);

export const briefToCart = (briefId: string, cmsType: string) =>
  Request({ withToken: true }).get(`/briefs/${briefId}/cart/${cmsType}`);
