import { array, number, object } from "yup";

export const LayoutSchemaWithOptions = (required: boolean) => {
  let numberSchema = number().min(0);
  if (required) {
    numberSchema = numberSchema.required();
  }

  return array()
    .nullable(!required)
    .of(
      object({
        width: numberSchema,
        height: numberSchema,
        position: number().min(0)
      })
    )
    .min(required ? 1 : 0);
};
