import { Request } from "../../utils/request";
import {
  CorrugatedCompositionCreateDTO,
  CorrugatedCompositionCreateManyDTO,
  CorrugatedCompositionUpdateDTO
} from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-material-compositions");

export const createOne = (dto: CorrugatedCompositionCreateDTO) =>
  Request({ withToken: true }).post("/corrugated-material-compositions", dto);

export const patchOne = ({ id, ...dto }: CorrugatedCompositionUpdateDTO) =>
  Request({ withToken: true }).patch(
    `/corrugated-material-compositions/${id}`,
    dto
  );

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(
    `/corrugated-material-compositions/${id}`
  );

export const createMany = (dto: CorrugatedCompositionCreateManyDTO) =>
  Request({ withToken: true }).post(
    "/corrugated-material-compositions/bulk",
    dto
  );
