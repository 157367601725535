import {
  QueryDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import { UseQueryStateDefaultResult } from "../../utils/hooks/useQuery";
import { UserWPredCount } from "../../entities/user";
import { PageMetaDto } from "../common/types";

export interface UserPagination {
  meta: PageMetaDto;
  data: UserWPredCount[];
}

export type resultUser = UseQueryStateDefaultResult<
  QueryDefinition<
    Partial<PageMetaDto>,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      Record<string, never>,
      FetchBaseQueryMeta
    >,
    "Users",
    UserPagination,
    "api"
  >
>;

export enum ContactContext {
  MY_CONTACTS = "my contacts",
  CONTACTS = "contacts",
  COMPANY_CONTACTS = "company contacts",
  COMPANY = "company"
}

export interface UserContacts {
  withOptions?: boolean;
}

export interface AccountFilter {
  role: string;
  filterId?: string;
}

export interface ContactFilter {
  filterId?: string;
}
