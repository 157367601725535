import { Project, ProjectWithRelations } from "../../entities/project";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { AxiosRequestConfig } from "axios";

export enum ProjectActionsTypes {
  PROJECT_FETCH = "@@PROJECT fetch projects",
  PROJECT_FETCH_STATUS = "@@PROJECT fetch status project",
  PROJECT_FOR_USER_FETCH = "@@PROJECT fetch project users",
  PROJECT_FOR_USER_FETCH_STATUS = "@@PROJECT fetch status projects user",
  PROJECT_UPDATE = "@@PROJECT update",
  PROJECT_UPDATE_STATUS = "@@PROJECT update status",
  PROJECT_SELECT_ID = "@@PROJECT select project id",
  PROJECT_FETCH_ID = "@@PROJECT fetch project by id",
  PROJECT_FETCH_ID_STATUS = "@@PROJECT fetch status project by id",
  PROJECT_CREATE = "@@PROJECT create project",
  PROJECT_CREATE_STATUS = "@@PROJECT create project status",
  PROJECT_CREATE_MANAGED = "@@PROJECT create managed project",
  PROJECT_CREATE_MANAGED_STATUS = "@@PROJECT create  managed project status",
  PROJECT_CREATE_FOR_COMPANY = "@@PROJECT create for company",
  PROJECT_CREATE_FOR_COMPANY_STATUS = "@@PROJECT create  for company status",
  PROJECT_DELETE = "@@PROJECT delete project",
  PROJECT_DELETE_STATUS = "@@PROJECT delete project status",
  PROJECT_ARCHIVED = "@@PROJECT archived",
  PROJECT_ARCHIVED_STATUS = "@@PROJECT archived status ",
  PROJECT_GET_FILES = "@@PROJECT get files",
  PROJECT_GET_FILES_STATUS = "@@PROJECT get files status",
  PROJECT_UPLOAD_FILE = "@@PROJECT upload file",
  PROJECT_UPLOAD_FILE_STATUS = "@@PROJECT upload file status",
  PROJECT_DOWNLOAD_FILE = "@@PROJECT download file",
  PROJECT_DELETE_FILE = "@@PROJECT delete file",
  PROJECT_DELETE_FILE_STATUS = "@@PROJECT delete file status",
  PROJECT_SET_CONTEXT = "@@PROJECT set project context",
  PROJECT_ASSIGN_BRIEF = "@@PROJECT change brief assignation",
  PROJECT_UNASSIGN_BRIEF = "@@PROJECT change brief unassignation",
  PROJECT_ADD_MANY = "@@PROJECT add many"
}

export class ProjectFetch {
  readonly type = ProjectActionsTypes.PROJECT_FETCH;
}

export class ProjectFetchStatus {
  readonly type = ProjectActionsTypes.PROJECT_FETCH_STATUS;
  constructor(public status: FetchingStatus, public projects?: Project[]) {}
}

export class ProjectForUserFetch {
  readonly type = ProjectActionsTypes.PROJECT_FOR_USER_FETCH;
}

export class ProjectForUserFetchStatus {
  readonly type = ProjectActionsTypes.PROJECT_FOR_USER_FETCH_STATUS;
  constructor(public status: FetchingStatus, public projects?: Project[]) {}
}

export class ProjectSelectId {
  readonly type = ProjectActionsTypes.PROJECT_SELECT_ID;
  constructor(public id?: string) {}
}

export class ProjectFetchById {
  readonly type = ProjectActionsTypes.PROJECT_FETCH_ID;
  constructor(public id: string) {}
}

export class ProjectFetchByIdStatus {
  readonly type = ProjectActionsTypes.PROJECT_FETCH_ID_STATUS;
  constructor(public status: FetchingStatus, public project?: Project) {}
}

export class ProjectUpdate {
  readonly type = ProjectActionsTypes.PROJECT_UPDATE;
  constructor(public projectId: string, public project: Partial<Project>) {}
}

export class ProjectUpdateStatus {
  readonly type = ProjectActionsTypes.PROJECT_UPDATE_STATUS;
  constructor(public status: FetchingStatus, public project?: Project) {}
}

export class ProjectCreate {
  readonly type = ProjectActionsTypes.PROJECT_CREATE;
  constructor(public project: Partial<Project>) {}
}

export class ProjectCreateStatus {
  readonly type = ProjectActionsTypes.PROJECT_CREATE_STATUS;
  constructor(public status: FetchingStatus, public project?: Project) {}
}

export class ProjectCreateManaged {
  readonly type = ProjectActionsTypes.PROJECT_CREATE_MANAGED;
  constructor(public project: Partial<Project>) {}
}

export class ProjectCreateManagedStatus {
  readonly type = ProjectActionsTypes.PROJECT_CREATE_MANAGED_STATUS;
  constructor(public status: FetchingStatus, public project?: Project) {}
}

export class ProjectCreateForCompany {
  readonly type = ProjectActionsTypes.PROJECT_CREATE_FOR_COMPANY;
  constructor(public companyId: string, public project: Partial<Project>) {}
}

export class ProjectCreateForCompanyStatus {
  readonly type = ProjectActionsTypes.PROJECT_CREATE_FOR_COMPANY_STATUS;
  constructor(public status: FetchingStatus, public project?: Project) {}
}

export class ProjectDelete {
  readonly type = ProjectActionsTypes.PROJECT_DELETE;
  constructor(public projectId: string) {}
}

export class ProjectDeleteStatus {
  readonly type = ProjectActionsTypes.PROJECT_DELETE_STATUS;
  constructor(
    public status: FetchingStatus,
    public project?: ProjectWithRelations,
    public callback?: any
  ) {}
}

export class ProjectArchived {
  readonly type = ProjectActionsTypes.PROJECT_ARCHIVED;
  constructor(public projectId: string, public redirect: string) {}
}
export class ProjectArchivedStatus {
  readonly type = ProjectActionsTypes.PROJECT_ARCHIVED_STATUS;
  constructor(public status: FetchingStatus, public projectId?: string) {}
}

// FILES
export class ProjectGetFiles {
  readonly type = ProjectActionsTypes.PROJECT_GET_FILES;
  constructor(public projectId: string) {}
}
export class ProjectGetFilesStatus {
  readonly type = ProjectActionsTypes.PROJECT_GET_FILES_STATUS;
  constructor(public status: FetchingStatus, public projectId?: string) {}
}

export class ProjectUploadFile {
  readonly type = ProjectActionsTypes.PROJECT_UPLOAD_FILE;
  constructor(
    public projectId: string,
    public formdata: FormData,
    public onUploadProgress?: AxiosRequestConfig["onUploadProgress"]
  ) {}
}
export class ProjectUploadFileStatus {
  readonly type = ProjectActionsTypes.PROJECT_UPLOAD_FILE_STATUS;
  constructor(public status: FetchingStatus) {}
}

export class ProjectDownloadFile {
  readonly type = ProjectActionsTypes.PROJECT_DOWNLOAD_FILE;
  constructor(
    public projectId: string,
    public fileId: string,
    public preview?: boolean
  ) {}
}

export class ProjectDeleteFile {
  readonly type = ProjectActionsTypes.PROJECT_DELETE_FILE;
  constructor(public projectId: string, public fileId: string) {}
}
export class ProjectDeleteFileStatus {
  readonly type = ProjectActionsTypes.PROJECT_DELETE_FILE_STATUS;
  constructor(public status: FetchingStatus) {}
}

export class ProjectSetContext {
  readonly type = ProjectActionsTypes.PROJECT_SET_CONTEXT;
  constructor(public id?: string) {}
}

export class ProjectAssignBrief {
  readonly type = ProjectActionsTypes.PROJECT_ASSIGN_BRIEF;
  constructor(public briefId: string, public projectId: string) {}
}

export class ProjectUnAssignBrief {
  readonly type = ProjectActionsTypes.PROJECT_UNASSIGN_BRIEF;
  constructor(public briefId: string, public projectId: string) {}
}

export class ProjectAddMany {
  readonly type = ProjectActionsTypes.PROJECT_ADD_MANY;
  constructor(public projects: Project[]) {}
}

export type ProjectActions =
  | ProjectFetch
  | ProjectFetchStatus
  | ProjectForUserFetch
  | ProjectForUserFetchStatus
  | ProjectSelectId
  | ProjectFetchById
  | ProjectFetchByIdStatus
  | ProjectUpdate
  | ProjectUpdateStatus
  | ProjectCreate
  | ProjectCreateStatus
  | ProjectCreateManaged
  | ProjectCreateManagedStatus
  | ProjectCreateForCompany
  | ProjectCreateForCompanyStatus
  | ProjectDelete
  | ProjectDeleteStatus
  | ProjectArchived
  | ProjectArchivedStatus
  | ProjectGetFiles
  | ProjectGetFilesStatus
  | ProjectUploadFile
  | ProjectUploadFileStatus
  | ProjectDeleteFile
  | ProjectDeleteFileStatus
  | ProjectSetContext
  | ProjectAssignBrief
  | ProjectUnAssignBrief
  | ProjectAddMany;
