import { AutoCompleteOption } from "../../components/common/form/MyAutocompleteField";
import { Project } from "../../entities/project";
import { RtkTag } from "../common/types";
import { ProjectContext } from "./project.types";

export const getInvalidatTagsProjects = (
  context: ProjectContext,
  projectId?: string
): RtkTag[] => {
  switch (context) {
    case ProjectContext.MY_PROJECTS:
      return [{ type: "MyProjects", id: "PARTIAL-LIST" }];
    case ProjectContext.PROJECTS:
      return [{ type: "Projects", id: "PARTIAL-LIST" }];
    case ProjectContext.COMPANY_PROJECTS:
      return [{ type: "CompanyProjects", id: "PARTIAL-LIST" }];
    case ProjectContext.PROJECT:
      return [{ type: "Project", id: projectId as string }];
    case ProjectContext.CONTACT_PROJECTS:
      return [{ type: "ProjectContacts", id: "PARTIAL-LIST" }];
    default:
      return [];
  }
};

export const getProjectOptions = (
  projects: Project[]
): AutoCompleteOption[] => {
  return projects.map((project: Project) => ({
    label: project.name,
    value: project.id
  }));
};
