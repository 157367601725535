import { Request } from "../../utils/request";
import { FormatCreateDTO, FormatUpdateDTO } from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-formats");

export const createOne = (format: FormatCreateDTO) =>
  Request({ withToken: true }).post("/corrugated-formats", format);

export const patchOne = ({ id, ...dto }: FormatUpdateDTO) =>
  Request({ withToken: true }).patch(`/corrugated-formats/${id}`, dto);
