import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { ConditionFailComponent } from "./ConditionFail";
import { ErrorComponent } from "./ErrorComponent";
import { LoaderComponent } from "./LoaderComponent";
import { NoDataComponent } from "./NoDataComponent";
import { fp } from "../../../utils/fp";

interface Action {
  action: AnyAction;
  status: FetchingStatus;
  forceDispatch?: boolean;
}

export interface DynamicElementProps<T> {
  // Recall is for recalling every
  // action call methods
  recall?: boolean;
  actions: Action[] | Action | any;
  showCondition?: boolean;
  data?: T;
  errorComponent?: ReactElement;
  noDataComponent?: ReactElement;
  conditionFailComponent?: ReactElement;
  loaderComponent?: ReactElement;
  children: (props: NonNullable<T>) => ReactElement;
}

const MINIMUM_TIME_BEFORE_SHOW_LOADER = 1000;

const DynamicElementComponents = {
  loader: LoaderComponent,
  noData: NoDataComponent,
  error: ErrorComponent,
  conditionFail: ConditionFailComponent
};

// TODO OPTIMIZE CODE PLEASE
export function DynamicElement<T>({
  data,
  actions,
  noDataComponent,
  errorComponent,
  loaderComponent,
  conditionFailComponent,
  showCondition = true,
  recall = false,
  children
}: DynamicElementProps<T>) {
  const [showLoader, setShowLoader] = useState(false);
  const [status, setStatus] = useState({
    success: false,
    failed: false,
    pending: false
  });
  const dispatch = useDispatch();
  const init = useRef<boolean>(false);

  const showLoaderFunc = debounce(
    () => setShowLoader(true),
    MINIMUM_TIME_BEFORE_SHOW_LOADER
    // { leading: !status.success }
  );

  useEffect(() => {
    if (!init.current) {
      if (Array.isArray(actions)) {
        actions
          .filter(
            (a) =>
              recall || a.forceDispatch || a.status !== FetchingStatus.SUCCESS
          )
          .forEach((a) => {
            dispatch(a.action);
          });
      } else {
        if (
          recall ||
          actions.forceDispatch ||
          actions.status !== FetchingStatus.SUCCESS
        )
          dispatch(actions.action);
      }
      showLoaderFunc();
      init.current = true;
    }
  }, [actions, dispatch, recall, showCondition, showLoaderFunc]);

  useEffect(() => {
    const allStatus = {
      success: Array.isArray(actions)
        ? actions.every((a) => a.status === FetchingStatus.SUCCESS)
        : actions.status === FetchingStatus.SUCCESS,
      failed: Array.isArray(actions)
        ? actions.some((a) => a.status === FetchingStatus.FAILED)
        : actions.status === FetchingStatus.FAILED,
      pending: Array.isArray(actions)
        ? actions.some((a) => a.status === FetchingStatus.PENDING)
        : actions.status === FetchingStatus.PENDING
    };
    if (!isEqual(status, allStatus)) {
      setStatus(allStatus);
    }
  }, [actions, status]);

  if (status.pending) {
    if (showLoader) {
      return (
        loaderComponent || React.createElement(DynamicElementComponents.loader)
      );
    } else {
      return null;
    }
  }
  if (status.success) {
    if (fp.isEmpty(data)) {
      if (noDataComponent) return noDataComponent;
      return children(data as NonNullable<T>);
    } else if (!showCondition) {
      return (
        conditionFailComponent ||
        React.createElement(DynamicElementComponents.conditionFail)
      );
    } else {
      return children(data as NonNullable<T>);
    }
  }
  if (status.failed) {
    if (
      (Array.isArray(actions) &&
        actions.some((action) => action.status === FetchingStatus.FAILED)) ||
      (!Array.isArray(actions) && actions.status === FetchingStatus.FAILED)
    ) {
      return (
        errorComponent || React.createElement(DynamicElementComponents.error)
      );
    } else {
      return (
        noDataComponent || React.createElement(DynamicElementComponents.noData)
      );
    }
  }
  return null;
}
