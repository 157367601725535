import React from "react";
import { Container, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import i18next from "i18next";
import { useHistory } from "react-router-dom";
import { captureConsoleIntegration } from "@sentry/react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    animation: "$fadeIn 0.5s ease-in-out"
  },
  icon: {
    fontSize: 100,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    animation: "$bounce 1s infinite"
  },
  message: {
    marginBottom: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(1)
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0, transform: "scale(0.9)" },
    "100%": { opacity: 1, transform: "scale(1)" }
  },
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: "translateY(-10px)" },
    "60%": { transform: "translateY(-5px)" }
  }
}));

interface ErrorFallbackProps {
  // error: unknown;
  // componentStack: string;
  // eventId: string;
  // resetError(): void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = () => {
  const classes = useStyles();

  // Handler to refresh the page
  const handleRefresh = () => {
    window.location.reload();
  };

  // Handler to go back in the browser history
  const handleGoBack = () => {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  if (i18next?.isInitialized) {
    return (
      <Container className={classes.root}>
        <ErrorOutlineIcon className={classes.icon} />
        <Typography variant="h4" className={classes.message}>
          {i18next.t("common:errorFallback.title")}
        </Typography>
        <Typography variant="body1">
          {i18next.t("common:errorFallback.description")}
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleRefresh}
          >
            {i18next.t("common:errorFallback.refreshButton")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleGoBack}
          >
            {i18next.t("common:errorFallback.goBackButton")}
          </Button>
        </Box>
      </Container>
    );
  }

  // Fallback to English if i18next is not initialized
  return (
    <Container className={classes.root}>
      <ErrorOutlineIcon className={classes.icon} />
      <Typography variant="h4" className={classes.message}>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1">
        An unexpected error has occurred. Please try refreshing the page or go
        back to the previous page.
      </Typography>
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleRefresh}
        >
          Refresh Page
        </Button>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorFallback;
