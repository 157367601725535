import React, { cloneElement, useState } from "react";
import EyeIcon from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Cancel from "@material-ui/icons/CancelOutlined";
import { omit } from "lodash";
import {
  MutationDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition
} from "@reduxjs/toolkit/dist/query";
import {
  MutationTrigger,
  UseQuery
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ObjectSchema } from "yup";
import { getLanguage } from "../../../reducers/app/selector";
import { FormMode } from "../../../utils/components/form";
import {
  MyMaterialTable2PropsVirtualized,
  MyMaterialTable2Virtualized
} from "../MyMaterialTable2Virtualize";
import { MenuItem, ListItemIcon, ListItemText, Box } from "@material-ui/core";
import { PreventArchivedDialogGeneric } from "../PreventArchiveDialogGeneric";
import { ExportCsvButton } from "./components/ExportCsvButton";
import { useHistory } from "react-router-dom";

export interface Mutation<T, K extends string> {
  query: MutationTrigger<
    MutationDefinition<
      Partial<T>,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, never>,
        FetchBaseQueryMeta
      >,
      K,
      T,
      "api"
    >
  >;
  updateLoading?: boolean;
  createLoading?: boolean;
  icon?: JSX.Element;
}

// need to get one query who get all the data formated in csv
// also need to get the name of the file
// implement it on the rightActions props of the generic table

interface Labels<T> {
  create?: string;
  update?: string;
  delete?: string;
  preventRemoveContent?: string | ((data: T) => string);
  preventRemoveTitle?: string;
  seeDetails?: string;
}

interface Export<T, K extends string> {
  query: UseQuery<
    QueryDefinition<
      void,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, never>,
        FetchBaseQueryMeta
      >,
      K,
      T[],
      "api"
    >
  >;
  fileName: string;
  exportCsvLoading?: boolean;
}

interface CustomIcons {
  seeDetails?: React.ReactNode;
}

export interface GenericTableProps<T, K extends string>
  extends Pick<
    MyMaterialTable2PropsVirtualized<T>,
    | "data"
    | "columns"
    | "rowHeight"
    | "rightActions"
    | "defaultFieldSort"
    | "allowBackDrop"
    | "disableMenuActions"
    | "rowMenuActions"
    | "defaultFieldSortDirection"
  > {
  initialValues?: Partial<T>;
  create?: Mutation<T, K>;
  update?: Mutation<T, K>;
  exportCsv?: Export<T, K>;
  archive?: Mutation<T, K>;
  deactivate?: boolean;
  transformCreate?: (data: Partial<T>, currentLang: string) => any;
  transformUpdate?: (data: Partial<T>, currentLang: string) => any;
  // Can be improve columns by adding specific columns names using Partial<keyof T>?
  schema?: Record<"UPDATE" | "CREATE", ObjectSchema<Partial<T>>>;
  genericForm?: JSX.Element;
  labels?: Labels<T>;
  hideTimeStamps?: boolean;
  omitFields?: string[];
  validationContext?: any;
  /* viewDetailsPath?: (id: string) => string; */
  viewDetailsPath?: (data: T) => string;
  customIcons?: CustomIcons;
  tableProps?: Partial<MyMaterialTable2PropsVirtualized<T>>;
  reduceHeight?: number;
  dataContext?: string;
}

export interface DeleteState<T> {
  data?: Partial<T>;
  open: boolean;
}

export function GenericTable<
  T extends { id: string; enabled?: boolean },
  K extends string
>({
  data,
  exportCsv,
  create,
  update,
  archive,
  deactivate,
  transformCreate,
  transformUpdate,
  initialValues,
  columns,
  defaultFieldSort = "createdAt",
  defaultFieldSortDirection = "desc",
  rowHeight = 100,
  schema,
  genericForm,
  labels,
  hideTimeStamps = true,
  omitFields,
  validationContext,
  allowBackDrop,
  rightActions,
  disableMenuActions,
  rowMenuActions,
  viewDetailsPath,
  tableProps: parentTableProps,
  reduceHeight,
  customIcons
}: GenericTableProps<T, K>): JSX.Element {
  const { t: tCommon } = useTranslation("common");
  const lang = useSelector(getLanguage);
  const history = useHistory();

  const [deletedId, setDeletedId] = useState<DeleteState<T>>({
    open: false,
    data: undefined
  });

  const onCreateOne =
    create && schema
      ? async (values: T) => {
          const cleanedValues = omit(values, [
            ...((omitFields as string[]) || "")
          ]);

          schema.CREATE.validateSync(
            omit(values, [...((omitFields as string[]) || "")]),
            {
              ...(validationContext && { context: { ...validationContext } })
            }
          );

          if (transformCreate) {
            await create.query(transformCreate(cleanedValues, lang));
          } else {
            await create.query({
              ...cleanedValues
            });
          }
        }
      : undefined;

  const onPatchOne =
    update && schema
      ? async (values: T) => {
          const cleanedValues = omit(values, [
            "createdAt",
            "updatedAt",
            ...((omitFields as string[]) || "")
          ]);

          schema.UPDATE.validateSync(cleanedValues, {
            ...(validationContext && { context: { ...validationContext } })
          });

          if (transformUpdate) {
            await update.query(transformUpdate(cleanedValues, lang));
          } else {
            await update.query({
              ...cleanedValues,
              id: values.id
            });
          }
        }
      : undefined;

  const onArchive = async (values: Partial<T>) => {
    if (!values.id && !archive?.query) return;
    await archive?.query({ ...values });
  };

  const defaultColumns = [{ field: "id", hidden: true }];

  const myColumns = [
    ...defaultColumns,
    ...columns,
    {
      title: tCommon("createdAt"),
      field: "createdAt",
      hidden: hideTimeStamps,
      minWidth: 240,
      format: (value: string | number | Date) =>
        new Date(value).toLocaleString(lang, {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric"
        })
    },
    {
      title: tCommon("updatedAt"),
      field: "updatedAt",
      hidden: hideTimeStamps,
      minWidth: 240,
      format: (value: string | number | Date) =>
        new Date(value).toLocaleString(lang, {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric"
        })
    }
  ];

  return (
    <>
      <MyMaterialTable2Virtualized<T>
        menuActionsAfterEdit
        rightActions={
          <>
            {rightActions}
            {exportCsv && (
              <ExportCsvButton
                action={exportCsv?.query()}
                fileName={exportCsv?.fileName}
              />
            )}
          </>
        }
        disableRow={
          !!data?.length && Object.keys(data[0]).includes("enabled")
            ? (row) => {
                if (row.enabled === true) {
                  return false;
                } else {
                  return true;
                }
              }
            : undefined
        }
        replaceEmptyValues={false}
        height={
          reduceHeight
            ? `calc(100vh - 240px - ${reduceHeight}px)`
            : `calc(100vh - 240px)`
        }
        rowHeight={rowHeight}
        defaultFieldSort={defaultFieldSort}
        defaultFieldSortDirection={defaultFieldSortDirection}
        allowBackDrop={allowBackDrop}
        disableMenuActions={disableMenuActions}
        updateElement={
          update &&
          schema &&
          genericForm && {
            status: update.updateLoading as boolean,
            title: () => labels?.update ?? tCommon("edit"),
            buttonLabel: () => labels?.update ?? tCommon("edit"),
            formik: {
              validateOnMount: true,
              validationSchema: schema.UPDATE,
              ...(validationContext && {
                validationContext: { ...validationContext }
              }),
              initialValues: { ...initialValues },
              onSubmit: onPatchOne,
              children: (props) =>
                cloneElement(genericForm, {
                  ...props,
                  mode: FormMode.UPDATE
                })
            },
            setFormikInitialValue: (row) => row
          }
        }
        addElement={
          create &&
          schema &&
          genericForm && {
            status: create.createLoading as boolean,
            buttonLabel: labels?.create ?? tCommon("create"),
            title: labels?.create ?? tCommon("create"),
            formik: {
              initialValues: { ...initialValues },
              onSubmit: onCreateOne,
              ...(genericForm.props.validateOnMount && {
                validateOnMount: true
              }),
              validationSchema: schema.CREATE,
              ...(validationContext && {
                validationContext: { ...validationContext }
              }),
              children: (props) =>
                cloneElement(genericForm, {
                  ...props,
                  mode: FormMode.CREATE
                })
            }
          }
        }
        rowMenuActions={(data: T, closeMenu) => (
          <Box>
            {viewDetailsPath && (
              <MenuItem
                onClick={() => {
                  data && history.push(viewDetailsPath(data));
                }}
              >
                <ListItemIcon style={{ minWidth: 36 }}>
                  {customIcons?.seeDetails || <EyeIcon color="primary" />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "primary" }}>
                  {labels?.seeDetails ?? tCommon("seeDetails")}
                </ListItemText>
              </MenuItem>
            )}
            {deactivate && onPatchOne && (
              <MenuItem
                onClick={() => {
                  onPatchOne({ ...data, enabled: !data.enabled });
                  closeMenu();
                }}
              >
                {data.enabled ? (
                  <>
                    <ListItemIcon>
                      <Cancel fontSize="small" color="error" />
                    </ListItemIcon>
                    <ListItemText>{tCommon("disable")}</ListItemText>
                  </>
                ) : (
                  <>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        htmlColor="#18A957"
                      />
                    </ListItemIcon>
                    <ListItemText>{tCommon("enable")}</ListItemText>
                  </>
                )}
              </MenuItem>
            )}
            {archive && (
              <MenuItem
                onClick={() => {
                  setDeletedId({
                    open: true,
                    data: data
                  });
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  {archive?.icon ?? (
                    <DeleteIcon fontSize="small" color="error" />
                  )}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "error" }}>
                  {labels?.delete ?? tCommon("delete")}
                </ListItemText>
              </MenuItem>
            )}
            {rowMenuActions && rowMenuActions(data, closeMenu)}
          </Box>
        )}
        columns={[...myColumns]}
        data={data ?? []}
        {...parentTableProps}
      />
      {deletedId.open && (
        <PreventArchivedDialogGeneric
          open={deletedId.open}
          onClose={() => setDeletedId({ open: false, data: undefined })}
          onConfirm={() => {
            onArchive({ id: deletedId?.data?.id } as Partial<T>);
            setDeletedId({ open: false, data: undefined });
          }}
          isActions
          content={
            labels?.preventRemoveContent
              ? typeof labels?.preventRemoveContent === "string"
                ? labels?.preventRemoveContent
                : labels?.preventRemoveContent(deletedId?.data as T)
              : undefined
          }
          title={labels?.preventRemoveTitle}
        />
      )}
    </>
  );
}
