import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { BoxCaseFormat } from "./type";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["BoxCaseFormat"]
});

const extendedApiSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getBoxCaseFormat: build.query<BoxCaseFormat[], void>({
      query: () => "/box-case-formats",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "BoxCaseFormat" as const,
                id
              })),
              { type: "BoxCaseFormat", id: "PARTIAL-LIST" }
            ]
          : [{ type: "BoxCaseFormat", id: "PARTIAL-LIST" }]
    }),
    createBoxCaseFormat: build.mutation<BoxCaseFormat, Partial<BoxCaseFormat>>({
      query(box) {
        return {
          url: "/box-case-formats",
          method: "POST",
          body: box
        };
      },
      invalidatesTags: ["BoxCaseFormat"],
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed")
            )
          );
        }
      }
    }),
    updateBoxCaseFormat: build.mutation<BoxCaseFormat, Partial<BoxCaseFormat>>({
      query(box) {
        const { id, ...patch } = box;
        return {
          url: `/box-case-formats/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      invalidatesTags: ["BoxCaseFormat"],
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed")
            )
          );
        }
      }
    })
  })
});

export const {
  useCreateBoxCaseFormatMutation,
  useGetBoxCaseFormatQuery,
  useUpdateBoxCaseFormatMutation
} = extendedApiSlice;
