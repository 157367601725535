import {
  SheetSize,
  SheetSizeCreateDTO,
  SheetSizeUpdateDTO
} from "../../entities/sheetsize";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum SheetSizeActionsTypes {
  SHEETSIZE_CREATE = "@@SHEETSIZE create sheetsize",
  SHEETSIZE_CREATE_STATUS = "@@SHEETSIZE create sheetsize status",
  SHEETSIZE_READ = "@@SHEETSIZE read sheetsize",
  SHEETSIZE_READ_STATUS = "@@SHEETSIZE read status sheetsize",
  SHEETSIZE_UPDATE = "@@SHEETSIZE update sheetsize",
  SHEETSIZE_UPDATE_STATUS = "@@SHEETSIZE update sheetsize status",
  SHEETSIZE_DELETE = "@@SHEETSIZE delete sheetsize",
  SHEETSIZE_DELETE_STATUS = "@@SHEETSIZE delete sheetsize status",
  SHEETSIZE_READ_ONE = "@@SHEETSIZE read one sheetsize",
  SHEETSIZE_READ_ONE_STATUS = "@@SHEETSIZE read one status sheetsize",
  SHEETSIZE_ADD = "@@SHEETSIZE add sheetsize",
  SHEETSIZE_REMOVE = "@@SHEETSIZE remove sheetsize"
}

export class SheetSizeCreate {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_CREATE;
  constructor(public sheetsize: SheetSizeCreateDTO) {}
}
export class SheetSizeCreateStatus {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_CREATE_STATUS;
  constructor(public status: FetchingStatus, public sheetsize?: SheetSize) {}
}

export class SheetSizeRead {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_READ;
}
export class SheetSizeReadStatus {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_READ_STATUS;
  constructor(public status: FetchingStatus, public sheetsizes?: SheetSize[]) {}
}

export class SheetSizeReadOne {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_READ_ONE;
  constructor(public id: string) {}
}
export class SheetSizeReadOneStatus {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_READ_ONE_STATUS;
  constructor(public status: FetchingStatus, public sheetsize?: SheetSize) {}
}

export class SheetSizeUpdate {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_UPDATE;
  constructor(public sheetsize: SheetSizeUpdateDTO) {}
}
export class SheetSizeUpdateStatus {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_UPDATE_STATUS;
  constructor(public status: FetchingStatus, public sheetsize?: SheetSize) {}
}

export class SheetSizeDelete {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_DELETE;
  constructor(public sheetsize: SheetSize) {}
}
export class SheetSizeDeleteStatus {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_DELETE_STATUS;
  constructor(public status: FetchingStatus, public sheetsize?: SheetSize) {}
}

export class SheetSizeAdd {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_ADD;
  constructor(public sheetsize: SheetSize) {}
}
export class SheetSizeRemove {
  readonly type = SheetSizeActionsTypes.SHEETSIZE_REMOVE;
  constructor(public sheetsize: SheetSize) {}
}

export type SheetSizeActions =
  | SheetSizeCreate
  | SheetSizeCreateStatus
  | SheetSizeRead
  | SheetSizeReadStatus
  | SheetSizeUpdate
  | SheetSizeUpdateStatus
  | SheetSizeDelete
  | SheetSizeDeleteStatus
  | SheetSizeReadOne
  | SheetSizeReadOneStatus
  | SheetSizeAdd
  | SheetSizeRemove;
