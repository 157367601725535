import { AppSetting } from "./../appSettings/entity";
export enum ConnectSettingNames {
  MAGENTO = "MAGENTO",
  WOOCOMMERCE = "WOOCOMMERCE",
  MICROSOFT = "MICROSOFT"
}

export interface ConnectSetting {
  id: string;
  name: string;
  enabled: boolean;
  env: Record<string, any>;
}

export interface ConnectSettingCreateDTO {}

export interface ConnectSettingUpdateDTO {
  id: string;
  enabled: boolean;
  env: Record<string, any>;
}

export const getSsoName = (setting: AppSetting) => {
  return setting.woocommerceToCart.enabled ? "woocommerce" : "magento";
};
