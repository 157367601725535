import { Role } from "../../entities/role";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum RoleActionsTypes {
  ROLE_FETCH = "@@ROLE fetch role",
  ROLE_FETCH_STATUS = "@@ROLE fetch status role"
}

export class RoleFetch {
  readonly type = RoleActionsTypes.ROLE_FETCH;
}
export class RoleStatus {
  readonly type = RoleActionsTypes.ROLE_FETCH_STATUS;
  constructor(public status: FetchingStatus, public roles?: Role[]) {}
}

export type RoleActions = RoleFetch | RoleStatus;
