import { IconButton, Theme, CircularProgress } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { MyMenu, MyMenuProps } from "./MyMenu";

export interface MyIconButtonProps extends IconButtonProps {
  to?: LinkProps["to"];
  href?: LinkProps["href"];
  linkProps?: Omit<LinkProps, "to">;
  icon?: React.ReactElement;
  loading?: boolean;
  menus?: (props: { onClose: () => void }) => ReactNode;
  myMenuProps?: Omit<MyMenuProps, "component" | "id" | "children">;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none"
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -16,
      marginLeft: -16
    }
  })
);

export const MyIconButton: React.FC<MyIconButtonProps> = ({
  to,
  href,
  children,
  linkProps,
  loading,
  menus,
  myMenuProps,
  ...rest
}) => {
  const classes = useStyle();

  let button = (
    <IconButton {...rest} disabled={rest.disabled || loading}>
      {children}
      {loading && (
        <CircularProgress
          data-testid="circular-component"
          size={32}
          className={classes.buttonProgress}
        />
      )}
    </IconButton>
  );
  if (to) {
    button = (
      <Link to={to} className={classes.link} {...linkProps}>
        {button}
      </Link>
    );
  } else if (href) {
    button = (
      <a href={href} className={classes.link} {...linkProps}>
        {button}
      </a>
    );
  } else if (menus) {
    button = (
      <MyMenu component={button} id="button-menu" {...myMenuProps}>
        {menus}
      </MyMenu>
    );
  }
  return button;
};
