import produce from "immer";
import { Company } from "../../entities/company";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ReducerMethods } from "../../utils/reducers/methods";
import { CompanyActions, CompanyActionsTypes } from "./action";

export interface CompanyState {
  companies: Company[];
  fetchStatus: FetchingStatus;
  fetchForUserStatus: FetchingStatus;
  fetchOneStatus: FetchingStatus;
  createStatus: FetchingStatus;
  createManagedByUserStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  archivedStatus: FetchingStatus;
}

const initialState: CompanyState = {
  companies: [],
  fetchStatus: FetchingStatus.NULL,
  fetchForUserStatus: FetchingStatus.NULL,
  fetchOneStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  createManagedByUserStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  archivedStatus: FetchingStatus.NULL
};

export const CompanyReducer = (state = initialState, action: CompanyActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CompanyActionsTypes.COMPANY_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.companies = action.companies || state.companies;
        break;
      case CompanyActionsTypes.COMPANY_FOR_USER_FETCH_STATUS:
        draft.fetchForUserStatus = action.status;
        ReducerMethods.pushArrayUniqueByMutate(
          draft.companies,
          action.companies || state.companies
        );
        break;
      case CompanyActionsTypes.COMPANY_FETCH_ONE_STATUS:
        draft.fetchOneStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.company)
          ReducerMethods.upsertByIdMutate(draft.companies, action.company);
        break;
      case CompanyActionsTypes.COMPANY_CREATE_STATUS:
        draft.createStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.company) {
          draft.companies.push(action.company);
        }
        break;
      case CompanyActionsTypes.COMPANY_CREATE_MANAGED_BY_USER_STATUS:
        draft.createManagedByUserStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.company) {
          draft.companies.push(action.company);
        }
        break;
      case CompanyActionsTypes.COMPANY_UPDATE_STATUS:
        draft.updateStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.company)
          ReducerMethods.updateByIdMutate(draft.companies, action.company);
        break;
      case CompanyActionsTypes.COMPANY_ARCHIVED_STATUS:
        draft.archivedStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.companyId)
          ReducerMethods.removeByIdMutate(draft.companies, {
            id: action.companyId
          });
        break;
      case CompanyActionsTypes.COMPANY_ADD_MANY:
        ReducerMethods.pushArrayUniqueByMutate(
          draft.companies,
          action.companies
        );
        break;
      case CompanyActionsTypes.COMPANY_UPSERT_ONE:
        ReducerMethods.upsertByIdMutate(draft.companies, action.company);
        break;
      // case CompanyActionsTypes.COMPANY_ADD_COLLABORATERS:
      //   draft.companies[action.companyIndex].collaboraterIds.push(
      //     ...action.collaboraterIds
      //   );
      //   break;
      // case CompanyActionsTypes.COMPANY_REMOVE_COLLABORATERS:
      //   draft.companies[action.companyIndex].collaboraterIds = fp.pullAll(
      //     action.collaboraterIndexes,
      //     draft.companies[action.companyIndex].collaboraterIds
      //   );
      //   break;
      default:
        return draft;
    }
  });
