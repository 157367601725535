import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";

export interface AppSettingStyle {
  primaryColor: string;
  secondaryColor: string;
  fontSize: number;
  customizeSSO: boolean;
  ssoLabel: DynamicJsonTranslator;
  ssoButtonColor: string;
  ssoTextColor: string;
  logoRedirection: string;
  appTitle: string;
}
export interface AppSettingErpLabel {
  externalCompanyLabel: string;
  externalProjectLabel: string;
  allowErpCompany: boolean;
  allowErpProject: boolean;
  allowExternalSalesErp: boolean;
}

export enum AppSettingFileType {
  LOGO = "logo",
  FONT = "font",
  FAVICON = "favIcon"
}
