import { Request } from "../../utils/request";
import { LinerCreateDTO, LinerUpdateDTO } from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-liners");

export const createOne = (dto: LinerCreateDTO) =>
  Request({ withToken: true }).post("/corrugated-liners", dto);

export const patchOne = ({ id, ...dto }: LinerUpdateDTO) =>
  Request({ withToken: true }).patch(`/corrugated-liners/${id}`, dto);
