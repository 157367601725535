import produce from "immer";
import { ProductCategory } from "../../entities/productCategory";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ProductCategoryActions, ProductCategoryActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface ProductCategoryState {
  productsCategory: ProductCategory[];
  fetchStatus: FetchingStatus;
}

const initialState: ProductCategoryState = {
  productsCategory: [],
  fetchStatus: FetchingStatus.NULL
};

export const ProductCategoryReducer = (
  state = initialState,
  action: ProductCategoryActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ProductCategoryActionsTypes.PRODUCT_CATEGORY_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.productsCategory =
          action.productsCategory || state.productsCategory;
        break;
      case ProductCategoryActionsTypes.PRODUCT_CATEGORY_ADD:
        ReducerMethods.pushUniqueByMutate<ProductCategory>(
          draft.productsCategory,
          action.productCategory
        );
        break;
      default:
        return state;
    }
  });
