import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import * as Api from "./api";
import { TechnicalSettingsActionsTypes } from "./action";
import { TechnicalSetting } from "../../entities/technicalSettings";

export interface TechnicalSettingState {
  fetchStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  createStatus: FetchingStatus;
  technicalSettings: TechnicalSetting[];
}

export const TechnicalSettingInitialState: TechnicalSettingState = {
  fetchStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  technicalSettings: []
};

export const read = createMyAsyncThunk<TechnicalSetting[]>(
  TechnicalSettingsActionsTypes.READ,
  Api.read
);

export const patchOne = createMyAsyncThunk(
  TechnicalSettingsActionsTypes.PATCH_ONE,
  Api.patchOne,
  {
    onSuccessMessage: "saga:update-success"
  }
);

const TechnicalSettingSlice = createMySlice({
  name: "technical-settings",
  initialState: TechnicalSettingInitialState,
  asyncActions: [
    {
      action: read,
      statusName: "fetchStatus",
      onSuccess: (state, action) => {
        state.technicalSettings = action.payload;
      }
    },
    {
      action: patchOne,
      statusName: "patchStatus",
      onSuccess: (state, action) => {
        state.technicalSettings = state.technicalSettings.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      }
    }
  ],
  reducers: {}
});

export const TechnicalSettingReducer = TechnicalSettingSlice.reducer;
export const TechnicalSettingActions = {
  ...TechnicalSettingSlice.actions,
  async: { read, patchOne }
};
