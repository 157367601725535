import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CorrugatedMaterialActionsTypes } from "./action";
import * as Api from "./api";
import { CorrugatedMaterial } from "./entity";

export interface CorrugatedMaterialState
  extends EntityState<CorrugatedMaterial> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
}

export const CorrugatedMaterialInitialState: CorrugatedMaterialState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const CorrugatedMaterialAdapter = createEntityAdapter<CorrugatedMaterial>();

const CorrugatedMaterialAdapterState = CorrugatedMaterialAdapter.getInitialState(
  CorrugatedMaterialInitialState
);

export const read = createMyAsyncThunk<CorrugatedMaterial[]>(
  CorrugatedMaterialActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  CorrugatedMaterialActionsTypes.CREATE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);

export const update = createMyAsyncThunk(
  CorrugatedMaterialActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

const CorrugatedMaterialSlice = createMySlice({
  name: "corrugated-materials",
  initialState: CorrugatedMaterialAdapterState,
  adapter: CorrugatedMaterialAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: CorrugatedMaterialAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: CorrugatedMaterialAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: CorrugatedMaterialAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const CorrugatedMaterialReducer = CorrugatedMaterialSlice.reducer;
export const CorrugatedMaterialActions = {
  ...CorrugatedMaterialSlice.actions,
  read,
  create,
  update
};
