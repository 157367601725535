export interface dynamicInput {
  page: number;
  limit: number;
  order?: string;
  sort?: string;
  status?: string | string[];
  search?: string;
  price?: string | boolean;
  userId?: string;
  role?: string;
  taskType?: string;
  user?: string;
  date?: string;
  start?: string;
  end?: string;
  tab?: string | null;
  companyId?: string;
  pipeline?: string;
  step?: string;
  filterId?: string;
  manager?: string;
  createdBy?: string;
}

export const dynamicUrl = (prefix: string, param: dynamicInput): string => {
  const { limit, page, tab, companyId, ...params } = param;
  const currentParams = Object.entries(params).reduce(
    (params, [key, value]) => (value ? `${params}&${key}=${value}` : params),
    ""
  );

  const forCompany =
    typeof companyId === "string" ? `&company=${companyId}` : "";

  const hasTab = tab ? `tab=${param.tab}&` : "";

  return prefix
    ? `${prefix}?${hasTab}page=${page}&limit=${limit}${currentParams}${forCompany}`
    : `?${hasTab}page=${page}&limit=${limit}${currentParams}${forCompany}`;
};

export const setCrmFilterUrl = (param: any): string => {
  const { limit, page, tab, ...params } = param;

  const currentParams = Object.entries(params)
    .reduce((params, [key, value]) => {
      if (value) {
        params.push(`${key}=${value}`);
      }
      return params;
    }, [] as string[])
    .join("&");

  return `${currentParams}`;
};
