import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";

export enum LinerCategory {
  TEST = "Test",
  KRAFT = "Kraft",
  OTHER = "Other"
}

export enum LinerName {
  BLEACHED_KRAFT = "BLEACHED_KRAFT",
  BLEACHED_TEST = "BLEACHED_TEST",
  KRAFT = "KRAFT",
  RECYCLED = "RECYCLED",
  TEST = "TEST",
  WHITE_TOP_KRAFT = "WHITE_TOP_KRAFT",
  WHITE_TOP_TEST = "WHITE_TOP_TEST"
}

export interface Liner {
  id: string;
  name: string;
  label: DynamicJsonTranslator;
  category: LinerCategory;
  inkPorosity: number;
  varnishPorosity: number;
}

export interface LinerTranslated extends Omit<Liner, "label"> {
  label: string;
}

export type LinerCreateDTO = Omit<Liner, "id">;
export type LinerUpdateDTO = Partial<Liner>;
