import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../../entities/user";

interface ContactState {
  selectedCompanyId: string | null;
}

const initialState: ContactState = {
  selectedCompanyId: null
};

export const ContactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<string | null>) => {
      state.selectedCompanyId = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompanyId = null;
    },
    selectCompanyOnLogin: (state, action: PayloadAction<User>) => {
      const currentUser = action.payload;
      if (
        !currentUser.collaborations ||
        currentUser.collaborations?.length < 2
      ) {
        state.selectedCompanyId = null; // reset company (no need)
      }

      if (
        currentUser.collaborations &&
        currentUser.collaborations.length > 1 &&
        (!state.selectedCompanyId ||
          !currentUser.collaborations.some(
            (company) => company.id === state.selectedCompanyId
          ))
      ) {
        state.selectedCompanyId = currentUser.collaborations[0].id; // arbitrary select first company
      }
    }
  }
  // doc 2024
  // selectors: {
  //   getSelectedCompany: (state) => state.selectedCompany
  // }
});

// export const { getSelectedCompany } = ContactSlice.selectors; // doc 2024

export const {
  setSelectedCompany,
  clearSelectedCompany,
  selectCompanyOnLogin
} = ContactSlice.actions;

export const getSelectedCompany = (state: {
  contact: { selectedCompany: any };
}) => state.contact.selectedCompany;

export default ContactSlice.reducer;
