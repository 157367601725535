export enum SettingActionTypes {
  DARK_MODE = "[ Setting ] dark mode"
}

export class ChangeThemeAction {
  readonly type = SettingActionTypes.DARK_MODE;
  constructor(public theme: "dark" | "light") {}
}

export type SettingActions = ChangeThemeAction;
