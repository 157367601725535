import { Request } from "../../utils/request";
import {
  CorrugatedMaterialCreateDTO,
  CorrugatedMaterialUpdateDTO
} from "../corrugated-material/entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-suppliers");

export const createOne = (dto: CorrugatedMaterialCreateDTO) =>
  Request({ withToken: true }).post("/corrugated-suppliers", dto);

export const patchOne = ({ id, ...dto }: CorrugatedMaterialUpdateDTO) =>
  Request({ withToken: true }).patch(`/corrugated-suppliers/${id}`, dto);
