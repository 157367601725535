import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { AppReducer, AppState } from "./app/reducer";
import { AppSettingReducer, AppSettingState } from "./appSettings/reducer";
import {
  AuthenticationReducer,
  AuthenticationState
} from "./authentication/reducer";
import { AuthenticationSaga } from "./authentication/saga";
import {
  BriefConfiguratorReducer,
  BriefConfiguratorState
} from "./briefConfigurator/reducer";
import { BriefConfiguratorSaga } from "./briefConfigurator/saga";
import {
  BriefElementConfiguratorReducer,
  BriefElementConfiguratorState
} from "./briefElementConfigurator/reducer";
import { BriefElementConfiguratorSaga } from "./briefElementConfigurator/saga";
import { BriefReducer, BriefState } from "./briefs/reducer";
import { BriefSaga } from "./briefs/saga";
import {
  BriefComputedReducer,
  BriefComputedState
} from "./briefsComputed/reducer";
import { BriefStatusReducer, BriefStatusState } from "./briefStatuses/reducer";
import { CompanyReducer, CompanyState } from "./companies/reducer";
import { CompanySaga } from "./companies/saga";
import {
  ConfiguratorInputOptionReducer,
  ConfiguratorInputOptionState
} from "./configurator-input-options/reducer";
import {
  ConfiguratorInputReducer,
  ConfiguratorInputState
} from "./configurator-inputs/reducer";
import {
  ConnectSettingReducer,
  ConnectSettingState
} from "./connectSettings/reducer";
import {
  ContactInformationReducer,
  ContactInformationState
} from "./contactInformations/reducer";
import {
  CorrugatedCompositionReducer,
  CorrugatedCompositionState
} from "./corrugated-composition/reducer";
import {
  CorrugatedMaterialReducer,
  CorrugatedMaterialState
} from "./corrugated-material/reducer";
import {
  CorrugatedSupplierReducer,
  CorrugatedSupplierState
} from "./corrugated-supplier/reducer";
import { FileReducer, FileState } from "./files/reducer";
import {
  BasicVarnishReducer,
  BasicVarnishState
} from "./finishes/basicVarnish/reducer";
import {
  LaminationReducer,
  LaminationState
} from "./finishes/lamination/reducer";
import { LaminationSaga } from "./finishes/lamination/saga";
import {
  LuxuryVarnishReducer,
  LuxuryVarnishState
} from "./finishes/luxuryVarnish/reducer";
import { FluteReducer, FluteState } from "./flutes/reducer";
import { FormatReducer, FormatState } from "./format/reducer";
import { InkReducer, InkState } from "./ink/reducer";
import { LinerReducer, LinerState } from "./liners/reducer";
import {
  MachineOperationRateReducer,
  MachineOperationRateState
} from "./machine-operation-rates/reducer";
import { MachineRateReducer, MachineRateState } from "./machineRate/reducer";
import {
  MachineRateCoefficientSetReducer,
  MachineRateCoefficientSetState
} from "./machineRatesCoefficientSets/reducer";
import { MachineReducer, MachineState } from "./machines/reducer";
import { MachineSaga } from "./machines/saga";
import {
  MaterialReferenceReducer,
  MaterialReferenceState
} from "./materialReference/reducer";
import { MaterialReferenceSaga } from "./materialReference/saga";
import {
  MaterialReferenceSheetSizeReducer,
  MaterialReferenceSheetSizeState
} from "./materialReferenceSheetSize/reducer";
import { MaterialReferenceSheetSizeSaga } from "./materialReferenceSheetSize/saga";
import { MaterialTypeReducer, MaterialTypeState } from "./materialType/reducer";
import { MaterialTypeSaga } from "./materialType/saga";
import { MatterReducer, MatterState } from "./matter/reducer";
import { MatterSaga } from "./matter/saga";
import { OperationReducer, OperationState } from "./operations/reducer";
import {
  PredictionRequestReducer,
  PredictionRequestState
} from "./predictionRequest/reducer";
import { PredictionRequestSaga } from "./predictionRequest/saga";
import {
  ProductMachineOperationRateReducer,
  ProductMachineOperationRateState
} from "./product-machine-operation-rates/reducer";
import {
  ProductProviderOperationRateReducer,
  ProductProviderOperationRateState
} from "./product-provider-operation-rates/reducer";
import {
  ProductCategoryReducer,
  ProductCategoryState
} from "./productCategory/reducer";
import { ProductCategorySaga } from "./productCategory/saga";
import { ProductReducer, ProductState } from "./products/reducer";
import { ProjectReducer, ProjectState } from "./projects/reducer";
import { ProjectSaga } from "./projects/saga";
import {
  ProviderOperationRateReducer,
  ProviderOperationRateState
} from "./provider-operation-rates/reducer";
import {
  ProviderRateReducer,
  ProviderRateState
} from "./provider-rate/reducer";
import { ProviderReducer, ProviderState } from "./providers/reducer";
import {
  PurchaseSettingReducer,
  PurchaseSettingState
} from "./purchaseSettings/reducer";
import {
  QuoteSettingReducer,
  QuoteSettingState
} from "./quoteSettings/reducer";
import { RoleReducer, RoleState } from "./roles/reducer";
import { RoleSaga } from "./roles/saga";
import { SellSettingsReducer, SellSettingsState } from "./sellSettings/reducer";
import { SettingReducer, SettingState } from "./settings/reducer";
import { SheetSizeReducer, SheetSizeState } from "./sheetsizes/reducer";
import { SheetSizeSaga } from "./sheetsizes/saga";
import { TopBarReducer, TopBarState } from "./topbar/reducer";
import { UserReducer, UserState } from "./users/reducer";
import { UserSaga } from "./users/saga";
import { WindowReducer, WindowState } from "./window/reducer";
import { WindowSaga } from "./window/saga";
import {
  TechnicalSettingReducer,
  TechnicalSettingState
} from "./technicalSettings/reducer";
import { apiSlice } from "../utils/api/api";
import { CadSourceReducer, CadSourceState } from "./cadSources/reducer";
import {
  CorrugatedMaterialPriceReducer,
  CorrugatedMaterialPriceState
} from "./corrugated-material-price/reducer";
import ContactReducer from "./contact/contact.slice";

export const RootReducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authentication: AuthenticationReducer,
    roles: RoleReducer,
    users: UserReducer,
    app: AppReducer,
    settings: SettingReducer,
    topbar: TopBarReducer,
    briefConfigurator: BriefConfiguratorReducer,
    briefElementConfigurator: BriefElementConfiguratorReducer,
    products: ProductReducer,
    productsCategory: ProductCategoryReducer,
    briefs: BriefReducer,
    windows: WindowReducer,
    technicalSettings: TechnicalSettingReducer,
    matters: MatterReducer,
    materialTypes: MaterialTypeReducer,
    materialReferences: MaterialReferenceReducer,
    materialReferenceSheetSizes: MaterialReferenceSheetSizeReducer,
    sheetsizes: SheetSizeReducer,
    basicVarnishes: BasicVarnishReducer,
    laminations: LaminationReducer,
    luxuryVarnishes: LuxuryVarnishReducer,
    predictionRequests: PredictionRequestReducer,
    briefComputeds: BriefComputedReducer,
    machines: MachineReducer,
    providers: ProviderReducer,
    operations: OperationReducer,
    machinesOperationsRates: MachineOperationRateReducer,
    machineOperationRates: MachineOperationRateReducer,
    providerOperationRates: ProviderOperationRateReducer,
    productMachineOperationRates: ProductMachineOperationRateReducer,
    productProviderOperationRates: ProductProviderOperationRateReducer,
    machineRates: MachineRateReducer,
    providerRates: ProviderRateReducer,
    projects: ProjectReducer,
    configuratorInputOptions: ConfiguratorInputOptionReducer,
    configuratorInputs: ConfiguratorInputReducer,
    companies: CompanyReducer,
    connectSettings: ConnectSettingReducer,
    liners: LinerReducer,
    flutes: FluteReducer,
    formats: FormatReducer,
    corrugatedSuppliers: CorrugatedSupplierReducer,
    corrugatedMaterials: CorrugatedMaterialReducer,
    corrugatedCompositions: CorrugatedCompositionReducer,
    corrugatedMaterialPrices: CorrugatedMaterialPriceReducer,
    inks: InkReducer,
    files: FileReducer,
    appSettings: AppSettingReducer,
    briefStatuses: BriefStatusReducer,
    sellSettings: SellSettingsReducer,
    quoteSettings: QuoteSettingReducer,
    contactInformations: ContactInformationReducer,
    machineRatesCoefficientSets: MachineRateCoefficientSetReducer,
    purchaseSettings: PurchaseSettingReducer,
    cadSources: CadSourceReducer,
    contact: ContactReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  });

export function* RootSaga() {
  yield all([
    ...AuthenticationSaga,
    ...RoleSaga,
    ...UserSaga,
    ...ProductCategorySaga,
    ...WindowSaga,
    ...BriefSaga,
    ...MatterSaga,
    ...MaterialTypeSaga,
    ...MaterialReferenceSaga,
    ...MaterialReferenceSheetSizeSaga,
    ...LaminationSaga,
    ...PredictionRequestSaga,
    ...MachineSaga,
    ...SheetSizeSaga,
    ...BriefElementConfiguratorSaga,
    ...BriefConfiguratorSaga,
    ...ProjectSaga,
    ...CompanySaga
  ]);
}

export type RootState = {
  router: RouterState;
  authentication: AuthenticationState;
  roles: RoleState;
  users: UserState;
  app: AppState;
  settings: SettingState;
  topbar: TopBarState;
  briefConfigurator: BriefConfiguratorState;
  briefElementConfigurator: BriefElementConfiguratorState;
  products: ProductState;
  productsCategory: ProductCategoryState;
  briefs: BriefState;
  windows: WindowState;
  technicalSettings: TechnicalSettingState;
  matters: MatterState;
  materialTypes: MaterialTypeState;
  materialReferences: MaterialReferenceState;
  materialReferenceSheetSizes: MaterialReferenceSheetSizeState;
  sheetsizes: SheetSizeState;
  basicVarnishes: BasicVarnishState;
  laminations: LaminationState;
  luxuryVarnishes: LuxuryVarnishState;
  predictionRequests: PredictionRequestState;
  briefComputeds: BriefComputedState;
  machines: MachineState;
  providers: ProviderState;
  operations: OperationState;
  machineRates: MachineRateState;
  providerRates: ProviderRateState;
  machineOperationRates: MachineOperationRateState;
  providerOperationRates: ProviderOperationRateState;
  productMachineOperationRates: ProductMachineOperationRateState;
  productProviderOperationRates: ProductProviderOperationRateState;
  projects: ProjectState;
  configuratorInputOptions: ConfiguratorInputOptionState;
  configuratorInputs: ConfiguratorInputState;
  companies: CompanyState;
  connectSettings: ConnectSettingState;
  liners: LinerState;
  flutes: FluteState;
  formats: FormatState;
  corrugatedSuppliers: CorrugatedSupplierState;
  corrugatedMaterials: CorrugatedMaterialState;
  corrugatedCompositions: CorrugatedCompositionState;
  corrugatedMaterialPrices: CorrugatedMaterialPriceState;
  inks: InkState;
  files: FileState;
  appSettings: AppSettingState;
  quoteSettings: QuoteSettingState;
  contactInformations: ContactInformationState;
  purchaseSettings: PurchaseSettingState;
  briefStatuses: BriefStatusState;
  sellSettings: SellSettingsState;
  machineRatesCoefficientSets: MachineRateCoefficientSetState;
  cadSources: CadSourceState;
  contact: ReturnType<typeof ContactReducer>;
};
