import {
  ConfiguratorInputNames,
  EXTERNAL_INPUT_PREFIX
} from "../configurator-inputs/constant";
import {
  DecorationsProperties,
  FinishesProperties,
  WindowInputs
} from "./constant";
import { BriefElementConfiguratorValues } from "./reducer";
import { FomulaFunction } from "./types";

// In other terms: inputs that have no precondition different than "true" (this include external inputs)
export const notRootInput = (inputName: string): boolean =>
  !["wantDecoration", "wantProtection", "wantFinishes"].includes(inputName) &&
  (WindowInputs.includes(inputName) ||
    DecorationsProperties.includes(inputName as ConfiguratorInputNames) ||
    FinishesProperties.includes(inputName as ConfiguratorInputNames));

export const getFormulaFunction = (formula?: string): FomulaFunction => {
  if (!formula) return () => false;
  return new Function("values", `return (${formula});`) as FomulaFunction;
};

export const getInputNamesFromValues = (
  values: BriefElementConfiguratorValues
) => {
  const inputNames = Object.keys(values).filter((key) => key !== "external");
  if (values?.external) {
    Object.keys(values.external).forEach((key) => {
      inputNames.push(EXTERNAL_INPUT_PREFIX + key);
    });
  }
  return inputNames;
};
