import { Request } from "../../utils/request";
import { ConnectSettingUpdateDTO } from "./entity";

export const read = () => Request({ withToken: true }).get("/connect-settings");

export const readPublic = () =>
  Request({ withToken: true }).get("/connect-settings/public");

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const patchOne = ({ id, ...dto }: ConnectSettingUpdateDTO) =>
  Request({ withToken: true }).patch(`/connect-settings/${id}`, dto);
