import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { MatterActionsTypes, MatterStatus } from "./action";
import * as Api from "./api";

export function* fetchMatters() {
  yield put(new MatterStatus(FetchingStatus.PENDING));
  try {
    const { data: matters } = yield call(Api.fetchMatters);
    yield put(new MatterStatus(FetchingStatus.SUCCESS, matters));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("matters:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new MatterStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("matters:fetchFailed")));
  }
}

export const MatterSaga = [
  takeLatest(MatterActionsTypes.MATTER_FETCH, fetchMatters)
];
