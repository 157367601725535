import { TaskType } from "./../tasksTypes/type";
import { DynamicJsonTranslator } from "./../../utils/function/jsonTranslator";
import { Brief } from "../../entities/brief";
import { Company } from "../../entities/company";
import { Project } from "../../entities/project";
import { Submission } from "../../entities/submission";
import { User } from "../../entities/user";
import { File } from "../../reducers/files/entity";
import { Comment } from "../comments/type";
import { Status } from "../status/type";
import { Task } from "../tasks/type";
import { TaskUser } from "../taskUsers/type";
import {
  Notification,
  NottificationSettingsCategoriesEnum
} from "../notifications/notifications.type";
import { Call } from "../call/type";
import { Meeting } from "../meeting/type";
import { Visio } from "../visio/type";
import { Email } from "../emails/email.type";
import { Pipeline, Step } from "../opportunity/type";

export enum EventType {
  Create = "CREATE",
  Update = "UPDATE",
  Delete = "DELETE"
}

export enum EntityType {
  Tasks = "TASKS",
  Briefs = "BRIEFS",
  Projects = "PROJECTS",
  Submissions = "SUBMISSIONS",
  Companies = "COMPANIES",
  Comment = "COMMENT",
  File = "FILE",
  TaskUsers = "TASK_USER",
  Status = "STATUS",
  TaskType = "TASK_TYPE",
  User = "USER",
  Call = "CALL",
  Meeting = "MEETING",
  Visio = "VISIO",
  /*   COMPANY_CONTACT = "COMPANY_CONTACT",
  COMPANY_MANAGER = "COMPANY_MANAGER", */
  Mail = "MAIL",
  OpportunityPipeline = "OPPORTUNITY_PIPELINE",
  OpportunityStep = "OPPORTUNITY_STEP"
}

export enum EntityTypeCurrent {
  "Briefs" = "BRIEFS",
  "Projects" = "PROJECTS",
  "Submissions" = "SUBMISSIONS",
  "Tasks" = "TASKS"
}

export const eventTypeAccess = {
  [EntityType.Tasks]: "task",
  [EntityType.Briefs]: "brief",
  [EntityType.Projects]: "project",
  [EntityType.Companies]: "company",
  [EntityType.User]: "user"
};

// Future add new entity for status
export const entityStatusesType = [
  EntityTypeCurrent.Briefs,
  EntityTypeCurrent.Tasks,
  EntityTypeCurrent.Submissions,
  EntityTypeCurrent.Projects
];

export interface EntityEventRelations {
  user?: User;
  task?: Task;
  taskUser?: TaskUser;
  status?: Status;
  comment?: Comment;
  taskType?: TaskType;
  company?: Company;
  project?: Project;
  brief?: Brief;
  visio?: Visio;
  call?: Call;
  meeting?: Meeting;
  submission?: Submission;
  managedBy?: User;
  contact?: User;
  manager?: User;
  email?: Email;
  opportunityPipeline?: Pipeline;
  opportunityStep?: Step;
}

export interface EntityEventWCode {
  task?: Task;
  company?: Company;
  project?: Project;
  brief?: Brief;
  submission?: Submission;
}

export interface Event extends EntityEventRelations {
  id: string;
  code: string;
  type: EventType;
  category: NottificationSettingsCategoriesEnum;
  entity: EntityType;
  relation?: EntityType;
  payload?: Record<string, any>;
  snapshot?: Record<string, any>;
  fieldName?: string;
  taskId?: string;
  taskUserId?: string;
  commentId?: string;
  briefId?: string;
  projectId?: string;
  fileIds?: string[];
  files?: File[];
  fileId?: string;
  file?: File;
  submissionId?: string;
  companyId?: string;
  userId: string;
  statusId?: string;
  createdAt: Date;
  updatedAt: Date;
  relationLabel?: DynamicJsonTranslator;
  relationLabelPrev?: DynamicJsonTranslator;
  taskTypeId?: string;
  notifications?: Notification[];
  callId?: string;
  meetingId?: string;
  visioId?: string;
  managedById?: string;
  contactId?: string;
  managerId?: string;
  emailId?: string;
  email?: Email;
  opportunityPipelineId?: string;
  opportunityStepId?: string;
}
