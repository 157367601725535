import { Box, createStyles, InputLabel, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { FormHelperTextProps } from "@material-ui/core/FormHelperText";
import FormLabel, { FormLabelProps } from "@material-ui/core/FormLabel";
import { InputLabelProps } from "@material-ui/core/InputLabel";
import { FormikErrors, FormikTouched } from "formik";
import React, { useState } from "react";
import { useHover } from "../../../../utils/hooks/useHover";
import { ArrowTooltip } from "../../MyTooltip";
import { MyInputBox, MyInputBoxProps } from "../MyInputBox";
import { MyFormHelperText } from "./MyFormHelperText";

export interface CustomHelperProps {
  radio?: string;
}

export interface FormProps {
  label: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  formLabelProps?: InputLabelProps | FormLabelProps;
  formHelperTextProps?: FormHelperTextProps | CustomHelperProps;
}

export interface MyFormControlProps extends FormProps, FormControlProps {
  labelRef?: React.RefObject<HTMLLabelElement>;
  labelWidth?: number;
  showComponentLabel?: boolean;
  toolTipInlineWLabel?: boolean;
  isAnInputLabel?: boolean;
  errorField?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  touchField?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  inputBoxProps?: MyInputBoxProps;
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      background: "white",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "90%",
      height: "18px"
    },
    shrinked: {
      maxWidth: "100% !important",
      transitionDelay: "0ms",
      // transitionProperty: "max-width",
      transitionProperty: "maxWidth"
    },
    asterisk: {
      position: "absolute",
      right: 0
    }
  })
);

export const MyFormControl: React.FC<MyFormControlProps> = ({
  label,
  helperText,
  labelRef,
  children,
  showComponentLabel = true,
  toolTipInlineWLabel = false,
  isAnInputLabel,
  formLabelProps,
  formHelperTextProps,
  touchField,
  errorField,
  inputBoxProps,
  ...formControlProps
}) => {
  const classes = useStyles();
  const [ellipsis, setEllipsis] = useState(false);
  const [hoverRef, isHovered] = useHover();

  return (
    <ArrowTooltip
      disableHoverListener={true}
      disableTouchListener={ellipsis}
      title={<div>{label}</div>}
      open={ellipsis && isHovered}
      placement="top"
    >
      <FormControl
        ref={hoverRef}
        fullWidth={true}
        variant="outlined"
        margin="dense"
        style={{
          display: formControlProps.hidden ? "none" : "",
          position: "relative",
          ...((formHelperTextProps as CustomHelperProps)?.radio && {
            marginBottom: "10px"
          })
        }}
        {...formControlProps}
      >
        {showComponentLabel ? (
          isAnInputLabel ? (
            <InputLabel
              style={{
                color: formControlProps.disabled ? grey[600] : "black"
              }}
              /* shrink={true} */
              classes={{
                shrink: classes.shrinked,
                ...(ellipsis && { asterisk: classes.asterisk })
              }}
              className={classes.label}
              error={!!errorField && !!touchField}
              ref={(ref) => {
                if (!ref) return;
                let clientWidth = ref.clientWidth;
                if (formLabelProps?.required && ellipsis) {
                  clientWidth = ref.clientWidth - 5;
                }
                const isOverflowing = ref.scrollWidth > clientWidth;
                if (isOverflowing) {
                  setEllipsis(true);
                } else {
                  setEllipsis(false);
                }
              }}
              {...formLabelProps}
            >
              {label}
            </InputLabel>
          ) : (
            <Box style={{ display: "flex", alignItems: "center" }}>
              {toolTipInlineWLabel ? (
                <>
                  <FormLabel
                    error={!!errorField && !!touchField}
                    style={{
                      color: formControlProps.disabled ? grey[600] : "black",
                      background: "white"
                    }}
                    {...formLabelProps}
                  >
                    {label}
                  </FormLabel>
                  <MyInputBox
                    styleProps={{ flexDirection: "row-reverse" }}
                    fullWidth={true}
                    {...inputBoxProps}
                  >
                    <></>
                  </MyInputBox>
                </>
              ) : (
                <FormLabel
                  error={!!errorField && !!touchField}
                  style={{
                    color: formControlProps.disabled ? grey[600] : "black",
                    background: "white"
                  }}
                  {...formLabelProps}
                >
                  {label}
                </FormLabel>
              )}
            </Box>
          )
        ) : null}
        {children}
        <MyFormHelperText
          label={label}
          errorField={errorField}
          touchField={touchField}
          {...formHelperTextProps}
        >
          {helperText}
        </MyFormHelperText>
      </FormControl>
    </ArrowTooltip>
  );
};
