import {
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { MyButton } from "../../../components/common/MyButton";
import { Slide } from "./slide";

const useStyles = makeStyles(() =>
  createStyles({
    view: {
      margin: 0,
      width: "100%"
    },
    viewTitle: {
      fontWeight: 600
    },
    button: {
      minWidth: "100px"
    },
    img: {
      width: "100%"
    }
  })
);

export interface TextImageViewProps {
  view: Slide;
  onMobile: boolean;
  navigationLabel: string;
  navigationFunction: React.MouseEventHandler<HTMLButtonElement>;
  navigationIcon?: React.ReactElement;
}

export const TextImageView: React.FC<TextImageViewProps> = ({
  view,
  onMobile,
  navigationLabel,
  navigationFunction,
  navigationIcon
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      key={view.position}
      alignItems={onMobile ? "stretch" : "center"}
      direction={onMobile ? "column-reverse" : "row"}
      className={classes.view}
      spacing={2}
    >
      <Grid item md={5} xs={12}>
        <Typography variant="h4" className={classes.viewTitle}>
          {view.title}
        </Typography>
        <Box mt={4}>
          <Typography style={{ whiteSpace: "pre-line" }}>
            {view.text}
          </Typography>
        </Box>
        <Box mt={4} display={onMobile ? "block" : "flex"}>
          {!onMobile && (
            <MyButton
              color="primary"
              variant="contained"
              rightIcon={navigationIcon}
              className={classes.button}
              onClick={navigationFunction}
            >
              {navigationLabel}
            </MyButton>
          )}
          {view.buttonLink && view.buttonLabel && (
            <Box ml={onMobile ? 0 : 2}>
              <Link
                href={view.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MyButton
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  fullWidth={onMobile}
                >
                  {view.buttonLabel}
                </MyButton>
              </Link>
            </Box>
          )}
        </Box>
      </Grid>
      {view.image && (
        <Grid item md={7} xs={12}>
          <img alt="illustration" src={view.image} className={classes.img} />
        </Grid>
      )}
    </Grid>
  );
};
