import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import {
  MaterialTypeActionsTypes,
  MaterialTypeCreate,
  MaterialTypeCreateStatus,
  MaterialTypeStatus,
  MaterialTypeUpdate,
  MaterialTypeUpdateStatus
} from "./action";
import * as Api from "./api";
import { AppAddSnackbar } from "../app/action";

export function* fetchMaterialTypes() {
  yield put(new MaterialTypeStatus(FetchingStatus.PENDING));
  try {
    const { data: materialTypes } = yield call(Api.fetchMaterialTypes);
    yield put(new MaterialTypeStatus(FetchingStatus.SUCCESS, materialTypes));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialTypes:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new MaterialTypeStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("materialTypes:fetchFailed"))
    );
  }
}

export function* createMaterialTypes(action: MaterialTypeCreate) {
  yield put(new MaterialTypeCreateStatus(FetchingStatus.PENDING));
  try {
    const { data: materialType } = yield call(
      Api.createMaterialType,
      action.materialType
    );
    yield put(
      new MaterialTypeCreateStatus(FetchingStatus.SUCCESS, materialType)
    );
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MaterialTypeCreateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* updateMaterialTypes(action: MaterialTypeUpdate) {
  yield put(new MaterialTypeUpdateStatus(FetchingStatus.PENDING));
  try {
    const { data: materialType } = yield call(
      Api.updateMaterialTypes,
      action.materialType
    );
    yield put(
      new MaterialTypeUpdateStatus(FetchingStatus.SUCCESS, materialType)
    );
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MaterialTypeUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export const MaterialTypeSaga = [
  takeLatest(MaterialTypeActionsTypes.MATERIAL_TYPE_FETCH, fetchMaterialTypes),
  takeLatest(
    MaterialTypeActionsTypes.MATERIAL_TYPE_CREATE,
    createMaterialTypes
  ),
  takeLatest(
    MaterialTypeActionsTypes.MATERIAL_TYPE_REQUEST_UPDATE,
    updateMaterialTypes
  )
];
