import * as Sentry from "@sentry/react";
import { push } from "connected-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { Brief } from "../../entities/brief";
import { Company } from "../../entities/company";
import { Project, ProjectWithRelations } from "../../entities/project";
import { User } from "../../entities/user";
import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import { getCurrentUser } from "../authentication/selector";
import { BriefsAddMany } from "../briefs/action";
import { briefRedistribution } from "../briefs/saga";
import { getBriefsByProjectId } from "../briefs/selector";
import { CompanyAddMany } from "../companies/action";
import { FileActions } from "../files/reducer";
import { UserAddMany } from "../users/action";
import {
  ProjectActionsTypes,
  ProjectArchived,
  ProjectArchivedStatus,
  ProjectCreate,
  ProjectCreateForCompany,
  ProjectCreateForCompanyStatus,
  ProjectCreateManaged,
  ProjectCreateManagedStatus,
  ProjectCreateStatus,
  ProjectDelete,
  ProjectDeleteFile,
  ProjectDeleteFileStatus,
  ProjectDeleteStatus,
  ProjectDownloadFile,
  ProjectFetchById,
  ProjectFetchByIdStatus,
  ProjectFetchStatus,
  ProjectForUserFetchStatus,
  ProjectGetFiles,
  ProjectGetFilesStatus,
  ProjectUpdate,
  ProjectUpdateStatus,
  ProjectUploadFile,
  ProjectUploadFileStatus
} from "./action";
import * as Api from "./api";
import { projectApiSlice } from "../../services/project/project.service";
import { navigateToProjectTabSecure } from "../../pages/BackOffice/Projects/utils/projectTabNavigation";

export function* fetchProjects() {
  yield put(new ProjectFetchStatus(FetchingStatus.PENDING));
  try {
    const { data: projects } = yield call(Api.read);
    yield put(new ProjectFetchStatus(FetchingStatus.SUCCESS, projects));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new ProjectFetchStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:projects.fetchFailed"))
    );
  }
}

export function* fetchProjectsForUser() {
  yield put(new ProjectForUserFetchStatus(FetchingStatus.PENDING));
  const currentUser: User = yield select(getCurrentUser);
  try {
    const { data } = yield call(Api.readForUser, currentUser);

    const users: Map<string, User> = new Map();
    const companies: Map<string, Company> = new Map();
    const projects: Map<string, Project> = new Map();
    for (const project of data as Project[]) {
      const { user, company, manager, ...restProject } = project;
      user && users.set(user.id, user);
      manager && users.set(manager.id, manager);
      company && companies.set(company.id, company);
      projects.set(restProject.id, restProject);
    }

    yield put(
      new ProjectForUserFetchStatus(
        FetchingStatus.SUCCESS,
        Array.from(projects.values())
      )
    );
    yield put(new UserAddMany(Array.from(users.values())));
    yield put(new CompanyAddMany(Array.from(companies.values())));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new ProjectForUserFetchStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:projects.fetchFailed"))
    );
  }
}

export function* fetchProjectById(action: ProjectFetchById) {
  yield put(new ProjectFetchByIdStatus(FetchingStatus.PENDING));
  try {
    const { data: projectData } = yield call(Api.fetchProjectId, action.id);
    const {
      briefs,
      files,
      company,
      user,
      manager,
      ...project
    } = projectData as ProjectWithRelations;

    if (briefs) {
      const briefFiltered: Brief[] = yield briefRedistribution(briefs);
      yield put(new BriefsAddMany(briefFiltered as Array<Brief>));
    }
    if (files) {
      for (const { updatedBy, ...file } of files) {
        if (updatedBy) yield put(new UserAddMany([updatedBy]));
        yield put(FileActions.addMany([file]));
      }
    }
    if (user) yield put(new UserAddMany([user]));
    if (manager) yield put(new UserAddMany([manager]));
    if (company) yield put(new CompanyAddMany([company]));
    yield put(new ProjectFetchByIdStatus(FetchingStatus.SUCCESS, project));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:getByIdFailed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectFetchByIdStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:projects.getByIdFailed"))
    );
  }
}

export function* updateProject(action: ProjectUpdate) {
  yield put(new ProjectUpdateStatus(FetchingStatus.PENDING));
  try {
    const {
      data: { company, ...newProject }
    } = yield call(Api.updateProject, action.projectId, action.project);
    yield put(new ProjectUpdateStatus(FetchingStatus.SUCCESS, newProject));
    yield put(new CompanyAddMany([company]));
    yield put(
      projectApiSlice.util.invalidateTags([
        { type: "Project", id: newProject.id },
        { type: "MyProjects", id: newProject.id },
        { type: "Projects", id: newProject.id }
      ])
    );
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:update-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* createProject(action: ProjectCreate) {
  yield put(new ProjectCreateStatus(FetchingStatus.PENDING));
  try {
    const { data: createdProject } = yield call(
      Api.createProject,
      action.project
    );
    yield put(new ProjectCreateStatus(FetchingStatus.SUCCESS, createdProject));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
    yield put(push(navigateToProjectTabSecure(createdProject.id)));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectCreateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

// TODO can be refactor with create Project and pre-assign api call endpoint
// based on who is the user behind
export function* createManagedProject(action: ProjectCreateManaged) {
  yield put(new ProjectCreateManagedStatus(FetchingStatus.PENDING));
  const user: User = yield select(getCurrentUser);
  try {
    const { data: createdProject } = yield call(
      Api.createManagedProject,
      user,
      action.project
    );
    yield put(
      new ProjectCreateManagedStatus(FetchingStatus.SUCCESS, createdProject)
    );
    yield put(push(navigateToProjectTabSecure(createdProject.id)));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:create-managed-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectCreateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* createProjectForCompany(action: ProjectCreateForCompany) {
  yield put(new ProjectCreateForCompanyStatus(FetchingStatus.PENDING));
  try {
    const { data: createdProject } = yield call(
      Api.createForCompany,
      action.companyId,
      action.project
    );

    yield put(
      new ProjectCreateForCompanyStatus(FetchingStatus.SUCCESS, createdProject)
    );
    yield put(push(navigateToProjectTabSecure(createdProject.id)));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:create-for-company-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectCreateForCompanyStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:create-for-company-failed"))
    );
  }
}

export function* deleteProject(action: ProjectDelete) {
  yield put(new ProjectDeleteStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.deleteProject, action.projectId);
    yield put(new ProjectDeleteStatus(FetchingStatus.SUCCESS));
    yield put(new AppAddSnackbar(i18n.t("saga:delete-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:delete-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectDeleteStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:delete-failed")));
  }
}

export function* archived(action: ProjectArchived) {
  yield put(new ProjectArchivedStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.archived, action.projectId);
    yield put(push(action.redirect));
    yield put(
      new ProjectArchivedStatus(FetchingStatus.SUCCESS, action.projectId)
    );
    // Update data based on the project archived logic
    // Remove all project id props to linked briefs
    const briefs: Brief[] = yield select(
      getBriefsByProjectId(action.projectId)
    );
    yield put(
      new BriefsAddMany(briefs.map((b) => ({ ...b, projectId: null })))
    );
    yield put(
      new AppAddSnackbar(
        i18n.t("saga:archived-success", { name: i18n.t("common:project") }),
        "success"
      )
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:archivedFailed");
      Sentry.captureException(error);
    });
    yield put(new ProjectArchivedStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(
        error,
        i18n.t("saga:archived-failed", { name: i18n.t("common:project") })
      )
    );
  }
}
export function* getFiles(action: ProjectGetFiles) {
  try {
    const { data: files } = yield call(Api.getFiles, action.projectId);
    yield put(new ProjectGetFilesStatus(FetchingStatus.SUCCESS));
    yield put(FileActions.addMany(files));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:getFilesFailed");
      Sentry.captureException(error);
    });
    yield put(new ProjectGetFilesStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(
        error,
        i18n.t("saga:read-failed", { name: i18n.t("common:project") })
      )
    );
  }
}

export function* uploadFile(action: ProjectUploadFile) {
  try {
    const { data: file } = yield call(
      Api.uploadFile,
      action.projectId,
      action.formdata,
      action.onUploadProgress
    );
    yield put(new ProjectUploadFileStatus(FetchingStatus.SUCCESS));
    yield put(FileActions.addMany([file]));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:uploadFileFailed");
      Sentry.captureException(error);
    });
    yield put(new ProjectUploadFileStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(
        error,
        i18n.t("saga:create-failed", { name: i18n.t("common:project") })
      )
    );
  }
}

export function* downloadFile(action: ProjectDownloadFile) {
  const { data: url } = yield call(
    Api.downloadFile,
    action.projectId,
    action.fileId,
    action.preview
  );
  window.open(url, action.preview ? "_blank" : "_self");
}

export function* deleteFile(action: ProjectDeleteFile) {
  yield put(new ProjectDeleteFileStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.deleteFile, action.projectId, action.fileId);
    yield put(new ProjectDeleteFileStatus(FetchingStatus.SUCCESS));
    yield put(FileActions.removeOne(action.fileId));
    yield put(new AppAddSnackbar(i18n.t("saga:delete-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("projects:delete-file-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new ProjectDeleteFileStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:delete-failed")));
  }
}

export function* deleteProjectCallback(action: ProjectDeleteStatus) {
  if (action.status === FetchingStatus.SUCCESS && action.callback) {
    yield call(action.callback());
  }
}

export const ProjectSaga = [
  takeLatest(ProjectActionsTypes.PROJECT_FETCH, fetchProjects),
  takeLatest(ProjectActionsTypes.PROJECT_FOR_USER_FETCH, fetchProjectsForUser),
  takeLatest(ProjectActionsTypes.PROJECT_FETCH_ID, fetchProjectById),
  takeLatest(ProjectActionsTypes.PROJECT_UPDATE, updateProject),
  takeLatest(ProjectActionsTypes.PROJECT_CREATE, createProject),
  takeLatest(ProjectActionsTypes.PROJECT_CREATE_MANAGED, createManagedProject),
  takeLatest(
    ProjectActionsTypes.PROJECT_CREATE_FOR_COMPANY,
    createProjectForCompany
  ),
  takeLatest(ProjectActionsTypes.PROJECT_DELETE, deleteProject),
  takeLatest(ProjectActionsTypes.PROJECT_ARCHIVED, archived),
  // TODO what is that ?
  takeLatest(ProjectActionsTypes.PROJECT_DELETE_STATUS, deleteProjectCallback),
  // FILES
  takeEvery(ProjectActionsTypes.PROJECT_UPLOAD_FILE, uploadFile),
  takeLatest(ProjectActionsTypes.PROJECT_DOWNLOAD_FILE, downloadFile),
  takeLatest(ProjectActionsTypes.PROJECT_DELETE_FILE, deleteFile),
  takeLatest(ProjectActionsTypes.PROJECT_GET_FILES_STATUS, getFiles)
];
