import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MachineRateActionsTypes } from "./action";
import * as Api from "./api";
import { MachineRate } from "./entity";

export interface MachineRateState extends EntityState<MachineRate> {
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
}

export const MachineRateInitialState: MachineRateState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL
};

export const MachineRateAdapter = createEntityAdapter<MachineRate>();

export const read = createMyAsyncThunk<MachineRate[]>(
  MachineRateActionsTypes.READ,
  Api.read
);

export const updateOne = createMyAsyncThunk<MachineRate, MachineRate>(
  MachineRateActionsTypes.UPDATE_ONE,
  Api.updateOne,
  { onSuccessMessage: "saga:update-success" }
);

const MachineRateSlice = createMySlice({
  name: "machine-rates",
  initialState: MachineRateAdapter.getInitialState(MachineRateInitialState),
  adapter: MachineRateAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: MachineRateAdapter.upsertMany
    },
    {
      action: updateOne,
      statusName: "updateStatus",
      onSuccess: MachineRateAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const MachineRateReducer = MachineRateSlice.reducer;
export const MachineRateActions = {
  ...MachineRateSlice.actions,
  async: {
    read,
    updateOne
  }
};
