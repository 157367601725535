/* eslint-disable no-sequences */
import { array, number, object } from "yup";
import i18n from "../../../../../../utils/i18n";
import { setYupLocale } from "../../../../../../utils/yup";
import { ConfiguratorInputOptionComputed } from "../../../../../../reducers/configurator-input-options/selector";
import { get } from "lodash";
import { QuantityCount } from "../../../../../../entities/quantityCount";

setYupLocale();

export const PurchaseConditionSchema = {
  decorationsCount: array()
    .min(1)
    .max(12)
    .required()
    .of(
      object({
        quantity: number()
          .typeError(() => i18n.t("yup:mixed.required"))
          .min(0)
          .integer()
          .required()
      })
    )
    .test({
      name: "decorationsCount",
      test: function (decorationsCount?: QuantityCount[]) {
        const { options, parent, createError, path } = this;

        //Check format: number of decoration counts according to referencesCount (should not happen in frontend)
        const referencesCount = get(parent, "referencesCount") || 0;
        if (!decorationsCount) {
          return createError({
            message: i18n.t("yup:mixed.required")
          });
        }

        if (
          referencesCount > 0 &&
          decorationsCount.length !== referencesCount
        ) {
          return createError({
            path,
            message: `should have ${referencesCount} decoration counts`
          });
        }

        if (!referencesCount && decorationsCount.length !== 1) {
          return createError({
            path,
            message: "should have one decoration count"
          });
        }

        //Check sum: min max if specified in input options
        const inputOption = get(
          options.context,
          "configuratorInputOptions.decorationsCount"
        ) as ConfiguratorInputOptionComputed | undefined;
        if (!inputOption?.options) return true;
        const { min, max } = inputOption.options;

        let error = undefined;
        const sum = decorationsCount.reduce(
          (sum, count) => (count.quantity ? sum + count.quantity : sum),
          0
        );
        if (min !== undefined && sum < min) {
          error = i18n.t("yup:number.minWithName", {
            min,
            name: i18n.t("configurator:purchaseCondition.form.theTotalQuantity")
          });
        } else if (max !== undefined && max < sum) {
          error = i18n.t("yup:number.maxWithName", {
            max,
            name: i18n.t("configurator:purchaseCondition.form.theTotalQuantity")
          });
        }

        if (error) {
          const pathError =
            decorationsCount.length === 1 ? `${path}.0.quantity` : path;
          return createError({
            path: pathError,
            message: error
          });
        }

        return true;
      }
    })
};
