export enum CorrugatedMaterialPriceActionsTypes {
  READ = "@@CORRUGATED_MATERIAL_PRICE read corrugated price",
  READ_STATUS = "@@CORRUGATED_MATERIAL_PRICE read status corrugated price",
  CREATE = "@@CORRUGATED_MATERIAL_PRICE create corrugated price",
  CREATE_STATUS = "@@CORRUGATED_MATERIAL_PRICE create status corrugated price",
  UPDATE = "@@CORRUGATED_MATERIAL_PRICE update corrugated price",
  UPDATE_STATUS = "@@CORRUGATED_MATERIAL_PRICE update status corrugated price",
  DELETE = "@@CORRUGATED_MATERIAL_PRICE delete corrugated price",
  DELETE_STATUS = "@@CORRUGATED_MATERIAL_PRICE delete status corrugated price",
  CREATE_MANY = "@@CORRUGATED_MATERIAL_PRICE create many corrugated price",
  CREATE_MANY_STATUS = "@@CORRUGATED_MATERIAL_PRICE create many status corrugated price"
}
