import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { Request } from "../../utils/request";

export const fetchUsers = () => Request({ withToken: true }).get("/users");

export const fetchOne = (id: string, qb: string) =>
  Request({ withToken: true }).get(`/users/${id}/?${qb}`);

export const fetchContactUsers = (userId: string) =>
  Request({ withToken: true }).get(`/users/${userId}/contacts`);

export const fetchOneContact = (userId: string) =>
  Request({ withToken: true }).get(`/users/${userId}/contact`);

export const inviteUser = (inviteUser: Partial<User>) =>
  Request({ withToken: true }).post("/users/invite", inviteUser);

export const replaceOne = (userId: string, user: Partial<User>) =>
  Request({ withToken: true }).put<User>(`/users/${userId}`, user);

export const patchOne = (userId: string, user: Partial<User>) =>
  Request({ withToken: true }).patch<User>(`/users/${userId}`, user);

export const updatePassword = (userId: string, user: Partial<User>) =>
  Request({ withToken: true }).patch<User>(`/users/${userId}/password`, user);

// Contact
export const createContact = (user: Partial<User>) =>
  Request({ withToken: true }).post(`/users/contacts`, user);

export const updateContact = (userId: string, user: Partial<User>) =>
  Request({ withToken: true }).patch<User>(`/users/${userId}/contacts`, user);

// Project
export const createProject = (userId: string, project: Partial<Project>) =>
  Request({ withToken: true }).post(`/users/${userId}/projects`, project);

export const archived = (userId: string) =>
  Request({ withToken: true }).delete(`/users/${userId}/archived`);
