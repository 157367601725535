import { Form } from "formik";
import React, { useEffect } from "react";
import {
  NotificationFilter,
  notificationGroupedOptions
} from "../../../../services/notifications/notifications.type";
import { MyAutocompleteField } from "../../../../components/common/form/MyAutocompleteField";
import { useTranslation } from "react-i18next";
import { List } from "@material-ui/core";

interface NotificationFilterProps {
  values: NotificationFilter;
  setFilters: React.Dispatch<React.SetStateAction<NotificationFilter>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  onClose?: () => void;
}

export const NotificationActionFilter: React.FC<NotificationFilterProps> = ({
  values,
  setFieldValue,
  setFilters,
  onClose
}) => {
  const { t } = useTranslation("backoffice");

  useEffect(() => {
    setFilters({
      ...values
    });
  }, [setFilters, values]);

  return (
    <Form style={{ width: "100%" }}>
      <MyAutocompleteField
        isClearable
        onClear={() => {
          setFieldValue("category", undefined);
          return undefined;
        }}
        isAbsolute={true}
        value={values?.category}
        label={t("notifications.inApp.filter.category")}
        name="category"
        options={notificationGroupedOptions() as any}
        components={{
          MenuList: (props: any) => (
            <List style={{ maxHeight: 400, overflowY: "auto" }}>
              {props.children}
            </List>
          )
        }}
      />
    </Form>
  );
};
