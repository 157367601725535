import { EditNameValues } from "../../components/common/form/action/EditNamedEntityForm";
import { Brief, BriefWithRelations } from "../../entities/brief";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum BriefActionsTypes {
  BRIEF_FETCH = "@@BRIEF fetch brief ",
  BRIEF_FETCH_STATUS = "@@BRIEF status brief ",
  BRIEF_CREATE = "@@BRIEF create brief ",
  BRIEF_CREATE_STATUS = "@@BRIEF create brief status ",
  BRIEF_SELECTED = "@@BRIEF selected",
  BRIEF_GET_BY_ID = "@@BRIEF get by id brief ",
  BRIEF_GET_BY_ID_STATUS = "@@BRIEF get by id brief status ",
  BRIEF_DUPLICATE_AND_EDIT = "@@BRIEF duplicate and edit",
  BRIEF_ADD = "@@BRIEF add brief",
  BRIEF_EDIT_NAME = "@@BRIEF edit name",
  BRIEF_EDIT_NAME_STATUS = "@@BRIEF edit name status",
  BRIEF_UPDATE = "@@BRIEF update",
  BRIEF_UPDATE_STATUS = "@@BRIEF update status",
  BRIEF_OPEN_ORDER_SUCCESS = "@@BRIEF open order success",
  BRIEF_OPEN_SAVE_BRIEF_SUCCESS = "@@BRIEF open save brief success",
  BRIEF_ORDER = "@@BRIEF order",
  BRIEF_ORDER_STATUS = "@@BRIEF order status",
  BRIEF_FINALIZE_ORDER = "@@BRIEF finalize order",
  BRIEF_FINALIZE_ORDER_STATUS = "@@BRIEF finalize order status",
  BRIEF_ASSIGN_PROJECT = "@@BRIEF assign project",
  BRIEF_ASSIGN_PROJECT_STATUS = "@@BRIEF assign project status",
  BRIEF_FOR_USER_FETCH = "@@BRIEF user fetch ",
  BRIEF_FOR_USER_FETCH_STATUS = "@@BRIEF user fetch status",
  BRIEF_ADD_MANY = "@@BRIEF add many briefs"
}

export class BriefFetch {
  readonly type = BriefActionsTypes.BRIEF_FETCH;
}
export class BriefFetchStatus {
  readonly type = BriefActionsTypes.BRIEF_FETCH_STATUS;
  constructor(public status: FetchingStatus, public briefs?: Brief[]) {}
}

export class BriefCreate {
  readonly type = BriefActionsTypes.BRIEF_CREATE;
}
export class BriefCreateStatus {
  readonly type = BriefActionsTypes.BRIEF_CREATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public brief?: BriefWithRelations
  ) {}
}

export class BriefSelected {
  readonly type = BriefActionsTypes.BRIEF_SELECTED;
  constructor(readonly brief?: BriefWithRelations) {}
}

export class BriefGetByIdWithRelations {
  readonly type = BriefActionsTypes.BRIEF_GET_BY_ID;
  constructor(public id: string) {}
}

export class BriefGetByIdStatus {
  readonly type = BriefActionsTypes.BRIEF_GET_BY_ID_STATUS;
  constructor(
    public status: FetchingStatus,
    public brief?: BriefWithRelations
  ) {}
}

export class BriefAdd {
  readonly type = BriefActionsTypes.BRIEF_ADD;
  constructor(public brief: Brief) {}
}

export class BriefDuplicateAndEdit {
  readonly type = BriefActionsTypes.BRIEF_DUPLICATE_AND_EDIT;
  constructor(public brief: Brief) {}
}

export class BriefEditName {
  readonly type = BriefActionsTypes.BRIEF_EDIT_NAME;
  constructor(public values: EditNameValues) {}
}
export class BriefEditNameStatus {
  readonly type = BriefActionsTypes.BRIEF_EDIT_NAME_STATUS;
  constructor(public status: FetchingStatus, public values?: EditNameValues) {}
}

export class BriefUpdate {
  readonly type = BriefActionsTypes.BRIEF_UPDATE;
  constructor(public briefId: string, public partialBrief: Partial<Brief>) {}
}
export class BriefUpdateStatus {
  readonly type = BriefActionsTypes.BRIEF_UPDATE_STATUS;
  constructor(public status: FetchingStatus, public brief?: Brief) {}
}

export class BriefOrder {
  readonly type = BriefActionsTypes.BRIEF_ORDER;
  constructor(public brief: Brief) {}
}
export class BriefOrderStatus {
  readonly type = BriefActionsTypes.BRIEF_ORDER_STATUS;
  constructor(public status: FetchingStatus, public brief?: Brief) {}
}

export class BriefFinalizeOrder {
  readonly type = BriefActionsTypes.BRIEF_FINALIZE_ORDER;
  constructor(
    public brief: Brief,
    public cmsType: string,
    public setIsFinalisingOrder?: any
  ) {}
}
export class BriefFinalizeOrderStatus {
  readonly type = BriefActionsTypes.BRIEF_FINALIZE_ORDER_STATUS;
  constructor(public status: FetchingStatus, public brief?: Brief) {}
}

// TODO later if save brief has something to do
// export class BriefOrder {
//   readonly type = BriefActionsTypes.BRIEF_ORDER;
//   constructor(public brief: Brief) {}
// }

export class BriefAssignProject {
  readonly type = BriefActionsTypes.BRIEF_ASSIGN_PROJECT;
  constructor(public brief: Brief, public projectId?: string) {}
}

export class BriefAssignProjectStatus {
  readonly type = BriefActionsTypes.BRIEF_ASSIGN_PROJECT_STATUS;
  constructor(
    public status: FetchingStatus,
    public id?: string,
    public projectId?: string
  ) {}
}

export class BriefForUserFetch {
  readonly type = BriefActionsTypes.BRIEF_FOR_USER_FETCH;
}
export class BriefForUserFetchStatus {
  readonly type = BriefActionsTypes.BRIEF_FOR_USER_FETCH_STATUS;
  constructor(
    public readonly status: FetchingStatus,
    public readonly briefs?: Array<Brief>
  ) {}
}

export class BriefsAddMany {
  readonly type = BriefActionsTypes.BRIEF_ADD_MANY;
  constructor(public briefs: Brief[]) {}
}

export type BriefActions =
  | BriefFetch
  | BriefFetchStatus
  | BriefCreate
  | BriefSelected
  | BriefCreateStatus
  | BriefGetByIdWithRelations
  | BriefGetByIdStatus
  | BriefAdd
  | BriefEditName
  | BriefEditNameStatus
  | BriefUpdate
  | BriefUpdateStatus
  | BriefOrder
  | BriefOrderStatus
  | BriefFinalizeOrder
  | BriefFinalizeOrderStatus
  | BriefAssignProject
  | BriefAssignProjectStatus
  | BriefForUserFetch
  | BriefForUserFetchStatus
  | BriefsAddMany;
