import React from "react";
import { Grid, Box, BoxProps } from "@material-ui/core";

interface MaterialTableActionsProps extends BoxProps {}

export const MaterialTableActions: React.FC<MaterialTableActionsProps> = ({
  children,
  ...props
}) => {
  return (
    <Box p={2} py={1} clone {...props}>
      <Grid container alignItems="center">
        {children}
      </Grid>
    </Box>
  );
};
