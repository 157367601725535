import {
  Badge,
  Box,
  createStyles,
  Divider,
  Hidden,
  Link,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  SwipeableDrawer,
  Theme,
  Typography
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InfoIcon from "@material-ui/icons/Info";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import BackOfficeIcon from "@material-ui/icons/SupervisedUserCircle";
import BugReportOutlinedIcon from "@material-ui/icons/BugReportOutlined";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { showReportDialog, captureMessage } from "@sentry/react";
import { getAppState, getLanguage } from "../../../reducers/app/selector";
import { LogOutFetchAction } from "../../../reducers/authentication/action";
import {
  getCurrentUser,
  getEachUserRoleStatus
} from "../../../reducers/authentication/selector";
import { jsonTranslator } from "../../../utils/function/jsonTranslator";
import { MyButton } from "../MyButton";
import { MyIconButton } from "../MyIconButton";
import { MyLink } from "../MyLink";
import { useHistory } from "react-router-dom";
import { MyAvatar } from "../User/MyAvatar";
import { getUserDisplayName } from "../../../utils/function/transformEntityToOptions";
import { User } from "../../../entities/user";
import { LanguageSelector } from "./components/LanguagesSelector";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import {
  DRAWER_DESKTOP_WIDTH,
  DRAWER_MOBILE_WIDTH,
  DRAWER_TABLET_WIDTH
} from "../../../utils/constant";
import { InAppNotifications } from "../../../pages/BackOffice/Notifications";
import { useGetUserNotificationsQuery } from "../../../services/notifications/notifications.service";
import { RootState } from "../../../reducers";
import { CursorBasedMetaDto } from "../../../services/common/types";
import {
  intialNotificationFilters,
  NotificationFilter
} from "../../../services/notifications/notifications.type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      "& > *": {
        margin: theme.spacing(1)
      }
    },
    medium: {
      background: "rgba(77, 124, 254, 0.4)",
      width: theme.spacing(5),
      height: theme.spacing(5)
    },
    small: {
      background: "rgba(77, 124, 254, 0.4)",
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    tiny: {
      background: "rgba(77, 124, 254, 0.4)",
      width: "28px",
      height: "28px"
    },
    arrow: {
      marginLeft: "-8px",
      color: "#757575"
    },
    swipeableDrawer: {
      width: DRAWER_DESKTOP_WIDTH,
      [theme.breakpoints.down("md")]: {
        width: DRAWER_TABLET_WIDTH,
        flexShrink: 0
      },
      [theme.breakpoints.down("sm")]: {
        width: DRAWER_MOBILE_WIDTH,
        flexShrink: 0
      }
    }
  })
);

export const BackofficeComponent: React.FC<{
  admin: boolean;
  onClose?: () => void;
  t: TFunction;
  style?: React.CSSProperties;
}> = ({ admin, onClose, t, style }) => (
  <MyButton
    style={{ ...style }}
    onClick={() => {
      onClose && onClose();
    }}
    leftIcon={<BackOfficeIcon style={{ height: "24px", width: "28px" }} />}
    to={`/back-office/${admin ? "projects" : "my-projects"}`}
  >
    {t("myTopbar.backoffice")}
  </MyButton>
);

const LogoutComponent: React.FC<{
  onClose?: () => void;
  t: TFunction;
  loggout: () => void;
  style?: React.CSSProperties;
}> = ({ onClose, t, loggout, style }) => (
  <MyButton
    style={{ ...style }}
    leftIcon={<ExitToAppIcon style={{ height: "24px", width: "28px" }} />}
    onClick={() => {
      loggout();
      onClose && onClose();
    }}
  >
    {t("myTopbar.logout")}
  </MyButton>
);

const SettingComponent: React.FC<{
  onClose?: () => void;
  t: TFunction;
  access: () => void;
  style?: React.CSSProperties;
}> = ({ onClose, t, access, style }) => (
  <MyButton
    style={{ ...style }}
    leftIcon={<AccountCircleIcon style={{ height: "24px", width: "28px" }} />}
    onClick={() => {
      access();
      onClose && onClose();
    }}
  >
    {t("myTopbar.profile")}
  </MyButton>
);

const ProfileComponent: React.FC<{
  t: TFunction;
  loggout: () => LogOutFetchAction;
  redirectToProfile: () => void;
  user: User;
  showBackOffice?: boolean;
  isAdmin?: boolean;
  isProfile?: boolean;
}> = ({
  t,
  loggout,
  user,
  showBackOffice,
  isAdmin,
  redirectToProfile,
  isProfile
}) => {
  const lang = useSelector(getLanguage);
  const history = useHistory();

  const classes = useStyles();
  const displayName = getUserDisplayName(user, true);
  const { t: tCommon } = useTranslation("common");

  return (
    <MyButton
      disableRipple
      menuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }}
      endIcon={<ArrowDropDownIcon className={classes.arrow} />}
      style={{
        padding: "0 8px"
      }}
      menus={({ onClose }) => (
        <MenuList disablePadding style={{ minWidth: 200, outline: "none" }}>
          <MyAvatar
            innerText={displayName.initials}
            size="tiny"
            style={{ fontWeight: "500" }}
            primaryInfo={displayName.name}
            url={user?.avatar?.url}
            secondaryInfo={
              user?.job
                ? user?.job
                : jsonTranslator(
                    user.roles.length ? user.roles[0].label : tCommon("guest"),
                    lang
                  )
            }
          />
          <Divider light />
          {isProfile && (
            <SettingComponent
              t={t}
              access={redirectToProfile}
              onClose={onClose}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%"
              }}
            />
          )}
          {/*      {isAdmin && (
            <MyButton
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%"
              }}
              leftIcon={
                <SettingsIcon style={{ height: "24px", width: "28px" }} />
              }
              onClick={() => {
                history.push("/back-office/user-settings");
                onClose();
              }}
            >
              {t("myTopbar.settings") as string}
            </MyButton>
          )} */}
          <LogoutComponent
            t={t}
            loggout={loggout}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%"
            }}
          />
          {showBackOffice && (
            <BackofficeComponent
              admin={isAdmin!}
              t={t}
              onClose={onClose}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%"
              }}
            />
          )}
        </MenuList>
      )}
    >
      <MyAvatar
        innerText={displayName?.initials}
        size="small"
        url={user?.avatar?.url}
      />
    </MyButton>
  );
};

const NotificationComponent: React.FC<{
  isResponsive?: boolean;
}> = ({ isResponsive }) => {
  const user = useSelector(getCurrentUser);

  const [cursor, setCursor] = useState<string>("");

  // Filters
  const [
    actionNotfication,
    setActionNotifications
  ] = useState<NotificationFilter>({
    ...intialNotificationFilters
  });

  const requestArgs: Partial<CursorBasedMetaDto> = {
    cursor,
    userId: user.id,
    ...(actionNotfication.category && {
      category: actionNotfication.category
    })
  };

  const [openMenu, setOpenMenu] = React.useState(false);
  const iOS =
    (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const classes = useStyles();
  const handleDrawerToggle = (open?: boolean) => {
    setOpenMenu(open === undefined ? !openMenu : open);
  };

  const { data: notifications } = useGetUserNotificationsQuery(requestArgs);

  return (
    <>
      <MyButton
        style={{
          ...(isResponsive && {
            display: "flex",
            justifyContent: "flex-start"
          }),
          marginLeft: "-1rem"
        }}
        onClick={() => {
          handleDrawerToggle();
        }}
      >
        <Badge badgeContent={notifications?.meta?.unreadCount} color="primary">
          <NotificationsNoneIcon style={{ width: "28px", height: "24px" }} />
        </Badge>
      </MyButton>
      {notifications && (
        <SwipeableDrawer
          anchor="right"
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openMenu}
          variant="temporary"
          ModalProps={{
            keepMounted: true,
            BackdropProps: {
              invisible: true
            }
          }}
          onClose={() => handleDrawerToggle()}
          onOpen={() => handleDrawerToggle(true)}
          classes={{ paper: classes.swipeableDrawer }}
        >
          <InAppNotifications
            notifications={notifications?.data}
            meta={notifications?.meta}
            setCursor={setCursor}
            onCloseSwipeable={() => handleDrawerToggle()}
            actionNotfication={actionNotfication}
            setActionNotifications={setActionNotifications}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

const HelpComponent: React.FC<{
  userLogged: boolean;
  onClose?: () => void;
  t: TFunction;
  pathname: string;
  isResponsive?: boolean;
}> = ({ userLogged, onClose, t, pathname, isResponsive }) => {
  const { t: tCommon } = useTranslation("common");
  return (
    <MyButton
      style={{
        ...(isResponsive && { display: "flex", justifyContent: "flex-start" })
      }}
      menuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }}
      onClick={() => {
        onClose && onClose();
      }}
      menus={({ onClose }) => (
        <MenuList disablePadding style={{ minWidth: 200, outline: "none" }}>
          {userLogged && pathname.startsWith("/back-office") && (
            <MyLink to="?demo" disableUnderline>
              <MenuItem
                onClick={() => {
                  onClose && onClose();
                }}
              >
                <ListItemIcon>
                  <InfoIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography color="textPrimary">
                  {t("myTopbar.helpOptions.tour")}
                </Typography>
              </MenuItem>
            </MyLink>
          )}
          <Link
            href="https://help-hipe.packitoo.com/"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <MenuItem
              onClick={() => {
                onClose && onClose();
              }}
            >
              <ListItemIcon>
                <HelpOutlineIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography color="textPrimary">
                {t("myTopbar.helpOptions.helpCenter")}
              </Typography>
            </MenuItem>
          </Link>
          <Link
            href="https://hipe.packitoo.com/en/request-demo/"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <MenuItem
              onClick={() => {
                onClose && onClose();
              }}
            >
              <ListItemIcon>
                <SendIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography color="textPrimary">
                {t("myTopbar.helpOptions.contactUs")}
              </Typography>
            </MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              showReportDialog({
                eventId: captureMessage("User Feedback"),
                title: t("myTopbar.helpOptions.reportBug"),
                subtitle: "",
                subtitle2: "",
                labelSubmit: tCommon("submit")
              });
              onClose && onClose();
            }}
          >
            <ListItemIcon>
              <BugReportOutlinedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography color="textPrimary">
              {t("myTopbar.helpOptions.reportBug")}
            </Typography>
          </MenuItem>
        </MenuList>
      )}
    >
      <HelpOutlineIcon
        style={{ ...(isResponsive && { width: "28px", height: "24px" }) }}
      />
    </MyButton>
  );
};

export const ProjectLinkButton = () => {
  const { t: tCommon } = useTranslation("common");
  return (
    <Box mx={1}>
      <MyButton
        variant="contained"
        to="/back-office/my-projects"
        color="primary"
      >
        {tCommon("projects")}
      </MyButton>
    </Box>
  );
};

export interface MyTopBarCommonMenuProps {
  onClose?: () => void;
}

export const MyTopBarCommonMenu: React.FC<MyTopBarCommonMenuProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const userRoleStatus = useSelector(getEachUserRoleStatus);
  const dispatch = useDispatch();
  const { isOnBackOffice } = useSelector(getAppState);
  const { t } = useTranslation("components");
  const { pathname } = useLocation();
  const history = useHistory();

  const features = useSelector(
    (state: RootState) => state.appSettings.setting.features!
  );

  const loggout = () => dispatch(new LogOutFetchAction());

  const redirectToProfile = (): void => {
    history.push("/back-office/user-profile");
  };

  const showBackOffice =
    !isOnBackOffice && currentUser.roles && currentUser.roles.length > 0;
  const showLogout = currentUser.token;

  return (
    <>
      <Hidden smUp>
        {!!currentUser.token &&
          (userRoleStatus.isAdmin || userRoleStatus.external_sales) &&
          features.isNotificationInApp && <NotificationComponent />}
        <MyIconButton
          data-testid="topbar-menu-icon-button"
          menus={({ onClose }) => (
            <MenuList>
              <MenuItem>
                {userRoleStatus.isAdmin || userRoleStatus.external_sales ? (
                  <HelpComponent
                    userLogged={!!currentUser.token}
                    onClose={onClose}
                    t={t}
                    pathname={pathname}
                    isResponsive
                  />
                ) : isOnBackOffice || !currentUser.token ? null : (
                  <ProjectLinkButton />
                )}
              </MenuItem>
              <MenuItem>
                <LanguageSelector onClose={onClose} isResponsive />
              </MenuItem>
              {showBackOffice && (
                <MenuItem>
                  <BackofficeComponent
                    admin={userRoleStatus.isAdmin}
                    onClose={onClose}
                    t={t}
                  />
                </MenuItem>
              )}
              {showLogout &&
                (!userRoleStatus.guest || !userRoleStatus.isCmsUser) && (
                  <MenuItem
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <SettingComponent
                      onClose={onClose}
                      t={t}
                      access={redirectToProfile}
                    />
                  </MenuItem>
                )}
              {showLogout && (
                <MenuItem
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <LogoutComponent onClose={onClose} t={t} loggout={loggout} />
                </MenuItem>
              )}
            </MenuList>
          )}
        >
          <MoreVertIcon />
        </MyIconButton>
      </Hidden>
      <Hidden xsDown>
        {userRoleStatus.isAdmin || userRoleStatus.external_sales ? (
          <HelpComponent
            userLogged={!!currentUser.token}
            t={t}
            pathname={pathname}
          />
        ) : isOnBackOffice || !currentUser.token ? null : (
          <ProjectLinkButton />
        )}
        <LanguageSelector />
        {showBackOffice && (
          <BackofficeComponent
            admin={userRoleStatus.isAdmin}
            t={t}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%"
            }}
          />
        )}
        {!!currentUser.token &&
          (userRoleStatus.isAdmin || userRoleStatus.external_sales) &&
          features.isNotificationInApp && <NotificationComponent />}
        {showLogout && (
          <ProfileComponent
            t={t}
            loggout={loggout}
            user={currentUser}
            redirectToProfile={redirectToProfile}
            isProfile={!userRoleStatus.isCmsUser}
            isAdmin={userRoleStatus.isAdmin}
          ></ProfileComponent>
        )}
        {!showLogout && pathname === "/configurator" && <MyAvatar />}
      </Hidden>
    </>
  );
};
