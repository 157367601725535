import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { SellSettingsActionsTypes } from "./action";
import * as Api from "./api";
import { SellSettings } from "./entity";

export interface SellSettingsState extends EntityState<SellSettings> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
}

export const SellSettingsInitialState: SellSettingsState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const SellSettingsAdapter = createEntityAdapter<SellSettings>();

const SellSettingsAdapterState = SellSettingsAdapter.getInitialState(
  SellSettingsInitialState
);

export const read = createMyAsyncThunk<SellSettings[]>(
  SellSettingsActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  SellSettingsActionsTypes.CREATE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);

export const update = createMyAsyncThunk<SellSettings, Partial<SellSettings>>(
  SellSettingsActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

const SellSettingsSlice = createMySlice({
  name: "sell-settings",
  initialState: SellSettingsAdapterState,
  adapter: SellSettingsAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: SellSettingsAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: SellSettingsAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: SellSettingsAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const SellSettingsReducer = SellSettingsSlice.reducer;
export const SellSettingsActions = {
  ...SellSettingsSlice.actions,
  async: {
    read,
    create,
    update
  }
};
