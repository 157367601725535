import {
  Box,
  BoxProps,
  createStyles,
  Divider,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableSortLabel,
  Theme,
  Typography
} from "@material-ui/core";
import { grey, indigo } from "@material-ui/core/colors";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { TableCellProps } from "@material-ui/core/TableCell";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import EyeIcon from "@material-ui/icons/Visibility";
import UpdateIcon from "@material-ui/icons/Edit";
import clsx, { ClassValue } from "clsx";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  AutoSizer,
  ColumnSizer,
  GridCellRenderer,
  Index,
  MultiGrid
} from "react-virtualized";
import { useDebouncedCallback } from "use-debounce";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { EmptyState, EmptyStateProps } from "../EmptyState";
import { MyButton, MyButtonProps } from "../MyButton";
import { MyFormikProps } from "../MyFormik";
import { MaterialTableActions } from "./components/Actions";
import {
  MaterialTableFormDialog,
  MaterialTableFormDialogProps
} from "./components/DialogForm";
import {
  MaterialTableSearch,
  MaterialTableSearchProps
} from "./components/Search";
import { MyTooltipTypography } from "../MyTooltipTypography";
import InfoIcon from "@material-ui/icons/Info";
import { MyTypography } from "../MyTypography";
import { ReactComponent as CopyToClipboardIcon } from "../../../assets/images/icons/copyToClipboard.svg";

export const useStylesMaterialTable = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    header: {
      outline: "none"
    },
    cell: {
      color: "inherit",
      padding: "0px 12px 0px 12px",
      borderRight: "0.3px solid #e7e7e7"
    },
    cellDisabled: {
      background: theme.palette.grey[200]
    },
    cellHeader: {
      color: "inherit",
      padding: "0px 12px 0px 12px"
    },
    row: {
      display: "flex",
      transition: "all 50ms ease-in-out",
      outline: "none"
    },
    rowOddLines: { backgroundColor: grey[50] },
    rowHover: { backgroundColor: indigo[50] },
    rowSelected: {
      backgroundColor: theme.palette.primary.light,
      color: "white"
    },
    copyIcon: {
      color: `${theme.palette.primary.main}`,
      height: "22px",
      width: "22px"
    }
  })
);

export interface MaterialTableColumn<T> {
  title?: string;
  titleTooltipIcon?: JSX.Element;
  titleTooltipContent?: string;
  field: string;
  sortKey?: string;
  hidden?: boolean;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  disableSort?: boolean;
  align?: TableCellProps["align"];
  type?: "numeric";
  render?: (row: T, value: any) => React.ReactNode;
  format?: (value: any) => React.ReactNode;
  sort?: (a: T, b: T) => number;
  tableCellProps?: TableCellProps & { "data-testid": string };
  defaultSort?: Order;
}

type Order = "asc" | "desc";

export interface MyMaterialTable2PropsVirtualized<T> {
  columns: MaterialTableColumn<T>[];
  data: T[];
  customIdName?: keyof T;
  hasFilteredData?: boolean;
  onRowClick?: (event: React.MouseEvent, row: T) => void;
  rowMenuActions?: (row: T, closeMenu: () => void) => React.ReactNode;
  disableMenuActions?: boolean;
  disableSearch?: boolean;
  pagination?: {
    sort?: {
      value: { field?: string; direction?: "asc" | "desc" };
      onChange: (sort: string, direction: Order) => void;
    };
    search?: Pick<MaterialTableSearchProps, "onChange" | "debounce"> & {
      value: string;
    };
  };
  searchComponentProps?: Partial<MaterialTableSearchProps>;
  menuActionsAfterEdit?: boolean;
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  rowHeight?: number;
  rowHeaderHeight?: number;
  defaultFieldSort?: string;
  defaultFieldSortDirection?: "asc" | "desc";
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  actionComponent?: React.ReactNode;
  emptyStateProps?: EmptyStateProps;
  // Add Entity
  disabledAddElement?: boolean;
  disableRow?: (row: T) => boolean;
  addElement?: {
    formik: MyFormikProps<any>;
    status: FetchingStatus | boolean;
    title: string | React.ReactNode;
    dialog?: Partial<MaterialTableFormDialogProps>;
    buttonLabel: string | React.ReactNode;
    buttonComponent?: (fn: (open: boolean) => void) => React.ReactNode;
    buttonProps?: MyButtonProps;
  };
  updateElement?: {
    formik: MyFormikProps<any>;
    status: FetchingStatus | boolean;
    setFormikInitialValue?: (row: T) => any;
    title: (row: T) => string | React.ReactNode;
    dialog?: Partial<MaterialTableFormDialogProps>;
    buttonLabel: (row: T) => string | React.ReactNode;
    buttonComponent?: React.ReactNode;
    buttonProps?: MyButtonProps;
    disabled?: (row: T) => boolean;
  };
  viewDetailsElement?: {
    disabled?: (row: T) => boolean;
    buttonLabel: (row: T) => string | React.ReactNode;
    buttonAction?: (row: T) => void;
    viewDetailsComponent?: (row: T) => JSX.Element | JSX.Element[] | null;
  };
  copyIdElementToClipboard?: {
    buttonLabel: string | React.ReactNode;
    snackBarConfirmation?: () => void;
  };
  materialTableActionProps?: BoxProps;
  paperProps?: { elevation: number };
  replaceEmptyValues?: boolean;
  isLoading?: boolean;
  allowBackDrop?: boolean;
}

const initialMousePosition = { mouseX: null, mouseY: null };
const COLUMN_MIN_WIDTH = 200;
const ROW_HEADER_HEIGHT = 50;
const ROW_HEIGHT = 50;

function sortByOrder<T>(
  array: T[],
  order: Order,
  orderBy: string,
  columns: MaterialTableColumn<T>[]
) {
  const customSort = keyBy(columns, "field")[orderBy].sort;
  if (customSort) {
    return array
      .slice()
      .sort((a, b) => (order === "asc" ? customSort(a, b) : -customSort(a, b)));
  }
  return array.slice().sort((a, b) => {
    return get(a, orderBy) < get(b, orderBy)
      ? order === "asc"
        ? -1
        : 1
      : order === "asc"
      ? 1
      : -1;
  });
}

export function MyMaterialTable2Virtualized<T extends { id: string }>({
  columns,
  data,
  customIdName,
  hasFilteredData,
  height,
  width,
  rowHeight,
  rowHeaderHeight,
  onRowClick,
  emptyStateProps,
  rowMenuActions,
  disableMenuActions,
  disableSearch,
  viewDetailsElement,
  searchComponentProps,
  menuActionsAfterEdit,
  disableRow,
  defaultFieldSort,
  defaultFieldSortDirection,
  pagination,
  leftActions,
  rightActions,
  addElement,
  updateElement,
  materialTableActionProps,
  paperProps,
  isLoading,
  replaceEmptyValues = true,
  allowBackDrop,
  disabledAddElement,
  copyIdElementToClipboard
}: MyMaterialTable2PropsVirtualized<T>) {
  const containerRef = useRef<any>();
  const classes = useStylesMaterialTable();
  const [highlightedRow, setHighlightedRow] = useState<T>();
  const [hoverRowIndex, setHoverRowIndex] = useState<number>();
  const [openUpdateElement, setOpenUpdateElement] = useState(false);
  const [openAddElement, setOpenAddElement] = useState(false);
  const [hasInitialize, setHasInitialize] = useState(false);
  const [menuPosition, setMenuPosition] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialMousePosition);

  const [sortDirection, setSortDirection] = React.useState<Order>(
    defaultFieldSortDirection || "desc"
  );
  const [sortBy, setSortBy] = React.useState<string | undefined>(
    defaultFieldSort
  );

  const [list, setList] = React.useState<T[]>([]);
  const [totalColumnWidth] = React.useState(
    columns
      .filter((c) => !c.hidden)
      .reduce((prev, curr) => prev + (curr.minWidth || COLUMN_MIN_WIDTH), 0)
  );

  const [searchText, setSearchText] = React.useState("");

  const onUpdateMenuClick = () => {
    setOpenUpdateElement(true);
    setMenuPosition({
      mouseX: null,
      mouseY: null
    });
  };

  const handleMenuClose = () => {
    setMenuPosition(initialMousePosition);
  };

  const handleCopyIdToClipboard = (row: T) => {
    if (!copyIdElementToClipboard) return;

    navigator.clipboard.writeText(row.id);
    if (copyIdElementToClipboard.snackBarConfirmation) {
      copyIdElementToClipboard.snackBarConfirmation();
    }
  };

  const handleCellClassName = ({ index }: Index) => {
    const classNames: ClassValue[] = [];
    classNames.push(classes.row);
    if (
      highlightedRow &&
      list[index] &&
      list[index][customIdName || "id"] === highlightedRow[customIdName || "id"]
    ) {
      classNames.push(classes.rowSelected);
    }
    if (hoverRowIndex === index) {
      classNames.push(classes.rowHover);
    }
    if (index % 2 === 0) {
      classNames.push(classes.rowOddLines);
    }
    return classNames;
  };

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
    row: T
  ) => {
    setHighlightedRow(row);
    if (event) {
      event.preventDefault();
      setMenuPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4
      });
    }
    if (onRowClick) onRowClick(event, row);
  };

  const filterDataFromSearch = useCallback(
    () =>
      searchText
        ? data.filter((item) =>
            columns
              .filter((c) => !c.hidden)
              .map((c) => ({ field: c.field, format: c.format }))
              .some(({ field, format }) => {
                const value = get(item, field);
                if (!value) return false;
                return (JSON.stringify(format ? format(value) : value) || "")
                  .toLowerCase()
                  .includes(searchText.toLowerCase());
              })
          )
        : data,
    [columns, data, searchText]
  );

  const initList = useCallback(() => {
    let list = [];
    if (!pagination && sortBy) {
      list = sortByOrder(
        filterDataFromSearch(),
        sortDirection,
        sortBy,
        columns
      );
    } else {
      list = data;
    }
    setList(list);
  }, [columns, data, filterDataFromSearch, pagination, sortBy, sortDirection]);

  const initListDebounce = useDebouncedCallback(initList, 150);

  const handleSearchChange = (search: string) => {
    setSearchText(search);
  };

  const handleSortChange = useCallback(
    (property: string, order?: Order) => {
      const isAsc =
        (pagination?.sort?.value.field || sortBy) === property &&
        (pagination?.sort?.value.direction || sortDirection) === "asc";
      const newOrder = order || isAsc ? "desc" : "asc";

      if (pagination?.sort?.onChange) {
        return pagination.sort?.onChange(property, newOrder);
      }
      setSortDirection(newOrder);
      setSortBy(property);
    },
    [pagination, sortBy, sortDirection]
  );

  const gridCellRenderer: GridCellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    style
  }) => {
    if (!list) {
      return null;
    }
    const columnData = columns.filter((c) => !c.hidden)[columnIndex];
    const cellStyle: CSSProperties = {};

    // Headers
    if (rowIndex === 0) {
      return (
        <TableCell
          component="div"
          key={key}
          align={columnData.align || "inherit"}
          style={{
            display: "flex",
            ...style
          }}
          variant="head"
          size="small"
          sortDirection={
            (pagination?.sort?.value.field || sortBy) === columnData.sortKey ||
            columnData.field
              ? pagination?.sort?.value.direction || sortDirection
              : false
          }
          className={classes.cellHeader}
          // {...column.tableCellProps}
        >
          <Box width="100%" clone>
            <TableSortLabel
              disabled={columnData.disableSort}
              active={
                (pagination?.sort?.value.field || sortBy) ===
                (columnData.sortKey || columnData.field)
              }
              direction={
                (pagination?.sort?.value.field || sortBy) ===
                (columnData.sortKey || columnData.field)
                  ? pagination?.sort?.value.direction || sortDirection
                  : undefined
              }
              onClick={() =>
                handleSortChange(columnData.sortKey || columnData.field)
              }
            >
              {columnData.title && (
                <>
                  {columnData.titleTooltipContent ? (
                    <MyTooltipTypography
                      show={true}
                      icon={
                        <MyTypography
                          rightIcon={
                            columnData.titleTooltipIcon ?? <InfoIcon />
                          }
                          variant="body2"
                          style={{ fontWeight: "bold", alignSelf: "center" }}
                        >
                          {columnData.title}
                        </MyTypography>
                      }
                      contentComponent={
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold", alignSelf: "center" }}
                        >
                          {columnData.titleTooltipContent}
                        </Typography>
                      }
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", alignSelf: "center" }}
                    >
                      {columnData.title}
                    </Typography>
                  )}
                </>
              )}
            </TableSortLabel>
          </Box>
        </TableCell>
      );
    }

    // row Index -1 because we create the headers with the first row
    const rowData = list[rowIndex - 1];
    const cellData = get(rowData, columnData.field);
    const formatValue = columnData.format
      ? columnData.format(cellData)
      : cellData;
    const isDisabled = disableRow && disableRow(rowData);

    // Cells
    return (
      <TableCell
        style={{ ...style, ...cellStyle }}
        key={key}
        component="div"
        className={clsx(
          classes.cell,
          ...handleCellClassName({ index: rowIndex - 1 }),
          {
            // [classes.cellDisabled]: isDisabled
          }
        )}
        align={columnData.align || "inherit"}
        variant="body"
        size="small"
        onClick={(event) => handleRowClick(event, rowData)}
        onMouseEnter={() => {
          setHoverRowIndex(rowIndex - 1);
        }}
        {...columnData.tableCellProps}
      >
        {columnData.render ? (
          columnData.render(rowData, formatValue)
        ) : (
          <Box width="100%" clone>
            <Typography
              variant="body2"
              style={{ alignSelf: "center" }}
              color={isDisabled ? "textSecondary" : "initial"}
            >
              {replaceEmptyValues ? formatValue || "-" : formatValue}
            </Typography>
          </Box>
        )}
      </TableCell>
    );
  };

  const getColumnDataWidth = (
    params: Index,
    getColumnWidth: () => number
  ): number => {
    if (containerRef.current.clientWidth < totalColumnWidth) {
      return (
        columns.filter((c) => !c.hidden)[params.index].minWidth ||
        COLUMN_MIN_WIDTH - 2
      );
    } else {
      // TODO need to check all columns and set fixed values for small ones (number etc)
      // Can be added to get the remaining width and distribute it to the columns

      /*   const remaining = containerRef.current.clientWidth - totalColumnWidth;
      // Get all columns that are not fixed or hidden
      const totalColumns = columns.filter((c) => !c.hidden && !c.width).length;
      // Get the dispatch width per column
      const remainingPerColumn = remaining / totalColumns;
      // Set boolean to the fixed column
      const isFixed = columns.filter((c) => !c.hidden)[params.index].width;
      const columnWidth = isFixed || COLUMN_MIN_WIDTH - 3;
      return isFixed ? columnWidth : columnWidth + remainingPerColumn; */

      return (
        columns.filter((c) => !c.hidden)[params.index].width ||
        getColumnWidth() - 2
      );
    }
  };

  const getCellHeight = (params: Index) => {
    if (params.index === 0) {
      return rowHeaderHeight || ROW_HEADER_HEIGHT;
    }
    return rowHeight || ROW_HEIGHT;
  };
  const disabledUpdateElement = useMemo(
    () =>
      updateElement?.disabled &&
      highlightedRow &&
      updateElement?.disabled(highlightedRow as any),
    [highlightedRow, updateElement]
  );

  // TODO refactor it to be an independant component
  const addElementDialog = useMemo(
    () =>
      addElement ? (
        <MaterialTableFormDialog
          type="create"
          formik={{
            ...addElement.formik,
            onSubmit: (values, helpers) => {
              setOpenAddElement(false);
              addElement.formik.onSubmit(values, helpers);
            }
          }}
          allowBackDrop={allowBackDrop}
          title={addElement.title}
          open={openAddElement}
          onClose={() => {
            setOpenAddElement(false);
          }}
          {...addElement.dialog}
        />
      ) : null,
    [addElement, allowBackDrop, openAddElement]
  );

  useEffect(() => {
    if (!sortBy && defaultFieldSort && !pagination) {
      handleSortChange(defaultFieldSort, defaultFieldSortDirection || "asc");
    }
  }, [
    defaultFieldSort,
    defaultFieldSortDirection,
    handleSortChange,
    pagination,
    sortBy
  ]);

  useEffect(() => {
    if (!hasInitialize && data.length) {
      initList();
      setHasInitialize(true);
    } else {
      initListDebounce.callback();
    }
  }, [initList, initListDebounce, hasInitialize, data]);

  if (
    (!data || !data.length) &&
    !hasFilteredData &&
    emptyStateProps &&
    !pagination
  ) {
    return (
      <EmptyState
        onClick={() => setOpenAddElement(true)}
        {...emptyStateProps}
        dialogComponent={addElementDialog}
      />
    );
  }

  return (
    <Paper {...paperProps}>
      <MaterialTableActions {...materialTableActionProps}>
        {addElement && !disabledAddElement && (
          <MyButton
            leftIcon={<AddCircleIcon fontSize="small" />}
            color="primary"
            variant="outlined"
            style={{ height: 40 }}
            onClick={() => setOpenAddElement(true)}
            {...addElement.buttonProps}
          >
            {addElement.buttonLabel}
          </MyButton>
        )}
        {addElementDialog}
        {updateElement && highlightedRow && (
          <MaterialTableFormDialog
            type="update"
            formik={{
              ...updateElement.formik,
              onSubmit: (values, helpers) => {
                setOpenUpdateElement(false);
                updateElement.formik.onSubmit(values, helpers);
              },
              initialValues:
                (updateElement.setFormikInitialValue &&
                  updateElement.setFormikInitialValue(highlightedRow)) ||
                updateElement.formik.initialValues
            }}
            allowBackDrop={allowBackDrop}
            title={updateElement.title(highlightedRow)}
            open={openUpdateElement}
            onClose={() => setOpenUpdateElement(false)}
            {...updateElement.dialog}
          />
        )}
        {leftActions}
        <div style={{ flexGrow: 1 }} />
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          {!disableSearch && (
            <MaterialTableSearch
              onChange={
                pagination?.search
                  ? pagination.search.onChange
                  : handleSearchChange
              }
              debounce={pagination?.search?.debounce}
              defaultValue={pagination?.search?.value}
              {...searchComponentProps}
            />
          )}
          {rightActions}
        </Box>
      </MaterialTableActions>
      <div
        style={{
          height: height || 700,
          minHeight: 240,
          //width: width || "auto"
          width: "100%"
        }}
        ref={containerRef}
      >
        <AutoSizer>
          {({ width, height }) => {
            return (
              <ColumnSizer
                //columnMinWidth={COLUMN_MIN_WIDTH}
                columnCount={columns.filter((c) => !c.hidden).length}
                key="GridColumnSizer"
                width={width}
              >
                {({ adjustedWidth, getColumnWidth, registerChild }) => {
                  return (
                    <MultiGrid
                      ref={registerChild}
                      columnWidth={(params) => {
                        return getColumnDataWidth(params, getColumnWidth);
                      }}
                      fixedRowCount={1}
                      cellRenderer={gridCellRenderer}
                      columnCount={columns.filter((c) => !c.hidden).length}
                      rowCount={list.length + 1}
                      rowHeight={getCellHeight}
                      width={adjustedWidth}
                      height={height}
                    />
                  );
                }}
              </ColumnSizer>
            );
          }}
        </AutoSizer>
        {isLoading && <LinearProgress color="primary" />}
        {(rowMenuActions || updateElement) &&
          highlightedRow &&
          !disableMenuActions && (
            <Menu
              open={menuPosition.mouseY !== null}
              onClose={handleMenuClose}
              transitionDuration={{ appear: 300, enter: 300, exit: 100 }}
              anchorReference="anchorPosition"
              anchorPosition={
                menuPosition.mouseY !== null && menuPosition.mouseX !== null
                  ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                  : undefined
              }
            >
              {rowMenuActions &&
                !menuActionsAfterEdit &&
                rowMenuActions(highlightedRow, handleMenuClose)}

              {viewDetailsElement &&
                (!viewDetailsElement?.disabled ||
                  viewDetailsElement?.disabled?.(highlightedRow)) && (
                  <MenuItem
                    onClick={() => {
                      viewDetailsElement.buttonAction &&
                        viewDetailsElement.buttonAction(highlightedRow);
                    }}
                  >
                    <ListItemIcon>
                      <EyeIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ color: "primary" }}>
                      {viewDetailsElement.buttonLabel(highlightedRow)}
                    </ListItemText>
                  </MenuItem>
                )}
              {viewDetailsElement?.viewDetailsComponent &&
                viewDetailsElement.viewDetailsComponent(highlightedRow)}
              {copyIdElementToClipboard && (
                <>
                  <MenuItem
                    onClick={() => handleCopyIdToClipboard(highlightedRow)}
                  >
                    <ListItemIcon>
                      <CopyToClipboardIcon className={classes.copyIcon} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ color: "primary" }}>
                      {copyIdElementToClipboard.buttonLabel}
                    </ListItemText>
                  </MenuItem>
                  <Divider
                    style={{
                      width: "95%",
                      margin: "auto",
                      marginBottom: 4,
                      marginTop: 4
                    }}
                  />
                </>
              )}
              {updateElement && highlightedRow && !disabledUpdateElement && (
                <MenuItem onClick={onUpdateMenuClick}>
                  <ListItemIcon>
                    <UpdateIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ color: "primary" }}>
                    {updateElement.buttonLabel(highlightedRow)}
                  </ListItemText>
                </MenuItem>
              )}
              {rowMenuActions &&
                menuActionsAfterEdit &&
                rowMenuActions(highlightedRow, handleMenuClose)}
            </Menu>
          )}
      </div>
    </Paper>
  );
}
