import { CompanyWithProjectCollaboraters } from "../../entities/company";
import { User } from "../../entities/user";
import { setCustomFieldValues } from "../common/utils";
import { CustomFieldValue } from "../customFields/customFieldValues/types";
import { MetaEntityHipe } from "../customFields/types";
import { CompanyPagination } from "./types";

export const transformCompany = (
  responseData: CompanyPagination,
  currentUser: User,
  users?: User[]
) => {
  return {
    ...responseData,
    data: responseData.data.map((company: CompanyWithProjectCollaboraters) => {
      return {
        ...company,
        projectCount: company.projectIds.length,
        customFields: setCustomFieldValues(
          company?.customFields as CustomFieldValue[],
          MetaEntityHipe.Company
        ),
        rawCustomFields: company?.customFields as CustomFieldValue[]
      };
    })
  };
};
