import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import { CreateQueryParamsNoPagination } from "../../utils/api/api.types";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { MetaEntity, MetaEntityHipe, MetaEntityTransform } from "./types";
import { EntityType } from "../events/type";
import { transformMetaEntities, transformMetaEntity } from "./transform";
import { RootStateQuery } from "../../reducers/store";
import { createSelector } from "reselect";

export const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["MetaEntity", "MetaEntityInit"]
});

export const metaEntitySlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getMetaEntity: build.query<
      MetaEntity[],
      CreateQueryParamsNoPagination | undefined
    >({
      query: (params) => {
        const queryString = RequestQueryBuilder.create(params).query();
        return `/meta-entities${queryString ? `?${queryString}` : ""}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "MetaEntity" as const,
                id
              })),
              { type: "MetaEntity", id: "PARTIAL-LIST" }
            ]
          : [{ type: "MetaEntity", id: "PARTIAL-LIST" }]
    }),
    getOneMetaEntity: build.query<MetaEntity, { id: string }>({
      query: ({ id }) => {
        return `/meta-entities/${id}`;
      },
      transformResponse: (responseData: MetaEntity) => {
        return {
          ...responseData
        };
      },
      providesTags: (result) =>
        result
          ? [{ type: "MetaEntity", id: result.id }]
          : [{ type: "MetaEntity", id: "PARTIAL-LIST" }]
    }),
    getInitializeMetaEntityCustomFields: build.query<
      Record<MetaEntityHipe, MetaEntity>,
      undefined
    >({
      query: () => {
        return `/meta-entities/init`;
      },
      transformResponse: (responseData: Record<MetaEntityHipe, MetaEntity>) => {
        return transformMetaEntities(responseData);
      },
      providesTags: () => [{ type: "MetaEntityInit", id: "PARTIAL-LIST" }]
    }),
    getOneMetaEntityByName: build.query<
      MetaEntityTransform,
      { name: EntityType }
    >({
      query: ({ name }) => {
        return `/meta-entities/${name}/name`;
      },
      transformResponse: (responseData: MetaEntity) => {
        return transformMetaEntity(responseData);
      },
      providesTags: (result) =>
        result
          ? [{ type: "MetaEntity", id: result.id }]
          : [{ type: "MetaEntity", id: "PARTIAL-LIST" }]
    }),
    createMetaEntity: build.mutation<MetaEntity, Partial<MetaEntity>>({
      query(data: Partial<MetaEntity>) {
        return {
          url: `/meta-entities`,
          method: "POST",
          body: data
        };
      },
      invalidatesTags: (_, __, data) => {
        return [
          { type: "MetaEntity", id: "PARTIAL-LIST" },
          { type: "MetaEntity", id: data?.id as string }
        ];
      },
      async onQueryStarted(__, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:create-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed").toString()
            )
          );
        }
      }
    }),
    updateMetaEntity: build.mutation<MetaEntity, Partial<MetaEntity>>({
      query(data: Partial<MetaEntity>) {
        const { id, ...patch } = data;
        return {
          url: `/meta-entities/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(parameters, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // invalidate the form list
          dispatch(
            metaEntitySlice.util.invalidateTags([
              { type: "MetaEntity", id: parameters.id as string },
              { type: "MetaEntity", id: "PARTIAL-LIST" }
            ])
          );

          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    deleteMetaEntity: build.mutation<MetaEntity, Partial<MetaEntity>>({
      query(data: Partial<MetaEntity>) {
        const { id } = data;
        return {
          url: `/meta-entities/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: (_, __, data) => {
        return [
          { type: "MetaEntity", id: "PARTIAL-LIST" },
          { type: "MetaEntity", id: data?.id as string }
        ];
      },
      async onQueryStarted(__, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetMetaEntityQuery,
  useGetOneMetaEntityQuery,
  useCreateMetaEntityMutation,
  useUpdateMetaEntityMutation,
  useDeleteMetaEntityMutation,
  useGetOneMetaEntityByNameQuery,
  useGetInitializeMetaEntityCustomFieldsQuery
} = metaEntitySlice;

export const getInitializedMetaEntityCustomFields = createSelector(
  (state: RootStateQuery) =>
    state.api.queries["getInitializeMetaEntityCustomFields(undefined)"]
      ?.data as Record<MetaEntityHipe, MetaEntityTransform>,
  (metaEntity) => metaEntity ?? undefined
);

export const getMetaEntityCustomFields = createSelector(
  [
    (state: RootStateQuery) =>
      state.api.queries["getInitializeMetaEntityCustomFields(undefined)"]
        ?.data as Record<MetaEntityHipe, MetaEntityTransform>,
    (_: RootStateQuery, metaEntityHipe: MetaEntityHipe) => metaEntityHipe
  ],
  (metaEntity, metaEntityHipe) => metaEntity?.[metaEntityHipe]
);
