import { Request } from "../../utils/request";
import {
  MachineRateCoefficientSet,
  MachineRateCoefficientSetCreateDTO
} from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/machine-rates-coefficient-sets");

export const createOne = (dto: MachineRateCoefficientSetCreateDTO) =>
  Request({ withToken: true }).post(`/machine-rates-coefficient-sets`, dto);

export const updateOne = ({ id, ...dto }: MachineRateCoefficientSet) =>
  Request({ withToken: true }).put(
    `/machine-rates-coefficient-sets/${id}`,
    dto
  );

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(`/machine-rates-coefficient-sets/${id}`);
