import {
  Box,
  createStyles,
  makeStyles,
  TextField,
  TextFieldProps
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce/lib";
import { MyIconButton } from "../../MyIconButton";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: 300,
      margin: 0
    }
  })
);

export type MaterialTableSearchProps = Partial<
  Omit<TextFieldProps, "onChange">
> & {
  onChange: (search: string) => void;
  debounce?: number;
};

export const MaterialTableSearch: React.FC<MaterialTableSearchProps> = ({
  onChange,
  debounce,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation("components");
  const inputRef = useRef<any>();

  const onSearch = useDebouncedCallback(onChange, debounce || 300, {});

  return (
    <TextField
      inputRef={inputRef}
      autoFocus={rest?.defaultValue ? true : false}
      className={classes.root}
      InputProps={{
        startAdornment: (
          <Box mr={1} clone>
            <SearchIcon />
          </Box>
        ),
        endAdornment: (rest.defaultValue as string)?.length ? (
          <MyIconButton
            onClick={() => {
              inputRef.current.value = "";
              inputRef.current.focus();
              onSearch.callback("");
            }}
            size="small"
          >
            <ClearIcon />
          </MyIconButton>
        ) : null
      }}
      placeholder={t("myMaterialTable.toolbar.searchPlaceholder")}
      fullWidth
      // className={classes.searchComponent}
      margin="dense"
      name="search"
      variant="outlined"
      onChange={(ev) => onSearch.callback(ev.target.value)}
      {...rest}
    />
  );
};
