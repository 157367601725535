import * as Sentry from "@sentry/react";
import { object, string, mixed } from "yup";
import { setYupLocale } from "../yup";
import { Localisations } from "../../entities/localisations";
import { TFunction } from "i18next";
setYupLocale();

export enum Languages {
  "EN" = "en-US",
  "FR" = "fr-FR"
}

export const jsonTranslator = (
  json: DynamicJsonTranslator,
  lang: string
): string => {
  try {
    const languages = json;
    const fallbackLang = localStorage.getItem("defaultLanguage");
    return languages[lang] || languages[fallbackLang!];
  } catch (err) {
    const message = `Cannot transform ${json} to the current translation`;
    Sentry.withScope((scope) => {
      scope.setTransactionName("jsonTranslator");
      scope.setContext("action", { message });
      Sentry.captureException(err);
    });
    if (process.env.NODE_ENV === "production") {
      return "";
    }
    throw new Error(message);
  }
};

export const jsonTranslatorSafe = (
  json: DynamicJsonTranslator | null | undefined,
  lang: string
): string | undefined => {
  if (!json) return undefined;
  return jsonTranslator(json, lang);
};

export const compareLabels = <T>(
  getter: (object: T) => DynamicJsonTranslator | undefined,
  lang: string
) => (a: T, b: T) => {
  const labelA = getter(a);
  const labelB = getter(b);
  if (!labelA || !labelB) return 0;
  return jsonTranslator(labelA, lang).localeCompare(
    jsonTranslator(labelB, lang)
  );
};

export const mergeTranslations = (
  elements: Array<DynamicJsonTranslator | string>,
  separator = " "
) => {
  const result: DynamicJsonTranslator = {};
  for (const lang of Object.values(Languages)) {
    const translations: string[] = elements.map((element) => {
      if (typeof element === "string") return element;
      return jsonTranslator(element as DynamicJsonTranslator, lang);
    });

    result[lang] = translations.filter((tr) => !!tr).join(separator);
  }
  return result;
};

export type DynamicJsonTranslator = {
  [key: string]: string;
};

/**
 * Generates a dynamic JSON translator schema based on the provided localisations.
 * @returns The generated schema.
 */
export const DynamicJsonTranslatorSchema = () => {
  return mixed<DynamicJsonTranslator>().when(
    "$localisations",
    (localisations: Localisations[], _) => {
      const schema = object<DynamicJsonTranslator>().shape(
        localisations.reduce((acc, { id, default: defaultLanguage }) => {
          acc[id] = defaultLanguage ? string().required() : string();
          return acc;
        }, {} as { [key: string]: any })
      );

      return schema;
    }
  );
};

/**
 * Creates a dynamic JSON translator schema with optional fields.
 *
 * @param isNullable - A boolean indicating whether the schema should be nullable.
 * @returns The dynamic JSON translator schema.
 */
export const DynamicJsonTranslatorSchemaOptional = (isNullable?: boolean) => {
  return mixed<DynamicJsonTranslator>().when(
    "$localisations",
    (localisations: Localisations[], _) => {
      const schema = object<DynamicJsonTranslator>()
        .shape(
          localisations.reduce((acc, { id }) => {
            acc[id] = string();
            return acc;
          }, {} as { [key: string]: any })
        )
        [isNullable ? "nullable" : "notRequired"]();

      return schema;
    }
  );
};

export const createJsonTranslatorInitialValues = (
  localisations: Localisations[]
) =>
  localisations.reduce((acc, { id }) => {
    acc[id] = "";
    return acc;
  }, {} as { [key: string]: string });

export const createListOfTranslationsByLocalisation = (
  localisations: Localisations[],
  t: TFunction,
  key: string
): { [key: string]: string } => {
  return localisations.reduce((acc, { id }) => {
    acc[id] = t(key, { lng: id });
    return acc;
  }, {} as { [key: string]: string });
};
