import { User } from "../../entities/user";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { SignUpDTO } from "./types";

export enum AuthenticationActionsTypes {
  SIGNIN_STATUS = "@@AUTHENTICATION signin status",
  SIGNIN_FETCH = "@@AUTHENTICATION signin fetch",
  SIGNUP_STATUS = "@@AUTHENTICATION sign up status",
  SIGNUP = "@@AUTHENTICATION sign up",
  RETRY_CONNECTION = "@@AUTHENTICATION retry connection",
  RETRY_CONNECTION_STATUS = "@@AUTHENTICATION retry connection status",
  LOG_OUT_STATUS = "@@AUTHENTICATION log out status",
  LOG_OUT_FETCH = "@@AUTHENTICATION log out fetch",
  UPDATE_CURRENT_USER = "@@AUTHENTICATION update auth user",
  USER_CHANGE_PASSWORD = "@@USER change password",
  USER_CHANGE_PASSWORD_STATUS = "@@USER change password status"
}

export class RetryConnectionAction {
  readonly type = AuthenticationActionsTypes.RETRY_CONNECTION;
  constructor(public token: string, public redirection?: string) {}
}

export class RetryConnectionStatusAction {
  readonly type = AuthenticationActionsTypes.RETRY_CONNECTION_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class SignInFetchAction {
  readonly type = AuthenticationActionsTypes.SIGNIN_FETCH;
  constructor(
    public user: any,
    public onAfterSubmit?: (user: User) => void,
    public custom?: string
  ) {}
}

export class SignInStatusAction {
  readonly type = AuthenticationActionsTypes.SIGNIN_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class SignUpAction {
  readonly type = AuthenticationActionsTypes.SIGNUP;
  constructor(
    public dto: SignUpDTO,
    public onAfterSubmit?: (user: User) => void
  ) {}
}

export class SignUpStatusAction {
  readonly type = AuthenticationActionsTypes.SIGNUP_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class LogOutFetchAction {
  readonly type = AuthenticationActionsTypes.LOG_OUT_FETCH;
}
export class LogOutStatusAction {
  readonly type = AuthenticationActionsTypes.LOG_OUT_STATUS;
  constructor(public status: FetchingStatus) {}
}
export class UpdateCurrentUser {
  readonly type = AuthenticationActionsTypes.UPDATE_CURRENT_USER;
  constructor(public user: User) {}
}

export class UserChangePassword {
  readonly type = AuthenticationActionsTypes.USER_CHANGE_PASSWORD;
  constructor(
    public token: string,
    public qPath: string,
    public password: string
  ) {}
}
export class UserChangePasswordStatus {
  readonly type = AuthenticationActionsTypes.USER_CHANGE_PASSWORD_STATUS;
  constructor(public status: FetchingStatus) {}
}

export type AuthenticationActions =
  | RetryConnectionAction
  | RetryConnectionStatusAction
  | SignInFetchAction
  | SignInStatusAction
  | SignUpStatusAction
  | LogOutFetchAction
  | LogOutStatusAction
  | UpdateCurrentUser
  | UserChangePassword
  | UserChangePasswordStatus;
