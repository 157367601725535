import React, { useCallback } from "react";
import {
  Notification,
  NottificationSettingsCategoriesEnum
} from "../../../../services/notifications/notifications.type";
import { ReactComponent as Project } from "../assets/project.svg";
import { ReactComponent as OverDue } from "../assets/overdue.svg";
import { ReactComponent as UpComing } from "../assets/upcoming.svg";
import { ReactComponent as Request } from "../assets/request.svg";
import { ReactComponent as Configuration } from "../assets/configuration.svg";
import { ReactComponent as Task } from "../assets/task.svg";
import { ReactComponent as UploadedFile } from "../assets/uploaded_file.svg";
import { ReactComponent as Success } from "../assets/success.svg";
import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getUserDisplayName } from "../../../../utils/function/transformEntityToOptions";
import { User } from "../../../../entities/user";
import {
  DynamicJsonTranslator,
  jsonTranslator
} from "../../../../utils/function/jsonTranslator";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../reducers/app/selector";
import {
  ProjectTabsEnum,
  navigateToProjectTabSecure
} from "../../Projects/utils/projectTabNavigation";
import {
  navigateToTaskTabSecure,
  TaskTabsEnum
} from "../../Tasks/utils/taskTabNavigation";
import { MyTypography } from "../../../../components/common/MyTypography";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { Event } from "../../../../services/events/type";
import { useUpdateNotificationMutation } from "../../../../services/notifications/notifications.service";
import { navigateToBrief } from "../../../Brief/Detail/utils";
import { navigateToSubmission } from "../../../Cofn/helpers/utils";

interface NotificationCategoryDisplayProps {
  notification: Notification;
  isIcon?: boolean;
  isHeader?: boolean;
}

export const colorAnchor = "#4D7CFE";

export function getInAppCategoryLinkHref(event: Event): string {
  const category = event?.category;
  switch (category) {
    case NottificationSettingsCategoriesEnum.BRIEF_CREATION:
    case NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS:
      // return event.projectId
      //   ? navigateToProjectTabSecure(
      //       event?.project?.id as string,
      //       ProjectTabsEnum.BRIEFS
      //     )
      //   : navigateToBrief(event?.briefId);
      return event.projectId
        ? navigateToProjectTabSecure(
            event?.project?.id as string,
            ProjectTabsEnum.REQUESTS
          )
        : navigateToBrief(event?.briefId);
    case NottificationSettingsCategoriesEnum.BRIEF_TIMEOUT_PRICE:
      return navigateToBrief(event?.briefId, 1);
    case NottificationSettingsCategoriesEnum.BRIEF_TIMEOUT_ERROR:
      return navigateToBrief(event?.briefId);
    case NottificationSettingsCategoriesEnum.SUBMISSION_CREATION:
    case NottificationSettingsCategoriesEnum.SUBMISSION_CREATION_PROJECTS:
      // return event.projectId
      //   ? navigateToProjectTabSecure(
      //       event?.project?.id as string,
      //       ProjectTabsEnum.REQUESTS
      //     )
      //   : navigateToSubmission(event?.submissionId);
      return navigateToSubmission(event?.submissionId);
    case NottificationSettingsCategoriesEnum.PROJECT_CREATION_MANAGER:
      return navigateToProjectTabSecure(event?.project?.id as string);
    case NottificationSettingsCategoriesEnum.PROJECT_CREATION_COMMENT:
      return navigateToProjectTabSecure(
        event?.project?.id as string,
        ProjectTabsEnum.NOTES
      );
    case NottificationSettingsCategoriesEnum.PROJECT_CREATION_FILE:
      return navigateToProjectTabSecure(
        event?.project?.id as string,
        ProjectTabsEnum.DOCUMENTS,
        "createdAt"
      );
    case NottificationSettingsCategoriesEnum.PROJECT_REMINDER_OVERDUE:
    case NottificationSettingsCategoriesEnum.PROJECT_REMINDER_UPCOMING:
      return navigateToProjectTabSecure(event?.project?.id as string);
    case NottificationSettingsCategoriesEnum.PROJECT_UPDATE_STATUS:
      return navigateToProjectTabSecure(event?.project?.id as string);
    case NottificationSettingsCategoriesEnum.TASK_CREATION_MANAGER:
    case NottificationSettingsCategoriesEnum.TASK_UPDATE_STATUS:
    case NottificationSettingsCategoriesEnum.TASK_CREATION_COMMENT:
    case NottificationSettingsCategoriesEnum.TASK_REMINDER_OVERDUE:
    case NottificationSettingsCategoriesEnum.TASK_REMINDER_UPCOMING:
      return navigateToTaskTabSecure(event?.task?.id as string);
    case NottificationSettingsCategoriesEnum.PROJECT_COMMENT_MENTION:
      return navigateToProjectTabSecure(
        event?.project?.id as string,
        ProjectTabsEnum.NOTES,
        "createdAt"
      );
    case NottificationSettingsCategoriesEnum.TASK_COMMENT_MENTION:
      return navigateToTaskTabSecure(
        event?.task?.id as string,
        TaskTabsEnum.COMMENTS
      );
    default:
      return "";
  }
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > span": {
        color: `${colorAnchor} !important`
      },
      "& > a": {
        color: `${colorAnchor} !important`
      }
    },
    content: {
      maxWidth: "90% !important"
    },
    actionSvg: {
      //stroke: theme.palette.primary.main,
      // width: "1em",
      // height: "1em",
      display: "inline-block",
      fontSize: "1.25rem",
      transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      flexShrink: 0
    },
    mention: {
      "& > span": {
        color: `${colorAnchor} !important`,
        fontWeight: "bold",
        "&:hover": {
          background: "none"
        }
      },
      "& > a": {
        color: `${colorAnchor} !important`,
        fontWeight: "bold",
        "&:hover": {
          background: "none"
        }
      },
      padding: "0.5rem",
      position: "relative",
      background: "#E6E6E6 !important",
      "&:hover": {
        background: "#E6E6E6 !important"
      },
      "&::before": {
        content: '""',
        display: "block",
        width: "2px",
        height: "100%",
        background: "#778499",
        position: "absolute",
        left: "0",
        top: "0"
      }
    }
  })
);

function parseTaggedContent(inputString: string, userName: string): string {
  // Échappe les caractères spéciaux dans userName pour l'usage dans une expression régulière
  const escapedUserName = userName.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Construit une expression régulière pour trouver la mention spécifique de userName, y compris les espaces
  const regex = new RegExp(`@${escapedUserName}`, "g");

  // Trouve l'index de la première occurrence du userName
  const matchIndex = inputString.search(regex);

  let result;

  if (matchIndex !== -1) {
    // Calcule l'index de fin pour extraire jusqu'à 200 caractères après le userName
    const endIndex = Math.min(
      matchIndex + 200 + userName.length + 1,
      inputString.length
    );

    // Extrait la sous-chaîne à partir de l'index de la première occurrence du userName
    const extractedString = inputString.substring(matchIndex, endIndex);

    // Entoure la mention trouvée d'une balise <a>
    const withLink = extractedString.replace(regex, `<a>@${userName}</a>`);

    // Supprime toutes les balises HTML de la chaîne d'entrée, à l'exception de la balise <a> ajoutée
    result = withLink.replace(/<(?!\/?a\b)[^>]*>/g, "");

    // Ajoute des points de suspension si la chaîne d'entrée était plus longue que l'index de fin
    if (inputString.length > endIndex) {
      result += "...";
    }
  } else {
    // Si aucune mention spécifique n'est trouvée, renvoie une chaîne vide ou un message spécifique
    result = "Aucune mention spécifique trouvée.";
  }

  return result;
}

export const NotificationCategoryDisplay: React.FC<NotificationCategoryDisplayProps> = ({
  notification,
  isIcon,
  isHeader
}) => {
  const classes = useStyles();
  const { t } = useTranslation("backoffice");
  const lang = useSelector(getLanguage);
  const currentUser = useSelector(getCurrentUser);
  const [updateNotification] = useUpdateNotificationMutation();

  const switchType = useCallback(
    (notification: Notification, isIcon?: boolean): JSX.Element | undefined => {
      const event = notification?.events?.[0];
      const category = event?.category;
      let neverHappens: never;

      const handleMarkAsRead = () => {
        updateNotification({
          id: notification.id,
          isRead: true,
          category: notification.category
        });
      };

      if (!category) return <></>;

      // href not used >> NotificationCategoryDisplay.tsx:52
      switch (category) {
        case NottificationSettingsCategoriesEnum.BRIEF_CREATION:
        case NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS:
          if (isIcon) return <Configuration className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${
                    NottificationSettingsCategoriesEnum.BRIEF_CREATION
                  }.${isHeader ? "header" : "content"}`,
                  {
                    projectName: event?.project?.name,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string,
                      ProjectTabsEnum.BRIEFS
                    ),
                    configurationName:
                      event?.brief?.name ||
                      (event?.brief?.code ? `#${event?.brief?.code}` : "")
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.BRIEF_TIMEOUT_ERROR:
          if (isIcon) return <OverDue className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    name:
                      event?.brief?.name ||
                      (event?.brief?.code ? `#${event?.brief?.code}` : ""),
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string,
                      ProjectTabsEnum.BRIEFS
                    ),
                    configurationName:
                      event?.brief?.name ||
                      (event?.brief?.code ? `#${event?.brief?.code}` : "")
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.BRIEF_TIMEOUT_PRICE:
          if (isIcon) return <Success className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    name:
                      event?.brief?.name ||
                      (event?.brief?.code ? `#${event?.brief?.code}` : "")
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.SUBMISSION_CREATION:
        case NottificationSettingsCategoriesEnum.SUBMISSION_CREATION_PROJECTS:
          if (isIcon) return <Request className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${
                    NottificationSettingsCategoriesEnum.SUBMISSION_CREATION
                  }.${isHeader ? "header" : "content"}`,
                  {
                    projectName: event?.project?.name,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string,
                      ProjectTabsEnum.REQUESTS
                    ),
                    requestName: event?.submission?.code
                      ? `#${event?.submission?.code}`
                      : ""
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.PROJECT_CREATION_MANAGER:
          if (isIcon) return <Project className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    projectName: event?.project?.name,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string
                    )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.PROJECT_CREATION_COMMENT:
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    projectName: event?.project?.name,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string,
                      ProjectTabsEnum.NOTES
                    )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.PROJECT_CREATION_FILE:
          return (
            <Box display="flex" flexDirection={"column"}>
              <Typography
                className={classes.root}
                component={"span"}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `notifications.inApp.category.${category}.${
                      isHeader ? "header" : "content"
                    }`,
                    {
                      projectName: event?.project?.name,
                      userName: getUserDisplayName(event?.user as User)?.name,
                      href: navigateToProjectTabSecure(
                        event?.project?.id as string,
                        ProjectTabsEnum.DOCUMENTS,
                        "createdAt"
                      ),
                      fileName: event?.file?.name as string
                    }
                  )
                }}
              />
              <MyTypography
                className={classes.root}
                // TODO handle format of file with icons
                leftIcon={<UploadedFile className={classes.actionSvg} />}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `notifications.inApp.category.${category}.uploaded`,
                    {
                      fileName: event?.file?.name as string,
                      href: navigateToProjectTabSecure(
                        event?.project?.id as string,
                        ProjectTabsEnum.DOCUMENTS,
                        "createdAt"
                      )
                    }
                  )
                }}
              ></MyTypography>
            </Box>
          );
        case NottificationSettingsCategoriesEnum.PROJECT_REMINDER_OVERDUE:
          if (isIcon) return <OverDue className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    projectName: event?.project?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string
                    )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.PROJECT_REMINDER_UPCOMING:
          if (isIcon) return <UpComing className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    projectName: event?.project?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string
                    )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.PROJECT_UPDATE_STATUS:
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    projectName: event?.project?.name,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToProjectTabSecure(
                      event?.project?.id as string
                    ),
                    statusName: event?.status?.internalLabel
                      ? jsonTranslator(event?.status?.internalLabel, lang)
                      : jsonTranslator(
                          event?.relationLabel as DynamicJsonTranslator,
                          lang
                        )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.TASK_CREATION_MANAGER:
          if (isIcon) return <Task className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    taskName:
                      (event?.task?.name?.length as number) > 0
                        ? event?.task?.name
                        : `#${event?.task?.code}`,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToTaskTabSecure(event?.task?.id as string)
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.TASK_UPDATE_STATUS:
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    taskName:
                      (event?.task?.name?.length as number) > 0
                        ? event?.task?.name
                        : `#${event?.task?.code}`,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToTaskTabSecure(event?.task?.id as string),
                    statusName: event?.status?.internalLabel
                      ? jsonTranslator(event?.status?.internalLabel, lang)
                      : jsonTranslator(
                          event?.relationLabel as DynamicJsonTranslator,
                          lang
                        )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.TASK_CREATION_COMMENT:
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    taskName:
                      (event?.task?.name?.length as number) > 0
                        ? event?.task?.name
                        : `#${event?.task?.code}`,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToTaskTabSecure(
                      event?.task?.id as string,
                      TaskTabsEnum.COMMENTS
                    )
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.TASK_REMINDER_OVERDUE:
          if (isIcon) return <OverDue className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    taskName:
                      (event?.task?.name?.length as number) > 0
                        ? event?.task?.name
                        : `#${event?.task?.code}`,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToTaskTabSecure(event?.task?.id as string)
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.TASK_REMINDER_UPCOMING:
          if (isIcon) return <UpComing className={classes.actionSvg} />;
          return (
            <Typography
              className={classes.root}
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: t(
                  `notifications.inApp.category.${category}.${
                    isHeader ? "header" : "content"
                  }`,
                  {
                    taskName:
                      (event?.task?.name?.length as number) > 0
                        ? event?.task?.name
                        : `#${event?.task?.code}`,
                    userName: getUserDisplayName(event?.user as User)?.name,
                    href: navigateToTaskTabSecure(event?.task?.id as string)
                  }
                )
              }}
            />
          );
        case NottificationSettingsCategoriesEnum.PROJECT_COMMENT_MENTION: {
          return (
            <Box display="flex" flexDirection={"column"}>
              <Typography
                className={classes.root}
                component={"span"}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `notifications.inApp.category.${category}.${
                      isHeader ? "header" : "content"
                    }`,
                    {
                      name: event?.project?.name,
                      userName: getUserDisplayName(event?.user as User)?.name,
                      href: navigateToProjectTabSecure(
                        event?.project?.id as string,
                        ProjectTabsEnum.NOTES,
                        "createdAt"
                      )
                    }
                  )
                }}
              />
              <Typography
                onClick={(e: any) => {
                  const redirectUrl = navigateToProjectTabSecure(
                    event?.project?.id as string,
                    ProjectTabsEnum.NOTES,
                    "createdAt",
                    `comment=${event?.comment?.id}`
                  );
                  // TODO if not target blank then need to clean cache
                  window.open(redirectUrl, "_blank");
                  handleMarkAsRead();
                  e.stopPropagation();
                }}
                component={"span"}
                className={classes.mention}
                dangerouslySetInnerHTML={{
                  __html: parseTaggedContent(
                    JSON.parse(event?.comment?.content.trim() as string),
                    getUserDisplayName(currentUser)?.name
                  )
                }}
              />
            </Box>
          );
        }

        case NottificationSettingsCategoriesEnum.TASK_COMMENT_MENTION:
          return (
            <Box display="flex" flexDirection={"column"}>
              <Typography
                className={classes.root}
                component={"span"}
                dangerouslySetInnerHTML={{
                  __html: t(
                    `notifications.inApp.category.${category}.${
                      isHeader ? "header" : "content"
                    }`,
                    {
                      name: event?.task?.name?.length
                        ? event?.task?.name
                        : `#${event?.task?.code}`,
                      userName: getUserDisplayName(event?.user as User)?.name,
                      href: navigateToTaskTabSecure(
                        event?.task?.id as string,
                        TaskTabsEnum.COMMENTS
                      )
                    }
                  )
                }}
              />

              <Typography
                onClick={(e: any) => {
                  const redirectUrl = navigateToTaskTabSecure(
                    event?.task?.id as string,
                    TaskTabsEnum.COMMENTS,
                    `comment=${event?.comment?.id}`
                  );

                  // TODO if not target blank then need to clean cache
                  window.open(redirectUrl, "_blank");
                  handleMarkAsRead();
                  e.stopPropagation();
                }}
                component={"span"}
                className={classes.mention}
                dangerouslySetInnerHTML={{
                  __html: parseTaggedContent(
                    JSON.parse(event?.comment?.content.trim() as string),
                    getUserDisplayName(currentUser)?.name
                  )
                }}
              />
            </Box>
          );
        case NottificationSettingsCategoriesEnum.TASK_REMINDER_USER:
        case NottificationSettingsCategoriesEnum.COMPANY_CREATION:
        case NottificationSettingsCategoriesEnum.TASK_CREATION:
        case NottificationSettingsCategoriesEnum.PROJECT_CREATION:
        case NottificationSettingsCategoriesEnum.COMMENT_MENTION:
          return <></>;
        default:
          neverHappens = category;
          return neverHappens;
      }
    },
    [
      classes.actionSvg,
      classes.mention,
      classes.root,
      currentUser,
      isHeader,
      lang,
      t,
      updateNotification
    ]
  );

  return <>{switchType(notification, isIcon)}</>;
};
