import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MachineOperationRateActionsTypes } from "./action";
import * as Api from "./api";
import { MachineOperationRate } from "./entity";

export interface MachineOperationRateState
  extends EntityState<MachineOperationRate> {
  readStatus: FetchingStatus;
  deleteOneStatus: FetchingStatus;
}

export const MachineOperationRateInitialState: MachineOperationRateState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  deleteOneStatus: FetchingStatus.NULL
};

export const MachineOperationRateAdapter = createEntityAdapter<MachineOperationRate>();

export const read = createMyAsyncThunk<MachineOperationRate[]>(
  MachineOperationRateActionsTypes.READ,
  Api.read
);

export const deleteOne = createMyAsyncThunk<void, string>(
  MachineOperationRateActionsTypes.DELETE_ONE,
  Api.deleteOne
);

const MachineOperationRateSlice = createMySlice({
  name: "machine-operation-rates",
  initialState: MachineOperationRateAdapter.getInitialState(
    MachineOperationRateInitialState
  ),
  adapter: MachineOperationRateAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: MachineOperationRateAdapter.upsertMany
    },
    {
      action: deleteOne,
      statusName: "deleteOneStatus",
      onSuccess: MachineOperationRateAdapter.removeOne
    }
  ],
  reducers: {}
});

export const MachineOperationRateReducer = MachineOperationRateSlice.reducer;
export const MachineOperationRateActions = {
  ...MachineOperationRateSlice.actions,
  async: {
    read,
    deleteOne
  }
};
