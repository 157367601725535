import { MaterialType } from "../../entities/materialType";
import { Request } from "../../utils/request";

export const fetchMaterialTypes = () =>
  Request({ withToken: true }).get("/material-types");

export const createMaterialType = (materialType: Partial<MaterialType>) =>
  Request({ withToken: true }).post("/material-types", materialType);

export const updateMaterialTypes = ({ id, ...dto }: Partial<MaterialType>) =>
  Request({ withToken: true }).patch(`/material-types/${id}`, dto);
