import { Window } from "../../entities/window";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum WindowActionsTypes {
  WINDOW_FETCH = "@@WINDOW fetch windows",
  WINDOW_FETCH_STATUS = "@@WINDOW fetch status windows",
  WINDOW_ADD = "@@WINDOW add window"
}

export class WindowFetch {
  readonly type = WindowActionsTypes.WINDOW_FETCH;
}
export class WindowFetchStatus {
  readonly type = WindowActionsTypes.WINDOW_FETCH_STATUS;
  constructor(public status: FetchingStatus, public windows?: Window[]) {}
}
export class WindowAdd {
  readonly type = WindowActionsTypes.WINDOW_ADD;
  constructor(public window: Window) {}
}

export type WindowActions = WindowFetch | WindowFetchStatus | WindowAdd;
