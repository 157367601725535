import {
  Role,
  RolesWithContextType,
  RolesWithContext
} from "../../entities/role";
import { User, UserWPredCount } from "../../entities/user";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import i18n from "../../utils/i18n";
import {
  setCustomFieldValues,
  transformErpWNestedCustomFields
} from "../common/utils";
import { CustomFieldValue } from "../customFields/customFieldValues/types";
import { MetaEntityHipe } from "../customFields/types";
import { EntityType } from "../events/type";
import { UserPagination } from "./types";

const getRoleWithContext = (
  roles: Role[],
  collaborationIds: string[],
  lang: string
): any => {
  if (roles.length) {
    return {
      label: jsonTranslator(roles[0].label, lang),
      context: roles[0].name as RolesWithContextType
    };
  } else if (collaborationIds.length) {
    return {
      label: "Contact",
      context: RolesWithContext.CONTACT
    };
  } else {
    return {
      label: i18n.t("common:guest"),
      context: RolesWithContext.GUEST
    };
  }
};

export const transformAccount = (
  responseData: UserPagination,
  language: string
) => {
  return {
    ...responseData,
    data: responseData.data.map((user: UserWPredCount) => {
      return {
        ...user,
        requestCount: user.totalCount as number,
        role: getRoleWithContext(user.roles, user.collaborationIds, language),
        roleId: user.roleIds[0],
        customFields: setCustomFieldValues(
          user?.customFields as CustomFieldValue[],
          MetaEntityHipe.User
        ),
        rawCustomFields: user?.customFields as CustomFieldValue[]
      };
    })
  };
};

export const transformContact = <T extends User | UserWPredCount | undefined>(
  user: T
): T => {
  if (!user) {
    return user;
  }
  const customFields = setCustomFieldValues(
    user.customFields as CustomFieldValue[],
    MetaEntityHipe.Contact
  );
  const collaborations = user.collaborations?.map((company) => ({
    ...company,
    ...transformErpWNestedCustomFields(company, EntityType.Companies),
    customFields: setCustomFieldValues(
      company?.customFields as CustomFieldValue[],
      MetaEntityHipe.Company
    )
  }));

  return {
    ...user,
    collaborations: collaborations ?? [],
    customFields,
    rawCustomFields: user.customFields as CustomFieldValue[]
  };
};

export const transformContacts = (responseData: UserPagination) => {
  return {
    ...responseData,
    data: responseData.data.map((user: UserWPredCount) => {
      return transformContact(user);
    })
  };
};
