import React, { useState } from "react";
import {
  useGetMyFollowingQuery,
  useUnFollowMutation
} from "../../../../services/followers/followers.service";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { MySuspense } from "../../../../components/common/MySuspense";
import { Followers } from "../../../../services/followers/followers.type";
import { MyButton } from "../../../../components/common/MyButton";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../services/status/type";
import { ReactComponent as UnfollowIcon } from "../assets/unfollow.svg";
import { MyTypography } from "../../../../components/common/MyTypography";
import { getLanguage } from "../../../../reducers/app/selector";
import {
  DynamicJsonTranslator,
  jsonTranslator
} from "../../../../utils/function/jsonTranslator";
import { getUserDisplayName } from "../../../../utils/function/transformEntityToOptions";
import { User } from "../../../../entities/user";
import {
  EmptyState,
  EmptyStateContext
} from "../../../../components/common/EmptyState";
interface NotificationUserFollowingDisplayProps {
  name?: string;
  context?: "project" | "task";
  dueDate?: Date;
  assignee: User;
  status?: Status;
  code: number;
  id: string;
  entityId?: string;
  handleUnfollow: (id: string) => Promise<void>;
}

const formatDate = (dateString: any): string => {
  return dateString.split("T")[0];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyState: {
      "& > div > div > * > img": {
        maxWidth: "58px !important",
        maxHeight: "58px !important"
      }
    }
  })
);

const NotificationUserFollowingDisplayDisplay: React.FC<NotificationUserFollowingDisplayProps> = ({
  name,
  context,
  dueDate,
  assignee,
  status,
  code,
  id,
  entityId,
  handleUnfollow
}) => {
  const { t } = useTranslation("backoffice");
  const lang = useSelector(getLanguage);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      padding={"5px"}
      minHeight={"100px"}
    >
      <Box>
        <MyTypography
          dangerouslySetInnerHTML={{
            __html: t(
              `notifications.inApp.unfollow.header.${context?.toLowerCase()}`,
              {
                name,
                href: `/back-office/${
                  context === "project" ? "projects" : "tasks"
                }/${entityId}`,
                code
              }
            )
          }}
        ></MyTypography>
        <Box display={"flex"}>
          {status?.internalLabel && (
            <MyTypography
              dangerouslySetInnerHTML={{
                __html: `${t(`notifications.inApp.unfollow.metadataStatus`, {
                  statusName: jsonTranslator(
                    status?.internalLabel as DynamicJsonTranslator,
                    lang
                  )
                })} ${dueDate ? " | " : ""}`
              }}
            ></MyTypography>
          )}
          {dueDate && (
            <MyTypography
              dangerouslySetInnerHTML={{
                __html: t(`notifications.inApp.unfollow.metadataDueDate`, {
                  dueDate: formatDate(dueDate)
                })
              }}
            ></MyTypography>
          )}
        </Box>
        <MyTypography
          dangerouslySetInnerHTML={{
            __html: t(`notifications.inApp.unfollow.metadataAssignee`, {
              assignee: getUserDisplayName(assignee as User)?.name
            })
          }}
        ></MyTypography>
      </Box>
      <Box>
        <MyButton
          color="primary"
          variant="outlined"
          onClick={() => handleUnfollow(id)}
          leftIcon={<UnfollowIcon color="primary" />}
        >
          {t(`notifications.inApp.unfollow.${context?.toLowerCase()}`)}
        </MyButton>
      </Box>
    </Box>
  );
};

interface NotificationUserFollowingProps {}

export const NotificationUserFollowing: React.FC<NotificationUserFollowingProps> = () => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);

  const [cursor, setCursor] = useState<string>("");
  const { t } = useTranslation("backoffice");
  const {
    following,
    meta,
    isError,
    isSuccess,
    isLoading,
    isFetching
  } = useGetMyFollowingQuery(
    { userId: currentUser.id, cursor },
    {
      selectFromResult: (result: any) => {
        return {
          ...result,
          following: result.data?.data as Followers[],
          meta: result.data?.meta
        };
      }
    }
  );

  const [unfollow] = useUnFollowMutation();

  const handleUnfollow = async (id: string) => {
    await unfollow({ id });
  };

  const renderFollowing =
    following?.length > 0 &&
    following?.map((following: Followers) => {
      // TODO add company context
      const context = following?.project ? "project" : "task";
      const entity = following[context];

      return (
        <NotificationUserFollowingDisplayDisplay
          key={following.id}
          context={context}
          name={entity?.name as string}
          code={entity?.code as number}
          dueDate={entity?.dueDate as Date}
          assignee={following?.user as User}
          status={entity?.status}
          id={following.id}
          entityId={entity?.id as string}
          handleUnfollow={handleUnfollow}
        />
      );
    });

  return (
    <MySuspense
      data={following}
      loading={[]}
      success={[isSuccess]}
      error={[isError]}
      noDataComponent={
        <>
          {isSuccess && (
            <Box className={classes.emptyState}>
              <EmptyState
                context={EmptyStateContext.IN_APP_NOTIFICATIONS_FOLLOWING}
                disableAction
                containerStyle={{ backgroundColor: "transparent" }}
              />
            </Box>
          )}
        </>
      }
      showResultIf={() => (following?.length as number) > 0 && isSuccess}
    >
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gridGap="5px"
        padding="5px"
        alignItems="stretch"
      >
        {renderFollowing}
      </Box>
      {meta?.hasNextPage && (
        <Box mb={2} mt={2}>
          <MyButton
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => {
              const createdAt = (following?.slice(-1)[0]
                ?.createdAt as unknown) as string;
              setCursor(createdAt);
            }}
            leftIcon={<AddIcon color="primary" />}
          >
            {t("notifications.inApp.loadMore")}
          </MyButton>
        </Box>
      )}
    </MySuspense>
  );
};
