import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum UserActionsTypes {
  USER_FETCH = "@@USER fetch user",
  USER_FETCH_STATUS = "@@USER fetch status user",
  USER_FETCH_CONTACT = "@@USER fetch contact user",
  USER_FETCH_CONTACT_STATUS = "@@USER fetch status contact user",
  USER_FETCH_ONE_CONTACT = "@@USER fetch one contact user",
  USER_FETCH_ONE_CONTACT_STATUS = "@@USER fetch status one contact user",
  USER_FETCH_ONE = "@@USER fetch one user",
  USER_FETCH_ONE_STATUS = "@@USER fetch one status user",
  USER_INVITE = "@@USER invite user",
  USER_INVITE_STATUS = "@@USER invite user status",
  USER_UPDATE = "@@USER update",
  USER_UPDATE_STATUS = "@@USER update status",
  USER_CREATE_CONTACT = "@@USER create contact ",
  USER_CREATE_CONTACT_STATUS = "@@USER create contact status",
  USER_UPDATE_CONTACT = "@@USER update contact ",
  USER_UPDATE_CONTACT_STATUS = "@@USER update contact status",
  USER_CREATE_PROJECT = "@@USER create project ",
  USER_CREATE_PROJECT_STATUS = "@@USER create project status",
  USER_ARCHIVE = "@@USER archive contact ",
  USER_ARCHIVE_STATUS = "@@USER archive contact status",
  USER_ADD_MANY = "@@USER add many user",
  USER_REMOVE_MANY = "@@USER remove many user",
  USER_ADD_COLLABORATION = "@@USER add collaboration",
  USER_REMOVE_COLLABORATION = "@@USER remove collaboration",
  USER_UPDATE_PASSWORD = "@@USER update password",
  USER_UPDATE_PASSWORD_STATUS = "@@USER update password status",
  USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD",
  USER_FORGOT_PASSWORD_STATUS = "USER_FORGOT_PASSWORD_STATUS"
}

export class UserFetch {
  readonly type = UserActionsTypes.USER_FETCH;
}
export class UserStatus {
  readonly type = UserActionsTypes.USER_FETCH_STATUS;
  constructor(public status: FetchingStatus, public users?: User[]) {}
}

export class UserFetchOneContact {
  readonly type = UserActionsTypes.USER_FETCH_ONE_CONTACT;
  constructor(public userId: string) {}
}
export class UserFetchOneContactStatus {
  readonly type = UserActionsTypes.USER_FETCH_ONE_CONTACT_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class UserFetchContact {
  readonly type = UserActionsTypes.USER_FETCH_CONTACT;
}
export class UserFetchContactStatus {
  readonly type = UserActionsTypes.USER_FETCH_CONTACT_STATUS;
  constructor(public status: FetchingStatus, public users?: User[]) {}
}

export class UserFetchOne {
  readonly type = UserActionsTypes.USER_FETCH_ONE;
  constructor(public userId: string) {}
}
export class UserFetchOneStatus {
  readonly type = UserActionsTypes.USER_FETCH_ONE_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class UserInvite {
  readonly type = UserActionsTypes.USER_INVITE;
  constructor(public inviteUser: Partial<User>) {}
}
export class UserInviteStatus {
  readonly type = UserActionsTypes.USER_INVITE_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class UserUpdate {
  readonly type = UserActionsTypes.USER_UPDATE;
  constructor(public userId: string, public user: Partial<User>) {}
}
export class UserUpdateStatus {
  readonly type = UserActionsTypes.USER_UPDATE_STATUS;
  constructor(public status: FetchingStatus, public user?: User) {}
}

export class UserCreateProject {
  readonly type = UserActionsTypes.USER_CREATE_PROJECT;
  constructor(public userId: string, public project: Partial<Project>) {}
}
export class UserCreateProjectStatus {
  readonly type = UserActionsTypes.USER_CREATE_PROJECT_STATUS;
  constructor(
    public status: FetchingStatus,
    public project?: Partial<Project>
  ) {}
}

export class UserCreateContact {
  readonly type = UserActionsTypes.USER_CREATE_CONTACT;
  constructor(public contact: Partial<User>) {}
}
export class UserCreateContactStatus {
  readonly type = UserActionsTypes.USER_CREATE_CONTACT_STATUS;
  constructor(public status: FetchingStatus, public contact?: Partial<User>) {}
}

export class UserUpdateContact {
  readonly type = UserActionsTypes.USER_UPDATE_CONTACT;
  constructor(public userId: string, public contact: Partial<User>) {}
}
export class UserUpdateContactStatus {
  readonly type = UserActionsTypes.USER_UPDATE_CONTACT_STATUS;
  constructor(public status: FetchingStatus, public contact?: User) {}
}

export class UserArchive {
  readonly type = UserActionsTypes.USER_ARCHIVE;
  constructor(public userId: string, public cb?: () => void) {}
}
export class UserArchiveStatus {
  readonly type = UserActionsTypes.USER_ARCHIVE_STATUS;
  constructor(public status: FetchingStatus, public userId?: string) {}
}

export class UserAddCollaboration {
  readonly type = UserActionsTypes.USER_ADD_COLLABORATION;
  constructor(public userIndex: number, public companyIds: string[]) {}
}
export class UserRemoveCollaboration {
  readonly type = UserActionsTypes.USER_REMOVE_COLLABORATION;
  constructor(public userIndex: number, public companyIds: string[]) {}
}

export class UserAddMany {
  readonly type = UserActionsTypes.USER_ADD_MANY;
  constructor(public users: User[]) {}
}

export class UserRemoveMany {
  readonly type = UserActionsTypes.USER_REMOVE_MANY;
  constructor(public users: User[]) {}
}

export class UserUpdatePassword {
  readonly type = UserActionsTypes.USER_UPDATE_PASSWORD;
  constructor(public userId: string, public user: Partial<User>) {}
}
export class UserUpdatePasswordStatus {
  readonly type = UserActionsTypes.USER_UPDATE_PASSWORD_STATUS;
  constructor(public status: FetchingStatus) {}
}

export type UserActions =
  | UserFetch
  | UserStatus
  | UserFetchOneContact
  | UserFetchOneContactStatus
  | UserFetchContact
  | UserFetchContactStatus
  | UserFetchOne
  | UserFetchOneStatus
  | UserInvite
  | UserInviteStatus
  | UserUpdate
  | UserUpdateStatus
  | UserCreateContact
  | UserCreateContactStatus
  | UserCreateProject
  | UserCreateProjectStatus
  | UserUpdateContact
  | UserUpdateContactStatus
  | UserArchive
  | UserArchiveStatus
  | UserAddCollaboration
  | UserRemoveCollaboration
  | UserAddMany
  | UserRemoveMany
  | UserUpdate
  | UserUpdateStatus
  | UserUpdatePassword
  | UserUpdatePasswordStatus;
