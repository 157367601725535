import produce from "immer";

import { SheetSize } from "../../entities/sheetsize";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { SheetSizeActions, SheetSizeActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface SheetSizeState {
  sheetsizes: SheetSize[];
  createStatus: FetchingStatus;
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
  readOneStatus: FetchingStatus;
}

const initialState: SheetSizeState = {
  sheetsizes: [],
  createStatus: FetchingStatus.NULL,
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL,
  readOneStatus: FetchingStatus.NULL
};

export const SheetSizeReducer = (
  state = initialState,
  action: SheetSizeActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SheetSizeActionsTypes.SHEETSIZE_CREATE_STATUS:
        draft.createStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.pushUniqueByMutate(
            draft.sheetsizes,
            action.sheetsize as SheetSize
          );
        break;

      case SheetSizeActionsTypes.SHEETSIZE_READ_STATUS:
        draft.readStatus = action.status;
        draft.sheetsizes = action.sheetsizes || state.sheetsizes;
        break;

      case SheetSizeActionsTypes.SHEETSIZE_UPDATE_STATUS:
        draft.updateStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.sheetsizes,
            action.sheetsize as SheetSize
          );
        break;

      case SheetSizeActionsTypes.SHEETSIZE_DELETE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.removeByIdMutate(
            draft.sheetsizes,
            action.sheetsize as SheetSize
          );
        break;

      case SheetSizeActionsTypes.SHEETSIZE_READ_ONE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.sheetsizes,
            action.sheetsize as SheetSize
          );
        break;

      case SheetSizeActionsTypes.SHEETSIZE_ADD:
        ReducerMethods.upsertByIdMutate(
          draft.sheetsizes,
          action.sheetsize as SheetSize
        );
        break;

      case SheetSizeActionsTypes.SHEETSIZE_REMOVE:
        ReducerMethods.removeByIdMutate(
          draft.sheetsizes,
          action.sheetsize as SheetSize
        );
        break;

      default:
        return draft;
    }
  });
