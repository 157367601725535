import {
  MaterialReferenceSheetSize,
  MaterialReferenceSheetSizeCreateDTO,
  MaterialReferenceSheetSizeUpdateDTO
} from "../../entities/materialReferenceSheetSize";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum MaterialReferenceSheetSizeActionsTypes {
  MATERIAL_REFERENCE_SHEETSIZE_CREATE = "@@MATERIAL_REFERENCE_SHEETSIZE create materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_CREATE_STATUS = "@@MATERIAL_REFERENCE_SHEETSIZE create materialReferenceSheetSize status",
  MATERIAL_REFERENCE_SHEETSIZE_READ = "@@MATERIAL_REFERENCE_SHEETSIZE read materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_READ_STATUS = "@@MATERIAL_REFERENCE_SHEETSIZE read status materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_UPDATE = "@@MATERIAL_REFERENCE_SHEETSIZE update materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_UPDATE_STATUS = "@@MATERIAL_REFERENCE_SHEETSIZE update materialReferenceSheetSize status",
  MATERIAL_REFERENCE_SHEETSIZE_DELETE = "@@MATERIAL_REFERENCE_SHEETSIZE delete materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_DELETE_STATUS = "@@MATERIAL_REFERENCE_SHEETSIZE delete materialReferenceSheetSize status",
  MATERIAL_REFERENCE_SHEETSIZE_READ_ONE = "@@MATERIAL_REFERENCE_SHEETSIZE read one materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_READ_ONE_STATUS = "@@MATERIAL_REFERENCE_SHEETSIZE read one status materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_ADD = "@@MATERIAL_REFERENCE_SHEETSIZE add materialReferenceSheetSize",
  MATERIAL_REFERENCE_SHEETSIZE_REMOVE = "@@MATERIAL_REFERENCE_SHEETSIZE remove materialReferenceSheetSize"
}

export class MaterialReferenceSheetSizeCreate {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_CREATE;
  constructor(
    public materialReferenceSheetSize: MaterialReferenceSheetSizeCreateDTO
  ) {}
}
export class MaterialReferenceSheetSizeCreateStatus {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_CREATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReferenceSheetSize?: MaterialReferenceSheetSize
  ) {}
}

export class MaterialReferenceSheetSizeRead {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ;
}
export class MaterialReferenceSheetSizeReadStatus {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReferenceSheetSizes?: MaterialReferenceSheetSize[]
  ) {}
}

export class MaterialReferenceSheetSizeReadOne {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ_ONE;
  constructor(public id: string) {}
}
export class MaterialReferenceSheetSizeReadOneStatus {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ_ONE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReferenceSheetSize?: MaterialReferenceSheetSize
  ) {}
}

export class MaterialReferenceSheetSizeUpdate {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_UPDATE;
  constructor(
    public materialReferenceSheetSize: MaterialReferenceSheetSizeUpdateDTO
  ) {}
}
export class MaterialReferenceSheetSizeUpdateStatus {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_UPDATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReferenceSheetSize?: MaterialReferenceSheetSize
  ) {}
}

export class MaterialReferenceSheetSizeDelete {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_DELETE;
  constructor(public materialReferenceSheetSize: MaterialReferenceSheetSize) {}
}
export class MaterialReferenceSheetSizeDeleteStatus {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_DELETE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReferenceSheetSize?: MaterialReferenceSheetSize
  ) {}
}

export class MaterialReferenceSheetSizeAdd {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_ADD;
  constructor(public materialReferenceSheetSize: MaterialReferenceSheetSize) {}
}
export class MaterialReferenceSheetSizeRemove {
  readonly type =
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_REMOVE;
  constructor(public materialReferenceSheetSize: MaterialReferenceSheetSize) {}
}

export type MaterialReferenceSheetSizeActions =
  | MaterialReferenceSheetSizeCreate
  | MaterialReferenceSheetSizeCreateStatus
  | MaterialReferenceSheetSizeRead
  | MaterialReferenceSheetSizeReadStatus
  | MaterialReferenceSheetSizeUpdate
  | MaterialReferenceSheetSizeUpdateStatus
  | MaterialReferenceSheetSizeDelete
  | MaterialReferenceSheetSizeDeleteStatus
  | MaterialReferenceSheetSizeReadOne
  | MaterialReferenceSheetSizeReadOneStatus
  | MaterialReferenceSheetSizeAdd
  | MaterialReferenceSheetSizeRemove;
