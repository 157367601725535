import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import { ResultDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { AutoCompleteOption } from "../../components/common/form/MyAutocompleteField";
import { store } from "../../reducers/store";
import { AdminRoles } from "../../entities/role";
import { EntityType } from "../events/type";
import { Company } from "../../entities/company";
import { Project } from "../../entities/project";
import { CustomFieldValue } from "../customFields/customFieldValues/types";
import { safelyParseJSON } from "../../utils/function/stringUtils";
import { MetaEntityHipe, MetaEntityTransform } from "../customFields/types";
import { LogicAction } from "../forms/type";
import { CustomFieldNodesType } from "../customFields/customFieldNodes/types";
import { pick } from "lodash";

interface queryBuilder<K extends string, T> {
  query(arg: any): string | FetchArgs;
  providesTags?:
    | ResultDescription<
        K,
        AutoCompleteOption[],
        any,
        FetchBaseQueryError,
        FetchBaseQueryMeta | undefined
      >
    | undefined;
  transformResponse?(
    baseQueryReturnValue: unknown,
    meta: FetchBaseQueryMeta | undefined,
    arg: any
  ): AutoCompleteOption[] | Promise<AutoCompleteOption[]>;
}

interface QueryInput {
  url: string;
  type: string;
  fields?: string[];
  transform?: any;
}

export const getGenericOptions = <T extends { id: string }>(
  data: T[],
  fields: string[]
) => {
  const values = data.map((data) => ({
    value: data.id,
    label: fields.reduce((label, item, index) => {
      return index === 0
        ? (data as any)[item]
        : `${label} ${(data as any)[item]}`;
    }, "")
  }));
  return values;
};

export function queryEntityOptions<K extends string, T extends { id: string }>({
  url,
  type,
  fields,
  transform
}: QueryInput): queryBuilder<K, T> {
  return {
    query: () => url,
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({
              type: type as any,
              id
            })),
            { type: type, id: "PARTIAL-LIST" }
          ]
        : [{ type: type, id: "PARTIAL-LIST" }],
    transformResponse: <T extends { id: string }>(
      responseData: T[],
      _: any,
      args?: any
    ) => {
      return fields
        ? getGenericOptions(responseData, fields)
        : args
        ? transform(responseData, args)
        : transform(responseData);
    }
  };
}

export interface EntityRelationDepth {
  entity: EntityType;
  depth: number;
}
export const setCustomFieldValues = (
  customFields?: CustomFieldValue[],
  context?: MetaEntityHipe
) => {
  const metaEntity = store.getState().api.queries[
    "getInitializeMetaEntityCustomFields(undefined)"
  ]?.data as Record<MetaEntityHipe, MetaEntityTransform>;

  const customFieldsByContext = metaEntity?.[context as MetaEntityHipe];

  const requiredFields = pick(
    customFieldsByContext?.initialValues,
    customFieldsByContext?.customFields
      ?.filter((field) =>
        Boolean(
          field.logics?.find(
            (logic) => logic.action === LogicAction.REQUIRE && logic.require
          ) || field.type === CustomFieldNodesType.DATE
        )
      )
      .map((item) => item.inputName) || []
  );

  return (customFields?.length as number) > 0 ||
    (Object.keys(requiredFields).length as number) > 0
    ? customFields?.reduce(
        (
          customFields: Record<string, any>,
          customFieldValue: CustomFieldValue
        ) => {
          return {
            ...customFields,
            [customFieldValue.inputName]:
              customFieldValue.files && customFieldValue.files.length > 0
                ? customFieldValue.files
                : safelyParseJSON(customFieldValue?.value) ??
                  requiredFields?.[customFieldValue.inputName]
          };
        },
        { ...requiredFields } as any
      )
    : {};
};

// be sure that responseDate always has relations at the root level {...responseData, company, project, ...}
export function transformErpWNestedCustomFields<
  T extends {
    externalId?: string | null;
    name?: string;
    erpWName?: string;
    company?: Company;
    project?: Project | null;
  }
>(
  responseData: T,
  currentEntity?: EntityType,
  relations?: Partial<Record<EntityType, number>>
): Partial<T> {
  let erpWName, company, project;
  const user = store.getState()?.authentication?.user;
  const settings = store.getState()?.appSettings?.setting;
  const isExternalErpAllowed =
    user.roles.length !== 0 && settings.allowExternalSalesErp;

  const isAdminUser = AdminRoles.includes(user?.roles[0]?.name);

  const currentLabel =
    currentEntity === EntityType.Projects
      ? settings.externalProjectLabel
      : settings.externalCompanyLabel;

  if (currentEntity) {
    erpWName =
      responseData?.externalId &&
      (isExternalErpAllowed || isAdminUser) &&
      currentLabel
        ? `${responseData?.name} (${responseData?.externalId})`
        : responseData?.name;
  }

  if (relations?.COMPANIES) {
    company = {
      ...responseData?.company,
      customFields: setCustomFieldValues(
        responseData?.company?.customFields as CustomFieldValue[],
        MetaEntityHipe.Company
      ),
      erpWName:
        responseData?.company?.externalId &&
        (isExternalErpAllowed || isAdminUser) &&
        settings.externalCompanyLabel
          ? `${responseData?.company?.name} (${responseData?.company?.externalId})`
          : responseData?.company?.name
    } as Company;
  }

  if (relations?.PROJECTS) {
    project = {
      ...responseData?.project,
      customFields: setCustomFieldValues(
        responseData?.project?.customFields as CustomFieldValue[],
        MetaEntityHipe.Project
      ),
      erpWName:
        responseData?.project?.externalId &&
        (isExternalErpAllowed || isAdminUser) &&
        settings.externalProjectLabel
          ? `${responseData?.project?.name} (${responseData?.project?.externalId})`
          : responseData?.project?.name
    } as Project;
  }

  return {
    erpWName,
    ...(company?.erpWName && { company }),
    ...(project?.erpWName && { project })
  } as Partial<T>;
}

export function getDateParam(searchParam: string, initialParams: any) {
  const urlParam = new URLSearchParams(location.search).get(searchParam);
  const fallback = initialParams?.[searchParam];

  if (!urlParam && !fallback) {
    return undefined;
  }

  if (urlParam) {
    return new Date(parseInt(urlParam));
  } else {
    return new Date(parseInt(fallback));
  }
}
