import {
  LuxuryVarnish,
  LuxuryVarnishPatchDTO
} from "../../../entities/luxuryVarnish";
import { Request } from "../../../utils/request";

export const read = () =>
  Request({ withToken: true }).get<LuxuryVarnish>("/luxury-varnishes");

export const post = (basicVarnishs: LuxuryVarnish) =>
  Request({ withToken: true }).post<LuxuryVarnish>(
    `/luxury-varnishes`,
    basicVarnishs
  );

export const patch = ({ id, ...dto }: LuxuryVarnishPatchDTO) =>
  Request({ withToken: true }).patch(`/luxury-varnishes/${id}`, dto);
