import lodashIsEmpty from "lodash/isEmpty";
import React, { ReactElement, ReactNode, cloneElement, useMemo } from "react";
import { ErrorComponent } from "./DynamicElement/ErrorComponent";
import { LoaderComponent } from "./DynamicElement/LoaderComponent";
import { NoDataComponent } from "./DynamicElement/NoDataComponent";
import { RTKQuery } from "../../utils/api/api";

/**
 * Easy, non intrusive way to get right component based on the different fetching status you give.
 * fill all the statuses loading, success, error
 * and get back the correct component
 * Note you have to defined what will be your final data in your parent component first
 * since this component do not take any decision about what the data should be.
 * You can alter the decision of showing the result or not `showResultIf` even if the result
 * is a success
 *
 * Tips: This component has been created for the goal of skipping the Redux saga libraries
 * and implement a better generic way of handling fetching state on any libraries/logic
 * */
export type MySuspenseProps<T> = {
  /** Your final data expectation. it can be empty/undefined or not */
  data: T;
  /** List of loading attributes */
  loading: Array<boolean>;
  /** List of success attributes */
  success: Array<boolean>;
  /** List of error attributes */
  error: Array<boolean>;
  /** Define the condition yourself if you want to show the children even if there is no data
   * on a complete successfull result
   */
  showResultIf?: (data: T) => boolean;
  /** Resolve with your logic instead of this component logic.
   * We send back the current suspense state and the selected component status
   * It can help if you want to wrap the resole component or choose a different logic
   * But you have to send back a ReactNode
   * */
  getResult?: (status: SuspenseState, component: ReactNode) => ReactNode;
  /** Default loading component */
  loadingComponent?: ReactNode;
  /** Default error component */
  errorComponent?: ReactNode;
  /** Default no data component */
  noDataComponent?: ReactNode;
  /** Show even no data */
  showNoData?: boolean;
  /** Default children */
  children: ReactNode;
};

export enum SuspenseState {
  Null,
  Loading,
  Success,
  Error,
  NoData
}

export interface QueryOptions {
  pollingInterval?: 5;
  refetchOnMountOrArgChange?: boolean;
  refetchOnFocus?: boolean;
  refetchOnReconnect?: boolean;
  selectFromResult?: (data: any) => any;
}

export const MySuspense = <T extends any>(props: MySuspenseProps<T>) => {
  const {
    data,
    loading,
    success,
    error,
    getResult,
    showResultIf,
    loadingComponent,
    errorComponent,
    noDataComponent,
    showNoData,
    children
  } = props;

  const state = useMemo(() => {
    const isLoading = loading.some(Boolean);
    const isError = error.some(Boolean);
    const isSuccess = success.every(Boolean);

    /* 
      TODO not working as expected
    */
    if (isError) return SuspenseState.Error;
    if (isLoading) return SuspenseState.Loading;
    if (isSuccess) {
      if (showResultIf && showResultIf(data)) return SuspenseState.Success;
      else if (lodashIsEmpty(data)) return SuspenseState.NoData;
      else return SuspenseState.Success;
    }
    return SuspenseState.Null;
  }, [data, error, showResultIf, loading, success]);

  const result = useMemo(() => {
    let component = null;
    let neverHappen: never;
    switch (state) {
      case SuspenseState.Error:
        component = errorComponent || <ErrorComponent />;
        break;
      case SuspenseState.Loading:
        component = loadingComponent || <LoaderComponent />;
        break;
      case SuspenseState.Null:
      case SuspenseState.NoData:
        if (showNoData) {
          component = children;
          break;
        }
        component = noDataComponent || <NoDataComponent />;
        break;
      case SuspenseState.Success:
        component = children;
        break;
      default:
        neverHappen = state;
        break;
    }
    return getResult ? getResult(state, component) : component;
  }, [
    children,
    errorComponent,
    getResult,
    loadingComponent,
    noDataComponent,
    showNoData,
    state
  ]);

  return <>{result}</>;
};

export interface WithDataProps<T> {
  data?: T | undefined;
}

export interface MySuspenseQueryProps<T, K extends string, J>
  extends Omit<
    MySuspenseProps<T | undefined>,
    "data" | "loading" | "success" | "error"
  > {
  query: RTKQuery<K, T, J>;
  children: ReactElement<WithDataProps<T>>;
}

export const MySuspenseQuery = <T, K extends string, J>(
  props: MySuspenseQueryProps<T, K, J>
) => {
  const {
    query: { query, args, skip, advancedOptions },
    children,
    ...mySuspenseProps
  } = props;

  const { data, isLoading, isError, isSuccess } = query(
    { ...(args as J) },
    {
      skip,
      ...advancedOptions
    }
  );

  return (
    <MySuspense
      data={data}
      loading={[isLoading]}
      success={[isSuccess]}
      error={[isError]}
      {...mySuspenseProps}
    >
      {cloneElement(children, {
        data: data
      })}
    </MySuspense>
  );
};

export const DataProvider = <T extends any>(
  props: WithDataProps<T> & {
    children: (props: T | undefined) => ReactElement;
  }
) => {
  const { data, children } = props;
  return children(data);
};
