import { MaterialType } from "../../entities/materialType";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum MaterialTypeActionsTypes {
  MATERIAL_TYPE_FETCH = "@@MATERIAL_TYPE fetch materialType",
  MATERIAL_TYPE_FETCH_STATUS = "@@MATERIAL_TYPE fetch status materialType",
  MATERIAL_TYPE_ADD = "@@MATERIAL_TYPE add material type",
  MATERIAL_TYPE_CREATE = "@@MATERIAL_TYPE create materialType",
  MATERIAL_TYPE_CREATE_STATUS = "@@MATERIAL_TYPE create materialType status",
  MATERIAL_TYPE_REQUEST_UPDATE = "@@MATERIAL_TYPE update request",
  MATERIAL_TYPE_REQUEST_UPDATE_STATUS = "@@MATERIAL_TYPE update request status"
}

export class MaterialTypeFetch {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_FETCH;
}
export class MaterialTypeStatus {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_FETCH_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialTypes?: MaterialType[]
  ) {}
}
export class MaterialTypeAdd {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_ADD;
  constructor(public materialType: Partial<MaterialType>) {}
}

export class MaterialTypeUpdate {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_REQUEST_UPDATE;
  constructor(public materialType: Partial<MaterialType>) {}
}
export class MaterialTypeUpdateStatus {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_REQUEST_UPDATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialType?: MaterialType
  ) {}
}

export class MaterialTypeCreate {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_CREATE;
  constructor(public materialType: Partial<MaterialType>) {}
}
export class MaterialTypeCreateStatus {
  readonly type = MaterialTypeActionsTypes.MATERIAL_TYPE_CREATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialType?: MaterialType
  ) {}
}

export type MaterialTypeActions =
  | MaterialTypeFetch
  | MaterialTypeStatus
  | MaterialTypeAdd
  | MaterialTypeUpdate
  | MaterialTypeUpdateStatus
  | MaterialTypeCreate
  | MaterialTypeCreateStatus;
