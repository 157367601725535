import { memoize } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "..";
import { User } from "../../entities/user";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { getLanguage } from "../app/selector";
import { getCurrentUser } from "../authentication/selector";
import { OperationSelector } from "../operations/selector";
import { getProductsCategory } from "../productCategory/selector";
import { Product } from "./entity";
import { ProductAdapter } from "./reducer";

const AdapterSelector = ProductAdapter.getSelectors(
  (state: RootState) => state.products
);

export const getState = createSelector(
  (state: RootState) => state,
  (state) => state.products
);

export const getProductFetchStatus = createSelector(
  (state: RootState) => state.products,
  (products) => products.readStatus
);

export const getProductsEnabled = createSelector(
  AdapterSelector.selectAll,
  (products) => products.filter((p) => p.enabled)
);

export const getProductById = (id: string) =>
  createSelector(
    (state: RootState) => AdapterSelector.selectById(state, id),
    (product) => product
  );

export const getProductOptions = createSelector(
  getProductsEnabled,
  getLanguage,
  (products, lang) =>
    products.map((p) => ({
      value: p.id,
      label: jsonTranslator(p.label, lang)
    }))
);

export const getProdutsByProductCategory = (
  productCategoryId?: string,
  productSubCategoryIds?: string[]
) =>
  createSelector(
    getProductsEnabled,
    getLanguage,
    memoize((products: Product[], lang: string) => {
      if (!productCategoryId) {
        return;
      }
      let productsBySubCategories = products;
      if (productSubCategoryIds && productSubCategoryIds.length > 0) {
        productsBySubCategories = productsBySubCategories.filter((p) => {
          return productSubCategoryIds?.every((subCat) => {
            return p.subCategoriesIds!.includes(subCat);
          });
        });
      }

      return products
        .filter((p) => p.productCategoryId === productCategoryId)
        .map((p) => ({
          ...p,
          _label: jsonTranslator(p.label, lang)
        }))
        .sort((a, b) => a._label.localeCompare(b._label));
    })
  );

export const getProdutsByProductSubCategory = (
  productSubCategoryIds?: string[]
) =>
  createSelector(
    getProductsEnabled,
    getLanguage,
    memoize((products: Product[], lang: string) => {
      let productsBySubCategories = products;
      if (productSubCategoryIds && productSubCategoryIds.length > 0) {
        productsBySubCategories = productsBySubCategories.filter((p) => {
          return productSubCategoryIds?.every((subCat) => {
            return p.subCategoriesIds!.includes(subCat);
          });
        });
      }
      return productsBySubCategories
        .map((p) => ({
          ...p,
          _label: jsonTranslator(p.label, lang)
        }))
        .sort((a, b) => a._label.localeCompare(b._label));
    })
  );

export const getProductsOptionsByProductCategory = (
  productCategoryId?: string,
  productSubCategoryIds?: string[]
) =>
  createSelector(
    getProductsEnabled,
    getLanguage,
    memoize((products: Product[], lang: string) => {
      if (!productCategoryId) {
        return;
      }

      let productsBySubCategories = products;
      if (productSubCategoryIds && productSubCategoryIds.length > 0) {
        productsBySubCategories = productsBySubCategories.filter((p) => {
          return productSubCategoryIds?.every((subCat) => {
            return p.subCategoriesIds!.includes(subCat);
          });
        });
      }

      return productsBySubCategories
        .filter((p) => p.productCategoryId === productCategoryId)
        .map((p) => ({
          value: p.id,
          label: jsonTranslator(p.label, lang)
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    })
  );

const filterBySubCategoriesAndRoles = (
  products: Product[],
  user: User,
  productSubCategoryIds?: string[],
  duplicatedProductId?: string
) => {
  let productsBySubCategories =
    user.roleIds.length > 0
      ? products.filter(
          (item) =>
            !item.roleIds?.includes(user.roleIds[0]) ||
            item.id === duplicatedProductId
        )
      : products.filter(
          (item) => item.isAllowingGuest || item.id === duplicatedProductId
        );

  if (productSubCategoryIds && productSubCategoryIds.length > 0) {
    productsBySubCategories = productsBySubCategories.filter((p) => {
      return productSubCategoryIds?.every((subCat) => {
        return p.subCategoriesIds!.includes(subCat);
      });
    });
  }

  return productsBySubCategories;
};

export const getProductsBySubCategoriesAndRoles = (
  productSubCategoryIds?: string[],
  duplicatedProductId?: string
) =>
  createSelector(
    getProductsEnabled,
    getLanguage,
    getCurrentUser,
    memoize((products: Product[], lang: string, user: User) => {
      return filterBySubCategoriesAndRoles(
        products,
        user,
        productSubCategoryIds,
        duplicatedProductId
      );
    })
  );

export const getProductsOptionsBySubCategoriesAndRoles = (
  productSubCategoryIds?: string[],
  duplicatedProductId?: string
) =>
  createSelector(
    getProductsEnabled,
    getLanguage,
    getCurrentUser,
    memoize((products: Product[], lang: string, user: User) => {
      return filterBySubCategoriesAndRoles(
        products,
        user,
        productSubCategoryIds,
        duplicatedProductId
      )
        .map((p) => ({
          value: p.id,
          label: jsonTranslator(p.label, lang)
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    })
  );

export const getProductListWithRelations = createSelector(
  AdapterSelector.selectAll,
  getProductsCategory,
  OperationSelector.selectAll,
  getLanguage,
  (products, productCategories, operations) =>
    products.map((product) => {
      const productCategory = productCategories.find(
        (pc) => product.productCategoryId === pc.id
      );
      return {
        ...product,
        productCategory
      };
    })
);

export const getTotalProduct = createSelector(
  AdapterSelector.selectAll,
  (products) => products.length
);

export const ProductSelector = { ...AdapterSelector, getState };
