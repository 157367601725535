import { ProductCategory } from "../../entities/productCategory";
import { ConfiguratorInputOptionsComputed } from "../configurator-input-options/selector";
import { SectionNames } from "../configurator-inputs/constant";
import { Product, Scale3D } from "../products/entity";
import { BriefElementConfiguratorValues } from "./reducer";

export enum BriefElementConfiguratorActionsTypes {
  SET_SECTION_TOUCHED = "@@BRIEF_ELEMENT_CONFIGURATOR user has submit the form",
  BRIEF_ELEMENT_CONFIGURATOR_SECTION_TOUCHED = "@@BRIEF_ELEMENT_CONFIGURATOR expansion touched",
  BRIEF_ELEMENT_CONFIGURATOR_SECTION_EXPAND = "@@BRIEF_ELEMENT_CONFIGURATOR expand section",
  BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_SELECTED = "@@BRIEF_ELEMENT_CONFIGURATOR set product selected",
  BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_SELECTED_SCALE = "@@BRIEF_ELEMENT_CONFIGURATOR set product 3D viewer scale",
  BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_VISIBILITY_STATE = "@@BRIEF_ELEMENT_CONFIGURATOR set product visibility state",
  BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_CATEGORY_SELECTED = "@@BRIEF_ELEMENT_CONFIGURATOR set product category selected",
  BRIEF_ELEMENT_CONFIGURATOR_SET_VALUES = "@@BRIEF_ELEMENT_CONFIGURATOR set values",
  BRIEF_ELEMENT_CONFIGURATOR_SET_INITIAL_VALUES = "@@BRIEF_ELEMENT_CONFIGURATOR set initial values",
  BRIEF_ELEMENT_CONFIGURATOR_SET_SUBMIT_VALUES = "@@BRIEF_ELEMENT_CONFIGURATOR set submit values",
  BRIEF_ELEMENT_CONFIGURATOR_SET_WITH_PRODUCT = "@@BRIEF_ELEMENT_CONFIGURATOR set with product",
  BRIEF_ELEMENT_CONFIGURATOR_ON_CHANGE = "@@BRIEF_ELEMENT_CONFIGURATOR on change",
  BRIEF_ELEMENT_CONFIGURATOR_SET_SUBMIT_COUNT = "@@BRIEF_ELEMENT_CONFIGURATOR set submit count",
  BRIEF_ELEMENT_CONFIGURATOR_HAS_SUBMIT_ONCE = "@@BRIEF_ELEMENT_CONFIGURATOR has submit once",
  BRIEF_ELEMENT_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT = "@@BRIEF_ELEMENT_CONFIGURATOR has change after submit",
  BRIEF_ELEMENT_CONFIGURATOR_IS_VALID = "@@BRIEF_ELEMENT_CONFIGURATOR is valid",
  BRIEF_ELEMENT_CONFIGURATOR_ADD_AND_SELECT = "@@BRIEF_ELEMENT_CONFIGURATOR add and select",
  BRIEF_ELEMENT_CONFIGURATOR_ADD = "@@BRIEF_ELEMENT_CONFIGURATOR add",
  BRIEF_ELEMENT_CONFIGURATOR_REMOVE_AND_SELECT = "@@BRIEF_ELEMENT_CONFIGURATOR remove and select",
  BRIEF_ELEMENT_CONFIGURATOR_REMOVE = "@@BRIEF_ELEMENT_CONFIGURATOR remove",
  BRIEF_ELEMENT_CONFIGURATOR_CLEAR = "@@BRIEF_ELEMENT_CONFIGURATOR clear",
  BRIEF_ELEMENT_CONFIGURATOR_CLEAR_AND_SWITCH_TO_PRODUCT = "@@BRIEF_ELEMENT_CONFIGURATOR clear and switch to product",
  BRIEF_ELEMENT_CONFIGURATOR_BLUR = "@@BRIEF_ELEMENT_CONFIGURATOR blur",
  BRIEF_ELEMENT_CONFIGURATOR_INPUT_OPTIONS_COMPUTED = "@@BRIEF_ELEMENT_CONFIGURATOR set input options computed",
  BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_FROM_URL = "@@BRIEF_ELEMENT_CONFIGURATOR set product url"
}

export class BriefElementSetSectionTouched {
  readonly type = BriefElementConfiguratorActionsTypes.SET_SECTION_TOUCHED;
  constructor(readonly position: string) {}
}

export class BriefElementConfiguratorSectionTouched {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SECTION_TOUCHED;
  constructor(readonly position: string, readonly sectionName: SectionNames) {}
}

export class BriefElementConfiguratorSectionExpand {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SECTION_EXPAND;
  constructor(
    readonly position: string,
    public sectionName: SectionNames,
    public isExpanded: boolean,
    public closeOthers?: boolean,
    public product?: Product
  ) {}
}

export class BriefElementConfiguratorSetProductSelected {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_SELECTED;
  constructor(readonly position: string, readonly productSelected?: Product) {}
}

export class BriefElementConfiguratorSetProductSelectedScale {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_SELECTED_SCALE;
  constructor(readonly position: string, readonly scale3D?: Scale3D) {}
}

export class BriefElementConfiguratorSetProductVisibilityState {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_VISIBILITY_STATE;
  constructor(
    readonly position: string,
    readonly productVisibilityState?: boolean
  ) {}
}

export class BriefElementConfiguratorSetProductUrl {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_FROM_URL;
  constructor(readonly position: string, readonly productFromUrl?: boolean) {}
}

export class BriefElementConfiguratorSetProductCategorySelected {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_PRODUCT_CATEGORY_SELECTED;
  constructor(
    readonly position: string,
    readonly productCategorySelected?: ProductCategory
  ) {}
}

export class BriefElementConfiguratorSetValues {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_VALUES;
  constructor(
    readonly position: string,
    readonly values?: BriefElementConfiguratorValues
  ) {}
}

export class BriefElementConfiguratorSetInitialValues {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_INITIAL_VALUES;
  constructor(
    readonly position: string,
    readonly initialValues?: BriefElementConfiguratorValues
  ) {}
}

export class BriefElementConfiguratorSetWithProduct {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_WITH_PRODUCT;
  constructor(
    readonly position: string,
    readonly productId: string,
    readonly subcategory?: string,
    readonly isValidToken?: boolean
  ) {}
}

export class BriefElementConfiguratorClearAndSwitchToProduct {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_CLEAR_AND_SWITCH_TO_PRODUCT;
  constructor(
    readonly position: string,
    readonly product: Product,
    readonly productCategory: ProductCategory
  ) {}
}

export class BriefElementConfiguratorSetSubmitValues {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_SUBMIT_VALUES;
  constructor(
    readonly position: string,
    readonly submitValues: BriefElementConfiguratorValues
  ) {}
}

export class BriefElementConfiguratorOnChange {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_ON_CHANGE;
  constructor(
    public position: string,
    public values: BriefElementConfiguratorValues
  ) {}
}

export class BriefElementConfiguratorSetSubmitCount {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_SET_SUBMIT_COUNT;
  constructor(readonly position: string, readonly count: number) {}
}

export class BriefElementConfiguratorHasSubmitOnce {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_HAS_SUBMIT_ONCE;
  constructor(readonly position: string) {}
}

export class BriefElementConfiguratorHasChangeAfterSubmit {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_HAS_CHANGE_AFTER_SUBMIT;
  constructor(readonly position: string, readonly hasChange: boolean) {}
}

export class BriefElementConfiguratorIsValid {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_IS_VALID;
  constructor(readonly position: string, public isValid: boolean) {}
}

export class BriefElementConfiguratorClear {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_CLEAR;
}

export class BriefElementConfiguratorAddAndSelect {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_ADD_AND_SELECT;
}

export class BriefElementConfiguratorAdd {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_ADD;
  constructor(readonly nextPosition: string) {}
}

export class BriefElementConfiguratorRemoveAndSelect {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_REMOVE_AND_SELECT;
  constructor(readonly position: string) {}
}
export class BriefElementConfiguratorRemove {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_REMOVE;
  constructor(readonly position: string) {}
}

export class BriefElementConfiguratorBlur {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_BLUR;
  constructor(readonly position: string) {}
}

export class BriefElementConfiguratorInputOptionComputed {
  readonly type =
    BriefElementConfiguratorActionsTypes.BRIEF_ELEMENT_CONFIGURATOR_INPUT_OPTIONS_COMPUTED;
  constructor(
    readonly position: string,
    readonly inputOptionsComputed: ConfiguratorInputOptionsComputed
  ) {}
}

export type BriefElementConfiguratorActions =
  | BriefElementSetSectionTouched
  | BriefElementConfiguratorSectionTouched
  | BriefElementConfiguratorSectionExpand
  | BriefElementConfiguratorSetProductSelected
  | BriefElementConfiguratorSetProductSelectedScale
  | BriefElementConfiguratorSetProductVisibilityState
  | BriefElementConfiguratorSetProductCategorySelected
  | BriefElementConfiguratorSetValues
  | BriefElementConfiguratorSetInitialValues
  | BriefElementConfiguratorSetSubmitValues
  | BriefElementConfiguratorOnChange
  | BriefElementConfiguratorSetSubmitCount
  | BriefElementConfiguratorSetWithProduct
  | BriefElementConfiguratorClearAndSwitchToProduct
  | BriefElementConfiguratorHasSubmitOnce
  | BriefElementConfiguratorHasChangeAfterSubmit
  | BriefElementConfiguratorIsValid
  | BriefElementConfiguratorClear
  | BriefElementConfiguratorAdd
  | BriefElementConfiguratorAddAndSelect
  | BriefElementConfiguratorRemove
  | BriefElementConfiguratorRemoveAndSelect
  | BriefElementConfiguratorBlur
  | BriefElementConfiguratorInputOptionComputed
  | BriefElementConfiguratorSetProductUrl;
