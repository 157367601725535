import { AxiosError } from "axios";
import { apiSlice } from "../../utils/api/api";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { CrmFilter } from "./type";
import { CrmFiltersReturnMessage } from "./constant";
import { clearLocalStorageCrm } from "../../components/common/GenericTable/components/GenericFilterTable";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["CrmFilter"]
});

export const crmFitlerApiSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getOneCrmFilter: build.query<CrmFilter, string>({
      query: (id) => `/crm-filters/${id}/custom`,
      providesTags: (result) =>
        result ? [{ type: "CrmFilter", id: result.type }] : [],
      async onQueryStarted(_, { __, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const isCrmFilterNotFound =
            (err as any).error.data ===
            CrmFiltersReturnMessage.CRM_FILTER_NOT_FOUND;

          if (isCrmFilterNotFound) {
            clearLocalStorageCrm();
            return;
          }
        }
      }
    }),
    createCrmFilter: build.mutation<CrmFilter, Partial<CrmFilter>>({
      query: ({ id, ...body }) => ({
        url: `/crm-filters`,
        method: "POST",
        body: body
      }),
      async onQueryStarted(parameters, { dispatch, queryFulfilled }) {
        try {
          const data = await queryFulfilled;

          await dispatch(
            crmFitlerApiSlice.util.upsertQueryData(
              "getOneCrmFilter",
              data.data.id,
              data.data as CrmFilter
            )
          );
          /*   if (data?.data?.type) {  
            dispatch(
              crmFitlerApiSlice.util.invalidateTags([
                { type: "CrmFilter", id: data.data.type }
              ])
            );
          } */
        } catch (error) {
          const isInvalidSql =
            (error as any).error.data === CrmFiltersReturnMessage.SQL_INVALID;

          dispatch(
            sendErrorNotification(
              error as AxiosError,
              `${
                isInvalidSql
                  ? i18n.t("saga:crm-filter.failed").toString()
                  : i18n.t("saga:create-failed").toString()
              }`,
              true
            )
          );
        }
      }
    })
  }),
  overrideExisting: true
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetOneCrmFilterQuery,
  useCreateCrmFilterMutation,
  useLazyGetOneCrmFilterQuery
} = crmFitlerApiSlice;
