import type { FormikTouched, FormikErrors } from "formik";
import type { RootState } from "..";
import type { BriefElementConfiguratorValues } from "../briefElementConfigurator/reducer";
import type { SectionNames } from "../configurator-inputs/constant";
import type { Product } from "../products/entity";

// Props selectors
export const getPropsProduct = <T extends { product: Product | undefined }>(
  _state: RootState,
  props: T
) => props.product;

export const getPropsPositionOptional = <
  T extends { position: string | undefined }
>(
  _state: RootState,
  props: T
) => props.position;

export const getPropsPosition = <T extends { position: string }>(
  _state: RootState,
  props: T
) => props.position;

export const getPropsName = <T extends { name: SectionNames }>(
  _state: RootState,
  props: T
) => props.name;

export const getPropsIsMaterialSet = <T extends { isMaterialSet: boolean }>(
  _state: RootState,
  props: T
) => props.isMaterialSet;

export const getPropsTouched = <
  T extends {
    touched: FormikTouched<BriefElementConfiguratorValues>;
  }
>(
  _state: RootState,
  props: T
) => props.touched;

export const getPropsErrors = <
  T extends {
    errors: FormikErrors<BriefElementConfiguratorValues>;
  }
>(
  _state: RootState,
  props: T
) => props.errors;

// getPropsFactory
export const getPropsFactory = <V, K extends string>(key: K) => {
  return <T extends { [key in K]: V }>(_state: RootState, props: T) =>
    props[key];
};
