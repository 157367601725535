import { Socket, io } from "socket.io-client";

// Create the socket instance only once for a specific socket.io namespace.
export const createSocketFactory = (wsNamespace: string) => {
  let _socket: Socket;

  return async (): Promise<Socket> => {
    if (!_socket) {
      /*   const accessToken = await AsyncStorage.getItem(ACCESS_TOKEN); */
      _socket = io(
        //process.env.REACT_APP_API || "/api".replace(apiPrefix, wsNamespace),
        /* process.env.REACT_APP_API!.replace(apiPrefix, wsNamespace), */
        `${
          process.env.NODE_ENV === "production"
            ? `${window.origin}/${wsNamespace}`
            : `http://localhost:3001/${wsNamespace}`
        }`,
        {
          auth: { token: localStorage.getItem("token") },
          transports: ["websocket", "polling"],
          autoConnect: false,
          withCredentials: true
        }
      );
      // console.log("Socket created and connected");
      // console.log(
      //   process.env.REACT_APP_API,
      //   process.env.NODE_ENV,
      //   wsNamespace,
      //   `${
      //     process.env.NODE_ENV === "production"
      //       ? `${window.origin}/${wsNamespace}`
      //       : `http://localhost:3001/${wsNamespace}`
      //   }`
      // );
    }

    if (_socket.disconnected) {
      // console.log("Socket reconnection");
      _socket.connect();
    }

    // console.log("Socket returned");
    return _socket;
  };
};

// Since I use socket.io, i've made the socketEmitAsPromise to transform the response as a promise by using the callback of socket.io response.
// My API return either {data: xxx, error: null} OR {data: null, error: xxx}.
// So in the callback I can either reject or resolve my response and use the queryFulfilled of RTKq perfectly !
export const socketEmitAsPromise = (socket: Socket) => {
  return <TData = any>(message: string, data: TData): Promise<any> => {
    return new Promise((resolve, reject) => {
      socket.emit(message, data, (response: any) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(response);
        }
      });
    });
  };
};
