import { Role } from "../../entities/role";
import { apiSlice } from "../../utils/api/api";
import { queryEntityOptions } from "../common/utils";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["RoleOptions"]
});

const extendedApiSlice = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<Role[], void>({
      query: () => "roles"
    }),
    getRoleOptions: build.query<any[], void>({
      ...queryEntityOptions({
        url: "roles",
        type: "RoleOptions",
        transform: undefined,
        fields: ["name"]
      })
    }),
    getSalesRoles: build.query<Role, void>({
      query: () => "roles/sales"
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetRolesQuery,
  useGetSalesRolesQuery,
  useGetRoleOptionsQuery
} = extendedApiSlice;
