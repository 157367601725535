import { Box, Select, MenuItem } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/LanguageOutlined";
import { useDispatch, useSelector } from "react-redux";
import { AppSetLanguage } from "../../../../reducers/app/action";
import { getAllEnabledLocalisations } from "../../../../reducers/appSettings/selector";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../../../reducers/app/selector";
import { statusServiceUtil } from "../../../../services/status/status.service";
import { getIsPackitooUser } from "../../../../reducers/authentication/selector";

interface LanguageSelectorProps {
  onClose?: () => void;
  isResponsive?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onClose,
  isResponsive
}) => {
  const dispatch = useDispatch();
  const activeLanguage = useSelector(getLanguage);

  const isPackitoo = useSelector(getIsPackitooUser);
  const allLanguages = useSelector(getAllEnabledLocalisations);
  const { t: tLang } = useTranslation("lang");
  const { t } = useTranslation("backoffice");

  const setLanguage = (lang: string) => {
    dispatch(new AppSetLanguage(lang));
    dispatch(statusServiceUtil.invalidateTags(["EntityOptionStatuses"]));
  };

  return (
    <Box
      mr={2}
      display="flex"
      alignItems="center"
      style={{ ...(isResponsive && { padding: "0 8px" }) }}
    >
      <LanguageIcon
        style={{
          ...(isResponsive && {
            width: "28px",
            height: "24px",
            marginRight: "8px"
          })
        }}
      />
      <Select
        name="language"
        onChange={(e) => {
          setLanguage(e.target.value as string);
          onClose && onClose();
        }}
        value={activeLanguage}
      >
        {allLanguages.map((lang) => (
          <MenuItem key={lang.id} value={lang.id}>
            {tLang(`${lang.id}`)}
          </MenuItem>
        ))}
        {isPackitoo && (
          <MenuItem key="cimode" value="cimode">
            {t(`settings.languagesSettings.cimode`)}
          </MenuItem>
        )}
      </Select>
    </Box>
  );
};
