import { AxiosRequestConfig } from "axios";
import { Request } from "../../utils/request";
import { AppSettingFileType } from "./constant";
import { AppSetting } from "./entity";

export const read = () =>
  Request({ withToken: true }).get<AppSetting>("/app-settings");

export type UpdateFileTypeProps = {
  type: AppSettingFileType;
  formData: FormData;
  onUploadProgress: AxiosRequestConfig["onUploadProgress"];
};
export const updateFileType = ({
  type,
  formData,
  onUploadProgress
}: UpdateFileTypeProps) => {
  let path: string;
  switch (type) {
    case AppSettingFileType.LOGO:
      path = "logo";
      break;
    case AppSettingFileType.FONT:
      path = "font";
      break;
    case AppSettingFileType.FAVICON:
      path = "favIcon";
      break;
  }
  return Request({ withToken: true }).post<AppSetting>(
    `/app-settings/${path}`,
    formData,
    { onUploadProgress }
  );
};

export const patch = (appSetting: Partial<AppSetting>) =>
  Request({ withToken: true }).patch<AppSetting>(`/app-settings`, appSetting);
