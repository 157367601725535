/* eslint-disable no-sequences */
import {
  array,
  ArraySchema,
  boolean,
  number,
  NumberSchema,
  object,
  string
} from "yup";
import { SideName } from "../../../../../../entities/brief";
import { VarnishMethodName } from "../../../../../../entities/decoration";
import { VarnishParameterDTO } from "../../../../../../entities/varnishParameter";
import { InputOptionsAdvanced } from "../../../../../../reducers/configurator-input-options/entity";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import i18n from "../../../../../../utils/i18n";
import {
  applyInputOptionsLogic,
  applyInputOptionsLogicBoolean,
  applyInputOptionsLogicNumber,
  applyInputOptionsLogicObject,
  applyInputOptionsLogicString,
  setYupLocale
} from "../../../../../../utils/yup";
import { LayoutSchemaWithOptions } from "./layouCount.schema";

setYupLocale();

export type VarnishParamsSchemaType = ArraySchema<Partial<VarnishParameterDTO>>;

export const VarnishParamsSchemaWithOptions = (
  name: "varnishId" | "luxuryVarnishId"
) => (
  required: boolean,
  options?: InputOptionsAdvanced
): VarnishParamsSchemaType => {
  let coverageSchema: NumberSchema = number()
    .min(0)
    .max(1, () => i18n.t("yup:number.maxPercentage"));
  if (options?.object?.coverage?.min) {
    coverageSchema = coverageSchema.min(options?.object?.coverage?.min);
  }
  if (options?.object?.coverage?.max) {
    coverageSchema = coverageSchema.max(options?.object?.coverage?.max);
  }

  let varnishSchema = string();

  if (required) {
    coverageSchema = coverageSchema.required();
    varnishSchema = varnishSchema.required();
  }

  return array()
    .nullable(!required)
    .of(
      object({
        [name]: varnishSchema,
        coverage: coverageSchema,
        position: number().min(0)
      })
    )
    .min(required ? 1 : 0);
};

export const EmbossingDebossingParamsWithOptions = (
  required: boolean,
  options?: InputOptionsAdvanced
) => {
  let widthSchema = number().min(0);
  if (options?.object?.width?.min) {
    widthSchema = widthSchema.min(options.object.width.min);
  }
  if (options?.object?.width?.max) {
    widthSchema = widthSchema.max(options.object.width.max);
  }

  let heightSchema = number().min(0);
  if (options?.object?.width?.min) {
    heightSchema = heightSchema.min(options.object.width.min);
  }
  if (options?.object?.width?.max) {
    heightSchema = heightSchema.max(options.object.width.max);
  }

  let detailsSchema = string();

  if (required) {
    widthSchema = widthSchema.required();
    heightSchema = heightSchema.required();
    detailsSchema = detailsSchema.required();
  }

  return array()
    .nullable(!required)
    .of(
      object({
        width: widthSchema,
        height: heightSchema,
        details: detailsSchema,
        position: number().min(0)
      })
    )
    .min(required ? 1 : 0);
};

export const FinishSchema = {
  wantProtection: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_PROTECTION,
    boolean().default(false)
  ),
  sideProtected: applyInputOptionsLogicString(
    ConfiguratorInputNames.SIDE_PROTECTED,
    string().oneOf([
      SideName.INSIDE,
      SideName.INSIDE_AND_OUTSIDE,
      SideName.OUTSIDE
    ]),
    string()
  ),
  wantVarnishRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_VARNISH_RECTO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  wantVarnishVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_VARNISH_VERSO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  varnishMethodRecto: applyInputOptionsLogicString(
    ConfiguratorInputNames.VARNISH_METHOD_RECTO,
    string().oneOf([VarnishMethodName.FLEXOGRAPHY, VarnishMethodName.OFFSET]),
    string()
  ),
  varnishMethodVerso: applyInputOptionsLogicString(
    ConfiguratorInputNames.VARNISH_METHOD_VERSO,
    string().oneOf([VarnishMethodName.FLEXOGRAPHY, VarnishMethodName.OFFSET]),
    string()
  ),
  flexoVarnishRectoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.FLEXO_VARNISH_RECTO_ID,
    string()
  ),
  flexoVarnishVersoId: applyInputOptionsLogicString(
    ConfiguratorInputNames.FLEXO_VARNISH_VERSO_ID,
    string()
  ),
  flexoVarnishCoverageRecto: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.FLEXO_VARNISH_COVERAGE_RECTO,
    number()
      .min(0)
      .max(1, () => i18n.t("yup:number.maxPercentage"))
      .default(1) //Important: because should be 1 if current base precondition is not met
  ),
  flexoVarnishCoverageVerso: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.FLEXO_VARNISH_COVERAGE_VERSO,
    number()
      .min(0)
      .max(1, () => i18n.t("yup:number.maxPercentage"))
      .default(1) //Important: because should be 1 if current base precondition is not met
  ),
  finishPartialSurfaceRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FINISH_PARTIAL_SURFACE_RECTO,
    boolean().default(false)
  ),
  finishPartialSurfaceVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FINISH_PARTIAL_SURFACE_VERSO,
    boolean().default(false)
  ),
  flexoPlateVarnishExistRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_VARNISH_EXIST_RECTO,
    boolean().default(false)
  ),
  flexoPlateVarnishExistVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_VARNISH_EXIST_VERSO,
    boolean().default(false)
  ),
  flexoPlateVarnishForceRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_VARNISH_FORCE_RECTO,
    boolean().default(false)
  ),
  flexoPlateVarnishForceVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.FLEXO_PLATE_VARNISH_FORCE_VERSO,
    boolean().default(false)
  ),
  flexoPlateVarnishLayoutCountRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.FLEXO_PLATE_VARNISH_LAYOUT_COUNT_RECTO,
    LayoutSchemaWithOptions
  ),
  flexoPlateVarnishLayoutCountVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.FLEXO_PLATE_VARNISH_LAYOUT_COUNT_VERSO,
    LayoutSchemaWithOptions
  ),
  varnishParamsOffsetRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.VARNISH_PARAMS_OFFSET_RECTO,
    VarnishParamsSchemaWithOptions("varnishId")
  ),
  varnishParamsOffsetVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.VARNISH_PARAMS_OFFSET_VERSO,
    VarnishParamsSchemaWithOptions("varnishId")
  ),
  wantLaminationRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_LAMINATION_RECTO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  wantLaminationVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_LAMINATION_VERSO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  laminationIdRecto: applyInputOptionsLogic(
    ConfiguratorInputNames.LAMINATION_ID_RECTO,
    string()
  ),
  laminationIdVerso: applyInputOptionsLogic(
    ConfiguratorInputNames.LAMINATION_ID_VERSO,
    string()
  ),
  wantFinishes: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_FINISHES,
    boolean().default(false)
  ),
  sideFinished: applyInputOptionsLogicString(
    ConfiguratorInputNames.SIDE_FINISHED,
    string().oneOf([
      SideName.INSIDE,
      SideName.INSIDE_AND_OUTSIDE,
      SideName.OUTSIDE
    ]),
    string()
  ),
  wantLuxuryVarnishRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_LUXURY_VARNISH_RECTO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  wantLuxuryVarnishVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_LUXURY_VARNISH_VERSO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  luxuryVarnishParamsRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.LUXURY_VARNISH_PARAMS_RECTO,
    VarnishParamsSchemaWithOptions("luxuryVarnishId")
  ),
  luxuryVarnishParamsVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.LUXURY_VARNISH_PARAMS_VERSO,
    VarnishParamsSchemaWithOptions("luxuryVarnishId")
  ),
  wantEmbossingDebossingRecto: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING_RECTO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  wantEmbossingDebossingVerso: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WANT_EMBOSSING_DEBOSSING_VERSO,
    boolean().oneOf([true], () => i18n.t("yup:mixed.required")),
    boolean()
  ),
  embossingDebossingParamsRecto: applyInputOptionsLogicObject(
    ConfiguratorInputNames.EMBOSSING_DEBOSSING_PARAMS_RECTO,
    EmbossingDebossingParamsWithOptions
  ),
  embossingDebossingParamsVerso: applyInputOptionsLogicObject(
    ConfiguratorInputNames.EMBOSSING_DEBOSSING_PARAMS_VERSO,
    EmbossingDebossingParamsWithOptions
  )
};
