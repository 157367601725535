import { createSelector } from "reselect";
import { RootState } from "..";
import { ConfiguratorInputAdapter } from "./reducer";

export const AdapterSelector = ConfiguratorInputAdapter.getSelectors(
  (state: RootState) => state.configuratorInputs
);

export const getState = createSelector(
  (state: RootState) => state,
  (state) => state.configuratorInputs
);

export const getOptions = createSelector(AdapterSelector.selectAll, (options) =>
  options.map((opt) => ({ value: opt.id, label: opt.name }))
);

export const ConfiguratorInputSelector = {
  ...AdapterSelector,
  getState,
  getOptions
};
