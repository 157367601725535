import {
  Avatar,
  Box,
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core";
import React from "react";

interface MyAvatarProps {
  url?: string;
  innerText?: string;
  size?: "medium" | "large" | "small" | "tiny";
  avatarClass?: string;
  avatarStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  primaryInfo?: string;
  secondaryInfo?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(1)
      /*  "& > div > .MuiAvatar-img": {
        width: "auto"
      } */
    },
    large: {
      background: "rgba(77, 124, 254, 0.4)",
      width: "48px",
      height: "48px"
    },
    medium: {
      background: "rgba(77, 124, 254, 0.4)",
      width: "42px",
      height: "42px"
    },
    small: {
      background: "rgba(77, 124, 254, 0.4)",
      width: "36px",
      height: "36px"
    },
    tiny: {
      background: "rgba(77, 124, 254, 0.4)",
      width: "30px",
      height: "30px"
    },
    src: {
      width: "auto"
    }
  })
);

export const MyAvatar: React.FC<MyAvatarProps> = ({
  innerText,
  size = "small",
  avatarClass,
  style,
  avatarStyle,
  primaryInfo,
  secondaryInfo,
  url
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} style={{ ...style }}>
      <Avatar
        className={avatarClass ?? classes[size]}
        src={url}
        variant="circular"
        style={{ marginRight: "8px", ...avatarStyle }}
      >
        {innerText}
      </Avatar>
      <Box>
        {primaryInfo ? primaryInfo : null}
        <div> {secondaryInfo ? secondaryInfo : null}</div>
      </Box>
    </Box>
  );
};
