import { Project } from "../../entities/project";
import { DateDueDateFilter, PageMetaDto } from "../common/types";

export interface ProjectPagination {
  meta: PageMetaDto;
  data: Project[];
}

export interface ProjectFilter {
  status: string | string[];
  manager: string;
  date: DateDueDateFilter | undefined;
  start?: string | Date;
  end?: string | Date;
  pipeline?: string;
  step?: string;
  customCounter?: number;
  createdBy?: string;
  filterId?: string;
  comment?: string;
}

export const initialProjectFilters: ProjectFilter = {
  status: "",
  manager: "",
  date: undefined,
  pipeline: "",
  step: ""
};

export interface PageMetaDtoProject extends PageMetaDto {
  projectId: string;
}

export enum ProjectContext {
  MY_PROJECTS = "my_projects",
  PROJECTS = "projects",
  COMPANY_PROJECTS = "company_projects",
  CONTACT_PROJECTS = "contact_projects",
  PROJECT = "project"
}
