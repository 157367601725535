import { some, every } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "..";
import { getBriefElementsConfiguratorState } from "../briefElementConfigurator/selector";

export const getBriefConfiguratorState = createSelector(
  (state: RootState) => state.briefConfigurator,
  (configurator) => configurator
);

export const getBriefElementSelectedPosition = createSelector(
  [getBriefConfiguratorState],
  (state) => state.briefElementSelectedPosition
);

export const getConfiguratorHasChangeAfterSubmit = createSelector(
  [getBriefElementsConfiguratorState, getBriefConfiguratorState],
  (briefElementState, briefState) => {
    return some([
      some(briefElementState, (be) => !!be?.valuesHasChangedAfterSubmit),
      briefState.valuesHasChangedAfterSubmit
    ]);
  }
);

export const getConfiguratorIsValid = createSelector(
  [getBriefElementsConfiguratorState, getBriefConfiguratorState],
  (briefElementState, briefState) => {
    return every([
      every(briefElementState, (be) => !!be?.isValid),
      briefState.isValid
    ]);
  }
);
