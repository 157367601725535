import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { InkActionsTypes } from "./action";
import { Ink } from "./entity";
import * as Api from "./api";

export interface InkState extends EntityState<Ink> {
  readStatus: FetchingStatus;
  createOneStatus: FetchingStatus;
  patchOneStatus: FetchingStatus;
}

export const InkInitialState: InkState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createOneStatus: FetchingStatus.NULL,
  patchOneStatus: FetchingStatus.NULL
};

export const InkAdapter = createEntityAdapter<Ink>();

const InkAdapterState = InkAdapter.getInitialState(InkInitialState);

export const read = createMyAsyncThunk<Ink[]>(
  InkActionsTypes.INK_READ,
  Api.read
);

export const createOne = createMyAsyncThunk(
  InkActionsTypes.CREATE_ONE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);

export const patchOne = createMyAsyncThunk(
  InkActionsTypes.PATCH_ONE,
  Api.patch,
  {
    onSuccessMessage: "saga:update-success"
  }
);

const InkSlice = createMySlice({
  name: "configurator-inputs",
  initialState: InkAdapterState,
  adapter: InkAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: InkAdapter.upsertMany
    },
    {
      action: createOne,
      statusName: "createOneStatus",
      onSuccess: InkAdapter.addOne
    },
    {
      action: patchOne,
      statusName: "patchOneStatus",
      onSuccess: InkAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const InkReducer = InkSlice.reducer;
export const InkActions = {
  ...InkSlice.actions,
  read,
  patchOne,
  createOne
};
