import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import * as FileApi from "../files/api";
import { QuoteSettingActionsTypes } from "./action";
import * as Api from "./api";
import { QuoteSetting } from "./entity";

export interface QuoteSettingState {
  readStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  updateTermAndConditionStatus: FetchingStatus;
  removeTermAndConditionStatus: FetchingStatus;
  setting: QuoteSetting | null;
}

export const QuoteSettingInitialState: QuoteSettingState = {
  readStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  updateTermAndConditionStatus: FetchingStatus.NULL,
  removeTermAndConditionStatus: FetchingStatus.NULL,
  setting: null
};

export const read = createMyAsyncThunk<QuoteSetting>(
  QuoteSettingActionsTypes.GET,
  Api.read
);

export const patch = createMyAsyncThunk<QuoteSetting, Partial<QuoteSetting>>(
  QuoteSettingActionsTypes.PATCH,
  Api.patch,
  { onSuccessMessage: "saga:update-success" }
);

export const updateTermAndCondition = createMyAsyncThunk(
  QuoteSettingActionsTypes.UPDATE_TERM_AND_CONDITION,
  Api.updateFileType,
  { onSuccessMessage: "saga:update-success" }
);

export const removeTermAndCondition = createMyAsyncThunk<void, string>(
  QuoteSettingActionsTypes.REMOVE_TERM_AND_CONDITION,
  FileApi.deleteOne,
  { onSuccessMessage: "saga:update-success" }
);

const QuoteSettingSlice = createMySlice({
  name: "quote-setting",
  initialState: QuoteSettingInitialState,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: patch,
      statusName: "patchStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: updateTermAndCondition,
      statusName: "updateTermAndConditionStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: removeTermAndCondition,
      statusName: "removeTermAndConditionStatus",
      onSuccess: (state) => {
        if (state.setting) state.setting.termAndCondition = null;
      }
    }
  ],
  reducers: {}
});

export const QuoteSettingReducer = QuoteSettingSlice.reducer;
export const QuoteSettingActions = {
  ...QuoteSettingSlice.actions,
  async: { read, patch, updateTermAndCondition, removeTermAndCondition }
};
