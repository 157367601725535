export const downloadBlob = (blob: Blob, fileName?: string) => {
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(blob);
    return;
  }
  const file = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = file;
  link.download = fileName ?? "document";
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(file);
  }, 100);
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
