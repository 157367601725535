import { Company } from "../../entities/company";
import { Request } from "../../utils/request";

export const fetchCompanies = () =>
  Request({ withToken: true }).get("/companies");

export const fetchCompaniesForUser = (id: string) =>
  Request({ withToken: true }).get(`/users/${id}/companies`);

export const createOne = (company: Partial<Company>) =>
  Request({ withToken: true }).post("/companies", company);

export const createManagedByUser = (
  userId: string,
  company: Partial<Company>
) =>
  Request({ withToken: true }).post(
    `/users/${userId}/companies/managed`,
    company
  );

export const fetchOne = (id: string, qb: string) =>
  Request({ withToken: true }).get(`/companies/${id}/?${qb}`);

export const updateOne = (companyId: string, company: Partial<Company>) =>
  Request({ withToken: true }).patch<Company>(
    `/companies/${companyId}`,
    company
  );

export const archived = (companyId: string) =>
  Request({ withToken: true }).delete(`/companies/${companyId}/archived`);
