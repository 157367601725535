import { Middleware } from "redux";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPresent(obj: any) {
  return obj !== undefined && obj !== null;
}

function isObjectLike(val: any) {
  return isPresent(val) && typeof val === "object";
}

export const actionToPlainObject: Middleware = () => (next) => (action) => {
  if (
    action.constructor.name === "Function" ||
    action.constructor.name === "Object"
  ) {
    return next(action);
  } else if (isObjectLike(action)) {
    return next({ ...action });
  } else {
    return next(action);
  }
};
