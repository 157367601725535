import {
  Box,
  Collapse,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Typography
} from "@material-ui/core";
import CompanyIcon from "@material-ui/icons/Business";
import RequestIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ProjectIcon from "@material-ui/icons/FolderOpen";
import CofnIcon from "@material-ui/icons/DescriptionOutlined";
import AccountIcon from "@material-ui/icons/GroupOutlined";
import ContactIcon from "@material-ui/icons/ImportContacts";
import SettingIcon from "@material-ui/icons/SettingsOutlined";
import ReportsIcons from "@material-ui/icons/ShowChartOutlined";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AdminRoles, StandardRoles } from "../../entities/role";
import { RootState } from "../../reducers";
import { AppOpenMenu } from "../../reducers/app/action";
import { getLanguage } from "../../reducers/app/selector";
import {
  getCurrentUser,
  getEachUserRoleStatus,
  getUserRoleStatus
} from "../../reducers/authentication/selector";
import { ReactComponent as Tasks } from "./assets/task.svg";
import { ReactComponent as Dashboard } from "./assets/dashboard.svg";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { getUserDisplayName } from "../../utils/function/transformEntityToOptions";
import MaleProfile from "./assets/male-profile.svg";
import { UpdateCurrentUser } from "../../reducers/authentication/action";
import { store } from "../../reducers/store";
import { User } from "../../entities/user";
import { useGetRoleOptionsQuery } from "../../services/roles/roles";
import { useLazyGetRandomCompanyQuery } from "../../services/company/company";
import { Company } from "../../entities/company";
import { safeRedirection } from "../../routes/RouterManager";
import { setSelectedCompany } from "../../reducers/contact/contact.slice";
import { useGetOneProjectQuery } from "../../services/project/project.service";
import { MetaEntityHipe } from "../../services/customFields/types";
import { useLazyGetOneCrmFilterQuery } from "../../services/crmFilters/crmFilters.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      width: 120,
      display: "block",
      margin: `24px auto ${theme.spacing(2)}px`
    },
    divider: {
      width: "90%",
      margin: "2px auto"
    },
    menuActive: {
      borderRight: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: `rgba(0, 0, 0, 0.09)`,
      "& .MuiListItemText-root >span": {
        fontWeight: "bolder"
      }
    },
    nestedMenu: {
      paddingLeft: theme.spacing(6)
    }
  })
);
export interface DrawerMenuProps {
  icon?: React.ReactElement;
  label: string;
  path?: string;
  activePath?: string[];
  activePathStartsWith?: string;
  separator?: boolean;
  inProgress?: boolean;
  subscriptionNeeded?: boolean;
  hidden?: boolean;
  children?: DrawerMenuProps[];
  type?: MetaEntityHipe;
  preload?: () => Promise<any>;
}

export interface BackOfficeMenuProps {}

export const BackOfficeMenu: React.FC<BackOfficeMenuProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");
  const user = useSelector(getCurrentUser);
  const userRoles = useSelector(getEachUserRoleStatus);
  const lang = useSelector(getLanguage);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.appSettings.setting);
  const [collapseMenu, setCollapseMenu] = useState<{
    [name: string]: boolean;
  }>({});
  const hasAdminRole = useMemo(
    () => user.roles.map((r) => r.name).some((r) => AdminRoles.includes(r)),
    [user.roles]
  );
  const [selectedRole, selectRole] = useState<
    undefined | StandardRoles | "contact" | "guest"
  >(undefined);

  const roleStatus = useSelector(getUserRoleStatus);
  const [canFixCompany, setCanFixCompany] = useState(true);

  const { roleOptions } = useGetRoleOptionsQuery(undefined, {
    skip: !userRoles.packitoo && !user?.currentRoles,
    selectFromResult: (res) => {
      if (!res.data) return { ...res, roleOptions: [] };
      return {
        ...res,
        roleOptions: [
          ...res.data,
          { label: "contact", value: "contact" },
          { label: "guest", value: "guest" }
        ]
      };
    }
  });

  const projectContext = location.pathname.startsWith("/back-office/projects/");
  const { data: project } = useGetOneProjectQuery(
    { id: location.pathname.split("/")?.pop() ?? "" },
    {
      skip:
        !user?.token ||
        !projectContext ||
        !userRoles.contact ||
        !(user?.collaborations && user.collaborations.length > 1)
    }
  );

  useEffect(() => {
    if (projectContext && project && canFixCompany) {
      setCanFixCompany(false);
      dispatch(setSelectedCompany(project.companyId ?? null));
    }
  }, [
    canFixCompany,
    dispatch,
    project,
    projectContext,
    user.collaborations,
    user.selectedCompanyId
  ]);

  const [trigger] = useLazyGetRandomCompanyQuery();
  const [triggerCrmFilter] = useLazyGetOneCrmFilterQuery();

  const handlePackitooRoles = async (
    name: StandardRoles | "contact" | "guest"
  ) => {
    const currentUser = store.getState().authentication.user as User;
    selectRole(name);

    if (name === "contact") {
      const company = await trigger();
      dispatch(
        new UpdateCurrentUser({
          ...currentUser,
          ...(!currentUser?.currentRoles && {
            currentRoles: currentUser?.roles[0] as any
          }),
          roles: [],
          collaborationIds: [company?.data?.id as string],
          collaborations: [{ ...(company?.data as Company) }]
        })
      );
      history.push(safeRedirection(roleStatus));
    } else if (name === "guest") {
      dispatch(
        new UpdateCurrentUser({
          ...currentUser,
          ...(!currentUser?.currentRoles && {
            currentRoles: currentUser?.roles[0] as any
          }),
          roles: []
        })
      );
      history.push(safeRedirection(roleStatus));
    } else {
      let wasGuest = false;
      if (!currentUser?.roles.length) {
        wasGuest = true;
        /*  window.location.reload();
        return; */
      }

      dispatch(
        new UpdateCurrentUser({
          ...currentUser,
          ...(!currentUser?.currentRoles && {
            currentRoles: currentUser?.roles[0] as any
          }),
          collaborationIds: [],
          collaborations: [],
          roles: currentUser?.roles.length
            ? [
                {
                  ...currentUser?.roles[0],
                  name: name
                }
              ]
            : [
                {
                  ...(currentUser?.currentRoles as any),
                  name: name
                }
              ]
        })
      );

      if (wasGuest) {
        history.push(safeRedirection(roleStatus));
      }
    }
  };

  const menus: DrawerMenuProps[] = [
    {
      icon: (
        <Dashboard
          style={{
            stroke:
              "/back-office/dashboard" === location.pathname
                ? setting?.style?.primaryColor
                : "#00000089"
          }}
        />
      ),
      label: t("menu.dashboard"),
      path: "/back-office/dashboard",
      separator:
        ((setting.features?.isTaskManagement &&
          !setting.features?.isDashboardDisplay) ||
          (!setting.features?.isTaskManagement &&
            setting.features?.isDashboardDisplay)) ??
        false,
      activePathStartsWith: "/back-office/dashboard/",
      hidden:
        userRoles.guest ||
        userRoles.contact ||
        userRoles.external_sales ||
        !setting.features?.isDashboardDisplay,
      preload: () => import("./Dashboard")
    },
    {
      icon: (
        <ReportsIcons
          style={{
            stroke:
              "/back-office/dashboard" === location.pathname
                ? setting?.style?.primaryColor
                : "#00000089"
          }}
        />
      ),
      label: t("menu.reports"),
      path: "/back-office/reports",
      separator: !setting.features?.isTaskManagement ?? false,
      activePathStartsWith: "/back-office/reports/",
      hidden:
        userRoles.guest ||
        userRoles.contact ||
        userRoles.external_sales ||
        !setting.features?.isReport,
      preload: () => import("./Dashboard")
    },
    {
      icon: (
        <Tasks
          style={{
            stroke:
              "/back-office/tasks" === location.pathname
                ? setting?.style?.primaryColor
                : "#00000089"
          }}
        />
      ),
      label: t("menu.tasks"),
      path: "/back-office/tasks",
      separator: setting.features?.isTaskManagement ?? false,
      activePathStartsWith: "/back-office/tasks/",
      hidden:
        userRoles.guest ||
        userRoles.contact ||
        !setting.features?.isTaskManagement,
      type: MetaEntityHipe.Task,
      preload: () => import("./Tasks")
    },
    {
      icon: <ProjectIcon />,
      label: t("menu.my-projects"),
      path: "/back-office/my-projects",
      activePathStartsWith: "/back-office/my-projects/",
      type: MetaEntityHipe.Project,
      preload: () => import("./MyProjects")
    },
    {
      icon: <CompanyIcon />,
      label: t("menu.my-companies"),
      path: "/back-office/my-companies",
      activePathStartsWith: "/back-office/my-companies/",
      hidden: userRoles.contact || userRoles.guest,
      type: MetaEntityHipe.Company,
      preload: () => import("./MyCompanies")
    },
    {
      icon: <ContactIcon />,
      label: t("menu.my-contacts"),
      path: "/back-office/my-contacts",
      separator:
        !setting.features?.isCollectionOfNeeds &&
        !setting.features?.isConfigurator
          ? true
          : false,
      activePathStartsWith: "/back-office/my-contacts/",
      hidden: userRoles.contact || userRoles.guest,
      preload: () => import("./MyContacts")
    },
    {
      icon: <CofnIcon />,
      label: t("menu.my-collection-of-needs"),
      path: "/back-office/my-collection-of-needs",
      separator:
        setting.features?.isCollectionOfNeeds &&
        !setting.features?.isConfigurator
          ? true
          : false,
      hidden: !setting.features?.isCollectionOfNeeds,
      type: MetaEntityHipe.Submission,
      preload: () => import("./MySubmission")
    },
    {
      icon: <RequestIcon />,
      label: t("menu.my-configs"),
      path: "/back-office/my-configs",
      separator:
        (setting.features?.isConfigurator &&
          setting.features?.isCollectionOfNeeds) ||
        (setting.features?.isConfigurator &&
          !setting.features?.isCollectionOfNeeds)
          ? true
          : false,
      hidden: !setting.features?.isConfigurator,
      type: MetaEntityHipe.Brief,
      preload: () => import("./MyConfigs")
    },
    {
      icon: <ProjectIcon />,
      label: t("menu.projects"),
      path: "/back-office/projects",
      activePathStartsWith: "/back-office/projects/",
      hidden: !userRoles.isAdmin,
      type: MetaEntityHipe.Project,
      preload: () => import("./Projects")
    },
    {
      icon: <CofnIcon />,
      label: t("menu.collection-of-needs"),
      path: "/back-office/collection-of-needs",
      hidden: !setting.features?.isCollectionOfNeeds || !userRoles.isAdmin,
      type: MetaEntityHipe.Submission,
      preload: () => import("./Submissions")
    },
    {
      icon: <RequestIcon />,
      label: t("menu.requests"),
      path: "/back-office/requests",
      hidden: !userRoles.isAdmin || !setting.features?.isConfigurator,
      type: MetaEntityHipe.Brief,
      preload: () => import("./Requests")
    },
    {
      icon: <CompanyIcon />,
      label: t("menu.companies"),
      path: "/back-office/companies",
      hidden: !userRoles.isAdmin,
      type: MetaEntityHipe.Company,
      preload: () => import("./Companies")
    },
    {
      icon: <ContactIcon />,
      label: t("menu.contacts"),
      path: "/back-office/contacts",
      hidden: !userRoles.isAdmin,
      preload: () => import("./Contacts")
    },
    {
      icon: <SettingIcon />,
      label: t("menu.settings"),
      path: "/back-office/settings",
      hidden: !userRoles.isAdmin,
      activePathStartsWith: "/back-office/settings/",
      preload: () => import("./Settings")
    },
    {
      icon: <AccountIcon />,
      label: t("menu.accounts"),
      path: "/back-office/accounts",
      hidden: !hasAdminRole,
      type: MetaEntityHipe.User,
      preload: () => import("./Accounts")
    }
  ];

  const onMenuClick = (path: string, type?: MetaEntityHipe) => async () => {
    /*  let data;
    if (type && getLocalEntityFilter(type)) {
      data = await triggerCrmFilter(
        getLocalEntityFilter(MetaEntityHipe.Project),
        true
      );
    }
    history.push(data ? `${path}?${data?.data?.url}` : path); */
    history.push(path);
    dispatch(new AppOpenMenu(false));
  };

  const onCollapseMenuClick = (name: string) =>
    setCollapseMenu({ ...collapseMenu, [name]: !collapseMenu[name] });

  const generateMenus = (menus: DrawerMenuProps[], isNested = false) => {
    return menus.map((menu: DrawerMenuProps) => {
      if (menu.hidden) return null;

      const isActive =
        menu.path === location.pathname ||
        (menu.activePath && menu.activePath.includes(location.pathname)) ||
        (menu.activePathStartsWith &&
          location.pathname.startsWith(menu.activePathStartsWith));

      if (menu.children) {
        return (
          <div key={menu.path} onMouseEnter={menu.preload}>
            <ListItem button onClick={() => onCollapseMenuClick(menu.label)}>
              {menu.icon && (
                <ListItemIcon>
                  {React.cloneElement(menu.icon, {
                    color: isActive ? "primary" : "inherit",
                    fontSize: 6
                  })}
                </ListItemIcon>
              )}
              <ListItemText primary={menu.label} />
              {collapseMenu[menu.label] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={collapseMenu[menu.label]}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding>{generateMenus(menu.children, true)}</List>
            </Collapse>
          </div>
        );
      }

      return (
        <div key={menu.path} onMouseEnter={menu.preload}>
          <ListItem
            /* component="a"
            href={menu.path} */
            dense={isNested}
            button
            onClick={onMenuClick(menu.path as string, menu.type)}
            className={clsx({
              [classes.menuActive]: isActive,
              [classes.nestedMenu]: isNested
            })}
          >
            {menu.icon && (
              <ListItemIcon>
                {React.cloneElement(menu.icon, {
                  color: isActive ? "primary" : "inherit"
                })}
              </ListItemIcon>
            )}
            <ListItemText primary={menu.label} />
          </ListItem>
          {menu.separator && (
            <Box my={1}>
              <Divider variant="middle" />
            </Box>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <Box>
        <img
          alt="company-logo"
          src={setting?.logo?.url || MaleProfile}
          className={classes.avatar}
          style={{
            ...(setting?.style.logoRedirection && { cursor: "pointer" })
          }}
          onClick={() =>
            setting?.style.logoRedirection
              ? window.open(setting?.style.logoRedirection, "_blank")
              : null
          }
        />
        <Typography align="center">{getUserDisplayName(user).name}</Typography>
        {(userRoles.packitoo || user?.currentRoles) && !!roleOptions?.length && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              padding: ".5rem"
            }}
          >
            <Select
              variant="outlined"
              displayEmpty
              style={{ height: 40, width: 200 }}
              defaultValue={
                roleOptions?.find(
                  (item) => item?.label === StandardRoles.PACKITOO
                )?.value
              }
              onChange={(e) => {
                const roleName = roleOptions?.find(
                  (item) => item?.value === e.target.value
                )?.label;
                handlePackitooRoles(roleName as StandardRoles);
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }}
            >
              {(roleOptions as any[])?.map((item, id) => (
                <MenuItem key={id} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <Typography align="center">
          {user?.job
            ? user?.job
            : jsonTranslator(
                user.roles.length ? user.roles[0].label : tCommon("guest"),
                lang
              )}
        </Typography>
        {userRoles.contact &&
          user.collaborations &&
          user.collaborations.length === 1 && (
            <Typography align="center">
              {user.collaborations[0].name}
            </Typography>
          )}
        {/* {ifPathIsProject && project && (
          <Typography align="center">{project?.company?.name}</Typography>
        )} */}
        {userRoles.contact &&
          user.collaborations &&
          user.collaborations?.length > 1 &&
          (!projectContext || project) && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                padding: ".5rem"
              }}
            >
              <Select
                variant="outlined"
                displayEmpty
                style={{ height: 40, width: 200 }}
                defaultValue={
                  projectContext
                    ? project?.companyId ?? ""
                    : user.selectedCompanyId
                }
                onChange={async (e) => {
                  setCanFixCompany(false);
                  await dispatch(setSelectedCompany(e.target.value as string));
                  if (projectContext) {
                    history.push("/back-office/my-projects");
                  }
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                  }
                }}
              >
                {user.collaborations?.map((company, id) => (
                  <MenuItem key={id} value={company?.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
      </Box>
      <Divider className={classes.divider} />
      <List>{generateMenus(menus)}</List>
    </>
  );
};
