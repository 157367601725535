import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface NoDataComponentProps {}

export const NoDataComponent: React.FC<NoDataComponentProps> = () => {
  const { t } = useTranslation("components");
  return <Typography>{t("dynamicElement.noDataComponent.title")}</Typography>;
};
