import {
  makeStyles,
  Box,
  Divider,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { FC } from "react";
import { MyTypography } from "../../MyTypography";
import { ReactComponent as ExportIcon } from "../../../../assets/export.svg";
import { useTranslation } from "react-i18next";
import { AppAddSnackbar } from "../../../../reducers/app/action";
import { useDispatch } from "react-redux";

interface ExportCsvButtonProps {
  action: any;
  fileName: string;
  url?: string;
  errorMessage?: string;
  tooltip?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    marginLeft: "10px"
  }
}));

export const ExportCsvButton: FC<ExportCsvButtonProps> = ({
  action,
  fileName,
  errorMessage,
  tooltip,
  url = undefined
}) => {
  const classes = useStyles();
  const { t: tCommon } = useTranslation("common");
  const { t: tSaga } = useTranslation("saga");
  const dispatch = useDispatch();

  const getCSVFileName = () => {
    const date = new Date();
    return (
      date.toLocaleDateString("utc") +
      "__" +
      date.toLocaleTimeString("utc") +
      `_${fileName}.csv`
    );
  };

  const errorMessageToDisplay = errorMessage
    ? errorMessage
    : tSaga("export-failed");

  const handleExport = async () => {
    const data = url ? await action(url) : await action();

    if (!data.data) {
      dispatch(new AppAddSnackbar(errorMessageToDisplay, "error"));
      return;
    }

    const newBlob = new Blob([data["data"]], { type: "text/csv" });
    const nav = window.navigator as any;
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(newBlob);
      return;
    }
    const csv = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = csv;
    link.download = getCSVFileName();
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(csv);
    }, 100);
  };

  const tooltipToDisplay = tooltip ? tooltip : tCommon("export");
  // : t(`corrugated.export-${fileName}`);

  return (
    <Box className={classes.root}>
      <Divider variant="fullWidth" orientation="vertical" />
      <Box position="relative">
        <Tooltip
          title={
            <MyTypography variant="caption">{tooltipToDisplay}</MyTypography>
          }
        >
          <IconButton color="primary" onClick={handleExport}>
            <ExportIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
