import { useMemo } from "react";

export type HiddenFields<T> = Partial<Record<keyof T, boolean>>;
export enum FormMode {
  CREATE = "CREATE",
  UPDATE = "UPDATE"
}
export type GenericForm = {
  mode: FormMode;
  buttonLabel?: string;
};

export const getHiddenFields = <T>(
  objectFields: Array<keyof T>,
  hiddenFields: Array<keyof T> = []
) =>
  Object.assign<HiddenFields<T>, HiddenFields<T>>(
    objectFields.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
    hiddenFields.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
  );

export const useGetHiddenFields = <T>(
  objectFields: Array<keyof T>,
  hiddenFields: Array<keyof T> = []
) => {
  return useMemo(() => getHiddenFields(objectFields, hiddenFields), [
    objectFields,
    hiddenFields
  ]);
};

export interface HideFieldsProps<T> {
  hideFields?: Array<keyof T>;
}
