import { Request } from "../../utils/request";
import { FormatCreateDTO, FormatUpdateDTO } from "./entity";

export const read = () => Request({ withToken: true }).get("/cad-sources");

export const createOne = (format: FormatCreateDTO) =>
  Request({ withToken: true }).post("/cad-sources", format);

export const patchOne = ({ id, ...dto }: FormatUpdateDTO) =>
  Request({ withToken: true }).patch(`/cad-sources/${id}`, dto);
