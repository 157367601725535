import { BriefElement } from "./brief";
import { LuxuryVarnish } from "./luxuryVarnish";

export type LuxuryVarnishParameter = {
  id: string;
  coverage: number;
  position: number;
  luxuryVarnishId: string;
  luxuryVarnishBriefElementRectoId: string;
  luxuryVarnishBriefElementVersoId: string;
  luxuryVarnish?: LuxuryVarnish;
  luxuryVarnishBriefElementRecto?: BriefElement;
  luxuryVarnishBriefElementVerso?: BriefElement;
};
export type luxuryVarnishParameterDTO = Pick<
  LuxuryVarnishParameter,
  "coverage" | "position" | "luxuryVarnishId"
>;

export const transformLuxuryvarnishParams = (
  varnishParams: luxuryVarnishParameterDTO[] | undefined
): luxuryVarnishParameterDTO[] | undefined => {
  if (!varnishParams || varnishParams.length === 0) return;
  return varnishParams.map((varnishParams) => ({
    luxuryVarnishId: varnishParams.luxuryVarnishId,
    coverage: varnishParams.coverage,
    position: varnishParams.position
  }));
};
