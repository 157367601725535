export enum AppSettingActionsTypes {
  GET = "@@APP_SETTINGS get",
  GET_STATUS = "@@APP_SETTINGS get status",
  PATCH = "@@APP_SETTINGS patch ",
  PATCH_STATUS = "@@APP_SETTINGS patch status",
  UPDATE_LOGO = "@@APP_SETTINGS upload logo",
  UPDATE_LOGO_STATUS = "@@APP_SETTINGS upload logo status",
  UPDATE_FONT = "@@APP_SETTINGS upload font",
  UPDATE_FONT_STATUS = "@@APP_SETTINGS upload font status",
  UPDATE_FAVICON = "@@APP_SETTINGS upload favicon",
  UPDATE_FAVICON_STATUS = "@@APP_SETTINGS upload favicon status",
  UPDATE_STYLE = "@@APP_SETTINGS update style",
  UPDATE_STYLE_STATUS = "@@APP_SETTINGS update style status"
}
