import { Box } from "@material-ui/core";
import React from "react";
import { MyTooltip, MyTooltipProps } from "../MyTooltip";

export interface MyInputBoxProps {
  tooltipProps?: MyTooltipProps;
  fullWidth?: boolean;
  styleProps?: React.CSSProperties;
  isRadio?: boolean;
}

export const MyInputBox: React.FC<
  MyInputBoxProps & { children: JSX.Element }
> = ({ tooltipProps, fullWidth, children, styleProps }) => {
  if (!tooltipProps) return children;
  return (
    <Box display="flex" alignItems="center" style={{ ...styleProps }}>
      <Box flexGrow={fullWidth ? 1 : 0}>{children}</Box>
      <Box ml={1}>
        <MyTooltip {...tooltipProps} />
      </Box>
    </Box>
  );
};
