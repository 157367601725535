import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { ConfiguratorInputActionsTypes } from "./action";
import { ConfiguratorInput } from "./entity";
import * as Api from "./api";

export interface ConfiguratorInputState extends EntityState<ConfiguratorInput> {
  readStatus: FetchingStatus;
}

export const ConfiguratorInputInitialState: ConfiguratorInputState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL
};

export const ConfiguratorInputAdapter = createEntityAdapter<ConfiguratorInput>();

const ConfiguratorInputAdapterState = ConfiguratorInputAdapter.getInitialState(
  ConfiguratorInputInitialState
);

export const read = createMyAsyncThunk<ConfiguratorInput[]>(
  ConfiguratorInputActionsTypes.CONFIGURATOR_INPUT_READ,
  Api.read
);

const ConfiguratorInputSlice = createMySlice({
  name: "configurator-inputs",
  initialState: ConfiguratorInputAdapterState,
  adapter: ConfiguratorInputAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ConfiguratorInputAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const ConfiguratorInputReducer = ConfiguratorInputSlice.reducer;
export const ConfiguratorInputActions = {
  ...ConfiguratorInputSlice.actions,
  read
};
