// import { Box, Grid } from "@material-ui/core";
// import { BoxProps } from "@material-ui/core/Box";
// import { createStyles, makeStyles } from "@material-ui/core/styles";
// import clsx from "clsx";
// import * as React from "react";
// import { animated, useSpring } from "react-spring";

// export interface MyPageProps extends BoxProps {
//   boxProps?: BoxProps;
//   style?: React.CSSProperties;
//   className?: string;
//   center?: boolean;
//   fullHeight?: boolean;
// }

// const useStyles = makeStyles(() =>
//   createStyles({
//     root: {
//       minHeight: "100%",
//     },
//     center: {
//       height: "calc(100vh - 128px)",
//     },
//     fullHeight: {
//       height: "100%",
//     },
//   })
// );

// type Props = MyPageProps;

// const AnimatedBox = animated(Box);

// const PageComponent: React.FC<Props> = (props) => {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const { children, center, style, boxProps, fullHeight, ...rest } = props;
//   const classes = useStyles();
//   const pageAnimatedProps = useSpring({
//     from: { opacity: 0 },
//     opacity: 1,
//   });

//   return (
//     <AnimatedBox
//       className={clsx(classes.root, { [classes.center]: center })}
//       style={{ ...style, ...pageAnimatedProps }}
//       {...boxProps}
//       {...rest}
//     >
//       {center ? (
//         <Grid
//           container
//           data-testid="center-content"
//           justify="center"
//           alignItems="center"
//           className={clsx({ [classes.fullHeight]: center })}
//         >
//           {children}
//         </Grid>
//       ) : (
//         <>{children}</>
//       )}
//     </AnimatedBox>
//   );
// };

// export const Page = PageComponent;
import { Box } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import React from "react";
import { useSpring } from "react-spring";

export interface MyPageProps extends BoxProps {
  style?: React.CSSProperties;
  className?: string;
  center?: boolean;
  background?: string;
}

export const Page: React.FC<MyPageProps> = (props) => {
  const { children, center, style, background, ...rest } = props;
  const pageAnimatedProps = useSpring({
    from: { opacity: 0 },
    opacity: 1
  });

  return (
    <Box
      id="page"
      px={{ xs: 0.2, sm: 4 }}
      minHeight="100%"
      paddingTop="32px"
      paddingRight="32px"
      paddingLeft="32px"
      height={center ? "calc(100vh - 128px)" : "auto"}
      bgcolor={background ? background : "#FAFBFC"}
      style={{ ...style, ...pageAnimatedProps }}
      {...rest}
    >
      {center ? (
        <Box
          height="100%"
          display="flex"
          data-testid="center-content"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
