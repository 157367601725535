import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ProviderRateActionsTypes } from "./action";
import * as Api from "./api";
import { ProviderRate } from "./entity";

export interface ProviderRateState extends EntityState<ProviderRate> {
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
}

export const ProviderRateInitialState: ProviderRateState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL
};

export const ProviderRateAdapter = createEntityAdapter<ProviderRate>();

export const read = createMyAsyncThunk<ProviderRate[]>(
  ProviderRateActionsTypes.READ,
  Api.read
);

export const updateOne = createMyAsyncThunk<ProviderRate, ProviderRate>(
  ProviderRateActionsTypes.UPDATE_ONE,
  Api.updateOne,
  { onSuccessMessage: "saga:update-success" }
);

const ProviderRateSlice = createMySlice({
  name: "provider-rates",
  initialState: ProviderRateAdapter.getInitialState(ProviderRateInitialState),
  adapter: ProviderRateAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ProviderRateAdapter.upsertMany
    },
    {
      action: updateOne,
      statusName: "updateStatus",
      onSuccess: ProviderRateAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const ProviderRateReducer = ProviderRateSlice.reducer;
export const ProviderRateActions = {
  ...ProviderRateSlice.actions,
  async: {
    read,
    updateOne
  }
};
