/* export interface ProjectTabs {
  brief: number | boolean;
  cofn: number | boolean;
  task: number | boolean;
  document: number;
  approval: number | boolean;
  approvalHistory: number | boolean;
}

function setProjectTabs(
  isConfigurator: boolean,
  isCollectionOfNeeds: boolean,
  isTaskManagement: boolean,
  isApproval: boolean,
  isGuest = false
): ProjectTabs {
  const tabs: ProjectTabs = {
    brief: false,
    cofn: false,
    task: false,
    document: 1,
    approval: false,
    approvalHistory: false
  };

  if (isConfigurator) {
    tabs.brief = 1;
  }
  if (isCollectionOfNeeds) {
    tabs.cofn = 2;
  }

  if (isTaskManagement && !isGuest) {
    tabs.task = 3;
  }

  let index = 1;
  (Object.keys(tabs) as (keyof ProjectTabs)[]).forEach((key) => {
    if (tabs[key] && key !== "document") {
      tabs[key] = index;
      index++;
    }
  });

  return {
    ...tabs,
    document: index,
    approval: index + 1,
    approvalHistory: index + 2
  };
} */

export interface ProjectTabsSlug {
  brief: string | boolean;
  cofn: string | boolean;
  task: string | boolean;
  document: string;
  approval: string | boolean;
  approvalHistory: string | boolean;
  notes: string | boolean;
}

export enum ProjectTabsEnum {
  OVERVIEW = "overview",
  BRIEFS = "configs",
  REQUESTS = "requests",
  TASKS = "tasks",
  DOCUMENTS = "documents",
  APPROVALS = "approvals",
  APPROVAL_HISTORY = "historicalApprovals",
  NOTES = "notes"
}

export function navigateToProjectTabSecure(
  projectId?: string,
  tab?: ProjectTabsEnum,
  sortColumn = "code",
  extra?: string
): string {
  if (!projectId) return "#not-found";
  if (tab === ProjectTabsEnum.OVERVIEW || !tab) {
    return `/back-office/projects/${projectId}`;
  } else if (tab === ProjectTabsEnum.NOTES) {
    return `/back-office/projects/${projectId}?tab=${tab}${
      extra ? `&${extra}` : ""
    }`;
  } else {
    return `/back-office/projects/${projectId}?tab=${tab}&limit=50&page=1&sort=${sortColumn},DESC`;
  }
}

export function setProjectTabsWSlug(
  isConfigurator: boolean,
  isCollectionOfNeeds: boolean,
  isTaskManagement: boolean,
  isApproval: boolean,
  isGuest = false // contact or not
): ProjectTabsSlug {
  const tabs: ProjectTabsSlug = {
    brief: isConfigurator ? ProjectTabsEnum.BRIEFS : false,
    cofn: isCollectionOfNeeds ? ProjectTabsEnum.REQUESTS : false,
    task: isTaskManagement && !isGuest ? ProjectTabsEnum.TASKS : false,
    document: ProjectTabsEnum.DOCUMENTS,
    notes: !isGuest ? ProjectTabsEnum.NOTES : false,
    approval: isApproval ? ProjectTabsEnum.APPROVALS : false,
    approvalHistory: isApproval ? ProjectTabsEnum.APPROVAL_HISTORY : false
  };
  return tabs;
}
