import LightIcon from "mdi-material-ui/LightbulbOnOutline";
import React from "react";

import {
  makeStyles,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { TooltipProps } from "@material-ui/core/Tooltip";
import withWidth, { WithWidthProps } from "@material-ui/core/withWidth";
import { useHover } from "../../utils/hooks/useHover";

export interface MyTooltipProps extends Omit<TooltipProps, "children"> {
  iconProps?: SvgIconProps;
  iconComponent?: React.ReactElement;
}

const arrowGenerator = (color: string) => ({
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: 0,
    marginTop: "-0.95em",
    width: "2em",
    height: "1em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${color} transparent`
    }
  },
  '&[x-placement*="top"] $arrow': {
    bottom: 0,
    left: 0,
    marginBottom: "-0.95em",
    width: "2em",
    height: "1em",
    "&::before": {
      borderWidth: "1em 1em 0 1em",
      borderColor: `${color} transparent transparent transparent`
    }
  },
  '&[x-placement*="right"] $arrow': {
    left: 0,
    marginLeft: "-0.95em",
    height: "2em",
    width: "1em",
    boxShadow: "-4px 0px 20px -5px grey",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      borderColor: `transparent ${color} transparent transparent`
    }
  },
  '&[x-placement*="left"] $arrow': {
    right: 0,
    marginRight: "-0.95em",
    height: "2em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      borderColor: `transparent transparent transparent ${color}`
    }
  }
});

const useStylesArrow = makeStyles((theme: Theme) => ({
  tooltip: {
    position: "relative",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    boxShadow: "-4px 0px 20px -5px grey",
    fontSize: ".9rem",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "-0px 7px 20px -5px grey"
    }
  },
  arrow: {
    position: "absolute",
    fontSize: 8,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  popper: arrowGenerator(theme.palette.common.white)
}));

export const ArrowTooltip: React.FC<TooltipProps> = (props) => {
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Tooltip
      classes={classes}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      {...props}
      disableFocusListener
      disableTouchListener={!onMobile && props.disableTouchListener}
      title={
        <React.Fragment>
          {props.title}
          <span className={arrow} ref={setArrowRef} />
        </React.Fragment>
      }
    />
  );
};

export const MyTooltipComponent: React.FC<MyTooltipProps & WithWidthProps> = ({
  width,
  iconProps,
  iconComponent,
  ...tooltipProps
}) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <ArrowTooltip
      placement={width === "xs" ? "top" : "right"}
      {...tooltipProps}
      disableHoverListener
      disableTouchListener
      open={isHovered}
    >
      <div ref={hoverRef}>
        {iconComponent || (
          <LightIcon
            style={{ cursor: "help" }}
            color="primary"
            {...iconProps}
          />
        )}
      </div>
    </ArrowTooltip>
  );
};

export const MyTooltip = withWidth()(MyTooltipComponent);
