import { Project } from "../../entities/project";
import { setCustomFieldValues } from "../common/utils";
import { CustomFieldValue } from "../customFields/customFieldValues/types";
import { MetaEntityHipe } from "../customFields/types";
import { ProjectPagination } from "./project.types";

export const transformProject = (responseData: ProjectPagination) => {
  return {
    ...responseData,
    data: responseData.data.map((project: Project) => {
      return {
        ...project,
        customFields: setCustomFieldValues(
          project?.customFields as CustomFieldValue[],
          MetaEntityHipe.Project
        ),
        rawCustomFields: project?.customFields as CustomFieldValue[]
      };
    })
  };
};
