import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ProviderOperationRateActionsTypes } from "./action";
import * as Api from "./api";
import { ProviderOperationRate } from "./entity";

export interface ProviderOperationRateState
  extends EntityState<ProviderOperationRate> {
  readStatus: FetchingStatus;
  deleteOneStatus: FetchingStatus;
}

export const ProviderOperationRateInitialState: ProviderOperationRateState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  deleteOneStatus: FetchingStatus.NULL
};

export const ProviderOperationRateAdapter = createEntityAdapter<ProviderOperationRate>();

export const read = createMyAsyncThunk<ProviderOperationRate[]>(
  ProviderOperationRateActionsTypes.READ,
  Api.read
);

export const deleteOne = createMyAsyncThunk<void, string>(
  ProviderOperationRateActionsTypes.DELETE_ONE,
  Api.deleteOne
);

const ProviderOperationRateSlice = createMySlice({
  name: "provider-operation-rates",
  initialState: ProviderOperationRateAdapter.getInitialState(
    ProviderOperationRateInitialState
  ),
  adapter: ProviderOperationRateAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ProviderOperationRateAdapter.upsertMany
    },
    {
      action: deleteOne,
      statusName: "deleteOneStatus",
      onSuccess: ProviderOperationRateAdapter.removeOne
    }
  ],
  reducers: {}
});

export const ProviderOperationRateReducer = ProviderOperationRateSlice.reducer;
export const ProviderOperationRateActions = {
  ...ProviderOperationRateSlice.actions,
  async: {
    read,
    deleteOne
  }
};
