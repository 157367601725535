import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import {
  MaterialReferenceSheetSizeActionsTypes,
  MaterialReferenceSheetSizeReadStatus,
  MaterialReferenceSheetSizeReadOneStatus,
  MaterialReferenceSheetSizeCreate,
  MaterialReferenceSheetSizeCreateStatus,
  MaterialReferenceSheetSizeUpdate,
  MaterialReferenceSheetSizeUpdateStatus,
  MaterialReferenceSheetSizeReadOne,
  MaterialReferenceSheetSizeDelete,
  MaterialReferenceSheetSizeDeleteStatus
} from "./action";
import * as Api from "./api";
import { AppAddSnackbar } from "../app/action";

export function* readMaterialReferenceSheetSizes() {
  yield put(new MaterialReferenceSheetSizeReadStatus(FetchingStatus.PENDING));
  try {
    const { data: materialReferenceSheetSizes } = yield call(
      Api.readMaterialReferenceSheetSizes
    );
    yield put(
      new MaterialReferenceSheetSizeReadStatus(
        FetchingStatus.SUCCESS,
        materialReferenceSheetSizes
      )
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:read-failed");
      Sentry.captureException(error);
    });
    yield put(new MaterialReferenceSheetSizeReadStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-failed")));
  }
}

export function* readOneMaterialReferenceSheetSize(
  action: MaterialReferenceSheetSizeReadOne
) {
  yield put(
    new MaterialReferenceSheetSizeReadOneStatus(FetchingStatus.PENDING)
  );
  try {
    const { data: materialReferenceSheetSize } = yield call(
      Api.readOneMaterialReferenceSheetSize,
      action.id
    );
    yield put(
      new MaterialReferenceSheetSizeReadOneStatus(
        FetchingStatus.SUCCESS,
        materialReferenceSheetSize
      )
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:read-one-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(
      new MaterialReferenceSheetSizeReadOneStatus(FetchingStatus.FAILED)
    );
    yield put(sendErrorNotification(error, i18n.t("saga:read-one-failed")));
  }
}

export function* createMaterialReferenceSheetSize(
  action: MaterialReferenceSheetSizeCreate
) {
  yield put(new MaterialReferenceSheetSizeCreateStatus(FetchingStatus.PENDING));
  try {
    const { data: materialReferenceSheetSize } = yield call(
      Api.createMaterialReferenceSheetSize,
      action.materialReferenceSheetSize
    );
    yield put(
      new MaterialReferenceSheetSizeCreateStatus(
        FetchingStatus.SUCCESS,
        materialReferenceSheetSize
      )
    );
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(
      new MaterialReferenceSheetSizeCreateStatus(FetchingStatus.FAILED)
    );
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* updateMaterialReferenceSheetSize(
  action: MaterialReferenceSheetSizeUpdate
) {
  yield put(new MaterialReferenceSheetSizeUpdateStatus(FetchingStatus.PENDING));
  try {
    yield call(
      Api.updateMaterialReferenceSheetSize,
      action.materialReferenceSheetSize
    );
    yield put(
      new MaterialReferenceSheetSizeUpdateStatus(
        FetchingStatus.SUCCESS,
        action.materialReferenceSheetSize
      )
    );
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:update-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(
      new MaterialReferenceSheetSizeUpdateStatus(FetchingStatus.FAILED)
    );
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* deleteMaterialReferenceSheetSize(
  action: MaterialReferenceSheetSizeDelete
) {
  yield put(new MaterialReferenceSheetSizeDeleteStatus(FetchingStatus.PENDING));
  try {
    yield call(
      Api.deleteMaterialReferenceSheetSize,
      action.materialReferenceSheetSize.id
    );
    yield put(
      new MaterialReferenceSheetSizeDeleteStatus(FetchingStatus.SUCCESS)
    );
    yield put(new AppAddSnackbar(i18n.t("saga:delete-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferenceSheetSizes:delete-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(
      new MaterialReferenceSheetSizeDeleteStatus(FetchingStatus.FAILED)
    );
    yield put(sendErrorNotification(error, i18n.t("saga:delete-failed")));
  }
}

export const MaterialReferenceSheetSizeSaga = [
  takeLatest(
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_CREATE,
    createMaterialReferenceSheetSize
  ),
  takeLatest(
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ,
    readMaterialReferenceSheetSizes
  ),
  takeLatest(
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ_ONE,
    readOneMaterialReferenceSheetSize
  ),
  takeLatest(
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_UPDATE,
    updateMaterialReferenceSheetSize
  ),
  takeLatest(
    MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_DELETE,
    deleteMaterialReferenceSheetSize
  )
];
