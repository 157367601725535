import { intersection } from "lodash";
import { StandardRoles, StandardRolesWGuest } from "../../entities/role";
import { User } from "../../entities/user";
import {
  getUserDisplayName,
  getUserOptionLabel
} from "../../utils/function/transformEntityToOptions";
import { ContactContext, UserPagination } from "./types";
import { RtkTag } from "../common/types";
import { AutoCompleteOption } from "../../components/common/form/MyAutocompleteField";
import { Company } from "../../entities/company";

export interface AutoCompleteOptionWRoles extends AutoCompleteOption {
  role?: StandardRolesWGuest | StandardRoles;
  roleId?: string;
  managedProjectsIds?: string[];
  managedCompaniesIds?: string[];
  linkedCompaniesIds?: string[];
}

export const addCurryUser = (
  users: User[],
  args?: StandardRolesWGuest[]
): AutoCompleteOptionWRoles[] => {
  /* const currentUser = store.getState()?.authentication?.user;

  const filter =
    args && typeof args === "object" && Object.values(args).length > 0
      ? Object.values(args)
      : ([
          ...AdminRoles.filter((item: any) =>
            currentUser?.roles[0]?.name !== StandardRolesWGuest.PACKITOO
              ? item !== StandardRolesWGuest.PACKITOO
              : item
          ),
          StandardRolesWGuest.EXTERNAL_SALES
        ] as StandardRolesWGuest[]); */

  return (
    users
      /* .filter((item: User) =>
      filter.length && !args?.includes(StandardRolesWGuest.GUEST)
        ? filter.includes(item?.roles?.[0]?.name as any)
        : item
    ) */
      .map((user) => {
        const managedProjectsIds =
          (user?.managedProjects?.length as number) > 0
            ? user?.managedProjects?.map((item) => item.id)
            : undefined;

        const managedCompaniesIds =
          (user?.managedCompanies?.length as number) > 0
            ? user?.managedCompanies?.map((item) => item.id)
            : undefined;
        return {
          value: user.id,
          label: getUserDisplayName(user)?.name.trim(),
          role: user?.roles?.[0]?.name,
          roleId: user?.roleIds?.[0],
          ...(managedProjectsIds && {
            managedProjectsIds
          }),
          ...(managedCompaniesIds && {
            managedCompaniesIds
          }),
          ...(managedCompaniesIds && {
            linkedCompaniesIds:
              (user?.roles?.[0]?.name as any) ===
              StandardRolesWGuest.EXTERNAL_SALES
                ? user?.managedProjectsIds
                  ? intersection([
                      ...managedCompaniesIds,
                      ...(user?.managedProjects?.map(
                        (item) => item?.companyId
                      ) as string[])
                    ])
                  : [...managedCompaniesIds]
                : managedCompaniesIds
          })
        };
      })
  );
};

export const getUserOptions = (
  users: User[],
  args?: StandardRolesWGuest[]
): AutoCompleteOptionWRoles[] => addCurryUser(users, args);

export const getUserOptionsInternals = (
  data: AutoCompleteOptionWRoles[]
): AutoCompleteOption[] => {
  return data.filter((user) => user.role);
};

export const getInvalidatTagsContacts = (context: ContactContext): RtkTag[] => {
  switch (context) {
    case ContactContext.MY_CONTACTS:
      return [{ type: "MyContacts", id: "PARTIAL-LIST" }];
    case ContactContext.CONTACTS:
      return [{ type: "Users", id: "PARTIAL-LIST" }];
    case ContactContext.COMPANY:
      return [
        { type: "Company", id: "PARTIAL-LIST" },
        { type: "CompanyHierarchy", id: "PARTIAL-LIST" },
        { type: "MyCompanies", id: "PARTIAL-LIST" }
      ];
    case ContactContext.COMPANY_CONTACTS:
      return [{ type: "CompanyContacts", id: "PARTIAL-LIST" }];
    default:
      return [];
  }
};

export const getUserOptionsFromPaginationWithEmail = (
  data: UserPagination,
  lang?: string
): AutoCompleteOption[] => {
  return data.data
    .filter((user) => user.email)
    .map((user) => ({
      label: getUserOptionLabel(user),
      value: user.id
    }));
};

export const getUserOptionsFromPagination = (
  data: UserPagination,
  lang?: string
): AutoCompleteOption[] => {
  return data.data.map((user) => ({
    label: getUserOptionLabel(user),
    value: user.id
  }));
};

export const getContactOptions = (
  users: User[]
): AutoCompleteOptionWRoles[] => {
  return users.map((user: User) => ({
    label: getUserDisplayName(user)?.name.trim(),
    value: user.id
  }));
};

export const getCompanyIfOnlyOne = (
  user?: User | null
): Company | undefined => {
  return user?.collaborationIds?.length === 1 && // could be different than collaborations.length if selected company context is applied
    user?.collaborations?.length === 1 // check is the relation is joined
    ? user?.collaborations[0]
    : undefined;
};

export const pathToContactOverview = (userId: string): string =>
  `/back-office/contacts/${userId}`;
