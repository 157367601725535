import { AxiosRequestConfig } from "axios";
import { Request } from "../../utils/request";
import { MachineRate } from "../machineRate/entity";
import { ProviderRate } from "../provider-rate/entity";
import {
  Product,
  ProductCreateDTO,
  ProductDuplicateDTO,
  ProductUpdateDTO,
  ProductUpdateOperationsDTO
} from "./entity";

export const read = (isJoin?: boolean) =>
  Request({ withToken: true }).get(
    isJoin ? "/products?join=subCategories" : "/products"
  );

// TODO query params is never used here
export const readOne = ({
  id,
  queryParams
}: {
  id: string;
  queryParams?: string;
}) => Request({ withToken: true }).get(`/products/${id}/?${queryParams || ""}`);

export const create = (product: ProductCreateDTO) =>
  Request({ withToken: true }).post("/products", product);

export type ProductDuplicateProps = {
  id: string;
  product: ProductDuplicateDTO;
};
export const duplicate = ({ id, product }: ProductDuplicateProps) =>
  Request({ withToken: true }).post(`/products/${id}/duplicate`, product);

export const update = ({ id, ...dto }: ProductUpdateDTO) =>
  Request({ withToken: true }).patch(`/products/${id}`, dto);

/* Operations */
export const updateOperations = ({ id, ...dto }: ProductUpdateOperationsDTO) =>
  Request({ withToken: true }).patch(`/products/${id}/operations`, dto);

/* Images */

export type ProductAddImagesProps = {
  productId: string;
  formData: FormData;
  onUploadProgress: AxiosRequestConfig["onUploadProgress"];
};
export const addImages = ({
  productId,
  formData,
  onUploadProgress
}: ProductAddImagesProps) =>
  Request({ withToken: true }).post<Product>(
    `/products/${productId}/images`,
    formData,
    { onUploadProgress }
  );

export type ProductSetDefaultImageProps = {
  productId: string;
  imageId: string;
};
export const setDefaultImage = ({
  imageId,
  productId
}: ProductSetDefaultImageProps) =>
  Request({ withToken: true }).patch<Product>(
    `/products/${productId}/images/${imageId}/default`
  );

export type ProductRemoveImageProps = {
  productId: string;
  imageId: string;
};
export const removeImage = ({ productId, imageId }: ProductRemoveImageProps) =>
  Request({ withToken: true }).delete(
    `/products/${productId}/images/${imageId}`
  );

/* Machine rates */
export type ProductCreateMachineRateProps = {
  productId: string;
  machineOperationRateId: string;
  machineRate: Omit<MachineRate, "id">;
};
export const createMachineRate = ({
  productId,
  machineOperationRateId,
  machineRate
}: ProductCreateMachineRateProps) =>
  Request({ withToken: true }).post(
    `/products/${productId}/machine-operation-rates/${machineOperationRateId}`,
    machineRate
  );

export type ProductSetDefaultMachineRateProps = {
  productId: string;
  machineOperationRateId: string;
};
export const setDefaultMachineRate = ({
  productId,
  machineOperationRateId
}: ProductSetDefaultMachineRateProps) =>
  Request({ withToken: true }).patch(
    `/products/${productId}/machine-operation-rates/${machineOperationRateId}/default`
  );

export type ProductRemoveMachineRateProps = {
  isDefault: boolean;
  machineRateId: string;
  productId: string;
  machineOperationRateId: string;
};
export const removeMachineRate = ({
  productId,
  machineOperationRateId
}: ProductRemoveMachineRateProps) =>
  Request({ withToken: true }).delete(
    `/products/${productId}/machine-operation-rates/${machineOperationRateId}`
  );

/* Provider rates */
export type ProductCreateProviderRateProps = {
  productId: string;
  providerOperationRateId: string;
  providerRate: Omit<ProviderRate, "id">;
};
export const createProviderRate = ({
  productId,
  providerOperationRateId,
  providerRate
}: ProductCreateProviderRateProps) =>
  Request({ withToken: true }).post(
    `/products/${productId}/provider-operation-rates/${providerOperationRateId}`,
    providerRate
  );

export type ProductSetDefaultProviderRateProps = {
  productId: string;
  providerOperationRateId: string;
};
export const setDefaultProviderRate = ({
  productId,
  providerOperationRateId
}: ProductSetDefaultProviderRateProps) =>
  Request({ withToken: true }).patch(
    `/products/${productId}/provider-operation-rates/${providerOperationRateId}/default`
  );

export type ProductRemoveProviderRateProps = {
  isDefault: boolean;
  providerRateId: string;
  productId: string;
  providerOperationRateId: string;
};
export const removeProviderRate = ({
  productId,
  providerOperationRateId
}: ProductRemoveProviderRateProps) =>
  Request({ withToken: true }).delete(
    `/products/${productId}/provider-operation-rates/${providerOperationRateId}`
  );

export type archiveProductProps = {
  productId: string;
};
export const archiveProduct = ({ productId }: archiveProductProps) =>
  Request({ withToken: true }).delete(`/products/${productId}/archived`);

export const getArchivedById = ({ productId }: archiveProductProps) =>
  Request({ withToken: true }).get(`/products/${productId}/archived`);
