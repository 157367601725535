import { BriefComputed } from "../../entities/briefComputed";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum BriefComputedActionsTypes {
  BRIEF_COMPUTED_FETCH = "@@BRIEF_COMPUTED fetch briefComputed",
  BRIEF_COMPUTED_FETCH_STATUS = "@@BRIEF_COMPUTED fetch status briefComputed",
  BRIEF_COMPUTED_ADD = "@@BRIEF_COMPUTED add briefComputed"
}

export class BriefComputedFetch {
  readonly type = BriefComputedActionsTypes.BRIEF_COMPUTED_FETCH;
}
export class BriefComputedStatus {
  readonly type = BriefComputedActionsTypes.BRIEF_COMPUTED_FETCH_STATUS;
  constructor(
    public status: FetchingStatus,
    public briefComputeds?: BriefComputed[]
  ) {}
}
export class BriefComputedAdd {
  readonly type = BriefComputedActionsTypes.BRIEF_COMPUTED_ADD;
  constructor(public briefComputed: BriefComputed) {}
}

export type BriefComputedActions =
  | BriefComputedFetch
  | BriefComputedStatus
  | BriefComputedAdd;
