import produce from "immer";

import { Lamination } from "../../../entities/lamination";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { LaminationActions, LaminationActionsTypes } from "./action";
import { ReducerMethods } from "../../../utils/reducers/methods";

export interface LaminationState {
  laminations: Lamination[];
  fetchStatus: FetchingStatus;
}

const initialState: LaminationState = {
  laminations: [],
  fetchStatus: FetchingStatus.NULL
};

export const LaminationReducer = (
  state = initialState,
  action: LaminationActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LaminationActionsTypes.LAMINATION_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.laminations = action.laminations || state.laminations;
        break;
      case LaminationActionsTypes.LAMINATION_ADD:
        ReducerMethods.pushUniqueByMutate<Lamination>(
          draft.laminations,
          action.lamination
        );
        break;
      default:
        return draft;
    }
  });
