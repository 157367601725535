import {
  Machine,
  MachineCreateDTO,
  MachineUpdateDTO
} from "../../entities/machine";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum MachineActionsTypes {
  MACHINE_CREATE = "@@MACHINE create machine",
  MACHINE_CREATE_STATUS = "@@MACHINE create machine status",
  MACHINE_READ = "@@MACHINE read machine",
  MACHINE_READ_STATUS = "@@MACHINE read status machine",
  MACHINE_UPDATE = "@@MACHINE update machine",
  MACHINE_UPDATE_STATUS = "@@MACHINE update machine status",
  MACHINE_DELETE = "@@MACHINE delete machine",
  MACHINE_DELETE_STATUS = "@@MACHINE delete machine status",
  MACHINE_READ_ONE = "@@MACHINE read one machine",
  MACHINE_READ_ONE_STATUS = "@@MACHINE read one status machine",
  MACHINE_ADD = "@@MACHINE add machine",
  MACHINE_REMOVE = "@@MACHINE remove machine"
}

export class MachineCreate {
  readonly type = MachineActionsTypes.MACHINE_CREATE;
  constructor(public machine: MachineCreateDTO) {}
}
export class MachineCreateStatus {
  readonly type = MachineActionsTypes.MACHINE_CREATE_STATUS;
  constructor(public status: FetchingStatus, public machine?: Machine) {}
}

export class MachineRead {
  readonly type = MachineActionsTypes.MACHINE_READ;
}
export class MachineReadStatus {
  readonly type = MachineActionsTypes.MACHINE_READ_STATUS;
  constructor(public status: FetchingStatus, public machines?: Machine[]) {}
}

export class MachineReadOne {
  readonly type = MachineActionsTypes.MACHINE_READ_ONE;
  constructor(public id: string) {}
}
export class MachineReadOneStatus {
  readonly type = MachineActionsTypes.MACHINE_READ_ONE_STATUS;
  constructor(public status: FetchingStatus, public machine?: Machine) {}
}

export class MachineUpdate {
  readonly type = MachineActionsTypes.MACHINE_UPDATE;
  constructor(public machine: MachineUpdateDTO) {}
}
export class MachineUpdateStatus {
  readonly type = MachineActionsTypes.MACHINE_UPDATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public machine?: MachineUpdateDTO
  ) {}
}

export class MachineDelete {
  readonly type = MachineActionsTypes.MACHINE_DELETE;
  constructor(public machine: Machine) {}
}
export class MachineDeleteStatus {
  readonly type = MachineActionsTypes.MACHINE_DELETE_STATUS;
  constructor(public status: FetchingStatus, public machine?: Machine) {}
}

export class MachineAdd {
  readonly type = MachineActionsTypes.MACHINE_ADD;
  constructor(public machine: Machine) {}
}
export class MachineRemove {
  readonly type = MachineActionsTypes.MACHINE_REMOVE;
  constructor(public machine: Machine) {}
}

export type MachineActions =
  | MachineCreate
  | MachineCreateStatus
  | MachineRead
  | MachineReadStatus
  | MachineUpdate
  | MachineUpdateStatus
  | MachineDelete
  | MachineDeleteStatus
  | MachineReadOne
  | MachineReadOneStatus
  | MachineAdd
  | MachineRemove;
