import React, { act } from "react";
import {
  Notification,
  NotificationFilter
} from "../../../../services/notifications/notifications.type";
import { Box, Theme, createStyles, makeStyles } from "@material-ui/core";
import { MyButton } from "../../../../components/common/MyButton";
import { useTranslation } from "react-i18next";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArchiveIcon from "@material-ui/icons/Archive";
import { NotificationUserCategory } from "./NotificationUserCategory";
import { useSelector } from "react-redux";
import {
  useArchiveReadMutation,
  useMarkAsAllReadMutation
} from "../../../../services/notifications/notifications.service";
import { getCurrentUser } from "../../../../reducers/authentication/selector";
import { CursorPaginationMeta } from "../../../../services/common/types";
import AddIcon from "@material-ui/icons/Add";
import {
  EmptyState,
  EmptyStateContext
} from "../../../../components/common/EmptyState";

interface NotificationUserListProps {
  notifications: Notification[];
  meta: CursorPaginationMeta;
  setCursor: React.Dispatch<React.SetStateAction<string>>;
  actionNotfication: NotificationFilter;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "1rem"
    },
    actionSvg: {
      height: "18px",
      width: "18px"
    },
    emptyState: {
      "& > div > div > * > img": {
        maxWidth: "58px !important",
        maxHeight: "58px !important"
      }
    }
  })
);

export const NotificationUserList: React.FC<NotificationUserListProps> = ({
  notifications,
  meta,
  setCursor,
  actionNotfication
}) => {
  const classes = useStyles();

  const currentUser = useSelector(getCurrentUser);

  const { t } = useTranslation("backoffice");

  const [archiveAllRead] = useArchiveReadMutation();
  const [markAllAsRead] = useMarkAsAllReadMutation();

  const onMarkAllAsRead = async () => {
    await markAllAsRead({
      userId: currentUser.id,
      ...(actionNotfication.category.length > 0 && {
        category: actionNotfication.category
      })
    });
  };

  const onArchiveAllRead = async () => {
    await archiveAllRead({
      userId: currentUser.id,
      ...(actionNotfication.category.length > 0 && {
        category: actionNotfication.category
      })
    });
  };

  const renderNotifications = notifications?.map((notification) => {
    return (
      <NotificationUserCategory
        key={notification.id}
        notification={notification}
        actionNotfication={actionNotfication}
      />
    );
  });

  if (notifications?.length === 0 && !meta?.hasNextPage) {
    return (
      <Box className={classes.emptyState}>
        <EmptyState
          context={EmptyStateContext.IN_APP_NOTIFICATIONS_INBOX}
          disableAction
          containerStyle={{ backgroundColor: "transparent" }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classes.actionContainer}>
        <Box mt={1} style={{ width: "50%", marginRight: "1rem" }}>
          <MyButton
            fullWidth
            variant="outlined"
            onClick={() => onMarkAllAsRead()}
            leftIcon={
              <DoneAllIcon color="primary" className={classes.actionSvg} />
            }
          >
            {t("notifications.inApp.actions.markAllAsRead")}
          </MyButton>
        </Box>
        <Box mt={1} style={{ width: "50%" }}>
          <MyButton
            fullWidth
            variant="outlined"
            onClick={() => onArchiveAllRead()}
            leftIcon={
              <ArchiveIcon color="primary" className={classes.actionSvg} />
            }
          >
            {t("notifications.inApp.actions.archiveAllRead")}
          </MyButton>
        </Box>
      </Box>
      {renderNotifications}
      {meta?.hasNextPage && (
        <Box mb={2} mt={2}>
          <MyButton
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => {
              const createdAt = (notifications?.slice(-1)[0]
                ?.createdAt as unknown) as string;
              setCursor(createdAt);
            }}
            leftIcon={<AddIcon color="primary" />}
          >
            {t("tasks.activities.loadMore")}
          </MyButton>
        </Box>
      )}
    </Box>
  );
};
