import { TopBarState } from "./reducer";

export enum TopBarActionsTypes {
  APPBAR_SET_STATE = "@@APPBAR set state"
}

export class TopBarSetState {
  readonly type = TopBarActionsTypes.APPBAR_SET_STATE;
  constructor(public state?: TopBarState) {}
}

export type TopBarActions = TopBarSetState;
