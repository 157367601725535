import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { Request } from "../../utils/request";
import { File } from "../files/entity";
import { AxiosRequestConfig } from "axios";

export const read = () => Request({ withToken: true }).get("/projects");
export const readForUser = (user: User) =>
  Request({ withToken: true }).get(`/users/${user.id}/projects`);

export const fetchProjectId = (id: string) =>
  Request({ withToken: true }).get(`/projects/${id}`);

export const updateProject = (projectId: string, project: Partial<Project>) =>
  Request({ withToken: true }).patch(`/projects/${projectId}`, project);

export const createProject = (project: Partial<Project>) =>
  Request({ withToken: true }).post(`/projects`, project);

export const createManagedProject = (user: User, project: Partial<Project>) =>
  Request({ withToken: true }).post(
    `/users/${user.id}/projects/managed`,
    project
  );

export const createForCompany = (
  companyId: string,
  project: Partial<Project>
) =>
  Request({ withToken: true }).post(
    `/companies/${companyId}/projects`,
    project
  );

export const deleteProject = (id: string) =>
  Request({ withToken: true }).delete(`/projects/${id}`);

export const archived = (projectId: string) =>
  Request({ withToken: true }).delete(`/projects/${projectId}/archived`);

// FILES
export const getFiles = (projectId: string) =>
  Request({ withToken: true }).get<File[]>(`/projects/${projectId}/files`);

export const downloadFile = (
  projectId: string,
  fileId: string,
  preview?: boolean
) =>
  Request({ withToken: true }).get(
    `/projects/${projectId}/files/${fileId}/signed?preview=${preview}`
  );

export const uploadFile = (
  projectId: string,
  formData: FormData,
  onUploadProgress: AxiosRequestConfig["onUploadProgress"]
) =>
  Request({ withToken: true }).post<File>(
    `/projects/${projectId}/files`,
    formData,
    { onUploadProgress }
  );
export const deleteFile = (projectId: string, fileId: string) =>
  Request({ withToken: true }).delete(`/projects/${projectId}/files/${fileId}`);
