import {
  createStyles,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  alpha
} from "@material-ui/core";

import { green, red } from "@material-ui/core/colors";
import { ExpansionPanelProps } from "@material-ui/core/ExpansionPanel";
import ActiveIcon from "@material-ui/icons/Brightness1";
import SuccesIcon from "@material-ui/icons/CheckCircleOutline";
import Arrow from "@material-ui/icons/ArrowDownwardRounded";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { MyButton } from "./MyButton";

export enum MyExpansionPanelStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

export interface MyExpansionPanelFormProps extends ExpansionPanelProps {
  onExpanded: (isExpanded: boolean) => void;
  onExpandedNext?: () => void;
  showNext?: boolean;
  expanded: boolean;
  status?: MyExpansionPanelStatus;
  title: string;
  alwaysMounted?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 0.5, 1, 0.5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5)
      },
      "&$disabled": {
        backgroundColor: theme.palette.common.white
      },
      "&::before": {
        height: "0px"
      }
    },
    expansionPanelDetail: {
      [theme.breakpoints.down("sm")]: {
        padding: "8px 8px 24px"
      }
    },
    disabled: {
      backgroundColor: "white"
    },
    statusIcon: {
      marginRight: theme.spacing(1),
      height: 24,
      width: 24
    },
    activeStatus: {
      height: "100%",
      width: "100%",
      borderRadius: "100%",
      backgroundColor: theme.palette.primary.main
    },
    titleSelected: {
      color: theme.palette.primary.main,
      fontWeight: "bold"
    },
    titleError: {
      color: red[400],
      fontWeight: "bold"
    },
    titleSuccess: {
      color: green[400],
      fontWeight: "bold"
    },
    buttonBackground: {
      backgroundColor: alpha(theme.palette.primary.main, 0.04)
    }
  })
);

export const StatusIcon: React.FC<{
  status?: MyExpansionPanelStatus;
  isActive: boolean;
  disabled?: boolean;
}> = ({ status, isActive, disabled }) => {
  const theme = useTheme();
  if (disabled) {
    return <ActiveIcon data-testid="active-icon-disabled" color="disabled" />;
  }
  switch (status) {
    case MyExpansionPanelStatus.SUCCESS:
      return (
        <SuccesIcon data-testid="success-icon" style={{ color: green[400] }} />
      );
    case MyExpansionPanelStatus.ERROR:
      return <ErrorIcon data-testid="error-icon" style={{ color: red[400] }} />;
    default:
      return isActive ? (
        <ActiveIcon
          data-testid="active-icon"
          style={{ color: theme.palette.primary.main }}
        />
      ) : (
        <ActiveIcon data-testid="active-icon-disabled" color="disabled" />
      );
  }
};

export const MyExpansionPanelForm: React.FC<MyExpansionPanelFormProps> = ({
  title,
  expanded,
  onExpanded,
  children,
  status,
  onExpandedNext,
  showNext,
  disabled,
  onClick,
  alwaysMounted = false,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation("components");
  const theme: Theme = useTheme();

  const onExpansionPanelChange = (
    _: React.ChangeEvent<Record<any, unknown>>,
    expanded: boolean
  ) => onExpanded(expanded);

  return (
    <Accordion
      data-testid="expansion-panel"
      {...rest}
      disabled={disabled}
      onClick={(e) => !disabled && onClick && onClick(e)}
      classes={{
        root: clsx(get(rest.classes, "root"), classes.root),
        disabled: classes.disabled
      }}
      elevation={1}
      expanded={expanded}
      onChange={onExpansionPanelChange}
      TransitionProps={
        alwaysMounted ? undefined : { mountOnEnter: false, unmountOnExit: true }
      }
    >
      <AccordionSummary data-testid="expansion-panel-summary">
        <Grid container alignItems="center">
          <Grid item className={classes.statusIcon}>
            <StatusIcon
              status={status}
              isActive={expanded}
              disabled={disabled}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              className={clsx({
                [classes.titleSelected]: expanded,
                [classes.titleError]:
                  !disabled && status === MyExpansionPanelStatus.ERROR,
                [classes.titleSuccess]:
                  !disabled &&
                  expanded &&
                  status === MyExpansionPanelStatus.SUCCESS
              })}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        style={{ display: "block" }}
        classes={{ root: classes.expansionPanelDetail }}
      >
        {children}
      </AccordionDetails>
      {onExpandedNext && showNext && (
        <AccordionActions data-testid={`my-expansion-panel-action`}>
          <MyButton
            color="primary"
            className={classes.buttonBackground}
            fullWidth
            rightIcon={
              <Arrow
                style={{
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: "100%",
                  fontSize: 20
                }}
              />
            }
            onClick={() => onExpandedNext()}
          >
            {t("myExpansionPanel.next")}
          </MyButton>
        </AccordionActions>
      )}
    </Accordion>
  );
};
