import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ProviderActionsTypes } from "./action";
import * as Api from "./api";
import { Provider } from "./entity";

export interface ProviderState extends EntityState<Provider> {
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  sheetSupplierStatus: FetchingStatus;
}

export const ProviderInitialState: ProviderState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  sheetSupplierStatus: FetchingStatus.NULL
};

export const ProviderAdapter = createEntityAdapter<Provider>();

export const read = createMyAsyncThunk<Provider[]>(
  ProviderActionsTypes.READ,
  Api.read
);

export const updateOne = createMyAsyncThunk<Provider, Provider>(
  ProviderActionsTypes.UPDATE_ONE,
  Api.updateOne,
  { onSuccessMessage: "saga:update-success" }
);

export const setAsSheetSupplier = createMyAsyncThunk<Provider, string>(
  ProviderActionsTypes.SET_SHEET_SUPPLIER,
  Api.setAsSheetSupplier
);

const ProviderSlice = createMySlice({
  name: "providers",
  initialState: ProviderAdapter.getInitialState(ProviderInitialState),
  adapter: ProviderAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ProviderAdapter.upsertMany
    },
    {
      action: updateOne,
      statusName: "updateStatus",
      onSuccess: ProviderAdapter.upsertOne
    },
    {
      action: setAsSheetSupplier,
      statusName: "sheetSupplierStatus"
    }
  ],
  reducers: {}
});

export const ProviderReducer = ProviderSlice.reducer;
export const ProviderActions = {
  ...ProviderSlice.actions,
  async: {
    read,
    updateOne,
    setAsSheetSupplier
  }
};
