import * as Sentry from "@sentry/react";

export const saveToken = (token: string) => {
  try {
    localStorage.setItem("token", token);
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("saveToken");
      Sentry.captureException(e);
    });
    // tslint:disable-next-line:no-console
    console.warn(e);
  }
};

export const saveLanguage = (lang: string) => {
  try {
    localStorage.setItem("i18nextLng", lang);
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("saveLanguage");
      Sentry.captureException(e);
    });
    // tslint:disable-next-line:no-console
    console.warn(e);
  }
};

export const removeToken = () => {
  try {
    localStorage.removeItem("token");
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("removeToken");
      Sentry.captureException(e);
    });
    // tslint:disable-next-line:no-console
    console.warn(e);
  }
};

export const getToken = () => {
  try {
    return localStorage.getItem("token") || undefined;
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("getToken");
      Sentry.captureException(e);
    });
    return undefined;
  }
};
