import { Role } from "../../entities/role";
import { User } from "../../entities/user";
import { SyntheticUser } from "../../services/approval/approval.type";

export const transformEntityToOptions = <T extends { id: string }>(
  labelKey: (entity: T) => string
) => (entity: T) => ({
  value: entity.id,
  label: labelKey(entity)
});

const formatFirstLetterUpperCaseAndLastLetterLowerCase = (
  str: string
): string => {
  return str
    .split("")
    .reduce((acc: string[], item: string, index: number) => {
      const test =
        index === 0 ? item?.toUpperCase() : item?.toLocaleLowerCase();
      acc.push(test);
      return acc;
    }, [])
    .join("")
    .slice(0, 2);
};

export type UserDisplayName = {
  name: string;
  initials: string;
  email?: string | null;
};
export const getUserDisplayName = (
  user: User | SyntheticUser,
  secondLetterLocal?: boolean
): UserDisplayName => {
  const name = user?.firstName || user?.lastName || user?.email;
  if (!name) {
    return { initials: "", name: "", email: "" };
  }

  if (user.firstName && user.lastName) {
    const withSpace = ""; // no whitespace secondLetterLocal ? "" : " ";
    return {
      initials: `${user.firstName[0].toUpperCase()}${withSpace}${user.lastName[0].toUpperCase()}`,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email
    };
  }

  const initials = secondLetterLocal
    ? formatFirstLetterUpperCaseAndLastLetterLowerCase(name)
    : name.slice(0, 2).toUpperCase();
  return { initials, name, email: user.email };
};

export const getUserOptionLabel = (user: User | SyntheticUser): string => {
  const { name, email } = getUserDisplayName(user);
  return name !== email ? `${name} (${email})` : name;
};

export type RoleNameProperties = {
  name: string;
  initial: string;
  label: string;
};
export const roleNameProperties = (
  role: Role,
  lang: string,
  fallBackLang = "en-US"
): RoleNameProperties => {
  const isWhiteSpace = /\s/.test(
    role.label[lang] ? role.label[lang] : role.label[fallBackLang]
  );
  let sliceFirst;
  let sliceLast;

  if (!role.label[lang]) {
    sliceFirst = role.label[fallBackLang].charAt(0).toUpperCase();
    sliceLast = !isWhiteSpace
      ? role.label[fallBackLang].charAt(1).toLowerCase()
      : role.label[fallBackLang].split(" ")[1].charAt(0).toUpperCase();
  } else {
    sliceFirst = role.label[lang].charAt(0).toUpperCase();
    sliceLast = !isWhiteSpace
      ? role.label[lang].charAt(1).toLowerCase()
      : role.label[lang].split(" ")[1].charAt(0).toUpperCase();
  }

  const innerText = `${sliceFirst}${sliceLast}`;

  return {
    initial: innerText,
    label: role.label[lang] ? role.label[lang] : role.label[fallBackLang],
    name: role.name
  };
};

const guestRole = {
  name: "guest"
};

export const guestProperty = (
  lang: string,
  label: { [key: string]: string }
): RoleNameProperties => {
  return roleNameProperties(
    ({ ...guestRole, label: label } as unknown) as Role,
    lang
  );
};
