import { Request } from "../../utils/request";
import { SellSettings } from "./entity";

export const read = () => Request({ withToken: true }).get("/sell-settings");

export const createOne = (dto: SellSettings) =>
  Request({ withToken: true }).post("/sell-settings", dto);

export const patchOne = ({ id, ...dto }: Partial<SellSettings>) =>
  Request({ withToken: true }).patch(`/sell-settings/${id}`, dto);
