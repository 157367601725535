import merge from "lodash/merge";

export class ReducerMethods {
  static pushUniqueBy = <T extends { id: string }>(
    array: Array<T>,
    item: T,
    by?: keyof T & "id"
  ): Array<T> => {
    const By = by || "id";
    if (!array.length) {
      return [item];
    }
    const findItem = array.find((a) => a[By] === item[By]);
    if (findItem) {
      return array;
    }
    return [...array, item];
  };

  static pushUniqueByMutate = <T extends { id: string }>(
    array: Array<T>,
    item: T,
    by?: keyof T & "id"
  ) => {
    const By = by || "id";
    if (!array.length) {
      array.push(item);
    }
    const itemIndex = array.findIndex((a) => a[By] === item[By]);
    if (itemIndex > -1) {
      return;
    }
    array.push(item);
  };

  static pushArrayUniqueBy = <T extends { id: string }>(
    array: Array<T>,
    items: Array<T>,
    by?: keyof T & "id"
  ): Array<T> => {
    const By = by || "id";
    if (array.length === 0) {
      return array.concat(items);
    }
    for (const item of items) {
      const findItem = array.find((a) => a[By] === item[By]);
      if (findItem) {
        continue;
      }
      array.push(item);
    }
    return array;
  };

  static pushArrayUniqueByMutate = <T extends { id: string }>(
    array: Array<T>,
    items: Array<T>,
    by?: keyof T & "id"
  ) => {
    const By = by || "id";
    if (array.length === 0) {
      array.push(...items);
    }
    for (const item of items) {
      const itemIndex = array.findIndex((a) => a[By] === item[By]);
      if (itemIndex > -1) {
        array.splice(itemIndex, 1, item);
        continue;
      }
      array.push(item);
    }
  };

  static removeById = <T extends { id: string }>(
    array: Array<T>,
    removedItem: Partial<T> & { id: string }
  ) => {
    return array.filter((a) => a.id !== removedItem.id);
  };

  static removeByIdMutate = <T extends { id: string }>(
    array: Array<T>,
    removedItem: Partial<T> & { id: string }
  ) => {
    const itemIndex = array.findIndex((item) => item.id === removedItem.id);
    if (itemIndex > -1) {
      array.splice(itemIndex, 1);
    }
  };

  static removeManyByIdMutate = <T extends { id: string }>(
    array: Array<T>,
    removedItems: Array<Partial<T> & { id: string }>
  ) => {
    for (const removedItem of removedItems) {
      const itemIndex = array.findIndex((item) => item.id === removedItem.id);
      if (itemIndex > -1) {
        array.splice(itemIndex, 1);
      }
    }
  };

  static updateById = <T extends { id: string }>(
    array: Array<T>,
    updatedItem: T,
    customUpdate?: (item: T) => T
  ): void => {
    const itemIndex = array.findIndex((item) => item.id === updatedItem.id);
    if (itemIndex > -1) {
      array.splice(
        itemIndex,
        1,
        customUpdate
          ? customUpdate(array[itemIndex])
          : merge({}, array[itemIndex], updatedItem)
      );
    }
  };

  static updateByIdMutate = <T extends { id: string }>(
    array: Array<T>,
    updatedItem: T,
    cb?: (item: T) => T
  ) => {
    const itemIndex = array.findIndex((item) => item.id === updatedItem.id);
    if (itemIndex === -1) {
      array.push(array[itemIndex]);
    } else {
      array.splice(itemIndex, 1, cb ? cb(array[itemIndex]) : updatedItem);
    }
  };

  static upsertById = <T extends { id: string }>(
    array: Array<T>,
    upsertedItem: T
  ): Array<T> => {
    if (!array.find((a) => a.id === upsertedItem.id)) {
      return [...array, upsertedItem];
    } else {
      return array.map((item) =>
        item.id === upsertedItem.id ? merge(item, upsertedItem) : item
      );
    }
  };

  static upsertByIdMutate = <T extends { id: string }>(
    array: Array<T>,
    upsertedItem: T,
    callback?: (item: T) => T
  ): void => {
    const itemIndex = array.findIndex((a) => a.id === upsertedItem.id);
    if (itemIndex === -1) {
      array.push(upsertedItem);
    } else {
      array.splice(
        itemIndex,
        1,
        callback
          ? callback(array[itemIndex])
          : merge(array[itemIndex], upsertedItem)
      );
    }
  };
}
