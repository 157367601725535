export enum CorrugatedCompositionActionsTypes {
  READ = "@@CORRUGATED_COMPOSITION read corrugated compositions",
  READ_STATUS = "@@CORRUGATED_COMPOSITION read status corrugated compositions",
  CREATE = "@@CORRUGATED_COMPOSITION create corrugated compositions",
  CREATE_STATUS = "@@CORRUGATED_COMPOSITION create status corrugated compositions",
  UPDATE = "@@CORRUGATED_COMPOSITION update corrugated compositions",
  UPDATE_STATUS = "@@CORRUGATED_COMPOSITION update status corrugated compositions",
  DELETE = "@@CORRUGATED_COMPOSITION delete corrugated compositions",
  DELETE_STATUS = "@@CORRUGATED_COMPOSITION delete status corrugated compositions",
  CREATE_MANY = "@@CORRUGATED_COMPOSITION create many corrugated compositions",
  CREATE_MANY_STATUS = "@@CORRUGATED_COMPOSITION create many status corrugated compositions"
}
