import { object } from "yup";
import { DecorationSchema } from "../../../common/BriefElementForm/schema/decoration.schema";
import { FinishSchema } from "../../../common/BriefElementForm/schema/finish.schema";
import { PurchaseConditionSchema } from "../../../common/BriefElementForm/schema/purchaseCondition.schema";
import { MaterialSchemaFolding } from "./material.schema";
import { PackagingSchemaFolding } from "./packaging.schema";

export const BriefElementSchemaFolding = object().shape(
  {
    ...PackagingSchemaFolding,
    ...MaterialSchemaFolding,
    ...DecorationSchema,
    ...FinishSchema,
    ...PurchaseConditionSchema
  },
  [
    ["versoColorTypeCMYK", "versoDirectToneColorType"],
    ["rectoColorTypeCMYK", "rectoDirectToneColorType"]
  ]
);
