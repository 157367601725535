import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  Theme,
  createStyles,
  makeStyles,
  useTheme
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import {
  Notification,
  NotificationFilter
} from "../../../services/notifications/notifications.type";
import { InAppTabsEnum } from "./utils/inAppTabNavigation";
import { useTranslation } from "react-i18next";
import { NotificationUserList } from "./components/NotificationUserList";
import { NotificationUserArchivedList } from "./components/NotificationUserArchivedList";
import { NotificationUserFollowing } from "./components/NotificationUserFollowing";
import FilterListIcon from "@material-ui/icons/FilterList";
import { MyTypography } from "../../../components/common/MyTypography";
import { MyButton } from "../../../components/common/MyButton";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArchiveIcon from "@material-ui/icons/Archive";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import {
  useArchiveAllMutation,
  useArchiveReadMutation,
  useMarkAsAllReadMutation
} from "../../../services/notifications/notifications.service";
import { CursorPaginationMeta } from "../../../services/common/types";
import { ReactComponent as OpenSettings } from "./assets/onsettings.svg";
import { MyIconButton } from "../../../components/common/MyIconButton";
import { MyFormik } from "../../../components/common/MyFormik";
import { object } from "yup";
import { NotificationActionFilter } from "./components/NotificationActionFilter";

interface IndexProps {
  notifications: Notification[];
  meta: CursorPaginationMeta;
  setCursor: React.Dispatch<React.SetStateAction<string>>;
  onCloseSwipeable: (open?: boolean) => void;
  actionNotfication: NotificationFilter;
  setActionNotifications: React.Dispatch<
    React.SetStateAction<NotificationFilter>
  >;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    closeIcon: {
      padding: 0
    },
    container: {
      marginTop: ".5rem"
      /*   [theme.breakpoints.between("md", 1600)]: {},
        [theme.breakpoints.down("md")]: {},
        [theme.breakpoints.down("sm")]: {},
      [theme.breakpoints.down("xs")]: {} */
    },
    containerHeader: {
      padding: "1rem 1rem 0 1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    containerBody: {
      padding: "1rem"
    },
    actionSvg: {
      stroke: theme.palette.primary.main,
      width: "24px !important",
      height: "24px !important"
    },
    action: {
      width: "32px",
      height: "32px",
      minWidth: "32px !important",
      padding: "0",
      margin: "0",
      "&:hover": {
        background: "none"
      }
    }
  })
);

export const InAppNotifications: React.FC<IndexProps> = ({
  notifications,
  meta,
  setCursor,
  onCloseSwipeable,
  actionNotfication,
  setActionNotifications
}) => {
  const { t } = useTranslation("backoffice");
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState<string>(InAppTabsEnum.INBOX);
  const currentUser = useSelector(getCurrentUser);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [filtering, setIsFiltering] = useState(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);

  const [archiveRead] = useArchiveReadMutation();
  const [markAsRead] = useMarkAsAllReadMutation();
  const [archiveAll] = useArchiveAllMutation();

  const onMarkAsAllRead = async () => {
    await markAsRead({
      userId: currentUser.id,
      ...(actionNotfication.category.length > 0 && {
        category: actionNotfication.category
      })
    });
  };

  const onArchiveReadAll = async () => {
    await archiveRead({
      userId: currentUser.id,
      ...(actionNotfication.category.length > 0 && {
        category: actionNotfication.category
      })
    });
  };

  const onArchiveAll = async () => {
    await archiveAll({
      userId: currentUser.id,
      ...(actionNotfication.category.length > 0 && {
        category: actionNotfication.category
      })
    });
  };

  const changeTab = (
    __event: React.ChangeEvent<Record<string, unknown>>,
    newValue: string
  ) => {
    if (newValue === tabValue) return;

    setTabValue(newValue);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.containerHeader}>
        <MyTypography variant="h5">
          {t("notifications.inApp.title")}
        </MyTypography>
        <Box>
          <IconButton
            onClick={() => {
              onCloseSwipeable(false);
            }}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <AppBar position="static" color="transparent" elevation={0}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            onChange={changeTab}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="notification tabs"
          >
            <Tab
              label={
                <Badge
                  badgeContent={meta?.unreadCount}
                  color="primary"
                  style={{ padding: "0px 10px 0px 0px" }}
                >
                  {t("notifications.inApp.tabs.inbox")}
                </Badge>
              }
              value={InAppTabsEnum.INBOX}
            />
            <Tab
              label={t("notifications.inApp.tabs.archived")}
              value={InAppTabsEnum.ARCHIVED}
            />
            <Tab
              label={t("notifications.inApp.tabs.following")}
              value={InAppTabsEnum.FOLLOWING}
            />
          </Tabs>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            style={{ maxWidth: "64px", marginRight: "1rem" }}
          >
            <MyIconButton
              classes={{ root: classes.action }}
              color={"primary"}
              myMenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right"
                }
              }}
              menus={({ onClose }) => (
                <MenuList style={{ outline: "none" }}>
                  <MenuItem
                    button={false}
                    style={{ width: "400px", minWidth: "300px" }}
                  >
                    <MyFormik
                      initialValues={{ ...actionNotfication }}
                      validationSchema={object()}
                      onSubmit={() => undefined}
                    >
                      {({ values, setFieldValue, setValues }) => (
                        <NotificationActionFilter
                          values={values}
                          setFilters={setActionNotifications}
                          setFieldValue={setFieldValue}
                          onClose={onClose}
                        />
                      )}
                    </MyFormik>
                  </MenuItem>
                </MenuList>
              )}
            >
              <Badge
                badgeContent={
                  Object.entries(actionNotfication).filter(
                    ([key, value]: [string, any]) => !!value?.length
                  ).length
                }
                color="primary"
              >
                <FilterListIcon onClick={() => setIsFiltering(true)} />
              </Badge>
            </MyIconButton>
            <div
              ref={containerRef}
              onClick={(event) => {
                event.preventDefault();
                setOpenSettings(!openSettings);
              }}
              style={{
                maxWidth: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {tabValue === InAppTabsEnum.INBOX && (
                <MyButton
                  disableRipple
                  disableFocusRipple
                  aria-hidden="false"
                  className={classes.action}
                  menuProps={{
                    style: { padding: 0, margin: 0 },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    }
                  }}
                  menus={({ onClose }) => (
                    <MenuList
                      style={{ width: 300, outline: "none" }}
                      disablePadding
                    >
                      <MenuItem
                        onClick={(e: any) => {
                          onClose();
                          history.push(
                            "/back-office/user-profile?tab=notificationSettings"
                          );
                          onCloseSwipeable();
                          e.stopPropagation();
                        }}
                      >
                        <MyTypography
                          variant="body2"
                          leftIcon={<SettingsIcon />}
                        >
                          {t(
                            "notifications.inApp.actions.notificationSettings"
                          )}
                        </MyTypography>
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={() => {
                          onMarkAsAllRead();
                          onClose();
                        }}
                      >
                        <MyTypography
                          variant="body2"
                          leftIcon={<DoneAllIcon />}
                        >
                          {t("notifications.inApp.actions.markAllAsRead")}
                        </MyTypography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onArchiveReadAll();
                          onClose();
                        }}
                      >
                        <MyTypography
                          variant="body2"
                          leftIcon={<ArchiveIcon />}
                        >
                          {t("notifications.inApp.actions.archiveAllRead")}
                        </MyTypography>
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={() => {
                          onArchiveAll();
                          onClose();
                        }}
                      >
                        <MyTypography
                          style={{ maxWidth: "12px" }}
                          leftIcon={<ArchiveIcon />}
                        >
                          {t("notifications.inApp.actions.archiveAll")}
                        </MyTypography>
                      </MenuItem>
                    </MenuList>
                  )}
                >
                  <MyTypography
                    style={{ maxWidth: "32px" }}
                    color="primary"
                    rightIcon={<OpenSettings className={classes.actionSvg} />}
                  />
                </MyButton>
              )}
            </div>
          </Box>
        </Box>
        <Divider light />
        <Box className={classes.containerBody}>
          {tabValue === InAppTabsEnum.INBOX && (
            <NotificationUserList
              notifications={notifications}
              meta={meta}
              setCursor={setCursor}
              actionNotfication={actionNotfication}
            />
          )}
          {tabValue === InAppTabsEnum.ARCHIVED && (
            <NotificationUserArchivedList
              actionNotfication={actionNotfication}
            />
          )}
          {tabValue === InAppTabsEnum.FOLLOWING && (
            <NotificationUserFollowing />
          )}
        </Box>
      </AppBar>
    </Box>
  );
};
