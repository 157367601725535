import { AccountFilter } from "./types";

export enum UserChangePasswordReturnMessage {
  SUCCESS = "Change password success",
  WRONG_PASSWORD = "Not the same password"
}

export enum UserUpdateContext {
  CONTACT = "contacts",
  ACCOUNT = "accounts",
  PROFILE = "user-profile"
}

export const accountInitialFilters: AccountFilter = {
  role: "",
  filterId: undefined
};
