import { boolean, number, string, StringSchema } from "yup";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import {
  applyInputOptionsLogic,
  applyInputOptionsLogicBoolean,
  applyInputOptionsLogicNumber,
  applyInputOptionsLogicString,
  setYupLocale
} from "../../../../../../utils/yup";
import { PackagingSchema } from "../../../common/BriefElementForm/schema/packaging.schema";

setYupLocale();

export const PackagingSchemaFolding = {
  ...PackagingSchema,
  windowWidth: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WINDOW_WIDTH,
    number().min(0)
  ),
  windowHeight: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WINDOW_HEIGHT,
    number().min(0)
  ),
  windowWantProtection: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WINDOW_HEIGHT,
    boolean().default(false)
  ),
  windowId: applyInputOptionsLogicString(
    ConfiguratorInputNames.WINDOW_ID,
    string()
  ),
  specifyWindow: applyInputOptionsLogic<StringSchema>(
    ConfiguratorInputNames.SPECIFY_WINDOW,
    string()
  )
};
