import produce from "immer";
import { User } from "../../entities/user";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { AuthenticationActions, AuthenticationActionsTypes } from "./action";

export interface AuthenticationState {
  user: User;
  signinStatus: FetchingStatus;
  logOutStatus: FetchingStatus;
  retryConnectionStatus: FetchingStatus;
  changePasswordStatus: FetchingStatus;
}

const initialState: AuthenticationState = {
  user: {
    job: "",
    id: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    firstName: "",
    username: "",
    roles: [],
    roleIds: [],
    token: "",
    cmsToken: "",
    isActive: true,
    onboarded: false,
    collaborationIds: []
  },
  retryConnectionStatus: FetchingStatus.NULL,
  signinStatus: FetchingStatus.NULL,
  logOutStatus: FetchingStatus.NULL,
  changePasswordStatus: FetchingStatus.NULL
};

export const AuthenticationReducer = (
  state = initialState,
  action: AuthenticationActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AuthenticationActionsTypes.RETRY_CONNECTION_STATUS:
        draft.retryConnectionStatus = action.status;
        draft.user = action.user || state.user;
        break;
      case AuthenticationActionsTypes.SIGNIN_STATUS:
        draft.signinStatus = action.status;
        draft.user = { ...state.user, ...action.user } || state.user;
        break;
      case AuthenticationActionsTypes.SIGNUP_STATUS:
        draft.signinStatus = action.status;
        draft.user = { ...state.user, ...action.user } || state.user;
        break;
      case AuthenticationActionsTypes.LOG_OUT_STATUS:
        draft.logOutStatus = action.status;
        if (draft.logOutStatus === FetchingStatus.SUCCESS) {
          draft = initialState;
        }
        break;
      case AuthenticationActionsTypes.UPDATE_CURRENT_USER:
        draft.user = { ...state.user, ...action.user } || state.user;
        break;
      case AuthenticationActionsTypes.USER_CHANGE_PASSWORD_STATUS:
        draft.changePasswordStatus = action.status;
        break;
      default:
        return state;
    }
  });
