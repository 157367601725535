import React, { lazy } from "react";
import { RouteProps } from "react-router";
import {
  AdminRoles,
  InternalAndSaleRoles,
  StandardRoles
} from "../entities/role";
import { User } from "../entities/user";
import { Features as IFeatures } from "../services/features/types";

// Component with childRoutes
import { BackOffice } from "../pages/BackOffice";
import { TruckFormat } from "../pages/BackOffice/Settings/TruckFormat";
import BoxCaseFormat from "../pages/BackOffice/Settings/BoxCaseFormat";

const HomePage = lazy(() => import("../pages/Home"));
const ForgotPage = lazy(() => import("../pages/ForgotPassword"));
const ChangePasswordPage = lazy(() => import("../pages/ChangePassword"));
const MicrosoftAuthorization = lazy(
  () => import("../pages/Microsoft/MicrosoftAuthorization")
);
const MicrosoftSignIn = lazy(
  () => import("../pages/Microsoft/MicrosoftSignIn")
);
const ConfiguratorPage = lazy(() => import("../pages/Configurator"));
const ConfiguratorWithProductPage = lazy(
  () => import("../pages/Configurator/ConfiguratorWithProduct")
);
const AccountsCrmWrapper = lazy(() => import("../pages/BackOffice/Accounts"));
const CompaniesPage = lazy(() => import("../pages/BackOffice/Companies"));
const CompanyDetailPage = lazy(
  () => import("../pages/BackOffice/Companies/Detail")
);
const ConnectSettingsPage = lazy(
  () => import("../pages/BackOffice/ConnectSettings")
);
const ContactPage = lazy(() => import("../pages/BackOffice/Contacts"));
const ContactDetailPage = lazy(
  () => import("../pages/BackOffice/Contacts/Detail")
);
const MyCompanyPage = lazy(() => import("../pages/BackOffice/MyCompanies"));
const MyConfigPage = lazy(() => import("../pages/BackOffice/MyConfigs"));
const MyContactPage = lazy(() => import("../pages/BackOffice/MyContacts"));
const MyProjectsPage = lazy(() => import("../pages/BackOffice/MyProjects"));
const ProjectsPage = lazy(() => import("../pages/BackOffice/Projects"));
const ProjectDetailContainer = lazy(
  () => import("../pages/BackOffice/Projects/Detail")
);
const BackOfficeSettings = lazy(() => import("../pages/BackOffice/Settings"));
const ConfiguratorInputsSettings = lazy(
  () => import("../pages/BackOffice/Settings/ConfiguratorInput")
);
const ConfiguratorInputOptionsSettings = lazy(
  () => import("../pages/BackOffice/Settings/ConfiguratorInputOptions")
);
const ContactInformations = lazy(
  () => import("../pages/BackOffice/Settings/ContactInformations")
);
const CorrugatedMaterialsSettings = lazy(
  () => import("../pages/BackOffice/Settings/CorrugatedMaterials")
);
const MachinesOperationsRatesSettings = lazy(
  () => import("../pages/BackOffice/Settings/MachineOperationRate")
);
const MachineRatesCoefficientSetsSettings = lazy(
  () => import("../pages/BackOffice/Settings/MachineRatesCoefficientSets")
);
const MachinesSettings = lazy(
  () => import("../pages/BackOffice/Settings/Machines")
);
const OperationsSettings = lazy(
  () => import("../pages/BackOffice/Settings/Operations")
);
const OperationDetailPage = lazy(
  () => import("../pages/BackOffice/Settings/Operations/Detail")
);
const ProductSettings = lazy(
  () => import("../pages/BackOffice/Settings/Products")
);
const ProductDetailPage = lazy(
  () => import("../pages/BackOffice/Settings/Products/Detail")
);
const ProvidersOperationsRatesSettings = lazy(
  () => import("../pages/BackOffice/Settings/ProviderOperationRate")
);
const ProvidersSettings = lazy(
  () => import("../pages/BackOffice/Settings/Providers")
);
const QuoteSettings = lazy(
  () => import("../pages/BackOffice/Settings/QuoteSettings")
);
const SellSettingsPage = lazy(
  () => import("../pages/BackOffice/Settings/SellSettings")
);
const StyleSettings = lazy(
  () => import("../pages/BackOffice/Settings/StyleSettings")
);
const BriefDetailContainer = lazy(() => import("../pages/Brief/Detail"));
const SignInByToken = lazy(() => import("../pages/SignInByToken"));
const BasicVarnishSettings = lazy(
  () => import("../pages/BackOffice/Settings/Varnishes")
);
const InksSettings = lazy(() => import("../pages/BackOffice/Settings/Inks"));
const IntegrationSettingsPage = lazy(
  () => import("../pages/BackOffice/IntegrationSettings")
);
const TechnicalSettings = lazy(
  () => import("../pages/BackOffice/Settings/TechnicalSettings")
);
const PurchaseSettings = lazy(
  () => import("../pages/BackOffice/Settings/PurchaseSettings")
);
const Features = lazy(() => import("../pages/BackOffice/Settings/Features"));
const CadSources = lazy(
  () => import("../pages/BackOffice/Settings/CadSources")
);
const ExternalIdSettings = lazy(
  () => import("../pages/BackOffice/Settings/ExternalId")
);
const ProductSubCategories = lazy(
  () => import("../pages/BackOffice/Settings/ProductsSubCategories")
);
const DigitalPrintingType = lazy(
  () => import("../pages/BackOffice/Settings/DigitalPrintingType")
);
const DigitalPrintingMode = lazy(
  () => import("../pages/BackOffice/Settings/DigitalPrintingMode")
);
const Submissions = lazy(() => import("../pages/BackOffice/Submissions"));
const MySubmissions = lazy(() => import("../pages/BackOffice/MySubmission"));
const CofnDetailContainer = lazy(() => import("../pages/Cofn/Detail"));
const DieCutTool = lazy(
  () => import("../pages/BackOffice/Settings/DieCutTool")
);
const MaterialsSettingsQuery = lazy(
  () => import("../pages/BackOffice/Settings/MaterialsQuery")
);
const Tasks = lazy(() => import("../pages/BackOffice/Tasks"));
const TaskDetails = lazy(() => import("../pages/BackOffice/Tasks/Detail"));
const TaskMangementSettings = lazy(
  () => import("../pages/BackOffice/Settings/TaskManagement")
);
const Statuses = lazy(() => import("../pages/Statuses"));
const BriefPredictionContainer = lazy(
  () => import("../pages/Brief/Prediction")
);
const CountriesAndRegions = lazy(
  () => import("../pages/BackOffice/Settings/CountriesAndRegions")
);
const PalletFormat = lazy(
  () => import("../pages/BackOffice/Settings/PalletFormat")
);
const FreightCost = lazy(
  () => import("../pages/BackOffice/Settings/FreightCost")
);
const ParcelDeliveryCost = lazy(
  () => import("../pages/BackOffice/Settings/ParcelDeliveryCost")
);
const FlexoPlate = lazy(
  () => import("../pages/BackOffice/Settings/FlexoPlate")
);
const NotificationsSettings = lazy(
  () =>
    import("../pages/BackOffice/Settings/Notifications/NotificationsSettings")
);
const NotificationsTable = lazy(
  () =>
    import(
      "../pages/BackOffice/Settings/Notifications/components/NotificationsTable"
    )
);
const NotificationPage = lazy(
  () =>
    import(
      "../pages/BackOffice/Settings/Notifications/components/NotificationsPage"
    )
);
const DebugSettings = lazy(
  () => import("../pages/BackOffice/Settings/Debug/DebugSettings")
);
const Dashboard = lazy(() => import("../pages/BackOffice/Dashboard"));
const MachineOperationFormulaTable = lazy(
  () =>
    import(
      "../pages/BackOffice/Settings/MachineOperationFormula/MachineOperationFormulaTable"
    )
);
const FormDetails = lazy(
  () => import("../pages/BackOffice/Settings/Forms/FormDetails")
);
const FormList = lazy(
  () => import("../pages/BackOffice/Settings/Forms/FormList")
);
const FormNodesDetails = lazy(
  () => import("../pages/BackOffice/Settings/Forms/FormNodes/FormNodesDetails")
);
const FormSelector = lazy(() => import("../pages/FormSelector/FormSelector"));
const RequestForm = lazy(() => import("../pages/Cofn/RequestForm"));
const CofnRedirect = lazy(() => import("../pages/Cofn/CofnRedirect"));
const CacheManagements = lazy(
  () => import("../pages/BackOffice/Settings/CacheManagement")
);

const ConfiguratorSystem = lazy(
  () => import("../pages/BackOffice/Settings/ConfiguratorSystem")
);

const CustomFieldList = lazy(
  () => import("../pages/BackOffice/Settings/CustomFields/CustomFieldList")
);
const CustomFieldNodeDetails = lazy(
  () =>
    import(
      "../pages/BackOffice/Settings/CustomFields/CustomFieldNode/CustomFieldNodeDetails"
    )
);
const CustomFieldDetails = lazy(
  () => import("../pages/BackOffice/Settings/CustomFields/CustomFieldDetails")
);
const CustomFieldGlobal = lazy(
  () => import("../pages/BackOffice/Settings/CustomFields/CustomFieldGlobal")
);
const CustomFieldTabs = lazy(
  () => import("../pages/BackOffice/Settings/CustomFields/CustomFieldTabs")
);
const Reports = lazy(() => import("../pages/BackOffice/Reports"));

const Opportunity = lazy(
  () => import("../pages/BackOffice/Settings/Opportunity")
);

const UserSettings = lazy(
  () => import("../pages/BackOffice/UserSettings/UserSettings")
);
const UserProfileDetail = lazy(
  () => import("../pages/BackOffice/UserProfile/UserProfileDetail")
);
const LanguagesTable = lazy(
  () => import("../pages/BackOffice/Settings/Languages")
);
const ImportsTable = lazy(() => import("../pages/BackOffice/Settings/Imports"));
const ExportsTable = lazy(() => import("../pages/BackOffice/Settings/Exports"));
const GetUserNotificationSettings = lazy(
  () =>
    import(
      "../pages/BackOffice/UserProfile/components/GetUserNotificationSettings"
    )
);
const Requests = lazy(() => import("../pages/BackOffice/Requests"));
const ConfiguratorDuplicatePage = lazy(
  () => import("../pages/Configurator/ConfiguratorDuplicationPage")
);
const LaminationTable = lazy(
  () => import("../pages/BackOffice/Settings/Lamination")
);

const NextStepsFlow = lazy(
  () => import("../pages/BackOffice/Settings/NextStepsFlow")
);

/* const NextStepsFlowItem = lazy(
  () => import("../pages/BackOffice/Settings/NextStepsFlow/NextStepsFlowItem")
); */

export type CustomValidationOptions = {
  user: User;
  hasRole: boolean;
  isAuthenticated: boolean;
};
export type RouteOptions = {
  authenticated?: boolean;
  ssoUser?: boolean;
  roles?: string[];
  features?: Array<keyof IFeatures>;
  customValidation?: (options: CustomValidationOptions) => boolean;
  toolbar?: {
    actions: React.ReactNode;
  };
  sidenav?: {
    show?: boolean;
    icon?: React.ReactElement;
    label?: React.ReactNode;
  };
};

export interface MyRouteProps extends RouteProps {
  options?: RouteOptions;
  childRoutes?: MyRouteProps[];
  name: string;
  label: string;
}

export const RootRoutes: MyRouteProps[] = [
  {
    name: "home",
    path: "/",
    exact: true,
    component: HomePage,
    label: "backoffice:menu.home"
  },
  {
    name: "forgot password",
    path: "/forgot-password",
    exact: true,
    component: ForgotPage,
    label: "backoffice:menu.forgot-password"
  },
  {
    name: "change password",
    path: "/change-password/:token/:qPath",
    exact: true,
    component: ChangePasswordPage,
    label: "backoffice:menu.change-password"
  },
  {
    name: "admin auth",
    path: "/admin/authentication",
    exact: true,
    component: () => <HomePage adminAuthentication />,
    label: "backoffice:menu.admin-authentication"
  },
  {
    name: "microsoft authorization",
    exact: true,
    path: "/microsoft/auth/callback",
    component: MicrosoftAuthorization,
    label: "",
    options: {
      authenticated: true,
      features: ["isMicrosoftSync"]
    }
  },
  {
    name: "microsoft signin",
    exact: true,
    path: "/microsoft/signin/callback",
    component: MicrosoftSignIn,
    label: ""
  },
  {
    name: "configurator",
    exact: true,
    path: "/configurator",
    component: ConfiguratorPage,
    label: "backoffice:menu.configurator",
    options: {
      features: ["isConfigurator"]
    }
  },
  /*  {
    name: "leaflet",
    exact: true,
    path: "/leaflet",
    component: Leaflet,
    label: "backoffice:menu.leaflet"
  }, */
  {
    name: "configurator-product-id",
    exact: true,
    path: "/configurator/product/:id",
    component: ConfiguratorWithProductPage,
    label: "backoffice:menu.configurator",
    options: {
      features: ["isConfigurator"]
    }
  },
  {
    name: "configurator-duplicate-id",
    exact: true,
    path: "/configurator/duplicate/:id",
    component: ConfiguratorDuplicatePage,
    label: "backoffice:menu.configurator",
    options: {
      authenticated: true,
      features: ["isConfigurator"]
    }
  },
  {
    name: "brief-prediction",
    path: "/briefs-prediction/:id",
    label: "",
    component: BriefPredictionContainer,
    options: { authenticated: true, features: ["isConfigurator"] }
  },
  {
    name: "brief-detail",
    path: "/briefs/:id",
    label: "backoffice:menu.configurator",
    component: BriefDetailContainer,
    options: { authenticated: true, features: ["isConfigurator"] }
  },
  {
    name: "cofn",
    path: "/cofn",
    label: "backoffice:menu.collection-of-needs",
    component: CofnRedirect,
    options: {
      authenticated: true,
      features: ["isCollectionOfNeeds"]
    }
  },
  {
    name: "form-selector",
    path: "/forms",
    exact: true,
    label: "backoffice:menu.form-selector",
    component: FormSelector,
    options: {
      authenticated: true,
      features: ["isCollectionOfNeeds"]
    }
  },
  {
    name: "form-redirect",
    path: "/forms/:slug",
    exact: true,
    label: "backoffice:menu.form",
    component: RequestForm,
    options: {
      //authenticated: true,
      features: ["isCollectionOfNeeds"]
    }
  },
  {
    name: "submissions-detail",
    path: "/submissions/:id",
    label: "backoffice:menu.submissions",
    options: { authenticated: true },
    component: CofnDetailContainer
  },
  {
    name: "back-office",
    path: "/back-office",
    label: "backoffice:menu.backoffice",
    component: BackOffice,
    childRoutes: [
      {
        name: "back-office-projects",
        component: ProjectsPage,
        exact: true,
        path: "/projects",
        options: {
          roles: AdminRoles
        },
        label: "backoffice:menu.projects",
        childRoutes: [
          {
            name: "back-office-projects-details",
            component: ProjectDetailContainer,
            path: "/:id",
            label: "backoffice:menu.projects",
            options: {
              authenticated: true
            }
          }
        ]
      },
      {
        name: "back-office-submissions",
        component: Submissions,
        exact: true,
        path: "/collection-of-needs",
        options: {
          authenticated: true,
          roles: AdminRoles,
          features: ["isCollectionOfNeeds"]
        },
        label: "backoffice:menu.collection-of-needs"
      },

      {
        name: "back-office-requests",
        component: Requests,
        path: "/requests",
        label: "backoffice:menu.requests",
        options: {
          roles: AdminRoles,
          features: ["isConfigurator"]
        }
      },
      {
        name: "back-office-user-profile",
        component: UserProfileDetail,
        path: "/user-profile",
        label: "backoffice:menu.user-profile",
        options: {
          authenticated: true
        }
      },
      {
        name: "back-office-user-notification-settings",
        component: GetUserNotificationSettings,
        path: "/user-notification-settings/:id",
        label: "backoffice:menu.user-notification-settings",
        options: {
          authenticated: true,
          roles: [StandardRoles.PACKITOO]
        }
      },
      {
        name: "back-office-user-settings",
        component: UserSettings,
        path: "/user-settings",
        label: "backoffice:menu.user-settings",
        options: {
          authenticated: true
        }
      },
      {
        name: "back-office-accounts",
        component: AccountsCrmWrapper,
        path: "/accounts",
        label: "backoffice:menu.accounts",
        options: {
          roles: AdminRoles
        }
      },
      {
        name: "back-office-settings",
        component: BackOfficeSettings,
        exact: true,
        path: "/settings",
        label: "backoffice:menu.settings",
        options: {
          authenticated: true,
          roles: AdminRoles
        },
        childRoutes: [
          {
            name: "back-office-imports",
            component: ImportsTable,
            exact: true,
            path: "/imports",
            label: "backoffice:menu.imports",
            options: {
              authenticated: true,
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-exports",
            component: ExportsTable,
            exact: true,
            path: "/exports",
            label: "backoffice:menu.exports",
            options: {
              authenticated: true,
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-cache-management",
            component: CacheManagements,
            exact: true,
            path: "/cache-managements",
            label: "backoffice:menu.cache-managements",
            options: {
              authenticated: true
            }
          },
          {
            name: "back-office-configurator-system",
            component: ConfiguratorSystem,
            exact: true,
            path: "/configurator-system",
            label: "backoffice:menu.configurator-system",
            options: {
              authenticated: true
            }
          },
          {
            name: "back-office-settings-machines",
            component: MachinesSettings,
            path: "/machines",
            label: "backoffice:menu.machines",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-operations",
            component: OperationsSettings,
            path: "/operations",
            exact: true,
            label: "backoffice:menu.operations",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            },
            childRoutes: [
              {
                name: "back-office-settings-operations-details",
                component: OperationDetailPage,
                path: "/:id",
                label: "backoffice:menu.operations",
                options: {
                  authenticated: true,
                  roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
                }
              }
            ]
          },
          {
            name: "back-office-settings-providers",
            component: ProvidersSettings,
            path: "/providers",
            label: "backoffice:menu.providers",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-machines-operations-rate",
            component: MachinesOperationsRatesSettings,
            path: "/rates",
            label: "backoffice:menu.machine-rates",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-machines-operations-formula",
            component: MachineOperationFormulaTable,
            path: "/machine-constraints",
            label: "backoffice:menu.machine-constraints",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-machine-rates-coefficients",
            component: MachineRatesCoefficientSetsSettings,
            path: "/machine-rates-coefficients",
            label: "backoffice:menu.machine-rates-coefficients",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-providers-operations-rate",
            component: ProvidersOperationsRatesSettings,
            path: "/provider-rates",
            label: "backoffice:menu.provider-rates",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-materials",
            component: MaterialsSettingsQuery,
            path: "/materials",
            label: "backoffice:menu.materials",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-corrugated-materials",
            component: CorrugatedMaterialsSettings,
            path: "/corrugated-materials",
            label: "backoffice:menu.corrugated-materials",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-configurator-input",
            component: ConfiguratorInputsSettings,
            path: "/configurator-inputs",
            label: "backoffice:menu.configurator-inputs",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isConfigurator"]
            }
          },
          {
            name: "back-office-settings-configurator-input-options",
            component: ConfiguratorInputOptionsSettings,
            path: "/configurator-input-options",
            label: "backoffice:menu.configurator-input-options",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isConfigurator"]
            }
          },
          {
            name: "back-office-settings-technical-settings",
            component: TechnicalSettings,
            path: "/technical-settings",
            label: "backoffice:menu.technical-settings",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-settings-purchase-settings",
            component: PurchaseSettings,
            path: "/purchase-settings",
            exact: true,
            label: "backoffice:menu.purchase-settings",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-settings-products",
            component: ProductSettings,
            path: "/products",
            exact: true,
            label: "backoffice:menu.products",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            },
            childRoutes: [
              {
                name: "back-office-settings-products-details",
                component: ProductDetailPage,
                path: "/:id",
                label: "backoffice:menu.products",
                options: {
                  authenticated: true,
                  roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
                }
              }
            ]
          },
          {
            name: "back-office-settings-sub-categories",
            component: ProductSubCategories,
            path: "/sub-categories",
            exact: true,
            label: "backoffice:menu.sub-categories",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-settings-inks",
            component: InksSettings,
            path: "/inks",
            exact: true,
            label: "backoffice:menu.inks",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-settings-basic-varnishes",
            component: BasicVarnishSettings,
            path: "/varnishes",
            label: "backoffice:menu.varnishes",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-settings-digital-printing-type",
            component: DigitalPrintingType,
            path: "/digital-printing-type",
            label: "backoffice:menu.digital-printing-type",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-settings-digital-printing-mode",
            component: DigitalPrintingMode,
            path: "/digital-printing-mode",
            label: "backoffice:menu.digital-printing-mode",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          /* {
            name: "back-office-settings-luxury-varnishes",
            component: LuxuryVarnishSettings,
            path: "/luxury-varnishes",
            label: "backoffice:menu.luxury-varnishes",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          }, */
          {
            name: "back-office-settings-style",
            component: StyleSettings,
            path: "/style-settings",
            exact: true,
            label: "backoffice:menu.style-settings",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-quote-settings",
            component: QuoteSettings,
            path: "/quote-settings",
            exact: true,
            label: "backoffice:menu.quote-settings",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-cad-sources",
            component: CadSources,
            path: "/cad-sources",
            exact: true,
            label: "backoffice:menu.cad-sources",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-features",
            component: Features,
            path: "/features",
            exact: true,
            label: "backoffice:menu.features",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO]
            }
          },
          {
            name: "back-office-contact-informations",
            component: ContactInformations,
            path: "/contact-informations",
            exact: true,
            label: "backoffice:menu.contact-informations",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-externalid-settings",
            component: ExternalIdSettings,
            path: "/externalid-settings",
            exact: true,
            label: "backoffice:menu.externalid-settings",
            options: {
              roles: [
                StandardRoles.PACKITOO,
                StandardRoles.SUPER_ADMIN,
                StandardRoles.ADMIN
              ]
            }
          },
          {
            name: "back-office-settings-sell",
            component: SellSettingsPage,
            path: "/sell-settings",
            exact: true,
            label: "backoffice:menu.sell-settings",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-integration-settings",
            component: IntegrationSettingsPage,
            path: "/integration-settings",
            label: "backoffice:menu.integration-settings",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-auth-settings",
            component: ConnectSettingsPage,
            path: "/auth-settings",
            label: "backoffice:menu.auth-settings",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-diecut-tool",
            component: DieCutTool,
            path: "/diecut-tool",
            label: "backoffice:menu.diecut-tool",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-flexo-plate",
            component: FlexoPlate,
            path: "/flexo-plate",
            label: "backoffice:menu.flexo-plate",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-task-management-settings",
            component: TaskMangementSettings,
            path: "/task-management-settings",
            exact: true,
            label: "backoffice:menu.task-management-settings",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isTaskManagement"]
            }
          },
          {
            name: "back-office-statuses",
            component: Statuses,
            path: "/statuses",
            label: "backoffice:menu.statuses",
            options: {
              authenticated: true
            }
          },
          {
            name: "back-office-countries-and-regions",
            component: CountriesAndRegions,
            path: "/countries-and-regions",
            label: "backoffice:menu.countries-and-regions",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-pallet-format",
            component: PalletFormat,
            path: "/pallet-format",
            label: "backoffice:menu.pallet-format",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-freight-cost",
            component: FreightCost,
            path: "/freight-cost",
            label: "backoffice:menu.freight-cost",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-parcel-delivery-cost",
            component: ParcelDeliveryCost,
            path: "/parcel-delivery-cost",
            label: "backoffice:menu.parcel-delivery-cost",
            options: {
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-notifications-settings",
            component: NotificationsSettings,
            path: "/notifications-settings",
            exact: true,
            label: "backoffice:menu.default-notifications-email",
            options: {
              authenticated: true,
              features: ["isNotificationEmail"],
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-notification-logs",
            component: NotificationsTable,
            path: "/notifications",
            exact: true,
            label: "backoffice:menu.notification-logs",
            options: {
              authenticated: true,
              features: ["isNotificationEmail"],
              roles: [StandardRoles.PACKITOO]
            },
            childRoutes: [
              {
                name: "back-office-notification-page",
                component: NotificationPage,
                path: "/:id",
                exact: true,
                label: "backoffice:menu.notification-page",
                options: {
                  authenticated: true,
                  features: ["isNotificationEmail"],
                  roles: [StandardRoles.PACKITOO]
                }
              }
            ]
          },
          {
            name: "back-office-debug-settings",
            component: DebugSettings,
            path: "/debug",
            exact: true,
            label: "backoffice:menu.debug-settings",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO]
            }
          },
          {
            name: "back-office-form-settings",
            component: FormList,
            exact: true,
            path: "/forms",
            label: "backoffice:menu.form-settings",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isCollectionOfNeeds"]
            },
            childRoutes: [
              {
                name: "back-office-form-settings-details",
                exact: true,
                component: FormDetails,
                path: "/:formId",
                label: "backoffice:menu.form-settings",
                options: {
                  authenticated: true,
                  roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
                  features: ["isCollectionOfNeeds"]
                },
                childRoutes: [
                  {
                    name: "back-office-form-settings-node-details",
                    exact: true,
                    component: FormNodesDetails,
                    path: "/nodes/:id",
                    label: "backoffice:menu.form-settings",
                    options: {
                      authenticated: true,
                      roles: [
                        StandardRoles.PACKITOO,
                        StandardRoles.SUPER_ADMIN
                      ],
                      features: ["isCollectionOfNeeds"]
                    }
                  }
                ]
              }
            ]
          },
          {
            name: "back-office-custom-entity-settings",
            component: CustomFieldList,
            exact: true,
            path: "/meta-entity",
            label: "backoffice:menu.meta-entity-settings",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isCustomFields"]
            },
            childRoutes: [
              {
                name: "back-office-meta-entity-details",
                exact: true,
                component: CustomFieldDetails,
                path: "/:metaEntityId",
                label: "backoffice:menu.meta-entity-details",
                options: {
                  authenticated: true,
                  roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
                  features: ["isCustomFields"]
                },
                childRoutes: [
                  {
                    name: "back-office-meta-entity-field-details",
                    exact: true,
                    component: CustomFieldNodeDetails,
                    path: "/nodes/:id",
                    label: "backoffice:menu.meta-entity-fields",
                    options: {
                      authenticated: true,
                      roles: [
                        StandardRoles.PACKITOO,
                        StandardRoles.SUPER_ADMIN
                      ],
                      features: ["isCustomFields"]
                    }
                  }
                ]
              }
            ]
          },
          {
            name: "back-office-custom-field-global",
            component: CustomFieldGlobal,
            exact: true,
            path: "/custom-fields",
            label: "backoffice:menu.custom-field-settings",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isCustomFields"]
            },
            childRoutes: [
              {
                name: "back-office-custom-field-global-options",
                component: CustomFieldTabs,
                exact: true,
                path: "/:id",
                label: "backoffice:menu.custom-field-settings",
                options: {
                  authenticated: true,
                  roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
                  features: ["isCustomFields"]
                }
              }
            ]
          },
          {
            name: "back-office-opportunity",
            component: Opportunity,
            exact: true,
            path: "/pipelines",
            label: "backoffice:menu.opportunity",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN],
              features: ["isOpportunity"]
            }
          },
          {
            name: "back-office-languages",
            component: LanguagesTable,
            path: "/languages",
            exact: true,
            label: "backoffice:menu.languages",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO]
            }
          },
          {
            name: "back-office-laminations",
            component: LaminationTable,
            exact: true,
            path: "/laminations",
            label: "backoffice:menu.laminations",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
          },
          {
            name: "back-office-truckFormat",
            component: TruckFormat,
            path: "/truck-format",
            exact: true,
            label: "backoffice:menu.truckFormat",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO]
            }
          },
          {
            name: "back-office-boxCaseFormat",
            component: BoxCaseFormat,
            path: "/box-case-format",
            exact: true,
            label: "backoffice:menu.boxCaseFormat",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO]
            }
          },
          {
            name: "back-office-next-steps-flow",
            component: NextStepsFlow,
            path: "/next-steps-flow",
            exact: true,
            label: "backoffice:menu.next-steps-flow",
            options: {
              authenticated: true,
              roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
            }
            /* childRoutes: [
              {
                name: "back-office-next-steps-flow-details",
                component: NextStepsFlowItem,
                path: "/:id",
                label: "backoffice:menu.next-steps-flow.details",
                options: {
                  authenticated: true,
                  roles: [StandardRoles.PACKITOO, StandardRoles.SUPER_ADMIN]
                }
              }
            ] */
          }
        ]
      },
      {
        name: "back-office-contacts",
        component: ContactPage,
        exact: true,
        path: "/contacts",
        label: "backoffice:menu.contacts",
        options: {
          authenticated: true,
          roles: InternalAndSaleRoles
        },
        childRoutes: [
          {
            name: "back-office-contacts-details",
            component: ContactDetailPage,
            path: "/:id",
            label: "backoffice:menu.contacts",
            options: {
              authenticated: true,
              roles: InternalAndSaleRoles
            }
          }
        ]
      },
      {
        name: "back-office-companies",
        component: CompaniesPage,
        exact: true,
        path: "/companies",
        label: "backoffice:menu.companies",
        options: {
          authenticated: true,
          roles: InternalAndSaleRoles
        },
        childRoutes: [
          {
            name: "back-office-companies-details",
            component: CompanyDetailPage,
            path: "/:id",
            label: "backoffice:menu.contacts",
            options: {
              authenticated: true,
              roles: InternalAndSaleRoles
            }
          }
        ]
      },
      // External sales & Normal users routes
      {
        name: "back-office-my-projects",
        component: MyProjectsPage,
        exact: true,
        path: "/my-projects",
        label: "backoffice:menu.my-projects",
        options: {
          authenticated: true
        }
      },
      {
        name: "back-office-my-collection-of-needs",
        component: MySubmissions,
        exact: true,
        path: "/my-collection-of-needs",
        label: "backoffice:menu.my-collection-of-needs",
        options: {
          authenticated: true,
          features: ["isCollectionOfNeeds"]
        }
      },
      {
        name: "back-office-my-contacts",
        component: MyContactPage,
        exact: true,
        path: "/my-contacts",
        label: "backoffice:menu.my-contacts",
        options: {
          authenticated: true,
          roles: InternalAndSaleRoles
        }
      },
      {
        name: "back-office-my-companies",
        component: MyCompanyPage,
        exact: true,
        path: "/my-companies",
        label: "backoffice:menu.my-companies",
        options: {
          authenticated: true,
          roles: InternalAndSaleRoles
        }
      },
      {
        name: "web2pack",
        exact: true,
        path: "/sign-in-by-token",
        component: SignInByToken,
        label: ""
      },
      {
        name: "back-office-my-configs",
        component: MyConfigPage,
        exact: true,
        path: "/my-configs",
        label: "backoffice:menu.my-configs",
        options: {
          authenticated: true,
          features: ["isConfigurator"]
        }
      },
      {
        name: "back-office-tasks",
        component: Tasks,
        exact: true,
        path: "/tasks",
        label: "backoffice:menu.tasks",
        options: {
          authenticated: true,
          roles: InternalAndSaleRoles,
          features: ["isTaskManagement"]
        },
        childRoutes: [
          {
            name: "back-office-tasks-details",
            component: TaskDetails,
            path: "/:id",
            label: "backoffice:menu.tasks",
            options: {
              authenticated: true,
              roles: InternalAndSaleRoles,
              features: ["isTaskManagement"]
            }
          }
        ]
      },
      {
        name: "back-office-dashboard",
        component: Dashboard,
        exact: true,
        path: "/dashboard",
        label: "backoffice:menu.dashboard",
        options: {
          authenticated: true,
          roles: AdminRoles,
          features: ["isDashboardDisplay"]
        }
      },
      {
        name: "back-office-reports",
        component: Reports,
        exact: true,
        path: "/reports",
        label: "backoffice:menu.reports",
        options: {
          authenticated: true,
          roles: [
            StandardRoles.PACKITOO,
            StandardRoles.SUPER_ADMIN,
            StandardRoles.ADMIN,
            StandardRoles.SALES
          ]
        }
      }
    ]
  }
];
