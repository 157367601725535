import {
  BasicVarnish,
  BasicVarnishPatchDTO
} from "../../../entities/basicVarnish";
import { Request } from "../../../utils/request";

export const read = () =>
  Request({ withToken: true }).get<BasicVarnish>("/basic-varnishes");

export const post = (basicVarnishs: BasicVarnish) =>
  Request({ withToken: true }).post<BasicVarnish>(
    `/basic-varnishes`,
    basicVarnishs
  );

export const patch = ({ id, ...dto }: BasicVarnishPatchDTO) =>
  Request({ withToken: true }).patch(`/basic-varnishes/${id}`, dto);
