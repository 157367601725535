import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { FluteActionsTypes } from "./action";
import * as Api from "./api";
import { Flute } from "./entity";

export interface FluteState extends EntityState<Flute> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
}

export const FluteInitialState: FluteState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const FluteAdapter = createEntityAdapter<Flute>();

const FluteAdapterState = FluteAdapter.getInitialState(FluteInitialState);

export const read = createMyAsyncThunk<Flute[]>(
  FluteActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  FluteActionsTypes.CREATE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);

export const update = createMyAsyncThunk(
  FluteActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

const FluteSlice = createMySlice({
  name: "flute",
  initialState: FluteAdapterState,
  adapter: FluteAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: FluteAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: FluteAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: FluteAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const FluteReducer = FluteSlice.reducer;
export const FluteActions = { ...FluteSlice.actions, read, create, update };
