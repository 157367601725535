import {
  MaterialReferenceSheetSizeCreateDTO,
  MaterialReferenceSheetSizeUpdateDTO
} from "../../entities/materialReferenceSheetSize";
import { Request } from "../../utils/request";

export const readMaterialReferenceSheetSizes = () =>
  Request({ withToken: true }).get("/materialreference-sheetsize");

export const readOneMaterialReferenceSheetSize = (id: string) =>
  Request({ withToken: true }).get(`/materialreference-sheetsize/${id}`);

export const createMaterialReferenceSheetSize = (
  materialReferenceSheetSize: MaterialReferenceSheetSizeCreateDTO
) =>
  Request({ withToken: true }).post(
    "/materialreference-sheetsize",
    materialReferenceSheetSize
  );

export const updateMaterialReferenceSheetSize = ({
  id,
  ...dto
}: MaterialReferenceSheetSizeUpdateDTO) =>
  Request({ withToken: true }).patch(`/materialreference-sheetsize/${id}`, dto);

export const deleteMaterialReferenceSheetSize = (id: string) =>
  Request({ withToken: true }).delete(`/materialreference-sheetsize/${id}`);
