import { RequestQueryBuilder } from "@nestjsx/crud-request";
import * as Sentry from "@sentry/react";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "..";
import { Company } from "../../entities/company";
import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import { UpdateCurrentUser } from "../authentication/action";
import { getCurrentUser } from "../authentication/selector";
import {
  CompanyAddCollaboraters,
  CompanyAddMany,
  CompanyRemoveCollaboraters
} from "../companies/action";
import { getCompanies } from "../companies/selector";
import { ProjectAddMany } from "../projects/action";
import {
  UserActionsTypes,
  UserAddMany,
  UserArchive,
  UserArchiveStatus,
  UserCreateContact,
  UserCreateContactStatus,
  UserCreateProject,
  UserCreateProjectStatus,
  UserFetchContactStatus,
  UserFetchOne,
  UserFetchOneContact,
  UserFetchOneContactStatus,
  UserFetchOneStatus,
  UserInvite,
  UserInviteStatus,
  UserStatus,
  UserUpdate,
  UserUpdateContact,
  UserUpdateContactStatus,
  UserUpdatePassword,
  UserUpdatePasswordStatus,
  UserUpdateStatus
} from "./action";
import * as Api from "./api";
import i18n from "../../utils/i18n";

export function* fetchUsers() {
  yield put(new UserStatus(FetchingStatus.PENDING));
  try {
    const { data: users } = yield call(Api.fetchUsers);
    yield put(new UserStatus(FetchingStatus.SUCCESS, users));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new UserStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("users:fetchFailed")));
  }
}

export function* fetchContactUsers() {
  yield put(new UserFetchContactStatus(FetchingStatus.PENDING));
  const userId: string = yield select(
    (state: RootState) => state.authentication.user.id
  );
  try {
    const { data: contacts } = yield call(Api.fetchContactUsers, userId);
    const contactCollaborations: Map<string, Company> = new Map();
    const newContacts: Map<string, User> = new Map();
    for (const contact of contacts as User[]) {
      const { collaborations, ...user } = contact;
      (collaborations || []).forEach((c) => contactCollaborations.set(c.id, c));
      newContacts.set(user.id, user);
    }

    yield put(new CompanyAddMany(Array.from(contactCollaborations.values())));
    yield put(
      new UserFetchContactStatus(
        FetchingStatus.SUCCESS,
        Array.from(newContacts.values())
      )
    );
  } catch (error) {
    console.error(error);
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:fetchContactFailed");
      Sentry.captureException(error);
    });
    yield put(new UserFetchContactStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("users:fetchContactFailed")));
  }
}

export function* fetchOneContact({ userId }: UserFetchOneContact) {
  yield put(new UserFetchOneContactStatus(FetchingStatus.PENDING));
  try {
    const { data: user } = yield call(Api.fetchOneContact, userId);
    const { collaborations, ...contact } = user as User;

    const companies: Map<string, Company> = new Map();
    const projects: Map<string, Project> = new Map();
    const users: Map<string, User> = new Map();

    if (collaborations?.length)
      for (const collaboration of collaborations) {
        const { projects: companyProjects = [], ...c } = collaboration;
        companies.set(c.id, c);
        if (companyProjects.length)
          for (const project of companyProjects) {
            const { manager, ...p } = project;
            manager && users.set(manager.id, manager);
            projects.set(p.id, p);
          }
      }

    yield put(new CompanyAddMany(Array.from(companies.values())));
    yield put(new ProjectAddMany(Array.from(projects.values())));
    yield put(new UserAddMany(Array.from(users.values())));
    yield put(new UserFetchOneContactStatus(FetchingStatus.SUCCESS, contact));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:fetchOneContactFailed");
      Sentry.captureException(error);
    });
    yield put(new UserFetchOneContactStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("users:fetchOneContactFailed"))
    );
  }
}

export function* fetchOneUser(action: UserFetchOne) {
  const qb = RequestQueryBuilder.create({
    join: [{ field: "projects" }, { field: "collaborations" }]
  }).query();
  yield put(new UserFetchOneStatus(FetchingStatus.PENDING));
  try {
    const {
      data: { projects, collaborations, ...user }
    } = yield call(Api.fetchOne, action.userId, qb);
    yield put(new UserFetchOneStatus(FetchingStatus.SUCCESS, user));
    yield put(new CompanyAddMany(collaborations));
    yield put(new ProjectAddMany(projects));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:fetchOneFailed");
      Sentry.captureException(error);
    });
    yield put(new UserFetchOneStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:reaf-one-failed")));
  }
}

export function* inviteUser(action: UserInvite) {
  yield put(new UserInviteStatus(FetchingStatus.PENDING));
  try {
    const { data: user } = yield call(Api.inviteUser, action.inviteUser);
    yield put(new UserInviteStatus(FetchingStatus.SUCCESS, user));
    yield put(
      new AppAddSnackbar(i18n.t("users:fetchInviteSuccess"), "success")
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:fetchInviteFailed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new UserInviteStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("users:fetchInviteFailed")));
  }
}

export function* createContact(action: UserCreateContact) {
  yield put(new UserCreateContactStatus(FetchingStatus.PENDING));
  try {
    const { data: userCreated } = yield call(Api.createContact, action.contact);
    const collaborationIds = (userCreated as User).collaborationIds;
    const companies: Company[] = yield select(getCompanies);
    if (collaborationIds.length)
      for (const col of collaborationIds) {
        const companyIndex = companies.findIndex((c) => c.id === col);
        yield put(new CompanyAddCollaboraters(companyIndex, [userCreated.id]));
      }
    yield put(new UserCreateContactStatus(FetchingStatus.SUCCESS, userCreated));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:createContact");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new UserCreateContactStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* updateContact(action: UserUpdateContact) {
  yield put(new UserUpdateContactStatus(FetchingStatus.PENDING));
  try {
    const { data: userCreated }: { data: User } = yield call(
      Api.updateContact,
      action.userId,
      action.contact
    );

    // Update company collaboraters
    const companies: Company[] = yield select(
      (state: RootState) => state.companies.companies
    );
    const users: User[] = yield select((state: RootState) => state.users.users);
    const previousContactCollaboration =
      users.find((u) => u.id === action.userId)?.collaborationIds || [];
    const collaborationsIndexToRemove = companies.reduce(
      (p, c, i) =>
        previousContactCollaboration.includes(c.id) ? [...p, i] : p,
      [] as number[]
    );
    const collaborationsIndexToUpdate = companies.reduce(
      (p, c, i) =>
        userCreated.collaborationIds.includes(c.id) ? [...p, i] : p,
      [] as number[]
    );
    for (const col of collaborationsIndexToRemove)
      yield put(new CompanyRemoveCollaboraters(col, [userCreated.id]));
    for (const col of collaborationsIndexToUpdate)
      yield put(new CompanyAddCollaboraters(col, [userCreated.id]));

    yield put(new UserUpdateContactStatus(FetchingStatus.SUCCESS, userCreated));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:updateContact");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new UserUpdateContactStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* createProject(action: UserCreateProject) {
  yield put(new UserCreateProjectStatus(FetchingStatus.PENDING));
  try {
    const { data: projectCreated } = yield call(
      Api.createProject,
      action.userId,
      action.project
    );
    yield put(new ProjectAddMany([projectCreated]));
    yield put(
      new UserCreateProjectStatus(FetchingStatus.SUCCESS, projectCreated)
    );
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:createProject");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new UserCreateProjectStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* archived(action: UserArchive) {
  yield put(new UserArchiveStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.archived, action.userId);
    action.cb && action.cb();
    yield put(new UserArchiveStatus(FetchingStatus.SUCCESS, action.userId));
    yield put(
      new AppAddSnackbar(i18n.t("saga:users.archived-success"), "success")
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:archived");
      Sentry.captureException(error);
    });
    yield put(new UserArchiveStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:users.archived-failed"))
    );
  }
}

export function* update(action: UserUpdate) {
  yield put(new UserUpdateStatus(FetchingStatus.PENDING));
  try {
    const { data: userUpdated }: { data: User } = yield call(
      Api.patchOne,
      action.userId,
      action.user
    );
    yield put(new UserUpdateStatus(FetchingStatus.SUCCESS, userUpdated));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
    const currentUser: User = yield select(getCurrentUser);
    if (currentUser.id === userUpdated.id)
      yield put(new UpdateCurrentUser(userUpdated));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:updateUser");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new UserUpdateContactStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* updatePassword(action: UserUpdatePassword) {
  yield put(new UserUpdatePasswordStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.updatePassword, action.userId, {
      password: action.user.password
    });
    yield put(new UserUpdatePasswordStatus(FetchingStatus.SUCCESS));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("users:updatePassword");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new UserUpdatePasswordStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export const UserSaga = [
  takeLatest(UserActionsTypes.USER_FETCH, fetchUsers),
  takeLatest(UserActionsTypes.USER_FETCH_ONE_CONTACT, fetchOneContact),
  takeLatest(UserActionsTypes.USER_FETCH_CONTACT, fetchContactUsers),
  takeLatest(UserActionsTypes.USER_FETCH_ONE, fetchOneUser),
  takeLatest(UserActionsTypes.USER_INVITE, inviteUser),
  takeLatest(UserActionsTypes.USER_UPDATE, update),
  takeLatest(UserActionsTypes.USER_CREATE_CONTACT, createContact),
  takeLatest(UserActionsTypes.USER_UPDATE_CONTACT, updateContact),
  takeLatest(UserActionsTypes.USER_ARCHIVE, archived),
  takeLatest(UserActionsTypes.USER_UPDATE_PASSWORD, updatePassword)
];
