import { put, takeLeading } from "redux-saga/effects";
import { ConfiguratorInputOptionActions } from "../configurator-input-options/reducer";
import { ConfiguratorInputActions } from "../configurator-inputs/reducer";
import { CorrugatedCompositionActions } from "../corrugated-composition/reducer";
import { CorrugatedMaterialActions } from "../corrugated-material/reducer";
import { BasicVarnishActions } from "../finishes/basicVarnish/reducer";
import { LuxuryVarnishActions } from "../finishes/luxuryVarnish/reducer";
import { LaminationFetch } from "../finishes/lamination/action";
import { FluteActions } from "../flutes/reducer";
import { InkActions } from "../ink/reducer";
import { LinerActions } from "../liners/reducer";
import { MachineOperationRateActions } from "../machine-operation-rates/reducer";
import { MachineRead } from "../machines/action";
import { MaterialReferenceRead } from "../materialReference/action";
import { MaterialTypeFetch } from "../materialType/action";
import { MatterFetch } from "../matter/action";
import { OperationActions } from "../operations/reducer";
import { ProductCategoryFetch } from "../productCategory/action";
import { ProductActions } from "../products/reducer";
import { BriefConfiguratorActionsTypes } from "./action";
import { TechnicalSettingActions } from "../technicalSettings/reducer";
import { ContactInformationActions } from "../contactInformations/reducer";

export function* briefConfiguratorInit() {
  yield put(new ProductCategoryFetch());
  yield put<any>(ProductActions.async.read());
  yield put(new MatterFetch());
  yield put(new MaterialTypeFetch());
  yield put(new MaterialReferenceRead());
  yield put(new LaminationFetch());
  yield put(new MachineRead());
  yield put<any>(OperationActions.read());
  yield put<any>(MachineOperationRateActions.async.read());
  yield put<any>(TechnicalSettingActions.async.read());
  yield put<any>(ConfiguratorInputActions.read());
  yield put<any>(ConfiguratorInputOptionActions.read());
  yield put<any>(CorrugatedCompositionActions.read());
  yield put<any>(CorrugatedMaterialActions.read());
  yield put<any>(FluteActions.read());
  yield put<any>(LinerActions.read());
  yield put<any>(InkActions.read());
  yield put<any>(BasicVarnishActions.async.read());
  yield put<any>(LuxuryVarnishActions.async.read());
  yield put<any>(ContactInformationActions.async.read());
}

export const BriefConfiguratorSaga = [
  takeLeading(
    BriefConfiguratorActionsTypes.BRIEF_CONFIGURATOR_INIT,
    briefConfiguratorInit
  )
];
