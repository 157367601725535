import produce from "immer";
import { PredictionRequest } from "../../entities/predictionRequest";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ReducerMethods } from "../../utils/reducers/methods";
import {
  PredictionRequestActions,
  PredictionRequestActionsTypes
} from "./action";

export interface PredictionRequestState {
  predictionRequests: PredictionRequest[];
  fetchStatus: FetchingStatus;
  updateStatus: FetchingStatus;
}

const initialState: PredictionRequestState = {
  predictionRequests: [],
  fetchStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL
};

export const PredictionRequestReducer = (
  state = initialState,
  action: PredictionRequestActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PredictionRequestActionsTypes.PREDICTION_REQUEST_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.predictionRequests =
          action.predictionRequests || state.predictionRequests;
        break;
      case PredictionRequestActionsTypes.PREDICTION_REQUEST_UPDATE_STATUS:
        draft.updateStatus = action.status;
        if (
          action.status === FetchingStatus.SUCCESS &&
          action.predictionRequest
        ) {
          ReducerMethods.upsertByIdMutate<PredictionRequest>(
            draft.predictionRequests,
            action.predictionRequest
          );
        }
        break;
      default:
        return draft;
    }
  });
