import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ProductMachineOperationRateActionsTypes } from "./action";
import * as Api from "./api";
import { ProductMachineOperationRate } from "./entity";

export interface ProductMachineOperationRateState
  extends EntityState<ProductMachineOperationRate> {
  readStatus: FetchingStatus;
}

export const ProductMachineOperationRateInitialState: ProductMachineOperationRateState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL
};

export const ProductMachineOperationRateAdapter = createEntityAdapter<ProductMachineOperationRate>(
  { selectId: (m) => `${m.productId}_${m.machineOperationRateId}` }
);

export const read = createMyAsyncThunk<ProductMachineOperationRate[]>(
  ProductMachineOperationRateActionsTypes.READ,
  Api.read
);

const ProductMachineOperationRateSlice = createMySlice({
  name: "product-machine-operation-rates",
  initialState: ProductMachineOperationRateAdapter.getInitialState(
    ProductMachineOperationRateInitialState
  ),
  adapter: ProductMachineOperationRateAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ProductMachineOperationRateAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const ProductMachineOperationRateReducer =
  ProductMachineOperationRateSlice.reducer;
export const ProductMachineOperationRateActions = {
  ...ProductMachineOperationRateSlice.actions,
  async: {
    read
  }
};
