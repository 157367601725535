import { AxiosError } from "axios";
import * as React from "react";

import { Typography } from "@material-ui/core";

import { AppAddSnackbar } from "../../reducers/app/action";
import i18n from "../i18n";

export enum AliciaMessageType {
  SHEET_SIZE_NOT_FOUND = "SHEET_SIZE_NOT_FOUND",
  PALLET_TRANSPORTATION_COST_NOT_FOUND = "PALLET_TRANSPORTATION_COST_NOT_FOUND"
}

export const getAliciaMessage = (message: string) => {
  switch (message) {
    case AliciaMessageType.SHEET_SIZE_NOT_FOUND:
      return i18n.t("saga:alicia.sheet-size-not-found");

    case AliciaMessageType.PALLET_TRANSPORTATION_COST_NOT_FOUND:
      return i18n.t("saga:alicia.pallet-transportation-cost-not-found");

    default:
      return message;
  }
};

export const sendErrorNotification = (
  error: AxiosError | any,
  initialMessage: string | JSX.Element,
  withPersist = false
): any => {
  console.error(error);
  console.log("message", error.response && error.response.data.message);
  const DefaultMessage = <Typography>{initialMessage}</Typography>;
  let allErrors: React.ReactNode = null;
  if (error.response && error.response.data.message) {
    if (typeof error.response.data.message === "string") {
      const aliciaError = getAliciaMessage(error.response.data.message);
      if (aliciaError) {
        allErrors = <Typography>{JSON.stringify(aliciaError)}</Typography>;
      } else {
        allErrors = error.response.data.message
          .split(",")
          .map((message: string, index: string) => (
            <Typography key={index} variant="body2">
              {JSON.stringify(message)}
            </Typography>
          ));
      }
    } else {
      try {
        allErrors = (
          <Typography>{JSON.stringify(error.response.data.message)}</Typography>
        );
      } catch (e) {
        return DefaultMessage;
      }
    }
  }
  const errorComponent = (
    <div>
      {DefaultMessage}
      {allErrors}
    </div>
  );

  return new AppAddSnackbar(errorComponent, "error", {
    autoHideDuration: 8000,
    persist: withPersist
  });
};
