import { boolean, number, NumberSchema, string, StringSchema } from "yup";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import {
  applyInputOptionsLogic,
  applyInputOptionsLogicBoolean,
  setYupLocale
} from "../../../../../../utils/yup";

setYupLocale();
export const MaterialSchemaFolding = {
  matterId: applyInputOptionsLogic<StringSchema>(
    ConfiguratorInputNames.MATTER_ID,
    string()
  ),
  grammage: applyInputOptionsLogic<NumberSchema>(
    ConfiguratorInputNames.GRAMMAGE,
    number()
  ),
  materialTypeId: applyInputOptionsLogic<StringSchema>(
    ConfiguratorInputNames.MATERIAL_TYPE_ID,
    string()
  ),
  materialReferenceName: applyInputOptionsLogic<StringSchema>(
    ConfiguratorInputNames.MATERIAL_REFERENCE_NAME,
    string()
  ),
  materialReferenceId: applyInputOptionsLogic<StringSchema>(
    ConfiguratorInputNames.MATERIAL_REFERENCE_ID,
    string()
  ),
  cardboardVersoPosition: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.CARDBOARD_VERSO_POSITION,
    boolean()
  )
};
