import React from "react";
import { TypographyProps, Box, Typography, BoxProps } from "@material-ui/core";
import { ReactNode } from "react";

export interface MyTypographyProps extends TypographyProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  boxProps?: BoxProps;
  uppercase?: boolean;
  underline?: boolean;
  bold?: boolean;
  whiteSpace?: "normal" | "pre-line" | "inherit";
}
export const MyTypography: React.FC<MyTypographyProps> = ({
  boxProps,
  leftIcon,
  rightIcon,
  uppercase,
  underline,
  bold,
  whiteSpace,
  ...rest
}) => {
  const style: React.CSSProperties = {
    textTransform: uppercase ? "uppercase" : "initial",
    fontWeight: bold ? "bold" : "inherit",
    textDecoration: underline ? "underline" : "initial",
    whiteSpace
  };
  let typography = <Typography style={style} {...rest} />;
  if (leftIcon || rightIcon) {
    typography = (
      <Box display="flex" alignItems="center" {...boxProps}>
        {leftIcon && (
          <Box mr={1} lineHeight="12px">
            {leftIcon}
          </Box>
        )}
        {typography}
        {rightIcon && (
          <Box ml={1} lineHeight="12px">
            {rightIcon}
          </Box>
        )}
      </Box>
    );
  }
  return typography;
};
