import {
  Breadcrumbs,
  BreadcrumbsProps,
  createStyles,
  makeStyles,
  Typography
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { MyRouteProps, RootRoutes } from "../../routes";

interface MyBreadcrumbsProps extends BreadcrumbsProps {
  start?: string;
  links?: string[];
  itemName?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
);

export const MyBreadcrumbs: React.FC<MyBreadcrumbsProps> = ({
  start,
  // TODO should be call lastPathName
  itemName,
  links = [],
  ...props
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();

  const getAppPath = useCallback(
    (routes: MyRouteProps[], prefix?: string) => {
      return routes.reduce((acc, curr) => {
        const path = prefix
          ? `${prefix}${curr.path as string}`
          : (curr.path as string);
        acc = { ...acc, [path]: t(curr.label) };
        if (curr.childRoutes) {
          acc = { ...acc, ...getAppPath(curr.childRoutes, path) };
        }
        return acc;
      }, {} as { [k: string]: string });
    },
    [t]
  );

  const pathnames = (pathname as string).split("/").filter((x: string) => x);
  const breadcrumbNameMap = getAppPath(RootRoutes);

  return (
    <Breadcrumbs
      className={classes.root}
      separator={<NavigateNextIcon fontSize="small" />}
      {...props}
    >
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        let to = `/${pathnames.slice(0, index + 1).join("/")}`;

        // Reassign links based on pathname index - 1
        if (index >= 1 && links && links[index - 1]) to = links[index - 1];

        if (start && value === start) {
          return null;
        }

        return last ? (
          <Typography color="textPrimary" key={to}>
            {breadcrumbNameMap[to] || itemName}
          </Typography>
        ) : (
          <Link
            color="inherit"
            to={to}
            key={to}
            style={{ textDecoration: "none" }}
          >
            {breadcrumbNameMap[to]}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
