import {
  SheetSizeCreateDTO,
  SheetSizeUpdateDTO
} from "../../entities/sheetsize";
import { Request } from "../../utils/request";

export const readSheetSizes = () =>
  Request({ withToken: true }).get("/sheetsizes");

export const readOneSheetSize = (id: string) =>
  Request({ withToken: true }).get(`/sheetsizes/${id}`);

export const createSheetSize = (sheetsize: SheetSizeCreateDTO) =>
  Request({ withToken: true }).post("/sheetsizes?upsert=true", sheetsize);

export const updateSheetSize = ({ id, ...dto }: SheetSizeUpdateDTO) =>
  Request({ withToken: true }).patch(`/sheetsizes/${id}`, dto);

export const deleteSheetSize = (id: string) =>
  Request({ withToken: true }).delete(`/sheetsizes/${id}`);
