import { OptionsObject, VariantType } from "notistack";

import { AppState } from "./reducer";

export enum AppActionTypes {
  ENQUEUE_SNACKBAR = "@@App enqueue snackbar",
  CLOSE_SNACKBAR = "@@App close snackbar",
  REMOVE_SNACKBAR = "@@App remove snackbar",
  CHANGE_THEME = "@@App change theme",
  SET_LANGUAGE = "@@App set language",
  OPEN_MENU = "@@App open drawer",
  IS_ON_BACKOFFICE = "@@App is on backoffice",
  REDIRECT_AFTER_SUBMIT = "@@App redirect after submit"
}

export class AppChangeTheme {
  readonly type = AppActionTypes.CHANGE_THEME;
  constructor(public theme: AppState["theme"]) {}
}

export class AppAddSnackbar {
  readonly type = AppActionTypes.ENQUEUE_SNACKBAR;
  constructor(
    public message: string | JSX.Element,
    public variant: VariantType,
    public options?: OptionsObject
  ) {}
}

export class AppCloseSnackbar {
  readonly type = AppActionTypes.CLOSE_SNACKBAR;
  constructor(public key: string, public dismissAll?: boolean) {}
}

export class AppRemoveSnackbar {
  readonly type = AppActionTypes.REMOVE_SNACKBAR;
  constructor(public key: string) {}
}

export class AppSetLanguage {
  readonly type = AppActionTypes.SET_LANGUAGE;
  constructor(public lang: string) {}
}

export class AppOpenMenu {
  readonly type = AppActionTypes.OPEN_MENU;
  constructor(public open: boolean) {}
}

export class AppIsOnBackOffice {
  readonly type = AppActionTypes.IS_ON_BACKOFFICE;
  constructor(public isOnBackOffice: boolean) {}
}

export class AppRedirectAfterSubmit {
  readonly type = AppActionTypes.REDIRECT_AFTER_SUBMIT;
  constructor(public redirect?: string) {}
}

export type AppAction =
  | AppChangeTheme
  | AppAddSnackbar
  | AppCloseSnackbar
  | AppRemoveSnackbar
  | AppSetLanguage
  | AppOpenMenu
  | AppIsOnBackOffice
  | AppRedirectAfterSubmit;
