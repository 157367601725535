import { LuxuryVarnish } from "../../../entities/luxuryVarnish";
import { createMyAsyncThunk } from "../../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { LuxuryVarnishActionsTypes } from "./action";
import * as Api from "./api";

export interface LuxuryVarnishState {
  fetchStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  createStatus: FetchingStatus;
  luxuryVarnishs: LuxuryVarnish[];
}

export const LuxuryVarnishInitialState: LuxuryVarnishState = {
  fetchStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  luxuryVarnishs: []
};

export const read = createMyAsyncThunk<LuxuryVarnish>(
  LuxuryVarnishActionsTypes.GET,
  Api.read
);
export const patch = createMyAsyncThunk(
  LuxuryVarnishActionsTypes.PATCH_ONE,
  Api.patch,
  { onSuccessMessage: "saga:update-success" }
);

export const post = createMyAsyncThunk(
  LuxuryVarnishActionsTypes.CREATE,
  Api.post,
  { onSuccessMessage: "saga:update-success" }
);

const LuxuryVarnishSlice = createMySlice({
  name: "luxury-varnishes",
  initialState: LuxuryVarnishInitialState,
  asyncActions: [
    {
      action: read,
      statusName: "fetchStatus",
      onSuccess: (state, action) => {
        state.luxuryVarnishs = action.payload;
      }
    },
    {
      action: post,
      statusName: "createStatus",
      onSuccess: (state, action) => {
        state.luxuryVarnishs.push(action.payload);
      }
    },
    {
      action: patch,
      statusName: "patchStatus",
      onSuccess: (state, action) => {
        state.luxuryVarnishs = state.luxuryVarnishs.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      }
    }
  ],
  reducers: {}
});

export const LuxuryVarnishReducer = LuxuryVarnishSlice.reducer;
export const LuxuryVarnishActions = {
  ...LuxuryVarnishSlice.actions,
  async: { read, patch, post }
};
