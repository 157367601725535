export enum MachineRateCoefficientSetActionsTypes {
  READ = "@@COEFFICIENT_SETS read",
  READ_STATUS = "@@COEFFICIENT_SETS read status",
  CREATE_ONE = "@@COEFFICIENT_SETS create one",
  CREATE_ONE_STATUS = "@@COEFFICIENT_SETS create one",
  UPDATE_ONE = "@@COEFFICIENT_SETS update one",
  UPDATE_ONE_STATUS = "@@COEFFICIENT_SETS update one",
  DELETE_ONE = "@@COEFFICIENT_SETS delete one",
  DELETE_ONE_STATUS = "@@COEFFICIENT_SETS delete one"
}
