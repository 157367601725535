import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

interface ErrorComponentProps {
  message?: string;
}

export const ErrorComponent: React.FC<ErrorComponentProps> = ({ message }) => {
  const { t } = useTranslation("components");
  return (
    <Typography>
      {message ?? t("dynamicElement.errorComponent.title")}
    </Typography>
  );
};
