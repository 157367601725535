import { Request } from "../../utils/request";
import { Provider } from "./entity";

export const read = () => Request({ withToken: true }).get("/providers");

export const updateOne = ({ id, ...dto }: Provider) =>
  Request({ withToken: true }).patch(`/providers/${id}`, dto);

export const setAsSheetSupplier = (id: string) =>
  Request({ withToken: true }).patch(`/providers/${id}/set-as-sheet-supplier`);
