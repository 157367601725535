import { Product } from "../reducers/products/entity";
import { DynamicJsonTranslator } from "../utils/function/jsonTranslator";

export enum ProductCategoryName {
  "FoldingBoxes" = "FOLDING_BOXES",
  "RigidBoxes" = "RIGID_BOXES",
  "CD/DVD_BOXES" = "CD/DVD_BOXES",
  "Corrugated" = "CORRUGATED"
}

export class ProductCategory {
  id!: string;
  name!: ProductCategoryName;
  label!: DynamicJsonTranslator;
  products!: Product[];
}
