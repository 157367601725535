export enum SlideType {
  TITLE = "title",
  TEXT_IMAGE = "text image",
  VIDEO = "video"
}

export class Slide {
  position!: number;
  type!: SlideType;
  title?: string;
  text?: string;
  buttonLabel?: string;
  buttonLink?: string;
  image?: string;
  videoId?: string;
}
