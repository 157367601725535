import produce from "immer";
import { User } from "../../entities/user";
import { fp } from "../../utils/fp";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ReducerMethods } from "../../utils/reducers/methods";
import { UserActions, UserActionsTypes } from "./action";

export interface UserState {
  users: User[];
  fetchStatus: FetchingStatus;
  fetchOneContactStatus: FetchingStatus;
  fetchContactStatus: FetchingStatus;
  fetchOneStatus: FetchingStatus;
  inviteStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  createContactStatus: FetchingStatus;
  updateContactStatus: FetchingStatus;
  createProjectStatus: FetchingStatus;
  archiveStatus: FetchingStatus;
  updatePasswordStatus: FetchingStatus;
}

const initialState: UserState = {
  users: [],
  fetchOneStatus: FetchingStatus.NULL,
  fetchOneContactStatus: FetchingStatus.NULL,
  fetchContactStatus: FetchingStatus.NULL,
  fetchStatus: FetchingStatus.NULL,
  inviteStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  createContactStatus: FetchingStatus.NULL,
  updateContactStatus: FetchingStatus.NULL,
  createProjectStatus: FetchingStatus.NULL,
  archiveStatus: FetchingStatus.NULL,
  updatePasswordStatus: FetchingStatus.NULL
};

export const UserReducer = (state = initialState, action: UserActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UserActionsTypes.USER_FETCH_STATUS:
        draft.fetchStatus = action.status;
        ReducerMethods.pushArrayUniqueByMutate(
          draft.users,
          action.users || state.users
        );
        break;
      case UserActionsTypes.USER_FETCH_CONTACT_STATUS:
        draft.fetchContactStatus = action.status;
        ReducerMethods.pushArrayUniqueByMutate(
          draft.users,
          action.users || state.users
        );
        break;
      case UserActionsTypes.USER_FETCH_ONE_CONTACT_STATUS:
        draft.fetchOneContactStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS)
          ReducerMethods.upsertByIdMutate(draft.users, action.user as User);
        break;
      case UserActionsTypes.USER_INVITE_STATUS:
        draft.inviteStatus = action.status;
        action.user && draft.users.push(action.user);
        break;
      case UserActionsTypes.USER_UPDATE_STATUS:
        draft.updateContactStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS)
          ReducerMethods.upsertByIdMutate(draft.users, action.user as User);
        break;
      case UserActionsTypes.USER_FETCH_ONE_STATUS:
        draft.fetchOneStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS)
          ReducerMethods.upsertByIdMutate(draft.users, action.user as User);
        break;
      case UserActionsTypes.USER_CREATE_CONTACT_STATUS:
        draft.createContactStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS)
          ReducerMethods.upsertByIdMutate(draft.users, action.contact as User);
        break;
      case UserActionsTypes.USER_UPDATE_CONTACT_STATUS:
        draft.updateContactStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS)
          ReducerMethods.updateByIdMutate(draft.users, action.contact as User);
        break;
      case UserActionsTypes.USER_CREATE_PROJECT_STATUS:
        draft.createProjectStatus = action.status;
        break;
      case UserActionsTypes.USER_ARCHIVE_STATUS:
        draft.archiveStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.userId)
          ReducerMethods.removeByIdMutate(draft.users, { id: action.userId });
        break;
      case UserActionsTypes.USER_ADD_MANY:
        ReducerMethods.pushArrayUniqueByMutate(draft.users, action.users);
        break;
      case UserActionsTypes.USER_REMOVE_MANY:
        ReducerMethods.removeManyByIdMutate(draft.users, action.users);
        break;
      case UserActionsTypes.USER_ADD_COLLABORATION:
        draft.users[action.userIndex].collaborationIds.push(
          ...action.companyIds
        );
        break;
      case UserActionsTypes.USER_UPDATE_PASSWORD_STATUS:
        draft.updatePasswordStatus = action.status;
        break;
      case UserActionsTypes.USER_REMOVE_COLLABORATION:
        draft.users[action.userIndex].collaborationIds = fp.pullAll(
          action.companyIds,
          draft.users[action.userIndex].collaborationIds
        );
        break;
      default:
        return draft;
    }
  });
