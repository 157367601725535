import {
  ConfiguratorInputNames,
  FieldType,
  OptionsType,
  SectionNames,
  ConfiguratorInputMetaDataByName,
  ConfiguratorInputMetaData,
  EXTERNAL_INPUT_PREFIX
} from "./constant";

export const getInputMetaData = (
  name: ConfiguratorInputNames
): ConfiguratorInputMetaData => {
  if (name.startsWith(EXTERNAL_INPUT_PREFIX))
    return {
      fieldType: FieldType.NUMBER,
      optionsType: OptionsType.NUMBER,
      section: SectionNames.PACKAGING_TYPE
    };
  return ConfiguratorInputMetaDataByName[name];
};

export const getExternalInputName = (name: ConfiguratorInputNames | string) =>
  name.slice(EXTERNAL_INPUT_PREFIX.length);
