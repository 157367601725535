import { memoize } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../";
import { ProductCategoryName } from "../../entities/productCategory";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { getLanguage } from "../app/selector";

// TODO REFACTOR
export const getProductsCategory = createSelector(
  (state: RootState) => state.productsCategory.productsCategory,
  (productsCategory) => {
    const categories = productsCategory.filter(
      (pc) =>
        pc.name === ProductCategoryName.FoldingBoxes ||
        pc.name === ProductCategoryName.Corrugated
    );
    return categories;
  }
);

export const getProductCategoryFetchStatus = createSelector(
  (state: RootState) => state.productsCategory,
  (productsCategory) => productsCategory.fetchStatus
);

export const getProductCategoryById = (id?: string) =>
  createSelector(
    getProductsCategory,
    memoize((productsCategory) =>
      id && productsCategory.length > 0
        ? productsCategory.find((p) => p.id === id)
        : undefined
    )
  );

export const getProductsCategoryOptions = createSelector(
  getProductsCategory,
  getLanguage,
  (productsCategory, lang) =>
    productsCategory.map((pc) => ({
      value: pc.id,
      label: jsonTranslator(pc.label, lang)
    }))
);
