export interface TaskTabsSlug {
  comments: string;
  activities: string;
  notificationSettings: string;
}

export enum TaskTabsEnum {
  OVERVIEW = "overview",
  COMMENTS = "comments",
  ACTIVITIES = "activities",
  NOTIFICATION_SETTINGS = "notificationSettings"
}

export function navigateToTaskTabSecure(
  TaskId: string,
  tab?: TaskTabsEnum,
  extra?: string
): string {
  if (tab === TaskTabsEnum.OVERVIEW || !tab) {
    return `/back-office/tasks/${TaskId}`;
  } else {
    return `/back-office/tasks/${TaskId}?tab=${tab}${extra ? `&${extra}` : ""}`;
  }
}

export function setTaskTabsWSlug(): TaskTabsSlug {
  const tabs: TaskTabsSlug = {
    comments: "comments",
    activities: "activities",
    notificationSettings: "notificationSettings"
  };
  return tabs;
}
