import { Company, CompanyWithCollaboraters } from "../../entities/company";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum CompanyActionsTypes {
  COMPANY_FETCH = "@@COMPANY fetch company ",
  COMPANY_FETCH_STATUS = "@@COMPANY fetch status company ",
  COMPANY_FOR_USER_FETCH = "@@COMPANY fetch company of user",
  COMPANY_FOR_USER_FETCH_STATUS = "@@COMPANY fetch status company of user ",
  COMPANY_FETCH_ONE = "@@COMPANY fetch one company ",
  COMPANY_FETCH_ONE_STATUS = "@@COMPANY fetch one status company ",
  COMPANY_CREATE = "@@COMPANY create company ",
  COMPANY_CREATE_STATUS = "@@COMPANY create company status ",
  COMPANY_CREATE_MANAGED_BY_USER = "@@COMPANY create company managed by user ",
  COMPANY_CREATE_MANAGED_BY_USER_STATUS = "@@COMPANY create company managed by user status ",
  COMPANY_UPDATE = "@@COMPANY update company ",
  COMPANY_UPDATE_STATUS = "@@COMPANY update company status ",
  COMPANY_ARCHIVED = "@@COMPANY archived company ",
  COMPANY_ARCHIVED_STATUS = "@@COMPANY archived company status ",
  COMPANY_ADD_MANY = "@@COMPANY add many",
  COMPANY_UPSERT_ONE = "@@COMPANY add upsert one",
  COMPANY_ADD_COLLABORATERS = "@@COMPANY add collaboraters",
  COMPANY_REMOVE_COLLABORATERS = "@@COMPANY remove collaboraters",
  COMPANY_ADD_PROJECTS = "@@COMPANY add projects"
}

export class CompanyFetch {
  readonly type = CompanyActionsTypes.COMPANY_FETCH;
}
export class CompanyFetchStatus {
  readonly type = CompanyActionsTypes.COMPANY_FETCH_STATUS;
  constructor(public status: FetchingStatus, public companies?: Company[]) {}
}

export class CompanyFetchForUser {
  readonly type = CompanyActionsTypes.COMPANY_FOR_USER_FETCH;
}
export class CompanyFetchForUserStatus {
  readonly type = CompanyActionsTypes.COMPANY_FOR_USER_FETCH_STATUS;
  constructor(public status: FetchingStatus, public companies?: Company[]) {}
}

export class CompanyFetchOne {
  readonly type = CompanyActionsTypes.COMPANY_FETCH_ONE;
  constructor(public companyId: string) {}
}
export class CompanyFetchOneStatus {
  readonly type = CompanyActionsTypes.COMPANY_FETCH_ONE_STATUS;
  constructor(public status: FetchingStatus, public company?: Company) {}
}

export class CompanyCreate {
  readonly type = CompanyActionsTypes.COMPANY_CREATE;
  constructor(
    public company: Partial<CompanyWithCollaboraters>,
    public cb?: (company?: Company) => void
  ) {}
}
export class CompanyCreateStatus {
  readonly type = CompanyActionsTypes.COMPANY_CREATE_STATUS;
  constructor(public status: FetchingStatus, public company?: Company) {}
}

export class CompanyCreateManagedByUser {
  readonly type = CompanyActionsTypes.COMPANY_CREATE_MANAGED_BY_USER;
  constructor(
    public company: Partial<Company>,
    public cb?: (company?: Company) => void
  ) {}
}
export class CompanyCreateManagedByUserStatus {
  readonly type = CompanyActionsTypes.COMPANY_CREATE_MANAGED_BY_USER_STATUS;
  constructor(public status: FetchingStatus, public company?: Company) {}
}

export class CompanyUpdate {
  readonly type = CompanyActionsTypes.COMPANY_UPDATE;
  constructor(
    public companyId: string,
    public company: Partial<CompanyWithCollaboraters>
  ) {}
}
export class CompanyUpdateStatus {
  readonly type = CompanyActionsTypes.COMPANY_UPDATE_STATUS;
  constructor(public status: FetchingStatus, public company?: Company) {}
}

export class CompanyArchived {
  readonly type = CompanyActionsTypes.COMPANY_ARCHIVED;
  constructor(public companyId: string, public redirect: string) {}
}
export class CompanyArchivedStatus {
  readonly type = CompanyActionsTypes.COMPANY_ARCHIVED_STATUS;
  constructor(public status: FetchingStatus, public companyId?: string) {}
}

export class CompanyAddMany {
  readonly type = CompanyActionsTypes.COMPANY_ADD_MANY;
  constructor(public companies: Company[]) {}
}
export class CompanyUpsertOne {
  readonly type = CompanyActionsTypes.COMPANY_UPSERT_ONE;
  constructor(public company: Company) {}
}

export class CompanyAddCollaboraters {
  readonly type = CompanyActionsTypes.COMPANY_ADD_COLLABORATERS;
  constructor(public companyIndex: number, public collaboraterIds: string[]) {}
}

export class CompanyRemoveCollaboraters {
  readonly type = CompanyActionsTypes.COMPANY_REMOVE_COLLABORATERS;
  constructor(
    public companyIndex: number,
    public collaboraterIndexes: string[]
  ) {}
}

export type CompanyActions =
  | CompanyFetch
  | CompanyFetchStatus
  | CompanyFetchForUser
  | CompanyFetchForUserStatus
  | CompanyFetchOne
  | CompanyFetchOneStatus
  | CompanyCreate
  | CompanyCreateStatus
  | CompanyCreateManagedByUser
  | CompanyCreateManagedByUserStatus
  | CompanyUpdate
  | CompanyUpdateStatus
  | CompanyArchived
  | CompanyArchivedStatus
  | CompanyAddMany
  | CompanyUpsertOne
  | CompanyAddCollaboraters
  | CompanyRemoveCollaboraters;
