import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ConfiguratorInputOptionActionsTypes } from "./action";
import * as Api from "./api";
import { ConfiguratorInputOption } from "./entity";

export interface ConfiguratorInputOptionState
  extends EntityState<ConfiguratorInputOption> {
  readStatus: FetchingStatus;
  createOneStatus: FetchingStatus;
  deleteOneStatus: FetchingStatus;
  patchOneStatus: FetchingStatus;
}

export const ConfiguratorInputOptionInitialState: ConfiguratorInputOptionState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createOneStatus: FetchingStatus.NULL,
  deleteOneStatus: FetchingStatus.NULL,
  patchOneStatus: FetchingStatus.NULL
};

export const ConfiguratorInputOptionAdapter = createEntityAdapter<ConfiguratorInputOption>();

const ConfiguratorInputOptionAdapterState = ConfiguratorInputOptionAdapter.getInitialState(
  ConfiguratorInputOptionInitialState
);

export const read = createMyAsyncThunk<ConfiguratorInputOption[]>(
  ConfiguratorInputOptionActionsTypes.READ,
  Api.read
);
export const createOne = createMyAsyncThunk(
  ConfiguratorInputOptionActionsTypes.CREATE_ONE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);
export const deleteOne = createMyAsyncThunk(
  ConfiguratorInputOptionActionsTypes.DELETE_ONE,
  Api.deleteOne,
  {
    onSuccessMessage: "saga:delete-success"
  }
);
export const patchOne = createMyAsyncThunk(
  ConfiguratorInputOptionActionsTypes.PATCH_ONE,
  Api.patch,
  {
    onSuccessMessage: "saga:update-success"
  }
);

const ConfiguratorInputOptionSlice = createMySlice({
  name: "configurator-input-options",
  initialState: ConfiguratorInputOptionAdapterState,
  adapter: ConfiguratorInputOptionAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ConfiguratorInputOptionAdapter.upsertMany
    },
    {
      action: createOne,
      statusName: "createOneStatus",
      onSuccess: ConfiguratorInputOptionAdapter.upsertOne
    },
    {
      action: patchOne,
      statusName: "patchOneStatus",
      onSuccess: ConfiguratorInputOptionAdapter.upsertOne
    },
    {
      action: deleteOne,
      statusName: "deleteOneStatus",
      onSuccess: (state, payload) => {
        ConfiguratorInputOptionAdapter.removeOne(state, payload.meta.arg);
      }
    }
  ],
  reducers: {}
});

export const ConfiguratorInputOptionReducer =
  ConfiguratorInputOptionSlice.reducer;
export const ConfiguratorInputOptionActions = {
  ...ConfiguratorInputOptionSlice.actions,
  read,
  patchOne,
  deleteOne,
  createOne
};
