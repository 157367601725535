import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ContactInformationActionsTypes } from "./action";
import * as Api from "./api";
import { ContactInformation } from "./entity";

export interface ContactInformationState {
  readStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  contact: ContactInformation | undefined;
}

export const ContactInformationInitialState: ContactInformationState = {
  readStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  contact: undefined
};

export const read = createMyAsyncThunk<ContactInformation>(
  ContactInformationActionsTypes.GET,
  Api.read
);

export const patch = createMyAsyncThunk<
  ContactInformation,
  Partial<ContactInformation>
>(ContactInformationActionsTypes.PATCH, Api.patch, {
  onSuccessMessage: "saga:update-success"
});

const ContactSettingSlice = createMySlice({
  name: "contact-information",
  initialState: ContactInformationInitialState,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: (state, action) => {
        state.contact = action.payload;
      }
    },
    {
      action: patch,
      statusName: "patchStatus",
      onSuccess: (state, action) => {
        state.contact = action.payload;
      }
    }
  ],
  reducers: {}
});

export const ContactInformationReducer = ContactSettingSlice.reducer;
export const ContactInformationActions = {
  ...ContactSettingSlice.actions,
  async: { read, patch }
};

export const getContactKeys = createSelector(
  (state: RootState) => state.contactInformations.contact as ContactInformation,
  (contact) => {
    if (!contact) return null;
    return Object.keys(contact as ContactInformation)
      .filter(
        (key) =>
          typeof contact[key as keyof ContactInformation] === "boolean" &&
          contact[key as keyof ContactInformation]
      )
      .join("-");
  }
);
