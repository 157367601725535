import {
  OperationCreateDTO,
  OperationPatchDTO,
  OperationUpdateDTO
} from "./entity";
import { Request } from "../../utils/request";

export const read = () => Request({ withToken: true }).get("/operations");

export const readOneOperation = (id: string) =>
  Request({ withToken: true }).get(`/operations/${id}`);

export const createOperation = (operation: OperationCreateDTO) =>
  Request({ withToken: true }).post("/operations", operation);

export const updateOperation = ({ id, ...dto }: OperationUpdateDTO) =>
  Request({ withToken: true }).patch(`/operations/${id}`, dto);

export const update = ({ id, ...dto }: OperationPatchDTO) =>
  Request({ withToken: true }).put(`/operations/${id}`, dto);

export const deleteOperation = (id: string) =>
  Request({ withToken: true }).delete(`/operations/${id}`);
