import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { LinerActionsTypes } from "./action";
import * as Api from "./api";
import { Liner } from "./entity";

export interface LinerState extends EntityState<Liner> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
}

export const LinerInitialState: LinerState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const LinerAdapter = createEntityAdapter<Liner>();

const LinerAdapterState = LinerAdapter.getInitialState(LinerInitialState);

export const read = createMyAsyncThunk<Liner[]>(
  LinerActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  LinerActionsTypes.CREATE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);

export const update = createMyAsyncThunk(
  LinerActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

const LinerSlice = createMySlice({
  name: "liner",
  initialState: LinerAdapterState,
  adapter: LinerAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: LinerAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: LinerAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: LinerAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const LinerReducer = LinerSlice.reducer;
export const LinerActions = { ...LinerSlice.actions, read, create, update };
