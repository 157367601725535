import { Box, CircularProgress, Theme } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { MyMenu, MyMenuProps } from "./MyMenu";

export interface MyButtonProps extends ButtonProps {
  to?: LinkProps["to"];
  linkProps?: Omit<LinkProps, "to">;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  loading?: boolean;
  capitalize?: boolean;
  menuProps?: Partial<MyMenuProps>;
  customSpinner?: JSX.Element;
  children: string | JSX.Element;
  menus?: (props: { onClose: () => void }) => ReactNode;
}
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none"
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  })
);

export const MyButton: React.FC<MyButtonProps> = ({
  to,
  capitalize,
  rightIcon,
  leftIcon,
  linkProps,
  children,
  customSpinner,
  style,
  loading,
  menus,
  menuProps,
  ...rest
}) => {
  const classes = useStyle();
  const buttonStyle: React.CSSProperties = {
    ...style,
    textTransform: capitalize ? "uppercase" : "none"
  };

  let button = (
    <Button style={buttonStyle} {...rest} disabled={rest.disabled || loading}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {leftIcon && (
          <Box mr={1}>
            {(props: BoxProps) => React.cloneElement(leftIcon, props)}
          </Box>
        )}
        {children}
        {rightIcon && (
          <Box ml={1}>
            {(props: BoxProps) => React.cloneElement(rightIcon, props)}
          </Box>
        )}
        {loading && !customSpinner && (
          <CircularProgress
            data-testid="circular-component"
            size={24}
            className={classes.buttonProgress}
          />
        )}
        {customSpinner && loading && <>{customSpinner}</>}
      </Box>
    </Button>
  );
  if (to) {
    button = (
      <Link to={to} className={classes.link} {...linkProps}>
        {button}
      </Link>
    );
  }
  if (menus) {
    button = (
      <MyMenu component={button} id="button-menu" {...menuProps}>
        {menus}
      </MyMenu>
    );
  }

  return button;
};
