export enum FormNodeType {
  BRIEF_DETAIL = "briefs",
  CONFIGURATOR = "configurator",
  BRIEF_PREDICTION = "briefs-prediction",
  COFN = "forms",
  COFN_DETAILS = "submissions"
}

export const redirectionLogo = (queryParam: string): string => {
  const whichLocation = queryParam.split("/")[1] as FormNodeType;
  let neverHappens: never;
  switch (whichLocation) {
    case FormNodeType.BRIEF_DETAIL:
    case FormNodeType.CONFIGURATOR:
    case FormNodeType.BRIEF_PREDICTION:
      return "/configurator";
    case FormNodeType.COFN:
    case FormNodeType.COFN_DETAILS:
      return "/forms";
    default:
      neverHappens = whichLocation;
      return neverHappens;
  }
};
