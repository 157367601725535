import produce from "immer";

import { Matter } from "../../entities/matter";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MatterActions, MatterActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface MatterState {
  matters: Matter[];
  fetchStatus: FetchingStatus;
}

const initialState: MatterState = {
  matters: [],
  fetchStatus: FetchingStatus.NULL
};

export const MatterReducer = (state = initialState, action: MatterActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MatterActionsTypes.MATTER_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.matters = action.matters || state.matters;
        break;
      case MatterActionsTypes.MATTER_ADD:
        ReducerMethods.pushUniqueByMutate<Matter>(draft.matters, action.matter);
        break;
      default:
        return draft;
    }
  });
