import { createSelector } from "reselect";
import { RootState } from "..";
import { CompanyState } from "./reducer";

export const getCompanyState = (rootState: RootState): CompanyState =>
  rootState.companies;

export const getCompanies = createSelector(
  getCompanyState,
  (state) => state.companies
);

export const getCompaniesManaged = (userId: string) =>
  createSelector(getCompanies, (companies) =>
    companies.filter((c) => c.managedById === userId)
  );

export const getCompanyById = (id: string) =>
  createSelector(getCompanies, (companies) =>
    companies.find((c) => c.id === id)
  );

export const getCompaniesFetchStatus = createSelector(
  getCompanyState,
  (state) => state.fetchStatus
);
