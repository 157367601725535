import { Request } from "../../utils/request";
import { ContactInformation } from "./entity";

export const read = () =>
  Request({ withToken: true }).get<ContactInformation>("/contact-informations");

export const patch = (contactInformation: Partial<ContactInformation>) =>
  Request({ withToken: true }).patch<ContactInformation>(
    `/contact-informations`,
    contactInformation
  );
