import { memoize } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "..";
import { Company } from "../../entities/company";
import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { getCompanies } from "../companies/selector";
import { getUsers } from "../users/selector";
import { ProjectState } from "./reducer";

export const getProjectData = (user?: User) => (
  projects: Project[],
  companies: Company[],
  users: User[]
) => {
  let result = projects.map((project) => {
    const company = project?.companyId
      ? companies.find((c) => c.id === project.companyId)
      : undefined;
    const manager = project?.managerId
      ? users.find((u) => u.id === project.managerId)
      : undefined;
    return {
      ...project,
      company,
      manager
    };
  });
  if (user) {
    result = result.filter(
      (project) =>
        user.id === project.userId ||
        user.id === project.managerId ||
        companies.find((c) => c.id === project.companyId)?.managedById ===
          user.id
    );
  }
  return result;
};

const getProjectState = (rootState: RootState): ProjectState =>
  rootState.projects;

export const getProjects = createSelector(
  getProjectState,
  (state) => state.projects
);

export const getProjectsByMostRecent = createSelector(
  getProjectState,
  (state) =>
    state.projects.length > 0
      ? state.projects
          .slice()
          .sort(
            (a, b) =>
              -a?.createdAt?.toString().localeCompare(b?.createdAt?.toString())
          )
      : []
);

export const getProjectForUser = (user: User) =>
  createSelector([getProjects, getCompanies, getUsers], getProjectData(user));

export const getProjectById = (id: string) =>
  createSelector(
    getProjects,
    memoize((projects) => projects.find((p) => p.id === id))
  );

export const getSelectedProjectId = createSelector(
  getProjectState,
  (state) => state.selectedProjectId
);

export const getProjectsByCompanyId = (companyId: string) =>
  createSelector(getProjects, (projects) =>
    projects.filter((p) => p.companyId === companyId)
  );

export const getProjectsFetchStatus = createSelector(
  getProjectState,
  (state) => state.fetchStatus
);

export const getProjectFetchByIdStatus = createSelector(
  getProjectState,
  (state) => state.fetchByIdStatus
);

export const getProjectEditNameStatus = createSelector(
  getProjectState,
  (state) => state.updateStatus
);

export const getProjectEditStatus = createSelector(
  getProjectState,
  (state) => state.updateStatus
);

export const getProjectCreateStatus = createSelector(
  getProjectState,
  (state) => state.createStatus
);

export const getProjectDeleteStatus = createSelector(
  getProjectState,
  (state) => state.deleteProjectStatus
);

export const getProjectContext = createSelector(
  getProjectState,
  (state) => state.contextProjectId
);

export const getLastCreatedProjectId = createSelector(
  getProjectState,
  (state) => state.lastCreatedProjectId
);
