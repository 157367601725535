import React from "react";
import "./ellipsis.css";
import { makeStyles, useTheme } from "@material-ui/core";

const useEllipsisStyles = makeStyles((theme) => ({
  ldsEllipsisDiv: {
    background: theme.palette.primary.main
  }
}));

interface EllipsisProps {
  styles?: React.CSSProperties | undefined;
}

export const Ellipsis: React.FC<EllipsisProps> = ({ styles }) => {
  const classes = useEllipsisStyles();
  return (
    <div className="lds-ellipsis" style={{ ...styles }}>
      <div className={`${classes.ldsEllipsisDiv}`}></div>
      <div className={`${classes.ldsEllipsisDiv}`}></div>
      <div className={`${classes.ldsEllipsisDiv}`}></div>
      <div className={`${classes.ldsEllipsisDiv}`}></div>
    </div>
  );
};
