import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { MyButton } from "../../../components/common/MyButton";
import { Slide } from "./slide";

const useStyles = makeStyles(() =>
  createStyles({
    view: {
      height: "100%"
    },
    viewTitle: {
      fontSize: 32,
      fontWeight: 600
    },
    homeSlideText: {
      fontSize: 26,
      fontWeight: 400
    },
    navigationButton: {
      minWidth: "300px",
      minHeight: "45px"
    }
  })
);

export interface TitleViewProps {
  view: Slide;
  onMobile: boolean;
  navigationLabel: string;
  navigationFunction: React.MouseEventHandler<HTMLButtonElement>;
  navigationIcon?: React.ReactElement;
}

export const TitleView: React.FC<TitleViewProps> = ({
  view,
  onMobile,
  navigationLabel,
  navigationFunction,
  navigationIcon
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      direction="column"
      className={classes.view}
    >
      <Grid item>
        <Box textAlign="center">
          <Typography variant="h4" className={classes.viewTitle}>
            {view.title}
          </Typography>
          <Box mt={4}>
            <Typography className={classes.homeSlideText}>
              {view.text}
            </Typography>
          </Box>
          {!onMobile && (
            <Box mt={8}>
              <MyButton
                color="primary"
                variant="contained"
                rightIcon={navigationIcon}
                className={classes.navigationButton}
                onClick={navigationFunction}
              >
                <Typography>{navigationLabel}</Typography>
              </MyButton>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
