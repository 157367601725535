import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CorrugatedSupplierActionsTypes } from "./action";
import * as Api from "./api";
import { CorrugatedSupplier } from "./entity";

export interface CorrugatedSupplierState
  extends EntityState<CorrugatedSupplier> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
}

export const CorrugatedSupplierInitialState: CorrugatedSupplierState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const CorrugatedSupplierAdapter = createEntityAdapter<CorrugatedSupplier>();

const CorrugatedSupplierAdapterState = CorrugatedSupplierAdapter.getInitialState(
  CorrugatedSupplierInitialState
);

export const read = createMyAsyncThunk<CorrugatedSupplier[]>(
  CorrugatedSupplierActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  CorrugatedSupplierActionsTypes.CREATE,
  Api.createOne,
  { onSuccessMessage: "saga:create-success" }
);

export const update = createMyAsyncThunk(
  CorrugatedSupplierActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

const CorrugatedSupplierSlice = createMySlice({
  name: "corrugated-suppliers",
  initialState: CorrugatedSupplierAdapterState,
  adapter: CorrugatedSupplierAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: CorrugatedSupplierAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: CorrugatedSupplierAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: CorrugatedSupplierAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const CorrugatedSupplierReducer = CorrugatedSupplierSlice.reducer;
export const CorrugatedSupplierActions = {
  ...CorrugatedSupplierSlice.actions,
  read,
  create,
  update
};
