import produce from "immer";

import { Machine } from "../../entities/machine";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MachineActions, MachineActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface MachineState {
  machines: Machine[];
  createStatus: FetchingStatus;
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
  readOneStatus: FetchingStatus;
}

const initialState: MachineState = {
  machines: [],
  createStatus: FetchingStatus.NULL,
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL,
  readOneStatus: FetchingStatus.NULL
};

export const MachineReducer = (state = initialState, action: MachineActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MachineActionsTypes.MACHINE_CREATE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.pushUniqueByMutate(
            state.machines,
            action.machine as Machine
          );
        break;

      case MachineActionsTypes.MACHINE_READ_STATUS:
        draft.readStatus = action.status;
        draft.machines = action.machines || state.machines;
        break;

      case MachineActionsTypes.MACHINE_UPDATE_STATUS:
        draft.updateStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.machines,
            action.machine as Machine
          );
        break;

      case MachineActionsTypes.MACHINE_DELETE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.removeByIdMutate(
            draft.machines,
            action.machine as Machine
          );
        break;

      case MachineActionsTypes.MACHINE_READ_ONE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.machines,
            action.machine as Machine
          );
        break;

      case MachineActionsTypes.MACHINE_ADD:
        ReducerMethods.upsertByIdMutate(
          draft.machines,
          action.machine as Machine
        );
        break;

      case MachineActionsTypes.MACHINE_REMOVE:
        ReducerMethods.removeByIdMutate(
          draft.machines,
          action.machine as Machine
        );
        break;

      default:
        return draft;
    }
  });
