import { formatDistanceStrict, subMinutes } from "date-fns";
import { getLocaleForFns } from "./formatDate";

const formatDistanceStrictSafe = (
  date: Date | number,
  baseDate: Date | number,
  options?: {
    addSuffix?: boolean;
    unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
    roundingMethod?: "floor" | "ceil" | "round";
    locale?: Locale;
  }
): string => {
  try {
    return formatDistanceStrict(date, baseDate, options);
  } catch (e) {
    console.error(e);
  }
  return "";
};

export const getDistanceBetweenDatesInWordsUtc = (
  date: Date,
  lang: string
): string => {
  const now = new Date();
  const utcDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  const dbDate = new Date(date);
  const dbDateToUtc = new Date(
    dbDate.getTime() + now.getTimezoneOffset() * 60000
  );

  const differenceInMilliseconds = Math.abs(
    utcDate.getTime() - dbDateToUtc.getTime()
  );

  return formatDistanceStrictSafe(subMinutes(dbDateToUtc, 1), utcDate, {
    addSuffix: true,
    locale: getLocaleForFns(lang)
  });
};

export const checkIsDateBypass = (date: Date): boolean => {
  const now = new Date();
  const utcDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  const dbDate = new Date(date);
  const dbDateToUtc = new Date(
    dbDate.getTime() + now.getTimezoneOffset() * 60000
  );

  const differenceInMilliseconds = utcDate.getTime() - dbDateToUtc.getTime();

  return differenceInMilliseconds > 0;
};

export const getDistanceBetweenDatesInWordsLocal = (
  date: Date,
  lang: string
): string => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };

  const dateString = new Date(date).toLocaleString(lang);
  const now = new Date();
  const nowString = now.toLocaleString(lang);
  const dbDate = Date.parse(dateString);
  const nowDate = Date.parse(nowString);
  return formatDistanceStrictSafe(subMinutes(dbDate, 1), nowDate, {
    addSuffix: true,
    locale: getLocaleForFns(lang)
  });
};

export const getDistanceBetweenDatesInWord = (
  date: Date,
  lang: string
): string => {
  const now = new Date();
  const utcDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const dbDate = new Date(date);

  return formatDistanceStrictSafe(subMinutes(new Date(date), 1), utcDate, {
    addSuffix: true,
    locale: getLocaleForFns(lang)
  });
};
