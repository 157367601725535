import { createSelector } from "reselect";
import { RootState } from "..";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import { ConfiguratorInputNames } from "../configurator-inputs/constant";
import {
  ConfiguratorInputOption,
  InputOptionsAdvanced,
  JsonLogicFormula
} from "./entity";
import { ConfiguratorInputOptionAdapter } from "./reducer";
import { RolesWithContextType } from "../../entities/role";

export interface ConfiguratorInputOptionComputed {
  /** Input name including external input name with its prefix */
  id: string;
  label?: DynamicJsonTranslator | null;
  helperText?: DynamicJsonTranslator | null;
  external: boolean;
  linked: boolean;
  visible: boolean;
  visibleExcludedRoles?: RolesWithContextType[];
  required: boolean;
  formula: string;
  jsonLogicFormula: JsonLogicFormula;
  options?: InputOptionsAdvanced | null;
  interInputId?: string;
  interInputFormula?: string;
  precondition?: boolean;
  /** Input name and external input name without its prefix */
  name: ConfiguratorInputNames;
}

export interface InterInputOptionComputed extends ConfiguratorInputOption {
  jsonLogicFormula: JsonLogicFormula;
}

export type ExternalInputName = string; //with EXTERNAL_INPUT_PREFIX
export type InputName = ConfiguratorInputNames | ExternalInputName;
export type InputUUID = string;

export type ConfiguratorInputOptionsResult = Record<
  InputName,
  ConfiguratorInputOptionComputed
>;

export interface ConfiguratorInputOptionComputedPartial
  extends ConfiguratorInputOption {
  precondition: boolean;
}

export type ConfiguratorInputOptionsComputed = Record<
  InputUUID,
  ConfiguratorInputOptionComputedPartial
>;

export interface ProductInputOptions {
  // Input options
  iO: Map<InputUUID, ConfiguratorInputOption>;
  // Interinput options
  iIO: Map<InputUUID, Array<ConfiguratorInputOption>>;
}

export const AdapterSelector = ConfiguratorInputOptionAdapter.getSelectors(
  (state: RootState) => state.configuratorInputOptions
);

export const getState = createSelector(
  (state: RootState) => state,
  (state) => state.configuratorInputOptions
);

const getConfiguratorInputOptionsById = (id: string) =>
  createSelector(
    (state: RootState) => state,
    (state) => AdapterSelector.selectById(state, id)
  );

export const ConfiguratorInputOptionSelector = {
  ...AdapterSelector,
  getState
};
