import { useEffect } from "react";
import { useEditUserMutation } from "../../services/users/users";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/authentication/selector";

export const useUserTimeZone = (): string | null | undefined => {
  const user = useSelector(getCurrentUser);
  const [updateUser, { isUninitialized }] = useEditUserMutation();

  useEffect(() => {
    if (!isUninitialized) return;
    if (!user?.email) return;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!user?.timeZone && user?.timeZone !== timeZone) {
      updateUser({
        id: user.id,
        timeZone,
        notValidate: true,
        email: user?.email
      });
    }
  }, [updateUser, user.email, user.timeZone, user.id, isUninitialized]);

  return user.timeZone;
};
