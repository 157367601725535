import { Lamination } from "../../../entities/lamination";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";

export enum LaminationActionsTypes {
  LAMINATION_FETCH = "@@LAMINATION fetch lamination",
  LAMINATION_FETCH_STATUS = "@@LAMINATION fetch status lamination",
  LAMINATION_ADD = "@@LAMINATION add lamination"
}

export class LaminationFetch {
  readonly type = LaminationActionsTypes.LAMINATION_FETCH;
}
export class LaminationFetchStatus {
  readonly type = LaminationActionsTypes.LAMINATION_FETCH_STATUS;
  constructor(
    public status: FetchingStatus,
    public laminations?: Lamination[]
  ) {}
}
export class LaminationAdd {
  readonly type = LaminationActionsTypes.LAMINATION_ADD;
  constructor(public lamination: Lamination) {}
}

export type LaminationActions =
  | LaminationFetch
  | LaminationFetchStatus
  | LaminationAdd;
