import { boolean, number, object, string } from "yup";
import { EntitySchema } from "../../../../utils/yup";
import { TruckFormat } from "../../../../services/truckFormat/type";

export const TruckFormatSchema = EntitySchema<TruckFormat, "UPDATE" | "CREATE">(
  {
    name: string().nullable(),
    width: number().min(0),
    length: number().min(0),
    enabled: boolean(),
    weight: number().min(0).default(15),
    height: number().min(0).default(114),
    purchaseCost: number().min(0),
    maxHeight: number().min(0),
    maxWeight: number().min(0),
    preferredUsageLevel: number().min(0),
    insideWidth: number().min(0),
    insideHeight: number().min(0),
    insideLength: number().min(0)
  },
  { id: string().required() },
  () => ({
    CREATE: object({
      name: string().nullable(),
      width: number().min(0).required(),
      length: number().min(0).required(),
      enabled: boolean().required(),
      weight: number().min(0).required(),
      height: number().min(0).required(),
      purchaseCost: number().min(0).required(),
      maxHeight: number().min(0).required(),
      maxWeight: number().min(0).required(),
      preferredUsageLevel: number().min(0).required(),
      insideWidth: number().min(0).required(),
      insideHeight: number().min(0).required(),
      insideLength: number().min(0).required()
    }),
    UPDATE: object({
      name: string().nullable(),
      width: number().min(0).required(),
      length: number().min(0).required(),
      enabled: boolean().required(),
      weight: number().min(0).required(),
      height: number().min(0).required(),
      purchaseCost: number().min(0).required(),
      maxHeight: number().min(0).required(),
      maxWeight: number().min(0).required(),
      preferredUsageLevel: number().min(0).required(),
      insideWidth: number().min(0).required(),
      insideHeight: number().min(0).required(),
      insideLength: number().min(0).required()
    })
  })
);

export const TruckFormatInitialValues = {
  name: undefined,
  width: undefined,
  length: undefined,
  enabled: true,
  weight: 15,
  height: 114,
  purchaseCost: undefined,
  maxHeight: undefined,
  maxWeight: undefined,
  preferredUsageLevel: 0,
  insideWidth: undefined,
  insideHeight: undefined,
  insideLength: undefined
};
