import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ProductProviderOperationRateActionsTypes } from "./action";
import * as Api from "./api";
import { ProductProviderOperationRate } from "./entity";

export interface ProductProviderOperationRateState
  extends EntityState<ProductProviderOperationRate> {
  readStatus: FetchingStatus;
}

export const ProductProviderOperationRateInitialState: ProductProviderOperationRateState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL
};

export const ProductProviderOperationRateAdapter = createEntityAdapter<ProductProviderOperationRate>(
  { selectId: (m) => `${m.productId}_${m.providerOperationRateId}` }
);

export const read = createMyAsyncThunk<ProductProviderOperationRate[]>(
  ProductProviderOperationRateActionsTypes.READ,
  Api.read
);

const ProductProviderOperationRateSlice = createMySlice({
  name: "product-provider-operation-rates",
  initialState: ProductProviderOperationRateAdapter.getInitialState(
    ProductProviderOperationRateInitialState
  ),
  adapter: ProductProviderOperationRateAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: ProductProviderOperationRateAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const ProductProviderOperationRateReducer =
  ProductProviderOperationRateSlice.reducer;
export const ProductProviderOperationRateActions = {
  ...ProductProviderOperationRateSlice.actions,
  async: {
    read
  }
};
