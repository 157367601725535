export enum BriefStatusActionsTypes {
  CREATE = "@@BRIEF_STATUS create brief status",
  CREATE_STATUS = "@@BRIEF_STATUS create brief status status",
  READ = "@@BRIEF_STATUS read brief status",
  READ_STATUS = "@@BRIEF_STATUS read status brief status",
  UPDATE = "@@BRIEF_STATUS update brief status",
  UPDATE_STATUS = "@@BRIEF_STATUS update brief status status",
  REMOVE = "@@BRIEF_STATUS delete brief status",
  REMOVE_STATUS = "@@BRIEF_STATUS delete brief status status",
  READ_ONE = "@@BRIEF_STATUS read one brief status",
  READ_ONE_STATUS = "@@BRIEF_STATUS read one status brief status"
}
