import { Request } from "../../utils/request";
import { BriefStatusCreateDTO, BriefStatusUpdateDTO } from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/statuses/entity?entity=BRIEFS");

export const readOne = (id: string) =>
  Request({ withToken: true }).get(`/statuses/${id}`);

export const create = (briefStatus: BriefStatusCreateDTO) =>
  Request({ withToken: true }).post("/statuses", briefStatus);

export const update = ({ id, ...dto }: BriefStatusUpdateDTO) =>
  Request({ withToken: true }).patch(`/statuses/${id}`, dto);

export const remove = (id: string) =>
  Request({ withToken: true }).delete(`/statuses/${id}`);
