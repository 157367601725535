import { AxiosError } from "axios";
import { Brief, BriefElement, BriefWithRelations } from "../../entities/brief";
import { apiSlice } from "../../utils/api/api";
import { AppAddSnackbar } from "../../reducers/app/action";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AssignProject, BriefPagination, UpdateBriefStatus } from "./types";
import { dynamicUrl } from "../../utils/function/dynamicUrl";
import { PageMetaDto } from "../common/types";
import { transformBrief } from "./transform";
import { PredictionRequest } from "../../entities/predictionRequest";
import { BriefConfiguratorValues } from "../../reducers/briefConfigurator/reducer";
import { BriefElementConfiguratorValues } from "../../reducers/briefElementConfigurator/reducer";
import { Task } from "../tasks/type";
import { DecorationCount } from "../../entities/decorationsCount";
import { PredictionRequestComputed } from "../../entities/predictionRequestComputed";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Briefs",
    "Brief",
    "BriefDetails",
    "BriefElementInputLayout",
    "BriefPredictionProcess",
    "BriefValueAnalysis",
    "BriefPredictionPanel"
  ]
});

interface RecomputeBrief {
  id: string;
  decorationsCount: DecorationCount[];
  save: boolean;
}

export const extendedBriefApiSlice = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBriefs: builder.query<BriefPagination, Partial<PageMetaDto>>({
      query: ({
        page = 1,
        limit = 25,
        status,
        sort,
        price,
        search,
        createdBy
      }) =>
        dynamicUrl("/briefs", {
          page,
          limit,
          sort,
          status,
          price,
          search,
          createdBy
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Briefs" as const, id })),
              { type: "Briefs", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Briefs", id: "PARTIAL-LIST" }],
      transformResponse: (responseData: BriefPagination) => {
        return transformBrief(responseData);
      }
    }),
    editBriefName: builder.mutation<Brief, Partial<Brief>>({
      query(data) {
        const { id, name } = data;
        return {
          url: `/briefs/${id}`,
          method: "PATCH",
          body: { name }
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "Briefs", id: "PARTIAL-LIST" }
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (err) {
          dispatch(
            sendErrorNotification(
              err as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    assignProject: builder.mutation<void, AssignProject>({
      query({ brief, projectId }) {
        return {
          url: `/briefs/${brief.id}/project`,
          method: "PATCH",
          body: { projectId }
        };
      },
      invalidatesTags: () => [{ type: "Briefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    updateStatus: builder.mutation<void, UpdateBriefStatus>({
      query({ briefId, statusId }) {
        return {
          url: `/briefs/${briefId}`,
          method: "PATCH",
          body: { statusId }
        };
      },
      invalidatesTags: () => [{ type: "Briefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    getBriefCountByStatus: builder.query<number, string>({
      query(statusId) {
        return {
          url: `/briefs/${statusId}/status-count`,
          method: "GET"
        };
      }
    }),
    getBrief: builder.query<BriefWithRelations, Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}`,
          method: "GET"
        };
      },
      providesTags: (result) => [{ type: "Brief", id: result?.id }]
    }),
    getBriefDetails: builder.query<BriefWithRelations, Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}/details`,
          method: "GET"
        };
      },
      providesTags: (result) => [{ type: "BriefDetails", id: result?.id }]
    }),
    getBriefElementInputLayout: builder.query<
      Pick<BriefElement, "inputLayout">,
      { id: string; elementId: string }
    >({
      query({ id, elementId }) {
        return {
          url: `/briefs/${id}/elements/${elementId}/input-layout`,
          method: "GET"
        };
      },
      providesTags: (_, __, { elementId }) => [
        { type: "BriefElementInputLayout", id: elementId }
      ]
    }),
    getBriefPredictionProcess: builder.query<
      PredictionRequestComputed,
      { id: string; position: number }
    >({
      query({ id, position }) {
        return {
          url: `/briefs/${id}/process/${position}`,
          method: "GET"
        };
      },
      providesTags: (_, __, { position }) => [
        { type: "BriefPredictionProcess", id: position }
      ]
    }),
    getBriefValueAnalysis: builder.query<PredictionRequest, { id: string }>({
      query({ id }) {
        return {
          url: `/briefs/${id}/value-analysis`,
          method: "GET"
        };
      },
      providesTags: (_, __, { id }) => [{ type: "BriefValueAnalysis", id }]
    }),
    getBriefPrediction: builder.query<BriefWithRelations, Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}/prediction-panel`,
          method: "GET"
        };
      },
      providesTags: (result) => [
        { type: "BriefPredictionPanel", id: result?.id }
      ]
    }),
    getBriefMultiTask: builder.query<Partial<Task>[], Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}/multi-tasks`,
          method: "GET"
        };
      }
    }),
    createOneBrief: builder.mutation<
      BriefWithRelations,
      BriefConfiguratorValues & {
        briefElements: Array<Partial<BriefElementConfiguratorValues>>;
      }
    >({
      query(body) {
        return {
          url: `/briefs`,
          method: "POST",
          body: body
        };
      }
    }),
    recomputeBriefQuantity: builder.mutation<
      BriefWithRelations,
      RecomputeBrief
    >({
      query({ id, decorationsCount, save }) {
        return {
          url: `/briefs/${id}/new-prediction?save=true`,
          method: "POST",
          body: { decorationsCount, save }
        };
      }
    }),
    updatePredictionRequest: builder.mutation<
      PredictionRequest,
      Partial<PredictionRequest>
    >({
      query(dto) {
        const { id, ...patch } = dto;
        return {
          url: `/prediction-requests/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      },
      invalidatesTags: (_, __, data) => [
        { type: "Brief", id: data?.briefId },
        { type: "BriefDetails", id: data?.briefId },
        { type: "BriefValueAnalysis", id: data?.briefId },
        { type: "BriefPredictionPanel", id: data?.briefId }
      ]
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetBriefsQuery,
  useGetBriefQuery,
  useGetBriefDetailsQuery,
  useGetBriefPredictionProcessQuery,
  useGetBriefElementInputLayoutQuery,
  useGetBriefValueAnalysisQuery,
  useEditBriefNameMutation,
  useUpdateStatusMutation,
  useAssignProjectMutation,
  usePrefetch: useBriefsPrefetch,
  useLazyGetBriefsQuery,
  useGetBriefCountByStatusQuery,
  useLazyGetBriefQuery,
  useCreateOneBriefMutation,
  useGetBriefPredictionQuery,
  useUpdatePredictionRequestMutation,
  useGetBriefMultiTaskQuery,
  useRecomputeBriefQuantityMutation
} = extendedBriefApiSlice;
