import { Event } from "../../events/type";
import { Designs, Logics, OptionValues } from "../../forms/type";
import { CustomFieldValue } from "../customFieldValues/types";
import { DisplayOptions } from "../displayOptions/types";
import { MetaEntity } from "../types";

export enum CustomFieldNodesType {
  DATE = "date",
  TEXT = "text",
  TEXT_AREA = "textArea",
  NUMBER = "number",
  PERCENTAGE = "percentage",
  CHECKBOX = "checkbox",
  SWITCH = "switch",
  SELECT = "select",
  MULTI_SELECT = "multiSelect",
  RADIO = "radio",
  MULTI_CHECKBOX = "multiCheckbox",
  FILE = "file", // not yet
  CONTEXT = "context",
  TITLE = "title"
}

export const customFieldNodesOptions = [
  CustomFieldNodesType.SELECT,
  CustomFieldNodesType.MULTI_SELECT,
  CustomFieldNodesType.RADIO
];

export enum CustomFieldNodesComponentContext {
  GLOBAL = "GLOBAL",
  ENTITY = "ENTITY"
}

export interface CustomFieldNode {
  id: string;
  type: CustomFieldNodesType;
  metaEntityId: string;
  metaEntityContextIds?: string[];
  position: number;
  enabled: boolean;
  parentId?: string;
  inputName: string;
  createdAt: string;
  updatedAt: string;

  // Main relations
  parent?: CustomFieldNode;
  children?: CustomFieldNode[];
  customField?: CustomFieldNode;
  customFieldValues?: CustomFieldValue[];
  design?: Designs;
  displayOptions?: DisplayOptions;
  options?: OptionValues[];
  logics?: Logics[];

  metaEntity?: MetaEntity;
  metaEntityContexts?: CustomFieldNode[];

  events?: Event[];
}

export interface CustomFieldNodeAddDto {
  customFieldId: string;
  metaEntityId: string;
}

// Mock data for storybook

export const mockCustomFieldNodes: CustomFieldNode[] = [
  {
    id: "1",
    type: CustomFieldNodesType.TEXT,
    metaEntityId: "1",
    inputName: "Text",
    enabled: true,
    position: 1,
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  },
  {
    id: "2",
    type: CustomFieldNodesType.TEXT_AREA,
    metaEntityId: "1",
    enabled: true,
    position: 2,
    inputName: "Text Area",
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  },
  {
    id: "3",
    type: CustomFieldNodesType.NUMBER,
    metaEntityId: "1",
    enabled: true,
    position: 3,
    inputName: "Number",
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  },
  {
    id: "4",
    type: CustomFieldNodesType.PERCENTAGE,
    metaEntityId: "1",
    enabled: true,
    position: 4,
    inputName: "Percentage",
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  },
  {
    id: "5",
    type: CustomFieldNodesType.CHECKBOX,
    metaEntityId: "1",
    enabled: true,
    position: 5,
    inputName: "Checkbox",
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  },
  {
    id: "6",
    type: CustomFieldNodesType.SWITCH,
    metaEntityId: "1",
    enabled: true,
    position: 6,
    inputName: "Switch",
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  },
  {
    id: "7",
    type: CustomFieldNodesType.SELECT,
    metaEntityId: "1",
    enabled: true,
    position: 7,
    inputName: "Select",
    options: [
      {
        id: "1",
        value: "1",
        label: { fr: "Option 1", en: "Option 1" },
        createdAt: "2021-01-01T00:00:00.000",
        position: 1
      },
      {
        id: "2",
        value: "2",
        label: { fr: "Option 2", en: "Option 2" },
        createdAt: "2021-01-01T00:00:00.000",
        position: 2
      },
      {
        id: "3",
        value: "3",
        label: { fr: "Option 3", en: "Option 3" },
        createdAt: "2021-01-01T00:00:00.000",
        position: 3
      }
    ],
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000"
  }
];
