import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MachineRateCoefficientSetActionsTypes } from "./action";
import * as Api from "./api";
import { MachineRateCoefficientSet } from "./entity";

export interface MachineRateCoefficientSetState
  extends EntityState<MachineRateCoefficientSet> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  deleteOneStatus: FetchingStatus;
}

export const MachineRateCoefficientSetInitialState: MachineRateCoefficientSetState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  deleteOneStatus: FetchingStatus.NULL
};

export const MachineRateCoefficientSetAdapter = createEntityAdapter<MachineRateCoefficientSet>();

export const read = createMyAsyncThunk<MachineRateCoefficientSet[]>(
  MachineRateCoefficientSetActionsTypes.READ,
  Api.read
);

export const createOne = createMyAsyncThunk(
  MachineRateCoefficientSetActionsTypes.CREATE_ONE,
  Api.createOne,
  { onSuccessMessage: "saga:create-success" }
);

export const updateOne = createMyAsyncThunk<
  MachineRateCoefficientSet,
  MachineRateCoefficientSet
>(MachineRateCoefficientSetActionsTypes.UPDATE_ONE, Api.updateOne, {
  onSuccessMessage: "saga:update-success"
});

export const deleteOne = createMyAsyncThunk<void, string>(
  MachineRateCoefficientSetActionsTypes.DELETE_ONE,
  Api.deleteOne,
  { onSuccessMessage: "saga:delete-success" }
);

const MachineRateCoefficientSetSlice = createMySlice({
  name: "machine-operation-rates",
  initialState: MachineRateCoefficientSetAdapter.getInitialState(
    MachineRateCoefficientSetInitialState
  ),
  adapter: MachineRateCoefficientSetAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: MachineRateCoefficientSetAdapter.upsertMany
    },
    {
      action: createOne,
      statusName: "updateStatus",
      onSuccess: MachineRateCoefficientSetAdapter.addOne
    },
    {
      action: updateOne,
      statusName: "updateStatus",
      onSuccess: MachineRateCoefficientSetAdapter.upsertOne
    },
    {
      action: deleteOne,
      statusName: "deleteOneStatus",
      onSuccess: (state, payload) => {
        MachineRateCoefficientSetAdapter.removeOne(state, payload.meta.arg);
      }
    }
  ],
  reducers: {}
});

export const MachineRateCoefficientSetReducer =
  MachineRateCoefficientSetSlice.reducer;

export const MachineRateCoefficientSetActions = {
  ...MachineRateCoefficientSetSlice.actions,
  async: {
    read,
    createOne,
    updateOne,
    deleteOne
  }
};
