import produce from "immer";

import { Window } from "../../entities/window";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { WindowActions, WindowActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface WindowState {
  windows: Window[];
  fetchStatus: FetchingStatus;
}

const initialState: WindowState = {
  windows: [],
  fetchStatus: FetchingStatus.NULL
};

export const WindowReducer = (state = initialState, action: WindowActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case WindowActionsTypes.WINDOW_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.windows = action.windows || state.windows;
        break;
      case WindowActionsTypes.WINDOW_ADD:
        ReducerMethods.pushUniqueByMutate<Window>(draft.windows, action.window);
        break;
      default:
        return state;
    }
  });
