import { Request } from "../../utils/request";

export const read = () => Request({ withToken: true }).get("/files");

export const upload = (Private = true) =>
  Request({ withToken: true }).post(
    `/files/upload/${Private ? "private" : "public"}`
  );

export const uploadOnePublic = () =>
  Request({ withToken: true }).post("/files/upload/public");

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(`/files/${id}`);
