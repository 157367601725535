import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { FileActionsTypes } from "./action";
import { File } from "./entity";
import * as Api from "./api";

export interface FileState extends EntityState<File> {
  readStatus: FetchingStatus;
  uploadStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
}

export const FileInitialState: FileState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  uploadStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL
};

export const FileAdapter = createEntityAdapter<File>();

const FileAdapterState = FileAdapter.getInitialState(FileInitialState);

export const read = createMyAsyncThunk<File[]>(FileActionsTypes.READ, Api.read);
export const uploadOne = createMyAsyncThunk<File[], boolean | undefined>(
  FileActionsTypes.UPLOAD,
  Api.upload
);
export const deleteOne = createMyAsyncThunk<File[], string>(
  FileActionsTypes.DELETE,
  Api.deleteOne
);

const FileSlice = createMySlice({
  name: "files",
  initialState: FileAdapterState,
  adapter: FileAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: FileAdapter.upsertMany
    },
    {
      action: uploadOne,
      statusName: "uploadStatus",
      onSuccess: FileAdapter.addOne
    },
    {
      action: deleteOne,
      statusName: "deleteStatus",
      onSuccess: FileAdapter.removeOne
    }
  ],
  reducers: {}
});

export const FileReducer = FileSlice.reducer;
export const FileActions = { ...FileSlice.actions, read, uploadOne, deleteOne };
