import produce from "immer";
import { TopBarActions, TopBarActionsTypes } from "./action";

export interface TopBarState {
  title: string;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
}

export const TopBarInitialState: TopBarState = {
  title: "",
  rightActions: null,
  leftActions: null
};

export const TopBarReducer = (
  state = TopBarInitialState,
  action: TopBarActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TopBarActionsTypes.APPBAR_SET_STATE:
        return action.state || TopBarInitialState;
      default:
        return draft;
    }
  });
