export enum SideVariationPrintName {
  INSIDE = "Inside",
  OUTSIDE = "Outside"
}

export enum SidePrintedColorQuality {
  TRADITIONAL = "tr",
  UV_PREMIUM = "uv"
}

export enum StampingMethodName {
  HOT_STAMPING = "Hotstamping",
  POLYESTER = "Polyester"
}

export enum PrintMethodName {
  FLEXOGRAPHY = "flexography",
  OFFSET = "offset",
  SCREENPRINTING = "screenPrinting",
  DIGITAL = "digital"
}

export enum VarnishMethodName {
  FLEXOGRAPHY = "flexography",
  OFFSET = "offset"
}

export enum DigitalMode {
  SINGLE = "SINGLE_MODE",
  EXPRESS = "EXPRESS_MODE",
  STANDARD = "STANDARD_MODE",
  PRODUCTION = "PRODUCTION_MODE"
}

export enum DigitalType {
  STANDARD = "STANDARD_PRINTING",
  STANDARDWSILK = "STANDARD_PRINTING_WITH_SILK_EFFECT",
  STANDARDWWHITE = "STANDARD_PRINTING_WITH_WHITE_BACKGROUND",
  STANDARDWSILKWHITE = "STANDARD_PRINTING_WITH_WHITE_BACKGROUND_AND_SILK_EFFECT",
  STANDARDWRELIEF = "STANDARD_PRINTING_WITH_RELIEF"
}

export const PrintMethodSuffix = {
  [PrintMethodName.FLEXOGRAPHY]: "",
  [PrintMethodName.OFFSET]: "Offset",
  [PrintMethodName.SCREENPRINTING]: "ScreenPrinting",
  [PrintMethodName.DIGITAL]: "Digital"
};

export const PrintMethodInkParamsSuffixes = [
  PrintMethodSuffix[PrintMethodName.FLEXOGRAPHY],
  PrintMethodSuffix[PrintMethodName.OFFSET],
  PrintMethodSuffix[PrintMethodName.SCREENPRINTING],
  PrintMethodSuffix[PrintMethodName.DIGITAL]
];
