import produce from "immer";

import { MaterialType } from "../../entities/materialType";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { MaterialTypeActions, MaterialTypeActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface MaterialTypeState {
  materialTypes: MaterialType[];
  createStatus: FetchingStatus;
  fetchStatus: FetchingStatus;
  updateStatus: FetchingStatus;
}

const initialState: MaterialTypeState = {
  materialTypes: [],
  createStatus: FetchingStatus.NULL,
  fetchStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL
};

export const MaterialTypeReducer = (
  state = initialState,
  action: MaterialTypeActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MaterialTypeActionsTypes.MATERIAL_TYPE_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.materialTypes = action.materialTypes || state.materialTypes;
        break;
      case MaterialTypeActionsTypes.MATERIAL_TYPE_ADD:
        ReducerMethods.pushUniqueByMutate<MaterialType>(
          draft.materialTypes,
          action.materialType as MaterialType
        );
        break;

      case MaterialTypeActionsTypes.MATERIAL_TYPE_CREATE_STATUS:
        draft.createStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.pushUniqueByMutate(
            draft.materialTypes,
            action.materialType as MaterialType
          );
        break;
      case MaterialTypeActionsTypes.MATERIAL_TYPE_REQUEST_UPDATE_STATUS:
        draft.updateStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.materialType) {
          ReducerMethods.upsertByIdMutate<MaterialType>(
            draft.materialTypes,
            action.materialType as MaterialType
          );
        }
        break;
      default:
        return draft;
    }
  });
