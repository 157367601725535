import {
  MaterialReferenceCreateDTO,
  MaterialReferenceUpdateDTO
} from "../../entities/materialReference";
import { Request } from "../../utils/request";

export const readMaterialReferences = () =>
  Request({ withToken: true }).get("/material-references");

export const readOneMaterialReference = (id: string) =>
  Request({ withToken: true }).get(`/material-references/${id}`);

export const createMaterialReference = (
  materialReference: MaterialReferenceCreateDTO
) =>
  Request({ withToken: true }).post(
    "/material-references?upsert=true",
    materialReference
  );

export const updateMaterialReference = ({
  id,
  ...dto
}: MaterialReferenceUpdateDTO) =>
  Request({ withToken: true }).patch(`/material-references/${id}`, dto);

export const deleteMaterialReference = (id: string) =>
  Request({ withToken: true }).delete(`/material-references/${id}`);

export const enabledMaterialRefence = (id: string, enabled: boolean) =>
  Request({ withToken: true }).patch(`/material-references/${id}/`, {
    enabled
  });
