import { PredictionRequest } from "../../entities/predictionRequest";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum PredictionRequestActionsTypes {
  PREDICTION_REQUEST_FETCH = "@@PREDICTION_REQUEST fetch ",
  PREDICTION_REQUEST_FETCH_STATUS = "@@PREDICTION_REQUEST fetch status ",
  PREDICTION_REQUEST_UPDATE = "@@PREDICTION_REQUEST update request",
  PREDICTION_REQUEST_UPDATE_STATUS = "@@PREDICTION_REQUEST update request status"
}

export class PredictionRequestFetch {
  readonly type = PredictionRequestActionsTypes.PREDICTION_REQUEST_FETCH;
}
export class PredictionRequestStatus {
  readonly type = PredictionRequestActionsTypes.PREDICTION_REQUEST_FETCH_STATUS;
  constructor(
    public status: FetchingStatus,
    public predictionRequests?: PredictionRequest[]
  ) {}
}

export class PredictionRequestUpdate {
  readonly type = PredictionRequestActionsTypes.PREDICTION_REQUEST_UPDATE;
  constructor(public predictionRequest: Partial<PredictionRequest>) {}
}
export class PredictionRequestUpdateStatus {
  readonly type =
    PredictionRequestActionsTypes.PREDICTION_REQUEST_UPDATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public predictionRequest?: PredictionRequest
  ) {}
}

export type PredictionRequestActions =
  | PredictionRequestFetch
  | PredictionRequestStatus
  | PredictionRequestUpdate
  | PredictionRequestUpdateStatus;
