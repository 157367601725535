import { Helmet } from "react-helmet";

interface UpdateHeaderProps {
  favIcon: string;
  title: string;
}

export const UpdateHeader: React.FC<UpdateHeaderProps> = ({
  title,
  favIcon
}) => {
  return (
    <Helmet title={title}>
      {/* <link
        rel="manifest"
        href=""/> should be manifest.json*/}
      <link rel="icon" href={favIcon} />
      <meta name="name" content={title} />
    </Helmet>
  );
};
