import { ThemeOptions } from "@material-ui/core";
import { AppSetting } from "../../reducers/appSettings/entity";

export const commonThemeOptions = (setting?: AppSetting): ThemeOptions => ({
  palette: {
    background: { default: "#fff" },
    primary: {
      main: setting?.style?.primaryColor || "#3048c3"
    },
    secondary: {
      main: setting?.style?.secondaryColor || "#3048c3"
    }
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        "&::before": {
          backgroundColor: "initial"
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
        border: "solid 1px rgba(165, 178, 203, 0.17)"
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40
      }
    }
  },
  typography: {
    fontFamily: setting?.font?.url ? "Company Font" : "Lato",
    fontSize: setting?.style?.fontSize || 16,
    h1: { fontWeight: 600 },
    h3: { fontWeight: 600 },
    h4: { fontWeight: 600 }
  }
});
