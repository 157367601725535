import {
  TagDescription,
  createApi,
  fetchBaseQuery
} from "@reduxjs/toolkit/query/react";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition
} from "@reduxjs/toolkit/dist/query";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { RootState } from "../../reducers";
import { baseURL } from "../request";
import { AppAddSnackbar } from "../../reducers/app/action";
import i18n from "../i18n";
import { sendErrorNotification } from "../request/error_handler";
import { AxiosError } from "axios";
import { QueryOptions } from "../../components/common/MySuspense";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state?.authentication?.user?.token;
      const language =
        state?.authentication?.user?.defaultLanguage ?? state?.app?.language;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        console.warn("No token found in state");
      }
      if (language) {
        headers.set("accept-language", language);
      }
      headers.set(
        "time-zone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );

      return headers;
    }
  }),
  endpoints: () => ({})
});

export interface RTKQuery<K extends string, T, J> {
  query: UseQuery<
    QueryDefinition<
      J,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, never>,
        FetchBaseQueryMeta
      >,
      K,
      T,
      "api"
    >
  >;
  args?: J;
  skip?: boolean;
  advancedOptions?: QueryOptions;
}

export const genericManyTags = <
  A extends string,
  B extends Array<{ id: string }>
>(
  tag: A
) => (result: B | undefined): ReadonlyArray<TagDescription<A>> =>
  result
    ? [
        ...result.map(({ id }) => ({
          type: tag,
          id
        })),
        { type: tag, id: "LIST" }
      ]
    : [{ type: tag, id: "LIST" }];

export const genericOneTag = <
  A extends string,
  B,
  C extends Partial<{ id: string }>,
  D
>(
  tag: A
) => (_result: B, _error: D, arg: C): ReadonlyArray<TagDescription<A>> => [
  { type: tag, id: arg.id }
];

export const genericOneAndListTag = <
  A extends string,
  B,
  C extends Partial<{ id: string }>,
  D
>(
  tag: A
) => (_result: B, _error: D, arg: C): ReadonlyArray<TagDescription<A>> => [
  { type: tag, id: arg.id },
  { type: tag, id: "LIST" }
];

export function injectGetManyItem<T extends { id: string }>(
  name: string,
  tag: string,
  route: string
) {
  const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: [tag]
  });
  const safeRoute = route.replace(/^\//, "");

  const entityApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
      [name]: build.query<T[], void>({
        query: () => ({
          url: `/${safeRoute}/`,
          method: "GET",
          withoutBaseUrl: true
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: tag,
                  id
                })),
                { type: tag, id: "LIST" }
              ]
            : [{ type: tag, id: "LIST" }]
      })
    })
  });

  return entityApi;
}

export function injectGetOneItem<T extends { id: string }>(
  name: string,
  tag: string,
  route: string
) {
  const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: [tag]
  });
  const safeRoute = route.replace(/^\//, "");

  const entityApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
      [name]: build.query<T, string>({
        query: (id) => {
          if (!id) throw new Error("id is required");
          return {
            url: `/${safeRoute}/${id}`,
            method: "GET",
            withoutBaseUrl: true
          };
        },
        providesTags: (_result, _error, id) => [{ type: tag, id }]
      })
    })
  });

  return entityApi;
}

export function injectCreateOneItem<T extends { id: string }>(
  name: string,
  tag: string,
  route: string
) {
  const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: [tag]
  });
  const safeRoute = route.replace(/^\//, "");

  const entityApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
      [name]: build.mutation<T, Partial<T>>({
        query: (body) => ({
          url: `/${safeRoute}`,
          method: "POST",
          body,
          withoutBaseUrl: true
        }),
        invalidatesTags: [{ type: tag, id: "LIST" }],
        async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(
              new AppAddSnackbar(i18n.t("saga:create-success"), "success")
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:create-failed")
              )
            );
          }
        }
      })
    })
  });

  return entityApi;
}

export function injectUpdateItem<T extends { id: string }>(
  name: string,
  tag: string,
  route: string
) {
  const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: [tag]
  });
  const safeRoute = route.replace(/^\//, "");

  const entityApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
      [name]: build.mutation<T, Partial<T>>({
        query(body) {
          const { id, ...patch } = body;
          if (!id) throw new Error("id is required");
          return {
            url: `/${safeRoute}/${id}`,
            method: "PATCH",
            body: patch
          };
        },
        invalidatesTags: (_result, _error, { id }) => [{ type: tag, id }],
        async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(
              new AppAddSnackbar(i18n.t("saga:update-success"), "success")
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed")
              )
            );
          }
        }
      })
    })
  });

  return entityApi;
}

export function injectDeleteItem<T extends { id: string }>(
  name: string,
  tag: string,
  route: string
) {
  const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: [tag]
  });
  const safeRoute = route.replace(/^\//, "");

  const entityApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
      [name]: build.mutation<void, Partial<T>>({
        query({ id }) {
          if (!id) throw new Error("id is required");
          return {
            url: `/${safeRoute}/${id}`,
            method: "DELETE"
          };
        },
        invalidatesTags: (_result, _error, { id }) => [
          { type: tag, id },
          { type: tag, id: "LIST" }
        ],
        async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(
              new AppAddSnackbar(i18n.t("saga:delete-success"), "success")
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:delete-failed")
              )
            );
          }
        }
      })
    })
  });

  return entityApi;
}
