import i18n, { I18N_CACHE_PREFIX } from ".";
import { AppSetting } from "../../reducers/appSettings/entity";

export const resetLanguage = (setting: AppSetting) => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(I18N_CACHE_PREFIX)) {
      localStorage.removeItem(key);
    }
  });
  i18n.reloadResources(i18n.language);
  localStorage.setItem(
    "translationTimestamp",
    setting.translationTimestamp.toString()
  );
};
