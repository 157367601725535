import { CursorBasedMetaDto } from "../../services/common/types";

// TODO improve with reduce
export const dynamicQueryCursorUrl = (
  prefix: string,
  param: CursorBasedMetaDto
): string => {
  const { limit, cursor, tab } = param;
  const queryCursor = cursor ? `&cursor=${cursor}` : "";
  const queryTab = tab ? `&tab=${tab}` : "";

  return prefix
    ? `${prefix}?limit=${limit}${queryCursor}${queryTab}`
    : `?limit=${limit}${queryCursor}${queryTab}`;
};

export const dynamicQueryCursorUrlClean = (
  prefix: string,
  param: CursorBasedMetaDto
): string => {
  const { limit, ...params } = param;
  const currentParams = Object.entries(params).reduce(
    (params, [key, value]) => (value ? `${params}&${key}=${value}` : params),
    ""
  );
  return prefix
    ? `${prefix}?limit=${limit}${currentParams}`
    : `?limit=${limit}${currentParams}`;
};
