import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MobileStepper,
  Theme,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import BackofficeImg from "../../../assets/pages/onboarding/backoffice.png";
import ClientaccessImg from "../../../assets/pages/onboarding/clientaccess.png";
import HipeImg from "../../../assets/pages/onboarding/hipe.png";
import ProjectImg from "../../../assets/pages/onboarding/projects.png";
import { MyButton } from "../../../components/common/MyButton";
import { getLanguage } from "../../../reducers/app/selector";
import { getCurrentUser } from "../../../reducers/authentication/selector";
import { UserUpdate } from "../../../reducers/users/action";
import i18n from "../../../utils/i18n";
import { Slide, SlideType } from "./slide";
import { TextImageView } from "./TextImageView";
import { TitleView } from "./TitleView";
import { VideoView } from "./VideoView";

const getSlides = (): Slide[] => [
  {
    title: i18n.t("onboarding:slides.0.title"),
    text: i18n.t("onboarding:slides.0.text"),
    position: 0,
    type: SlideType.TITLE
  },
  {
    title: i18n.t("onboarding:slides.1.title"),
    text: i18n.t("onboarding:slides.1.text"),
    image: ProjectImg,
    position: 1,
    type: SlideType.TEXT_IMAGE
  },
  {
    title: i18n.t("onboarding:slides.2.title"),
    text: i18n.t("onboarding:slides.2.text"),
    image: ClientaccessImg,
    position: 2,
    type: SlideType.TEXT_IMAGE
  },
  {
    title: i18n.t("onboarding:slides.3.title"),
    text: i18n.t("onboarding:slides.3.text"),
    image: HipeImg,
    position: 3,
    type: SlideType.TEXT_IMAGE
  },
  {
    videoId: i18n.t("onboarding:slides.4.videoId"),
    position: 4,
    type: SlideType.VIDEO
  },
  {
    title: i18n.t("onboarding:slides.5.title"),
    text: i18n.t("onboarding:slides.5.text"),
    image: BackofficeImg,
    buttonLink: i18n.t("onboarding:slides.5.link"),
    buttonLabel: i18n.t("onboarding:slides.5.button"),
    position: 5,
    type: SlideType.TEXT_IMAGE
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      padding: `${theme.spacing(2)}px`,
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(1)}px`
      }
    }
  })
);

export interface OnboardingDialogProps {
  mobileStepper?: boolean;
}

export const OnboardingDialog: React.FC<OnboardingDialogProps> = ({
  mobileStepper = false
}) => {
  const classes = useStyles();
  const { t } = useTranslation("onboarding");
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();
  const history = useHistory();
  const lang = useSelector(getLanguage);
  const slides = useMemo(getSlides, [lang]);

  const ONBOARDING_KEY = "demo";
  const queryParams = new URLSearchParams(location.search);
  const onboardingRequired = queryParams.get(ONBOARDING_KEY) !== null;
  const nextStep = () => setActiveStep((step) => step + 1);
  const backStep = () => setActiveStep((step) => step - 1);
  const closeDialog = () => {
    if (onboardingRequired) {
      queryParams.delete(ONBOARDING_KEY);
      history.push({ search: queryParams.toString() });
      setActiveStep(0);
    }
    dispatch(new UserUpdate(user.id, { onboarded: true, email: user.email }));
  };

  const closeSpecificDialog = (
    event: Record<string, unknown>,
    reason: "backdropClick" | "escapeKeyDown"
  ): void => {
    if (reason === "backdropClick") {
      return;
    }
    closeDialog();
  };

  const isFirstSlide = (activeStep: number) => activeStep === 0;
  const isLastSlide = (activeStep: number) => activeStep === slides.length - 1;
  const getNextLabel = (activeStep: number) => {
    if (isFirstSlide(activeStep)) return t("nav.start");
    if (isLastSlide(activeStep)) return t("nav.finish");
    return t("nav.next");
  };
  const navigationFunction = (position: number) =>
    isLastSlide(position) ? closeDialog : nextStep;
  const navigationIcon = (position: number) =>
    isFirstSlide(position) || isLastSlide(position) ? undefined : (
      <NavigateNextIcon />
    );

  return (
    <Dialog
      open={onboardingRequired || !user.onboarded}
      onClose={closeSpecificDialog}
      fullScreen={onMobile}
      maxWidth="lg"
      data-testid="onboarding"
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {activeStep !== 0 && (
              <MyButton
                color="default"
                variant="text"
                leftIcon={<ArrowBackIcon />}
                onClick={backStep}
              >
                <b>{t("nav.back")}</b>
              </MyButton>
            )}
          </Grid>
          <Grid item>
            <MyButton
              color="default"
              variant="text"
              name="skip-tour"
              onClick={closeDialog}
            >
              <b style={{ color: "#778499" }}>{t("nav.skip")}</b>
            </MyButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={(index) => setActiveStep(index)}
          resistance
        >
          {slides.map((slide) => {
            if (slide.type === SlideType.TITLE) {
              return (
                <TitleView
                  view={slide}
                  key={slide.position}
                  onMobile={onMobile}
                  navigationLabel={getNextLabel(slide.position)}
                  navigationFunction={navigationFunction(slide.position)}
                  navigationIcon={navigationIcon(slide.position)}
                />
              );
            }

            if (slide.type === SlideType.VIDEO && slide.videoId) {
              return (
                <VideoView
                  videoId={slide.videoId}
                  active={slide.position === activeStep}
                  key={slide.position}
                  onMobile={onMobile}
                  navigationLabel={getNextLabel(slide.position)}
                  navigationFunction={navigationFunction(slide.position)}
                  navigationIcon={navigationIcon(slide.position)}
                />
              );
            }

            return (
              <TextImageView
                view={slide}
                key={slide.position}
                onMobile={onMobile}
                navigationLabel={getNextLabel(slide.position)}
                navigationFunction={navigationFunction(slide.position)}
                navigationIcon={navigationIcon(slide.position)}
              />
            );
          })}
        </SwipeableViews>
      </DialogContent>
      {onMobile && (
        <DialogActions>
          <Grid container direction="column">
            {mobileStepper && (
              <Grid item style={{ margin: "auto" }}>
                <MobileStepper
                  variant="dots"
                  steps={slides.length}
                  position="static"
                  activeStep={activeStep}
                  backButton={null}
                  nextButton={null}
                />
              </Grid>
            )}
            <Grid item style={{ padding: "8px" }}>
              <MyButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={isLastSlide(activeStep) ? closeDialog : nextStep}
              >
                {getNextLabel(activeStep)}
              </MyButton>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};
