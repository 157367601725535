import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { BriefStatusActionsTypes } from "./action";
import * as Api from "./api";
import {
  BriefStatus,
  BriefStatusCreateDTO,
  BriefStatusUpdateDTO
} from "./entity";

export interface BriefStatusState extends EntityState<BriefStatus> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  removeStatus: FetchingStatus;
}

export const BriefStatusInitialState: BriefStatusState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  removeStatus: FetchingStatus.NULL
};

export const BriefStatusAdapter = createEntityAdapter<BriefStatus>();

const BriefStatusAdapterState = BriefStatusAdapter.getInitialState(
  BriefStatusInitialState
);

export const read = createMyAsyncThunk<BriefStatus[]>(
  BriefStatusActionsTypes.READ,
  Api.read
);
export const create = createMyAsyncThunk<BriefStatus, BriefStatusCreateDTO>(
  BriefStatusActionsTypes.CREATE,
  Api.create,
  { onSuccessMessage: "saga:create-sucess" }
);
export const update = createMyAsyncThunk<BriefStatus, BriefStatusUpdateDTO>(
  BriefStatusActionsTypes.UPDATE,
  Api.update,
  { onSuccessMessage: "saga:update-sucess" }
);
export const remove = createMyAsyncThunk<BriefStatus, string>(
  BriefStatusActionsTypes.REMOVE,
  Api.remove,
  { onSuccessMessage: "saga:delete-sucess" }
);

const BriefStatusSlice = createMySlice({
  name: "brief-statuses",
  initialState: BriefStatusAdapterState,
  adapter: BriefStatusAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: BriefStatusAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: BriefStatusAdapter.upsertOne
    },
    {
      action: update,
      statusName: "updateStatus",
      onSuccess: BriefStatusAdapter.upsertOne
    },
    {
      action: remove,
      statusName: "removeStatus",
      onSuccess: (state, action) => {
        BriefStatusAdapter.removeOne(state, action.meta.arg);
      }
    }
  ],
  reducers: {}
});

export const BriefStatusReducer = BriefStatusSlice.reducer;
export const BriefStatusActions = {
  ...BriefStatusSlice.actions,
  async: { read, create, update, remove }
};
