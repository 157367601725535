export function replaceWordBetweenIndexes(
  text: string,
  word: string,
  startIndex: number,
  endIndex: number
) {
  const newText = text.slice(0, startIndex) + word + text.slice(endIndex);
  return newText;
}

export function replaceWordAtIndex(text: string, word: string, index: number) {
  let startIndex = index;
  while (startIndex > 0 && /\w/.test(text[startIndex - 1])) {
    startIndex--;
  }

  let endIndex = index;
  while (endIndex < text.length && /\w/.test(text[endIndex])) {
    endIndex++;
  }

  const newText = replaceWordBetweenIndexes(text, word, startIndex, endIndex);
  return newText;
}

export function safelyParseJSON(json: string) {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    parsed = json;
  }
  return parsed;
}
