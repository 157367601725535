import { RtkTag } from "../common/types";
import { CompanyContextQuery } from "./types";

export const getInvalidatTagsCompany = (
  context: CompanyContextQuery
): RtkTag[] => {
  switch (context) {
    case CompanyContextQuery.COMPANIES:
      return [{ type: "Company", id: "PARTIAL-LIST" }];
    case CompanyContextQuery.MY_COMPANIES:
      return [{ type: "MyCompanies", id: "PARTIAL-LIST" }];
    case CompanyContextQuery.COMPANY_HIERARCHY:
      return [{ type: "CompanyHierarchy", id: "PARTIAL-LIST" }];
    default:
      return [];
  }
};
