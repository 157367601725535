import { createSelector } from "reselect";
import { RootState } from "../";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { getLanguage } from "../app/selector";
import { LinerName } from "./entity";
import { LinerAdapter } from "./reducer";

const AdapterSelector = LinerAdapter.getSelectors(
  (state: RootState) => state.liners
);

export const getState = createSelector(
  (state: RootState) => state,
  (state) => state.liners
);

export const getLinersTranslated = createSelector(
  AdapterSelector.selectAll,
  getLanguage,
  (liners, lang) =>
    liners.map((m) => ({ ...m, label: jsonTranslator(m.label, lang) }))
);

export const getLinerIdByName = (name: LinerName) =>
  createSelector(
    AdapterSelector.selectAll,
    (liners) => liners.find((liner) => liner.name === name)?.id
  );

export const getLinerIdsByName = (names: LinerName[]) =>
  createSelector(
    names.map((name) => getLinerIdByName(name)),
    (...ids: any[]) => ids.filter((id) => !!id) as string[]
  );

export const getReadStatus = createSelector(
  getState,
  (state) => state.readStatus
);

export const LinerSelector = {
  ...AdapterSelector,
  getState,
  getLinersTranslated,
  getLinerIdByName,
  getLinerIdsByName,
  getReadStatus
};
