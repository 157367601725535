import { Request } from "../../utils/request";
import {
  CorrugatedMaterialPriceCreateDTO,
  CorrugatedMaterialPriceCreateManyDTO,
  CorrugatedMaterialPriceUpdateDTO
} from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-material-composition-prices");

export const createOne = (dto: CorrugatedMaterialPriceCreateDTO) =>
  Request({ withToken: true }).post(
    "/corrugated-material-composition-prices",
    dto
  );

export const patchOne = ({ id, ...dto }: CorrugatedMaterialPriceUpdateDTO) =>
  Request({ withToken: true }).patch(
    `/corrugated-material-composition-prices/${id}`,
    dto
  );

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(
    `/corrugated-material-composition-prices/${id}`
  );

export const createMany = (dto: CorrugatedMaterialPriceCreateManyDTO) =>
  Request({ withToken: true }).post(
    "/corrugated-material-composition-prices/bulk",
    dto
  );
