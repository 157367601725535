import { createSelector } from "reselect";
import { RootState } from "../";

// Deprecated
/* export const getContactData = (user?: User) => (
  users: User[],
  companies: Company[]
) =>
  users
    // Type contact if have at least collaboration id
    .filter((u) => {
      const isContact =
        (u.collaborationIds || []).length && (u.roles || []).length === 0;

      if (user && isContact) {
        const managedCompanies = companies.filter(
          (c) => c.managedById === user.id
        );
        return managedCompanies.some((c) => u.collaborationIds.includes(c.id));
      }
      return isContact;
    })
    .map((u) => {
      const company = companies.find(
        (c) => c.id === (u.collaborationIds as string[])[0]
      );
      return {
        ...u,
        companyName: company?.name
      };
    }); */

export const getUsersState = createSelector(
  (state: RootState) => state,
  (state) => state.users
);

export const getUsers = createSelector(getUsersState, (users) => users.users); //TODO remove this, seem not applicable anymore

// Get contacts
export const getUsersByCompanyId = (companyId: string) =>
  createSelector(getUsers, (users) =>
    users.filter((u) => u.collaborationIds.includes(companyId))
  );

export const getUsersUpdateContactStatus = createSelector(
  getUsersState,
  (users) => users.updateContactStatus
);

export const getUsersFetchStatus = createSelector(
  getUsersState,
  (users) => users.fetchStatus
);

export const getUserInviteStatus = createSelector(
  getUsersState,
  (users) => users.inviteStatus
);
