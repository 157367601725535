import { BasicVarnish } from "./basicVarnish";
import { BriefElement } from "./brief";

export type VarnishParameter = {
  id: string;
  coverage: number;
  position: number;
  varnishId: string;
  varnishBriefElementRectoId: string;
  varnishBriefElementVersoId: string;
  varnish?: BasicVarnish;
  varnishBriefElementRecto?: BriefElement;
  varnishBriefElementVerso?: BriefElement;
};
export type VarnishParameterDTO = Pick<
  VarnishParameter,
  "coverage" | "position" | "varnishId"
>;

export const transformVarnishParams = (
  varnishParams: VarnishParameter[] | undefined
): VarnishParameterDTO[] | undefined => {
  if (!varnishParams || varnishParams.length === 0) return;
  return varnishParams.map((varnishParams) => ({
    varnishId: varnishParams.varnishId,
    coverage: varnishParams.coverage,
    position: varnishParams.position
  }));
};
