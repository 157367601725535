import { mixed } from "yup";
import {
  ProductCategory,
  ProductCategoryName
} from "../../../../../../entities/productCategory";
import { BriefElementSchemaCorrugated } from "../../../corrugated/BriefElementForm/schema";
import { BriefElementSchemaFolding } from "../../../folding/BriefElementForm/schema";

export const BriefElementConfiguratorSchema = mixed().when(
  "$productCategorySelected",
  (_productCategorySelected: ProductCategory) => {
    if (_productCategorySelected?.name === ProductCategoryName.Corrugated) {
      return BriefElementSchemaCorrugated;
    }
    return BriefElementSchemaFolding;
  }
);

// export const getBriefElementConfiguratorSchema = (context: )
