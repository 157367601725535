import { boolean, number } from "yup";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import {
  applyInputOptionsLogicBoolean,
  applyInputOptionsLogicNumber,
  setYupLocale
} from "../../../../../../utils/yup";
import { PackagingSchema } from "../../../common/BriefElementForm/schema/packaging.schema";

setYupLocale();

export const PackagingSchemaCorrugated = {
  ...PackagingSchema,
  addTearStrip: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_TEAR_STRIP,
    boolean().default(false)
  ),
  addSealingStrip: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_SEALING_STRIP,
    boolean().default(false)
  ),
  addMecanisation: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_MECANISATION,
    boolean().default(false)
  ),
  dividersLengthBigElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_LENGTH_BIG_ELEMENT,
    number()
  ),
  dividersLengthSmallElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_LENGTH_SMALL_ELEMENT,
    number()
  ),
  dividersHeightElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_HEIGHT_ELEMENT,
    number()
  ),
  dividersNotchBorderDistance: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_NOTCH_BORDER_DISTANCE,
    number()
  ),
  dividersSpaceBetweenNotchesBigElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_BIG_ELEMENT,
    number()
  ),
  dividersSpaceBetweenNotchesSmallElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT,
    number()
  )
};
