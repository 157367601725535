import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface LoaderComponentProps {}

export const LoaderComponent: React.FC<LoaderComponentProps> = () => {
  const { t } = useTranslation("components");
  return <Typography>{t("dynamicElement.loaderComponent.title")}</Typography>;
};
