export enum ProductActionTypes {
  READ = "@@Product read",
  READ_STATUS = "@@Product read status",
  UPDATE = "@@Product update",
  UPDATE_STATUS = "@@Product update status",
  READ_ONE = "@@Product read one",
  READ_ONE_STATUS = "@@Product read one status",
  CREATE = "@@Product create",
  CREATE_STATUS = "@@Product create status",
  DUPLICATE = "@@Product duplicate",
  DUPLICATE_STATUS = "@@Product duplicate status",
  UPDATE_OPERATIONS = "@@Product update operation",
  UPDATE_OPERATIONS_STATUS = "@@Product update operation status",
  ADD_IMAGES = "@@Product add images",
  ADD_IMAGES_STATUS = "@@Product add images status",
  SET_DEFAULT_IMAGE = "@@Product set default image",
  SET_DEFAULT_IMAGE_STATUS = "@@Product set default image status",
  REMOVE_IMAGE = "@@Produt remove image",
  REMOVE_IMAGE_STATUS = "@@Produt remove image status",
  CREATE_MACHINE_RATE = "@@Product create machine rate",
  CREATE_MACHINE_RATE_STATUS = "@@Product create machine rate status",
  SET_DEFAULT_MACHINE_RATE = "@@Product set default machine rate",
  SET_DEFAULT_MACHINE_RATE_STATUS = "@@Product set default machine rate status",
  REMOVE_MACHINE_RATE = "@@Product remove machine rate",
  REMOVE_MACHINE_RATE_STATUS = "@@Product remove machine rate status",
  CREATE_PROVIDER_RATE = "@@Product create provider rate",
  CREATE_PROVIDER_RATE_STATUS = "@@Product create provider rate status",
  SET_DEFAULT_PROVIDER_RATE = "@@Product set default provider rate",
  SET_DEFAULT_PROVIDER_RATE_STATUS = "@@Product set default provider rate status",
  REMOVE_PROVIDER_RATE = "@@Product remove provider rate",
  REMOVE_PROVIDER_RATE_STATUS = "@@Product remove provider rate status",
  ARCHIVE_PRODUCT = "@@Product archive",
  ARCHIVE_PRODUCT_STATUS = "@@Product archive status",
  GET_ARCHIVE_PRODUCT = "@@Product get archive",
  GET_ARCHIVE_PRODUCT_STATUS = "@@Product get archive status"
}
