import { memoize } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "..";

export const getBriefsState = createSelector(
  (state: RootState) => state.briefs,
  (briefs) => briefs
);

export const getBriefs = createSelector(
  (state: RootState) => state.briefs,
  (briefs) => briefs.briefs
);

export const getBriefById = (id: string) =>
  createSelector(
    getBriefs,
    memoize((briefs) =>
      id && briefs.length > 0 ? briefs.find((p) => p.id === id) : undefined
    )
  );

export const getBriefFetchStatus = createSelector(
  (state: RootState) => state.briefs,
  (briefs) => briefs.fetchStatus
);

export const getBriefCreateStatus = createSelector(
  (state: RootState) => state.briefs,
  (briefs) => briefs.createStatus
);
export const getBriefEditNameStatus = createSelector(
  getBriefsState,
  (state) => state.editNameStatus
);

export const getBriefByIdStatus = createSelector(
  (state: RootState) => state.briefs,
  (briefs) => briefs.getByIdStatus
);

export const getBriefSelected = createSelector(
  getBriefsState,
  (state) => state.selected
);

export const getLastBrief = createSelector(getBriefs, (briefs) =>
  briefs.length ? briefs[briefs.length - 1] : undefined
);

export const getBriefAssignProjectStatus = createSelector(
  getBriefsState,
  (state) => state.assignProjectStatus
);

export const getBriefsByProjectId = (projectId: string) =>
  createSelector(getBriefs, (briefs) =>
    briefs.filter((b) => b.projectId === projectId)
  );

export const getBriefCountByStatus = (statusId: string) =>
  createSelector(getBriefs, (briefs) =>
    briefs.filter((b) => b.statusId === statusId)
  );
