import { User } from "./user";
import { DynamicJsonTranslator } from "../utils/function/jsonTranslator";
import { ValueOf } from "../..";

export enum StandardRolesWGuest {
  PACKITOO = "packitoo",
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin",
  SALES = "sales",
  EXTERNAL_SALES = "external_sales",
  GUEST = "guest"
}

export enum StandardRoles {
  PACKITOO = "packitoo",
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin",
  SALES = "sales",
  EXTERNAL_SALES = "external_sales"
}
export enum ContextRoles {
  CONTACT = "contact",
  GUEST = "guest",
  CMS_USER = "isCmsUser"
}

export const RolesWithContext = { ...ContextRoles, ...StandardRoles };
export type RolesWithContextType = ValueOf<typeof RolesWithContext>;

export const AdminRoles = [
  StandardRoles.PACKITOO,
  StandardRoles.SUPER_ADMIN,
  StandardRoles.ADMIN,
  StandardRoles.SALES
];

export const InternalAndSaleRoles = [
  StandardRoles.PACKITOO,
  StandardRoles.SUPER_ADMIN,
  StandardRoles.ADMIN,
  StandardRoles.SALES,
  StandardRoles.EXTERNAL_SALES
];

export enum RoleStatus {
  IS_ADMIN = "IS_ADMIN",
  IS_EXTERNAL_SALES = "IS_EXTERNAL_SALES",
  IS_GUEST = "IS_GUEST"
}

export class Role {
  id!: string;
  name!: StandardRoles;
  label!: DynamicJsonTranslator;
  description!: string;
  users?: User[];
}

export const SettableRoleHierarchy: Record<
  StandardRoles,
  Array<StandardRoles>
> = {
  [StandardRoles.PACKITOO]: [
    StandardRoles.PACKITOO,
    StandardRoles.SUPER_ADMIN,
    StandardRoles.ADMIN,
    StandardRoles.SALES,
    StandardRoles.EXTERNAL_SALES
  ],
  [StandardRoles.SUPER_ADMIN]: [
    StandardRoles.ADMIN,
    StandardRoles.SALES,
    StandardRoles.EXTERNAL_SALES
  ],
  [StandardRoles.ADMIN]: [StandardRoles.SALES, StandardRoles.EXTERNAL_SALES],
  [StandardRoles.SALES]: [StandardRoles.EXTERNAL_SALES],
  [StandardRoles.EXTERNAL_SALES]: []
};
