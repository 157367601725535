import {
  AppBar,
  createStyles,
  Hidden,
  IconButton,
  Theme,
  Toolbar
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MenuRounded";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../../assets/hipe-logo.png";
import { RootState } from "../../../reducers";
import { AppOpenMenu } from "../../../reducers/app/action";
import { getAppState, getOpenMenu } from "../../../reducers/app/selector";
import { DRAWER_WIDTH } from "../../../utils/constant";
import { redirectionLogo } from "../../../utils/function/redirectionLogo";
import { MyTopBarCommonMenu } from "./Common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      background: "white",
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: "none",
      borderBottom: "1px solid #E8ECEF"
    },
    appBarWithMargin: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${DRAWER_WIDTH}px - 1px)`,
        marginLeft: DRAWER_WIDTH
      }
    },
    grow: {
      flexGrow: 1
    },
    logo: {
      height: 48
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {}
  })
);

export interface MyTopBarProps {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

export const MyTopBar: React.FC<MyTopBarProps> = ({
  rightActions,
  leftActions
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const openMenu = useSelector(getOpenMenu);
  const { isOnBackOffice } = useSelector(getAppState);
  const setting = useSelector((state: RootState) => state.appSettings.setting);
  const handleOpenMenu = () => dispatch(new AppOpenMenu(!openMenu));

  return (
    <AppBar
      position="fixed"
      color="inherit"
      classes={{
        root: clsx(classes.appbar, {
          [classes.appBarWithMargin]: isOnBackOffice
        })
      }}
    >
      <Toolbar>
        {isOnBackOffice && (
          <Hidden mdUp>
            <IconButton
              data-testid="menu-button"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenMenu}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        {!isOnBackOffice && (
          <img
            onClick={() =>
              setting?.style.logoRedirection
                ? window.open(setting?.style.logoRedirection, "_blank")
                : history.push(redirectionLogo(location?.pathname))
            }
            style={{
              cursor: "pointer"
            }}
            alt="logo"
            src={setting.logo?.url || Logo}
            className={classes.logo}
          />
        )}
        {leftActions}
        <div className={classes.grow} />
        {rightActions}
        <MyTopBarCommonMenu />
      </Toolbar>
    </AppBar>
  );
};
