import { Brief } from "../../entities/brief";
import { getCompanyIfOnlyOne } from "../users/utils";
import { BriefPagination } from "./types";

export const transformBrief = (responseData: BriefPagination) => {
  return {
    ...responseData,
    data: responseData.data.map((brief: Brief) => {
      return {
        ...brief,
        company: brief.project?.company || getCompanyIfOnlyOne(brief.user)
      };
    })
  };
};
