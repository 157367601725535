import { Request } from "../../utils/request";
import {
  CorrugatedMaterialCreateDTO,
  CorrugatedMaterialUpdateDTO
} from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-materials");

export const createOne = (dto: CorrugatedMaterialCreateDTO) =>
  Request({ withToken: true }).post("/corrugated-materials", dto);

export const patchOne = ({ id, ...dto }: CorrugatedMaterialUpdateDTO) =>
  Request({ withToken: true }).patch(`/corrugated-materials/${id}`, dto);

export const exportAll = () =>
  Request({ withToken: true }).get("/corrugated-material-compositions/export");
