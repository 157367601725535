import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import {
  ProductCategoryActionsTypes,
  ProductCategoryFetchStatus
} from "./action";
import * as Api from "./api";

export function* fetchProductCategorys() {
  yield put(new ProductCategoryFetchStatus(FetchingStatus.PENDING));
  try {
    const { data: products } = yield call(Api.fetchProductsCategory);
    yield put(new ProductCategoryFetchStatus(FetchingStatus.SUCCESS, products));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("productsCategory:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new ProductCategoryFetchStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("productsCategory:fetchFailed"))
    );
  }
}

export const ProductCategorySaga = [
  takeLatest(
    ProductCategoryActionsTypes.PRODUCT_CATEGORY_FETCH,
    fetchProductCategorys
  )
];
