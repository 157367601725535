import { RequestQueryBuilder } from "@nestjsx/crud-request";
import * as Sentry from "@sentry/react";
import { push } from "connected-react-router";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "..";
import { Company } from "../../entities/company";
import { Project } from "../../entities/project";
import { User } from "../../entities/user";
import { fp } from "../../utils/fp";
import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import { getCurrentUser } from "../authentication/selector";
import { ProjectAddMany } from "../projects/action";
import { getProjectsByCompanyId } from "../projects/selector";
import {
  UserAddCollaboration,
  UserAddMany,
  UserRemoveMany
} from "../users/action";
import { getUsers, getUsersByCompanyId } from "../users/selector";
import {
  CompanyActionsTypes,
  CompanyArchived,
  CompanyArchivedStatus,
  CompanyCreate,
  CompanyCreateManagedByUser,
  CompanyCreateManagedByUserStatus,
  CompanyCreateStatus,
  CompanyFetchForUserStatus,
  CompanyFetchOne,
  CompanyFetchOneStatus,
  CompanyFetchStatus,
  CompanyUpdate,
  CompanyUpdateStatus
} from "./action";
import * as Api from "./api";

export function* fetchCompanies() {
  yield put(new CompanyFetchStatus(FetchingStatus.PENDING));
  try {
    const { data: companies } = yield call(Api.fetchCompanies);
    yield put(new CompanyFetchStatus(FetchingStatus.SUCCESS, companies));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new CompanyFetchStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:companies.fetchFailed"))
    );
  }
}

export function* fetchCompaniesUser() {
  yield put(new CompanyFetchStatus(FetchingStatus.PENDING));
  try {
    const id: string = yield select(
      (state: RootState) => state.authentication.user.id
    );
    const { data: companies } = yield call(Api.fetchCompaniesForUser, id);
    yield put(new CompanyFetchForUserStatus(FetchingStatus.SUCCESS, companies));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new CompanyFetchForUserStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:companies.fetchFailed"))
    );
  }
}

export function* fetchOne(action: CompanyFetchOne) {
  const qb = RequestQueryBuilder.create({
    join: [{ field: "collaboraters" }, { field: "projects" }]
  }).query();
  yield put(new CompanyFetchOneStatus(FetchingStatus.PENDING));
  try {
    const {
      data: { managedBy, collaboraters, projects, ...company }
    } = yield call(Api.fetchOne, action.companyId, qb);
    yield put(new CompanyFetchOneStatus(FetchingStatus.SUCCESS, company));
    yield put(new ProjectAddMany(projects || []));
    yield put(
      new UserAddMany([...(collaboraters || []), managedBy].filter(Boolean))
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:fetchOneFailed");
      Sentry.captureException(error);
    });
    yield put(new CompanyFetchStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:companies.fetchOneFailed"))
    );
  }
}

export function* createCompany(action: CompanyCreate) {
  yield put(new CompanyCreateStatus(FetchingStatus.PENDING));
  try {
    const { data: company }: { data: Company } = yield call(
      Api.createOne,
      action.company
    );
    action.cb && action.cb(company);
    yield put(new CompanyCreateStatus(FetchingStatus.SUCCESS, company));
    const collaboraterIds = action.company.collaboraterIds || [];
    const users: User[] = yield select(getUsers);
    if (collaboraterIds.length)
      for (const col of collaboraterIds) {
        const userIndex = users.findIndex((c) => c.id === col);
        yield put(new UserAddCollaboration(userIndex, [company.id]));
      }

    yield put(
      new AppAddSnackbar(i18n.t("saga:companies.creationSuccess"), "success")
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:creationError");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    action.cb && action.cb();
    yield put(new CompanyCreateStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("saga:companies.creationError"))
    );
  }
}

export function* createCompanyManagedByUser(
  action: CompanyCreateManagedByUser
) {
  yield put(new CompanyCreateManagedByUserStatus(FetchingStatus.PENDING));
  const user = yield select(getCurrentUser);
  try {
    const { data: company }: { data: Company } = yield call(
      Api.createManagedByUser,
      user.id,
      action.company
    );
    action.cb && action.cb(company);
    yield put(
      new CompanyCreateManagedByUserStatus(FetchingStatus.SUCCESS, company)
    );
    // No need to sync user collaboration, because company managed cannot have
    // collaboraters set. It is disable in the form.

    yield put(
      new AppAddSnackbar(i18n.t("saga:companies.creationSuccess"), "success")
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:creationManagedByUserError");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    action.cb && action.cb();
    yield put(new CompanyCreateManagedByUserStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(
        error,
        i18n.t("saga:companies.creationManagedByUserError")
      )
    );
  }
}

const syncUserCollaborations = (
  companyId: string,
  newCollaboraterIds: string[],
  users: User[]
) => {
  const usersCloned = fp.cloneDeep(users);
  const previousCollaboraterIds = usersCloned
    .filter((u) => u.collaborationIds.includes(companyId))
    .map((u) => u.id);

  const collaborationToRemove = fp.difference(
    previousCollaboraterIds,
    newCollaboraterIds
  );
  const collaborationToAdd = fp.difference(
    newCollaboraterIds,
    previousCollaboraterIds
  );

  const newUsersState = usersCloned.map((u) => {
    if (collaborationToRemove.includes(u.id)) {
      u.collaborationIds.splice(
        u.collaborationIds.findIndex((cId) => cId === companyId),
        1
      );
    }
    if (collaborationToAdd.includes(u.id)) {
      u.collaborationIds.push(companyId);
    }
    return u;
  });
  return newUsersState;
};

export function* updateOne(action: CompanyUpdate) {
  yield put(new CompanyUpdateStatus(FetchingStatus.PENDING));
  try {
    const { data: companyUpdated }: { data: Company } = yield call(
      Api.updateOne,
      action.companyId,
      action.company
    );

    // Update contact collaboration
    const users: User[] = yield select(getUsers);
    const newUserState = syncUserCollaborations(
      action.companyId,
      action.company.collaboraterIds || [],
      users
    );

    yield put(new CompanyUpdateStatus(FetchingStatus.SUCCESS, companyUpdated));
    yield put(new UserAddMany(newUserState));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:updateOneFailed");
      Sentry.captureException(error);
    });
    yield put(new CompanyUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* archived(action: CompanyArchived) {
  yield put(new CompanyArchivedStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.archived, action.companyId);
    yield put(push(action.redirect));
    yield put(
      new CompanyArchivedStatus(FetchingStatus.SUCCESS, action.companyId)
    );

    // Update data based on the company archived logic
    // Remove company, manager, user link from company projects
    const companyProjects: Project[] = yield select(
      getProjectsByCompanyId(action.companyId)
    );
    yield put(
      new ProjectAddMany(
        companyProjects.map((p) => ({
          ...p,
          companyId: null,
          managerId: null,
          userId: null
        }))
      )
    );
    // Remove contact users
    const companyUsers = yield select(getUsersByCompanyId(action.companyId));
    yield put(new UserRemoveMany(companyUsers));

    yield put(
      new AppAddSnackbar(
        i18n.t("saga:archived-success", { name: i18n.t("common:company") }),
        "success"
      )
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("companies:archivedFailed");
      Sentry.captureException(error);
    });
    yield put(new CompanyArchivedStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(
        error,
        i18n.t("saga:archived-failed", { name: i18n.t("common:company") })
      )
    );
  }
}

export const CompanySaga = [
  takeLatest(CompanyActionsTypes.COMPANY_FETCH, fetchCompanies),
  takeLatest(CompanyActionsTypes.COMPANY_FOR_USER_FETCH, fetchCompaniesUser),
  takeLatest(CompanyActionsTypes.COMPANY_FETCH_ONE, fetchOne),
  takeLatest(CompanyActionsTypes.COMPANY_CREATE, createCompany),
  takeLatest(
    CompanyActionsTypes.COMPANY_CREATE_MANAGED_BY_USER,
    createCompanyManagedByUser
  ),
  takeLatest(CompanyActionsTypes.COMPANY_UPDATE, updateOne),
  takeLatest(CompanyActionsTypes.COMPANY_ARCHIVED, archived)
];
