import { PredictionRequest } from "../../entities/predictionRequest";
import { Request } from "../../utils/request";

export const fetchPredictionRequests = () =>
  Request({ withToken: true }).get("/prediction-requests");

export const updatePredictionRequest = ({
  id,
  ...dto
}: Partial<PredictionRequest>) =>
  Request({ withToken: true }).patch(`/prediction-requests/${id}`, dto);
