import { Box } from "@material-ui/core";
import { Form, FormikComputedProps, FormikProps } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MySwitchField } from "../../../../../components/common/form/MySwitchField";
import { MyTextField } from "../../../../../components/common/form/MyTextField";
import { MyButton } from "../../../../../components/common/MyButton";
import { BoxCaseFormat } from "../../../../../services/boxCaseFormat/type";
import { GenericForm } from "../../../../../utils/components/form";

interface BoxCaseFormatFormProps
  extends FormikProps<BoxCaseFormat>,
    FormikComputedProps<BoxCaseFormat>,
    Partial<GenericForm> {
  hideFields?: Array<keyof BoxCaseFormat>;
  hideButton?: boolean;
  loading?: boolean;
}

export const BoxCaseFormatForm: FC<BoxCaseFormatFormProps> = ({
  loading,
  mode,
  isValid
}) => {
  const { t } = useTranslation("backoffice");
  const { t: tCommon } = useTranslation("common");

  return (
    <Form noValidate>
      <Box my={2}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem"
          }}
        >
          <MyTextField
            name={"length"}
            type="number"
            numberFormatProps={{ allowNegative: false }}
            label={tCommon("length")}
            endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
          />
          <MyTextField
            name={"width"}
            type="number"
            numberFormatProps={{ allowNegative: false }}
            label={tCommon("width")}
            endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
          />
          <MyTextField
            name="height"
            type="number"
            label={tCommon("height")}
            numberFormatProps={{ allowNegative: false }}
            endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem"
          }}
        >
          <MyTextField
            name={"insideLength"}
            type="number"
            numberFormatProps={{ allowNegative: false }}
            label={t("boxCaseFormat.insideLength")}
            endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
          />
          <MyTextField
            name={"insideWidth"}
            type="number"
            numberFormatProps={{ allowNegative: false }}
            label={t("boxCaseFormat.insideWidth")}
            endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
          />
          <MyTextField
            name={"insideHeight"}
            type="number"
            numberFormatProps={{ allowNegative: false }}
            label={t("boxCaseFormat.insideHeight")}
            endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
          />
        </Box>
        <MyTextField
          name="maxHeight"
          type="number"
          label={t("boxCaseFormat.maxHeight")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>mm</span>}
        />
        <MyTextField
          name={"weight"}
          type="number"
          numberFormatProps={{ allowNegative: false }}
          label={tCommon("weight")}
          endAdornment={<span style={{ color: "darkgrey" }}>kg</span>}
        />
        <MyTextField
          name="maxWeight"
          type="number"
          label={t("boxCaseFormat.maxWeight")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>kg</span>}
        />
        <MyTextField
          name={"purchaseCost"}
          type="number"
          label={t("boxCaseFormat.box-case-purchase-cost")}
          numberFormatProps={{ allowNegative: false }}
          endAdornment={<span style={{ color: "darkgrey" }}>€</span>}
        />
        <MyTextField
          name={"preferredUsageLevel"}
          type="number"
          label={t("boxCaseFormat.preferred-usage-level")}
          numberFormatProps={{ allowNegative: false }}
        />
        <MySwitchField label={t("boxCaseFormat.enable")} name={"enabled"} />
        <Box mt={2}>
          <MyButton
            type="submit"
            size="large"
            variant={isValid ? "contained" : "outlined"}
            color="primary"
            loading={loading}
          >
            {mode === "CREATE" ? tCommon("create") : tCommon("save")}
          </MyButton>
        </Box>
      </Box>
    </Form>
  );
};
