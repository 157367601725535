import { Matter } from "../../entities/matter";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum MatterActionsTypes {
  MATTER_FETCH = "@@MATTER fetch matter",
  MATTER_FETCH_STATUS = "@@MATTER fetch status matter",
  MATTER_ADD = "@@MATTER add matter"
}

export class MatterFetch {
  readonly type = MatterActionsTypes.MATTER_FETCH;
}
export class MatterStatus {
  readonly type = MatterActionsTypes.MATTER_FETCH_STATUS;
  constructor(public status: FetchingStatus, public matters?: Matter[]) {}
}
export class MatterAdd {
  readonly type = MatterActionsTypes.MATTER_ADD;
  constructor(public matter: Matter) {}
}

export type MatterActions = MatterFetch | MatterStatus | MatterAdd;
