import { configureStore, ThunkDispatch, ThunkAction } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, History } from "history";
import { Middleware, AnyAction, Action, Reducer, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionToPlainObject } from "../utils/middlewares/action_object";
import { RootReducers, RootSaga, RootState } from "./";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AuthenticationActionsTypes } from "./authentication/action";
import { apiSlice } from "../utils/api/api";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const middlewares = (initialHistory?: History): Middleware[] => [
  routerMiddleware(initialHistory || history),
  actionToPlainObject,
  sagaMiddleware
];

export const runSaga = () => sagaMiddleware.run(RootSaga);

// Inject state from any source with this global window variable
const preloadedState = (window as any).__PRELOADED_STATE__;
delete (window as any).__PRELOADED_STATE__;

const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
  whitelist: ["contact"]
};

export const createMyStore = (
  initialStore?: any,
  initialHistory?: History
): Store => {
  const rootReducer: Reducer<any, AnyAction> = (
    state: any,
    action: AnyAction
  ) => {
    const combinedReducer = RootReducers(initialHistory || history);

    /*
        TODO LATER WILL BE A LOG OUT SUCCEED FROM BACKEND
        Here we reset everything but not the app setting which contain 
        logo, style, and colors
      */
    const newState =
      action.type === AuthenticationActionsTypes.LOG_OUT_FETCH
        ? { appSettings: state.appSettings }
        : state;

    return combinedReducer(newState, action);
  };

  const persistedReducer =
    process.env.NODE_ENV === "test"
      ? rootReducer
      : persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: { ...preloadedState, ...initialStore },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      })
        .prepend(middlewares(initialHistory))
        .concat(apiSlice.middleware)
  });
  return store;
};

export const store = createMyStore(preloadedState);
export const persistor = persistStore(store);
export type RootStateQuery = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, null, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
