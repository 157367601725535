import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useEffect } from "react";
import { MyButton } from "../../../components/common/MyButton";

const useStyles = makeStyles(() =>
  createStyles({
    navigationButton: {
      minWidth: "300px",
      minHeight: "45px",
      margin: "auto"
    },
    responsive: {
      margin: "auto",
      position: "relative",
      paddingBottom: "42%",
      width: "100%",
      height: "80%"
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%"
    },
    loading: {
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
      marginTop: "200px",
      height: "100%",
      width: "100%"
    }
  })
);

export interface VideoViewProps {
  videoId: string;
  active: boolean;
  onMobile: boolean;
  navigationLabel: string;
  navigationFunction: React.MouseEventHandler<HTMLButtonElement>;
  navigationIcon?: React.ReactElement;
}

export const VideoView: React.FC<VideoViewProps> = ({
  videoId,
  active,
  onMobile,
  navigationLabel,
  navigationFunction,
  navigationIcon
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (!active) setLoading(true);
  }, [active]);

  return (
    <>
      <Box className={classes.responsive}>
        {loading && (
          <CircularProgress
            color="primary"
            size={57}
            className={classes.loading}
          />
        )}
        {active && (
          <iframe
            width="560"
            height="315"
            onLoad={() => setLoading(false)}
            src={`https://www.youtube.com/embed/${videoId}?controls=0&rel=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={classes.iframe}
          />
        )}
      </Box>
      {!onMobile && (
        <Box my={4} display="flex">
          <MyButton
            color="primary"
            variant="contained"
            className={classes.navigationButton}
            rightIcon={navigationIcon}
            onClick={navigationFunction}
          >
            <Typography>{navigationLabel}</Typography>
          </MyButton>
        </Box>
      )}
    </>
  );
};
