import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { PurchaseSettingsActionsTypes } from "./action";
import * as Api from "./api";
import { PurchaseSetting } from "../../entities/purchaseSettings";

export interface PurchaseSettingState extends EntityState<PurchaseSetting> {
  readStatus: FetchingStatus;
  patchOneStatus: FetchingStatus;
}

export const PurchaseSettingInitialState: PurchaseSettingState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  patchOneStatus: FetchingStatus.NULL
};

export const PurchaseSettingAdapter = createEntityAdapter<PurchaseSetting>();

const PurchaseSettingAdapterState = PurchaseSettingAdapter.getInitialState(
  PurchaseSettingInitialState
);

export const read = createMyAsyncThunk<PurchaseSetting[]>(
  PurchaseSettingsActionsTypes.READ,
  Api.read
);

export const patchOne = createMyAsyncThunk(
  PurchaseSettingsActionsTypes.PATCH_ONE,
  Api.patchOne,
  {
    onSuccessMessage: "saga:update-success"
  }
);

const PurchaseSettingSlice = createMySlice({
  name: "configurator-inputs",
  initialState: PurchaseSettingAdapterState,
  adapter: PurchaseSettingAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: PurchaseSettingAdapter.upsertMany
    },
    {
      action: patchOne,
      statusName: "patchOneStatus",
      onSuccess: PurchaseSettingAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const PurchaseSettingReducer = PurchaseSettingSlice.reducer;
export const PurchaseSettingActions = {
  ...PurchaseSettingSlice.actions,
  read,
  patchOne
};
