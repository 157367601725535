import produce from "immer";

import { BriefComputed } from "../../entities/briefComputed";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { BriefComputedActions, BriefComputedActionsTypes } from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface BriefComputedState {
  briefComputeds: BriefComputed[];
  fetchStatus: FetchingStatus;
}

const initialState: BriefComputedState = {
  briefComputeds: [],
  fetchStatus: FetchingStatus.NULL
};

export const BriefComputedReducer = (
  state = initialState,
  action: BriefComputedActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BriefComputedActionsTypes.BRIEF_COMPUTED_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.briefComputeds = action.briefComputeds || state.briefComputeds;
        break;
      case BriefComputedActionsTypes.BRIEF_COMPUTED_ADD:
        ReducerMethods.pushUniqueByMutate<BriefComputed>(
          draft.briefComputeds,
          action.briefComputed
        );
        break;
      default:
        return draft;
    }
  });
