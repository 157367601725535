import {
  ConfiguratorInputOption,
  InputOptionsAdvanced,
  JsonLogicFormula
} from "./entity";
import JsonLogic from "json-logic-js";
import { get } from "lodash";
import type { JsonLogicTree } from "react-awesome-query-builder";
import {
  ConfiguratorInputOptionComputedPartial,
  ConfiguratorInputOptionsComputed,
  InputUUID
} from "./selector";
import { BriefElementConfiguratorValues } from "../briefElementConfigurator/reducer";
import { getInputNamesFromValues } from "../briefElementConfigurator/method";

export const getJsonLogicFormulaAlways = (
  value: boolean
): JsonLogicFormula => ({
  logic: value,
  data: {}
});

/**
 * Evaluate the jsonlogic in the given context
 * @param logic to evaluate
 * @param values current values of the inputs
 * @param identifier for debugging purpose
 * @returns the result of the formula or false if the formula is not valid or undefined
 */
export const applyJsonLogic = (
  logic: JsonLogicTree | undefined,
  values: Record<string, any>,
  identifier: string
) => {
  if (!logic) return false;
  try {
    return JsonLogic.apply(logic, values);
  } catch (e) {
    console.error("Unable to compute jsonlogic of", identifier, e);
    return false;
  }
};

/**
 * Evaluate the formula in the given context
 * @param identifier for debugging purpose
 * @param formula to evaluate
 * @param inputs current list of inputs to replace in the formula eventually
 * @param values current values of the inputs
 * @param ignoreRegexSecurity true to bypass security check
 * @returns the result of the formula or undefined if the formula is not valid
 */
export const computeFormula = (
  identifier: string,
  formula: string,
  inputs: Array<string>,
  values: Record<string, any>,
  ignoreRegexSecurity = false
): number | undefined => {
  const methods = ["if", "else", "Math.min", "Math.max", "Math.ceil"];
  const globalRegex = /[a-zA-Z0-9.*+\-()/\s?<>,:]+/g;

  if (!formula) {
    console.debug(`Formula not set for ${identifier}`);
    return;
  }

  const secureFormula = () => {
    let formulaSecurity = formula;
    const regex = /[0-9.*+\-()/?<>,:]+/g;

    [...inputs, ...methods].forEach((i) => {
      formulaSecurity = formulaSecurity.replace(
        new RegExp(`\\b${i}\\b`, "g"),
        ""
      );
    });
    formulaSecurity = formulaSecurity.replace(regex, "");

    const formulaWording = formulaSecurity.split(" ").filter((i) => {
      i = i.replace(/\s{2,}/, "");
      return i;
    });

    if (formulaSecurity.trim().length) {
      console.debug(
        `Formula cannot contain custom wording like ${formulaWording.join(
          ", "
        )}`
      );
      return;
    }
  };

  /* First fast check with invalid char */
  if (formula.replace(globalRegex, "").trim().length) {
    console.debug(
      `Formula cannot contain special char like ${formula
        .replace(globalRegex, "")
        .split("")
        .join(", ")}`
    );
    return;
  }
  /**
   * Deep check with remove wording & regex strategy
   * We ensure that there is no unknown wording
   * it improve security for unwanted command injection
   */
  if (ignoreRegexSecurity === false) secureFormula();
  try {
    const formulaInputReplacement = inputs.reduce((prev, curr) => {
      const briefElementInputValue = get(values, curr);
      if (
        prev.match(`\\b${curr}\\b`) &&
        (isNaN(briefElementInputValue) ||
          typeof briefElementInputValue !== "number")
      ) {
        throw `input ${curr} with value '${briefElementInputValue}' is not a number`;
      }
      return prev.replace(
        new RegExp(`\\b${curr}\\b`, "g"),
        briefElementInputValue
      );
    }, formula);
    const result = Number(eval(formulaInputReplacement));
    if (result < 0) {
      console.debug(
        `Formula computation have a negative result, formula : ${formula} become ${formulaInputReplacement}, and give a result of ${result}`
      );
      return;
    }
    return Number(result.toFixed(2));
  } catch (err) {
    console.debug(`Formula computation has failed: ${err}`);
    return;
  }
};

/**
 * Return the input options with evaluated formulas for default, min, max in the given context
 * @param inputId input identifier for debugging purpose
 * @param inputOption
 * @param inputNames current input names
 * @param values current input values
 * @returns computed input options
 */
export const computeInputOptionFormulas = <
  T extends {
    options?: InputOptionsAdvanced | null;
  }
>(
  inputId: string,
  inputOption: T,
  inputNames: Array<string>,
  values: Record<string, any>
): T => {
  const { options } = inputOption;
  let optionsComputed = null;
  if (options) {
    const defaultValue = options.defaultFormula
      ? computeFormula(inputId, options.defaultFormula, inputNames, values)
      : options.default;
    const minValue = options.minFormula
      ? computeFormula(inputId, options.minFormula, inputNames, values)
      : options.min;
    const maxValue = options.maxFormula
      ? computeFormula(inputId, options.maxFormula, inputNames, values)
      : options.max;
    optionsComputed = {
      ...options,
      default: defaultValue,
      min: minValue,
      max: maxValue
    };
  }
  return {
    ...inputOption,
    options: optionsComputed
  };
};

/**
 * Return the values with extra inputs (number or computed formula) and the list all of input names
 */
const addExtraInputs = (
  values: BriefElementConfiguratorValues,
  extraInputs?: Record<string, string | number>
): {
  allInputNames: string[];
  allValues: BriefElementConfiguratorValues;
} => {
  if (!extraInputs) {
    return {
      allInputNames: getInputNamesFromValues(values),
      allValues: values
    };
  }

  const allInputNames = [
    ...getInputNamesFromValues(values),
    ...Object.keys(extraInputs)
  ];

  let allValues = {
    ...values,
    ...Object.entries(extraInputs)
      .filter(([input, value]) => typeof value === "number")
      .reduce((acc, [input, value]) => ({ ...acc, [input]: value }), {})
  };

  const extraInputsComputed = Object.entries(extraInputs)
    .filter(([input, value]) => typeof value === "string")
    .reduce(
      (acc, [input, value]) => ({
        ...acc,
        [input]: computeFormula(
          input,
          value as string,
          allInputNames,
          allValues
        )
      }),
      {}
    );

  allValues = {
    ...allValues,
    ...extraInputsComputed
  };

  return { allInputNames, allValues };
};

/**
 * Return the input option or the inter input option enabled in the given context
 */
const selectInputOptionToApply = (
  inputOption: ConfiguratorInputOption,
  values: BriefElementConfiguratorValues,
  productInterInputOptions: Map<InputUUID, ConfiguratorInputOption[]>
): ConfiguratorInputOption => {
  if (productInterInputOptions.size === 0) return inputOption;

  const inputOptionList = productInterInputOptions.get(
    inputOption.configuratorInputId
  );
  if (inputOptionList) {
    const interInputOptionActivated = inputOptionList.find((opt) =>
      opt.jsonLogicFormula?.logic
        ? applyJsonLogic(opt.jsonLogicFormula.logic, values, opt.id)
        : false
    );
    if (interInputOptionActivated) {
      return interInputOptionActivated;
    }
  }
  return inputOption;
};

/**
 * Rerturn the computed input option to apply for the given input in the given context.
 * - Verify the precondition
 * - Select the inter input option if applicable
 * - Compute the input option formulas
 */
const computeInputOption = (
  inputOption: ConfiguratorInputOption,
  allInputNames: string[],
  allValues: BriefElementConfiguratorValues,
  productInterInputOptions: Map<InputUUID, ConfiguratorInputOption[]>
): ConfiguratorInputOptionComputedPartial => {
  const inputId = inputOption.configuratorInputId;
  const inputPrecondition = applyJsonLogic(
    inputOption.jsonLogicFormula?.logic,
    allValues,
    inputId
  );

  if (inputPrecondition) {
    inputOption = selectInputOptionToApply(
      inputOption,
      allValues,
      productInterInputOptions
    );

    const inputOptionComputed = computeInputOptionFormulas(
      inputId,
      inputOption,
      allInputNames,
      allValues
    );
    return {
      ...inputOptionComputed,
      precondition: true
    };
  } else {
    return {
      ...inputOption,
      precondition: false
    };
  }
};

/**
 * Compute base preconditions, inter inpution precondition and input options formulas
 * based on current values and extra contextual inputs
 */
export const computeInputOptions = (
  values: BriefElementConfiguratorValues,
  productInputOptions: Map<InputUUID, ConfiguratorInputOption>,
  productInterInputOptions: Map<InputUUID, ConfiguratorInputOption[]>,
  extraInputs: undefined | Record<string, string | number> = {}
): ConfiguratorInputOptionsComputed => {
  try {
    if (productInputOptions.size === 0) return {};

    const { allInputNames, allValues } = addExtraInputs(values, extraInputs);
    const inputOptionsComputed: ConfiguratorInputOptionsComputed = {};

    productInputOptions.forEach((inputOption, inputId) => {
      inputOptionsComputed[inputId] = computeInputOption(
        inputOption,
        allInputNames,
        allValues,
        productInterInputOptions
      );
    });

    return inputOptionsComputed;
  } catch (e) {
    console.error(e);
    return {};
  }
};
