export interface InAppTabsSlug {
  inbox: string | boolean;
  archives: string | boolean;
  following: string | boolean;
}

export enum InAppTabsEnum {
  INBOX = "inbox",
  ARCHIVED = "archived",
  FOLLOWING = "following"
}

export function setInAppTabsWSlug(): InAppTabsSlug {
  const tabs: InAppTabsSlug = {
    inbox: InAppTabsEnum.INBOX,
    archives: InAppTabsEnum.ARCHIVED,
    following: InAppTabsEnum.FOLLOWING
  };
  return tabs;
}
