import produce from "immer";

import { Role } from "../../entities/role";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { RoleActions, RoleActionsTypes } from "./action";

export interface RoleState {
  roles: Role[];
  fetchStatus: FetchingStatus;
}

const initialState: RoleState = {
  roles: [],
  fetchStatus: FetchingStatus.NULL
};

export const RoleReducer = (state = initialState, action: RoleActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RoleActionsTypes.ROLE_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.roles = action.roles || state.roles;
        break;
      default:
        return draft;
    }
  });
