import { MachineCreateDTO, MachineUpdateDTO } from "../../entities/machine";
import { Request } from "../../utils/request";

export const readMachines = () => {
  // const query = RequestQueryBuilder.create({
  //   join: [{ field: "rate" }]
  // }).query();
  return Request({ withToken: true }).get("/machines");
};

export const readOneMachine = (id: string) =>
  Request({ withToken: true }).get(`/machines/${id}`);

export const createMachine = (machine: MachineCreateDTO) =>
  Request({ withToken: true }).post("/machines", machine);

export const updateMachine = ({ id, ...dto }: MachineUpdateDTO) =>
  Request({ withToken: true }).patch(`/machines/${id}`, dto);

export const deleteMachine = (id: string) =>
  Request({ withToken: true }).delete(`/machines/${id}`);
