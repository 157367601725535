import { BasicVarnish } from "../../../entities/basicVarnish";
import { createMyAsyncThunk } from "../../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { BasicVarnishActionsTypes } from "./action";
import * as Api from "./api";

export interface BasicVarnishState {
  fetchStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  createStatus: FetchingStatus;
  basicVarnishs: BasicVarnish[];
}

export const BasicVarnishInitialState: BasicVarnishState = {
  fetchStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  basicVarnishs: []
};

export const read = createMyAsyncThunk<BasicVarnish>(
  BasicVarnishActionsTypes.GET,
  Api.read
);

export const patch = createMyAsyncThunk(
  BasicVarnishActionsTypes.PATCH_ONE,
  Api.patch,
  { onSuccessMessage: "saga:update-success" }
);

export const post = createMyAsyncThunk(
  BasicVarnishActionsTypes.CREATE,
  Api.post,
  { onSuccessMessage: "saga:update-success" }
);

const BasicVarnishSlice = createMySlice({
  name: "basic-varnishes",
  initialState: BasicVarnishInitialState,
  asyncActions: [
    {
      action: read,
      statusName: "fetchStatus",
      onSuccess: (state, action) => {
        state.basicVarnishs = action.payload;
      }
    },
    {
      action: post,
      statusName: "createStatus",
      onSuccess: (state, action) => {
        state.basicVarnishs.push(action.payload);
      }
    },
    {
      action: patch,
      statusName: "patchStatus",
      onSuccess: (state, action) => {
        state.basicVarnishs = state.basicVarnishs.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      }
    }
  ],
  reducers: {}
});

export const BasicVarnishReducer = BasicVarnishSlice.reducer;
export const BasicVarnishActions = {
  ...BasicVarnishSlice.actions,
  async: { read, patch, post }
};

// import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
// import { BasicVarnish } from "../../../entities/basicVarnish";
// import { createMyAsyncThunk } from "../../../utils/reducers/createMyAsyncThunk";
// import { createMySlice } from "../../../utils/reducers/createMySlice";
// import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
// import { BasicVarnishActionsTypes } from "./action";
// import * as Api from "./api";

// export interface BasicVarnishState extends EntityState<BasicVarnish> {
//   fetchStatus: FetchingStatus;
//   patchStatus: FetchingStatus;
//   createStatus: FetchingStatus;
//   // basicVarnishs: BasicVarnish[];
// }

// export const BasicVarnishInitialState: BasicVarnishState = {
//   ids: [],
//   entities: {},
//   fetchStatus: FetchingStatus.NULL,
//   patchStatus: FetchingStatus.NULL,
//   createStatus: FetchingStatus.NULL
// };

// export const BasicVarnishAdapter = createEntityAdapter<BasicVarnish>();

// const BasicVarnishAdapterAdapterState = BasicVarnishAdapter.getInitialState(
//   BasicVarnishInitialState
// );

// export const read = createMyAsyncThunk<BasicVarnish>(
//   BasicVarnishActionsTypes.GET,
//   Api.read
// );

// export const patch = createMyAsyncThunk(
//   BasicVarnishActionsTypes.PATCH_ONE,
//   Api.patch,
//   { onSuccessMessage: "saga:update-success" }
// );

// export const post = createMyAsyncThunk(
//   BasicVarnishActionsTypes.CREATE,
//   Api.post,
//   { onSuccessMessage: "saga:update-success" }
// );

// const BasicVarnishSlice = createMySlice({
//   name: "basic-varnishes",
//   initialState: BasicVarnishAdapterAdapterState,
//   adapter: BasicVarnishAdapter,
//   asyncActions: [
//     {
//       action: read,
//       statusName: "fetchStatus",
//       onSuccess: BasicVarnishAdapter.upsertMany
//     },
//     {
//       action: post,
//       statusName: "createStatus",
//       onSuccess: BasicVarnishAdapter.addOne
//     },
//     {
//       action: patch,
//       statusName: "patchStatus",
//       onSuccess: BasicVarnishAdapter.upsertOne
//     }
//   ],
//   reducers: {}
// });

// export const BasicVarnishReducer = BasicVarnishSlice.reducer;
// export const BasicVarnishActions = {
//   ...BasicVarnishSlice.actions,
//   async: { read, patch, post }
// };
