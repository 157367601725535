import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { FetchingStatus } from "../utils/reducers/fetchingStatus";
import { LoadingPage } from "./LoadingPage";

export const Reconnection: React.FC<{
  userIsAuthenticated: boolean;
  needAuthentication?: boolean;
  children?: React.ReactNode;
}> = ({ children, userIsAuthenticated, needAuthentication = false }) => {
  const { t } = useTranslation("reconnection");
  const retryConnectionStatus = useSelector(
    (state: RootState) => state.authentication.retryConnectionStatus
  );
  const retryConnection = retryConnectionStatus === FetchingStatus.PENDING;
  return (
    <>
      {retryConnection && <LoadingPage title={t("title")} />}
      {!needAuthentication
        ? children
        : retryConnectionStatus === FetchingStatus.SUCCESS ||
          !localStorage.getItem("token") ||
          userIsAuthenticated
        ? children
        : null}
    </>
  );
};
