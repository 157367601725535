import { createSelector } from "reselect";
import { RootState } from "..";
import { getPropsPosition } from "../common/utils.selector";
import { BriefElementState } from "./reducer";

export const getBriefElementsConfiguratorState = createSelector(
  (state: RootState) => state,
  (state) => state.briefElementConfigurator
);

export const getBriefElementsCount = createSelector(
  (state: RootState) => state,
  (state) => Object.keys(state.briefElementConfigurator).length
);
