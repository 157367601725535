import React from "react";
import { Link, LinkProps } from "react-router-dom";

export interface MyLinkProps extends LinkProps {
  disableUnderline?: boolean;
}

export const MyLink: React.FC<MyLinkProps> = ({
  children,
  disableUnderline,
  ...props
}) => (
  <Link
    {...props}
    style={{
      textDecoration: !disableUnderline ? "underline" : "inherit",
      color: "inherit",
      cursor: "pointer",
      ...props.style
    }}
  >
    {children}
  </Link>
);
