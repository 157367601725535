import produce from "immer";
import { Brief, BriefWithRelations } from "../../entities/brief";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ReducerMethods } from "../../utils/reducers/methods";
import { BriefActions, BriefActionsTypes } from "./action";

export interface BriefState {
  briefs: Brief[];
  selected?: BriefWithRelations;
  fetchStatus: FetchingStatus;
  fetchForUserStatus: FetchingStatus;
  createStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  getByIdStatus: FetchingStatus;
  editNameStatus: FetchingStatus;
  briefOrderStatus: FetchingStatus;
  briefFinalizeOrderStatus: FetchingStatus;
  assignProjectStatus: FetchingStatus;
}

const initialState: BriefState = {
  briefs: [],
  selected: undefined,
  fetchStatus: FetchingStatus.NULL,
  fetchForUserStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  getByIdStatus: FetchingStatus.NULL,
  editNameStatus: FetchingStatus.NULL,
  briefOrderStatus: FetchingStatus.NULL,
  briefFinalizeOrderStatus: FetchingStatus.NULL,
  assignProjectStatus: FetchingStatus.NULL
};

export const BriefReducer = (state = initialState, action: BriefActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BriefActionsTypes.BRIEF_FETCH_STATUS:
        draft.fetchStatus = action.status;
        draft.briefs = action.briefs || state.briefs;
        break;
      case BriefActionsTypes.BRIEF_FOR_USER_FETCH_STATUS:
        draft.fetchForUserStatus = action.status;
        ReducerMethods.pushArrayUniqueByMutate(
          draft.briefs,
          action.briefs || state.briefs
        );
        break;
      case BriefActionsTypes.BRIEF_CREATE_STATUS:
        draft.createStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS) {
          draft.briefs.push(action.brief as Brief);
        }
        break;
      case BriefActionsTypes.BRIEF_UPDATE_STATUS:
        draft.createStatus = action.status;
        if (action.brief)
          ReducerMethods.upsertByIdMutate(draft.briefs, action.brief);
        if (
          draft.selected &&
          action.brief &&
          action.brief.id === draft.selected.id
        ) {
          Object.assign(draft.selected, action.brief);
        }
        break;
      case BriefActionsTypes.BRIEF_SELECTED:
        draft.selected = action.brief;
        break;
      case BriefActionsTypes.BRIEF_GET_BY_ID_STATUS:
        draft.getByIdStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.brief)
          draft.selected = action.brief;
        else draft.selected = undefined;
        break;
      case BriefActionsTypes.BRIEF_ADD:
        ReducerMethods.pushUniqueByMutate<Brief>(draft.briefs, action.brief);
        break;
      case BriefActionsTypes.BRIEF_EDIT_NAME_STATUS:
        draft.editNameStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.values) {
          ReducerMethods.upsertByIdMutate(
            draft.briefs,
            { id: action.values.id } as Brief,
            (brief) => ({ ...brief, name: action.values?.name })
          );
        }
        if (draft.selected && action.values?.id === draft.selected.id) {
          draft.selected.name = action.values?.name;
        }
        break;
      case BriefActionsTypes.BRIEF_ORDER_STATUS:
        draft.briefOrderStatus = action.status;
        break;
      case BriefActionsTypes.BRIEF_FINALIZE_ORDER_STATUS:
        draft.briefFinalizeOrderStatus = action.status;
        break;
      case BriefActionsTypes.BRIEF_ASSIGN_PROJECT_STATUS:
        draft.assignProjectStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.id) {
          ReducerMethods.upsertByIdMutate(
            draft.briefs,
            { id: action.id } as Brief,
            (brief) => ({ ...brief, projectId: action.projectId })
          );
        }
        break;
      case BriefActionsTypes.BRIEF_ADD_MANY:
        ReducerMethods.pushArrayUniqueByMutate(draft.briefs, action.briefs);
        break;
      default:
        return state;
    }
  });
