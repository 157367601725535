export enum QuoteSettingActionsTypes {
  GET = "@@QUOTE_SETTINGS get",
  GET_STATUS = "@@QUOTE_SETTINGS get status",
  PATCH = "@@QUOTE_SETTINGS patch ",
  PATCH_STATUS = "@@QUOTE_SETTINGS patch status",
  UPDATE_TERM_AND_CONDITION = "@@QUOTE_SETTINGS upload term and condition",
  UPDATE_TERM_AND_CONDITION_STATUS = "@@QUOTE_SETTINGS upload term and condition status",
  REMOVE_TERM_AND_CONDITION = "@@QUOTE_SETTINGS remove term and condition",
  REMOVE_TERM_AND_CONDITION_STATUS = "@@QUOTE_SETTINGS remove term and condition status"
}
