import produce from "immer";

import { MaterialReferenceSheetSize } from "../../entities/materialReferenceSheetSize";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import {
  MaterialReferenceSheetSizeActions,
  MaterialReferenceSheetSizeActionsTypes
} from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface MaterialReferenceSheetSizeState {
  materialReferenceSheetSizes: MaterialReferenceSheetSize[];
  createStatus: FetchingStatus;
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
  readOneStatus: FetchingStatus;
}

const initialState: MaterialReferenceSheetSizeState = {
  materialReferenceSheetSizes: [],
  createStatus: FetchingStatus.NULL,
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL,
  readOneStatus: FetchingStatus.NULL
};

export const MaterialReferenceSheetSizeReducer = (
  state = initialState,
  action: MaterialReferenceSheetSizeActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_CREATE_STATUS:
        draft.createStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.pushUniqueByMutate(
            draft.materialReferenceSheetSizes,
            action.materialReferenceSheetSize as MaterialReferenceSheetSize
          );
        break;

      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ_STATUS:
        draft.readStatus = action.status;
        draft.materialReferenceSheetSizes =
          action.materialReferenceSheetSizes ||
          state.materialReferenceSheetSizes;
        break;

      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_UPDATE_STATUS:
        draft.updateStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.materialReferenceSheetSizes,
            action.materialReferenceSheetSize as MaterialReferenceSheetSize
          );
        break;

      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_DELETE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.removeByIdMutate(
            draft.materialReferenceSheetSizes,
            action.materialReferenceSheetSize as MaterialReferenceSheetSize
          );
        break;

      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_READ_ONE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.materialReferenceSheetSizes,
            action.materialReferenceSheetSize as MaterialReferenceSheetSize
          );
        break;

      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_ADD:
        ReducerMethods.upsertByIdMutate(
          draft.materialReferenceSheetSizes,
          action.materialReferenceSheetSize as MaterialReferenceSheetSize
        );
        break;

      case MaterialReferenceSheetSizeActionsTypes.MATERIAL_REFERENCE_SHEETSIZE_REMOVE:
        ReducerMethods.removeByIdMutate(
          draft.materialReferenceSheetSizes,
          action.materialReferenceSheetSize as MaterialReferenceSheetSize
        );
        break;

      default:
        return draft;
    }
  });
