import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { MyTooltip } from "./MyTooltip";

interface MyTooltipTypographyProps {
  show: boolean;
  icon: JSX.Element;
  content?: string;
  contentComponent?: JSX.Element;
}

export const MyTooltipTypography: FC<MyTooltipTypographyProps> = ({
  show,
  icon,
  content,
  contentComponent
}) => {
  return (
    <>
      {show && (
        <MyTooltip
          style={{ cursor: "pointer" }}
          iconComponent={icon}
          title={<Typography>{contentComponent ?? content}</Typography>}
        />
      )}
    </>
  );
};
