import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";
import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import {
  MachineActionsTypes,
  MachineCreate,
  MachineCreateStatus,
  MachineDelete,
  MachineDeleteStatus,
  MachineReadOne,
  MachineReadOneStatus,
  MachineReadStatus,
  MachineUpdate,
  MachineUpdateStatus
} from "./action";
import * as Api from "./api";

export function* readMachines() {
  yield put(new MachineReadStatus(FetchingStatus.PENDING));
  try {
    const { data: machines } = yield call(Api.readMachines);
    yield put(new MachineReadStatus(FetchingStatus.SUCCESS, machines));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("machines:read-failed");
      Sentry.captureException(error);
    });
    yield put(new MachineReadStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-failed")));
  }
}

export function* readOneMachine(action: MachineReadOne) {
  yield put(new MachineReadOneStatus(FetchingStatus.PENDING));
  try {
    const { data: machine } = yield call(Api.readOneMachine, action.id);
    yield put(new MachineReadOneStatus(FetchingStatus.SUCCESS, machine));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("machines:read-one-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MachineReadOneStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-one-failed")));
  }
}

export function* createMachine(action: MachineCreate) {
  yield put(new MachineCreateStatus(FetchingStatus.PENDING));
  try {
    const { data: machine } = yield call(Api.createMachine, action.machine);
    yield put(new MachineCreateStatus(FetchingStatus.SUCCESS, machine));
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("machines:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MachineCreateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* updateMachine(action: MachineUpdate) {
  yield put(new MachineUpdateStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.updateMachine, action.machine);
    yield put(new MachineUpdateStatus(FetchingStatus.SUCCESS, action.machine));
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("machines:update-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MachineUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* deleteMachine(action: MachineDelete) {
  yield put(new MachineDeleteStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.deleteMachine, action.machine.id);
    yield put(new MachineDeleteStatus(FetchingStatus.SUCCESS));
    yield put(new AppAddSnackbar(i18n.t("saga:delete-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("machines:delete-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MachineDeleteStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:delete-failed")));
  }
}

export const MachineSaga = [
  takeLatest(MachineActionsTypes.MACHINE_CREATE, createMachine),
  takeLatest(MachineActionsTypes.MACHINE_READ, readMachines),
  takeLatest(MachineActionsTypes.MACHINE_READ_ONE, readOneMachine),
  takeLatest(MachineActionsTypes.MACHINE_UPDATE, updateMachine),
  takeLatest(MachineActionsTypes.MACHINE_DELETE, deleteMachine)
];
