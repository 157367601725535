import axios from "axios";

interface RequestProps {
  withToken: boolean;
}

export const baseURL = process.env.REACT_APP_API || "/api";
export const Request = (options?: RequestProps) => {
  const instance = axios.create({
    baseURL,
    headers: options
      ? {
          Authorization: options.withToken
            ? "Bearer " + localStorage.getItem("token")
            : ""
        }
      : {}
  });

  // // Add a request interceptor
  // instance.interceptors.request.use(
  //   (config) => {
  //     console.log("Request sent at:", new Date().getTime());
  //     console.time(config.url);
  //     console.log("Request sent:", config);
  //     return config;
  //   },
  //   (error) => {
  //     console.error("Request error:", error);
  //     return Promise.reject(error);
  //   }
  // );

  // // Add a response interceptor
  // instance.interceptors.response.use(
  //   (response) => {
  //     console.timeEnd(response.config.url);
  //     console.log("Request received at:", new Date().getTime());
  //     console.log("Response received:", response);
  //     return response;
  //   },
  //   (error) => {
  //     console.error("Response error:", error);
  //     return Promise.reject(error);
  //   }
  // );

  return instance;
};
