import { CssBaseline } from "@material-ui/core";
import {
  createTheme,
  MuiThemeProvider,
  responsiveFontSizes
} from "@material-ui/core/styles";
import React from "react";
import { AppState } from "../../reducers/app/reducer";
import { AppSetting } from "../../reducers/appSettings/entity";
import { commonThemeOptions } from "./common";

export interface AppThemeProps {
  theme: AppState["theme"];
  setting: AppSetting;
  children: JSX.Element;
}

type Props = AppThemeProps;

export const AppTheme: React.FC<Props> = ({ children, setting }) => {
  const theme = createTheme(commonThemeOptions(setting));

  return (
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
