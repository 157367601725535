import React, { useEffect, useRef } from "react";
import posthog from "posthog-js";

export const InitPosthog: React.FC = () => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current && process.env.NODE_ENV === "production") {
      posthog.init("phc_6OC5zlR5ssReh5XzmI6hj4RJqWdBgFgrISfMpvMSrux", {
        api_host: "https://eu.i.posthog.com",
        person_profiles: "identified_only" // or 'always' to create profiles for anonymous users as well
      });
      isInitialized.current = true;
    }
  });

  return null;
};
