import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { WindowActionsTypes, WindowFetchStatus } from "./action";
import * as Api from "./api";

export function* fetchWindows() {
  yield put(new WindowFetchStatus(FetchingStatus.PENDING));
  try {
    const { data: products } = yield call(Api.fetchWindow);
    yield put(new WindowFetchStatus(FetchingStatus.SUCCESS, products));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("windows:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new WindowFetchStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("windows:fetchFailed")));
  }
}

export const WindowSaga = [
  takeLatest(WindowActionsTypes.WINDOW_FETCH, fetchWindows)
];
