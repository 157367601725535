import { createSelector } from "reselect";
import { RootState } from "../";
import { jsonTranslator } from "../../utils/function/jsonTranslator";
import { getLanguage } from "../app/selector";
import { OperationAdapter } from "./reducer";

const AdapterSelector = OperationAdapter.getSelectors(
  (state: RootState) => state.operations
);

export const getState = createSelector(
  (state: RootState) => state,
  (state) => state.operations
);

export const getOperationsTranslated = createSelector(
  AdapterSelector.selectAll,
  getLanguage,
  (operations, lang) =>
    operations.map((m) => ({ ...m, label: jsonTranslator(m.label, lang) }))
);

export const getOperationById = (id: string) =>
  createSelector(AdapterSelector.selectAll, (operations) =>
    operations.find((o) => o.id === id)
  );

export const getOperationByName = (name: string) =>
  createSelector(AdapterSelector.selectAll, (operations) =>
    operations.find((o) => o.name === name)
  );

export const isOperationByNameEnabled = (name: string) =>
  createSelector(
    AdapterSelector.selectAll,
    (operations) => operations.find((o) => o.name === name)?.enabled
  );

// TODO to be removed
export const getOperationsOptions = createSelector(
  AdapterSelector.selectAll,
  getLanguage,
  (operations, lang) =>
    operations.map((operation) => ({
      value: operation.id,
      label: jsonTranslator(operation.label, lang)
    }))
);

export const selectOptions = createSelector(
  AdapterSelector.selectAll,
  getLanguage,
  (operations, lang) =>
    operations.map((operation) => ({
      value: operation.id,
      label: jsonTranslator(operation.label, lang)
    }))
);
export const OperationSelector = {
  ...AdapterSelector,
  getState,
  selectOptions,
  // TODO to be removed
  getOperationsOptions,
  getOperationsTranslated
};
