import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import Grow from "@material-ui/core/Grow";
import { TransitionProps } from "@material-ui/core/transitions";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Edit";
import React, { forwardRef } from "react";
import { MyFormik, MyFormikProps } from "../../MyFormik";

export interface MaterialTableFormDialogProps {
  title: string | React.ReactNode;
  open: boolean;
  type: "update" | "create";
  formik: MyFormikProps<any>;
  dialogProps?: Partial<DialogProps>;
  dialogContentProps?: Partial<DialogContentProps>;
  onClose: () => void;
  allowBackDrop?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      marginBottom: 2
    },
    closeIcon: {
      padding: 0
    },
    dialogTitle: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}`
    },
    dialogContent: {
      padding: theme.spacing(4),
      paddingBottom: 46,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5)
      }
    },
    dialogAction: {
      justifyContent: "space-between"
    }
  })
);

const GrowTransition = forwardRef((props: TransitionProps, ref) => (
  <Grow ref={ref} {...props} />
));
export const MaterialTableFormDialog: React.FC<MaterialTableFormDialogProps> = ({
  title,
  open,
  formik,
  dialogProps,
  dialogContentProps,
  onClose,
  allowBackDrop,
  type
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (event: any, reason: any) => {
    if (reason == "backdropClick" && allowBackDrop) onClose();
  };

  return (
    <Dialog
      TransitionComponent={GrowTransition}
      transitionDuration={300}
      fullScreen={fullScreen}
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      className={classes.root}
      {...dialogProps}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box clone mr={1}>
              {type === "update" ? (
                <UpdateIcon color="primary" className={classes.icon} />
              ) : (
                <AddCircleIcon
                  color="primary"
                  className={classes.icon}
                  style={{ marginBottom: 0 }}
                />
              )}
            </Box>
            <Typography color="primary" variant="h6">
              {title}
            </Typography>
          </Box>
          <IconButton onClick={onClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.dialogContent}
        {...dialogContentProps}
      >
        <MyFormik {...formik} />
      </DialogContent>
    </Dialog>
  );
};
