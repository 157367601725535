import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import {
  MaterialReference,
  MaterialReferenceCreateDTO,
  MaterialReferenceUpdateDTO
} from "../../entities/materialReference";

export enum MaterialReferenceActionsTypes {
  MATERIAL_REFERENCE_CREATE = "@@MATERIAL_REFERENCE create materialReference",
  MATERIAL_REFERENCE_CREATE_STATUS = "@@MATERIAL_REFERENCE create materialReference status",
  MATERIAL_REFERENCE_READ = "@@MATERIAL_REFERENCE read materialReference",
  MATERIAL_REFERENCE_READ_STATUS = "@@MATERIAL_REFERENCE read status materialReference",
  MATERIAL_REFERENCE_UPDATE = "@@MATERIAL_REFERENCE update materialReference",
  MATERIAL_REFERENCE_UPDATE_STATUS = "@@MATERIAL_REFERENCE update materialReference status",
  MATERIAL_REFERENCE_DELETE = "@@MATERIAL_REFERENCE delete materialReference",
  MATERIAL_REFERENCE_DELETE_STATUS = "@@MATERIAL_REFERENCE delete materialReference status",
  MATERIAL_REFERENCE_READ_ONE = "@@MATERIAL_REFERENCE read one materialReference",
  MATERIAL_REFERENCE_READ_ONE_STATUS = "@@MATERIAL_REFERENCE read one status materialReference",
  MATERIAL_REFERENCE_ADD = "@@MATERIAL_REFERENCE add materialReference",
  MATERIAL_REFERENCE_REMOVE = "@@MATERIAL_REFERENCE remove materialReference"
}

export class MaterialReferenceCreate {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_CREATE;
  constructor(public materialReference: MaterialReferenceCreateDTO) {}
}
export class MaterialReferenceCreateStatus {
  readonly type =
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_CREATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReference?: MaterialReferenceCreateDTO
  ) {}
}

export class MaterialReferenceRead {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ;
}
export class MaterialReferenceReadStatus {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReferences?: MaterialReference[]
  ) {}
}

export class MaterialReferenceReadOne {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ_ONE;
  constructor(public id: string) {}
}
export class MaterialReferenceReadOneStatus {
  readonly type =
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ_ONE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReference?: MaterialReference
  ) {}
}

export class MaterialReferenceUpdate {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_UPDATE;
  constructor(
    public materialReference: MaterialReferenceUpdateDTO,
    // eslint-disable-next-line @typescript-eslint/ban-types
    public callback?: Function
  ) {}
}
export class MaterialReferenceUpdateStatus {
  readonly type =
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_UPDATE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReference?: MaterialReference
  ) {}
}

export class MaterialReferenceDelete {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_DELETE;
  constructor(public materialReference: MaterialReference) {}
}
export class MaterialReferenceDeleteStatus {
  readonly type =
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_DELETE_STATUS;
  constructor(
    public status: FetchingStatus,
    public materialReference?: MaterialReference
  ) {}
}

export class MaterialReferenceAdd {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_ADD;
  constructor(public materialReference: MaterialReference) {}
}
export class MaterialReferenceRemove {
  readonly type = MaterialReferenceActionsTypes.MATERIAL_REFERENCE_REMOVE;
  constructor(public materialReference: MaterialReference) {}
}

export type MaterialReferenceActions =
  | MaterialReferenceCreate
  | MaterialReferenceCreateStatus
  | MaterialReferenceRead
  | MaterialReferenceReadStatus
  | MaterialReferenceUpdate
  | MaterialReferenceUpdateStatus
  | MaterialReferenceDelete
  | MaterialReferenceDeleteStatus
  | MaterialReferenceReadOne
  | MaterialReferenceReadOneStatus
  | MaterialReferenceAdd
  | MaterialReferenceRemove;
