import { createSelector } from "reselect";
import { RootState } from "..";
import { getBriefElementSelectedPosition } from "../briefConfigurator/selector";
import { pickBy, some, every, get } from "lodash";
import { MyExpansionPanelStatus } from "../../components/common/MyExpansionPanel";
import {
  getPropsName,
  getPropsProduct,
  getPropsIsMaterialSet,
  getPropsTouched,
  getPropsErrors,
  getPropsPositionOptional,
  getPropsFactory
} from "../common/utils.selector";
import {
  AdapterSelector,
  ConfiguratorInputOptionComputed,
  ConfiguratorInputOptionsComputed,
  ConfiguratorInputOptionsResult,
  InputName,
  InputUUID,
  InterInputOptionComputed,
  ProductInputOptions
} from "../configurator-input-options/selector";
import {
  SectionNames,
  ConfiguratorInputMandatory,
  ConfiguratorInputNames
} from "../configurator-inputs/constant";
import {
  BriefElementConfiguratorState,
  BriefElementInterInputOptionsComputed
} from "./reducer";
import { getBriefElementsConfiguratorState } from "./selector";
import { getEachUserStandardRoleStatus } from "../authentication/selector";
import { EachUserRole } from "../authentication/types";
import { ConfiguratorInputOption } from "../configurator-input-options/entity";
import { getJsonLogicFormulaAlways } from "../configurator-input-options/method";
import { getExternalInputName } from "../configurator-inputs/method";
import { ConfiguratorInputSelector } from "../configurator-inputs/selector";
import {
  OperationSelector,
  getOperationsOptions
} from "../operations/selector";
import {
  ProductSelector,
  getProductOptions,
  getTotalProduct
} from "../products/selector";

const getBriefElementSelected = createSelector(
  getBriefElementSelectedPosition,
  (state: RootState) => state.briefElementConfigurator,
  (position, briefElementConfigurator) =>
    briefElementConfigurator?.[position?.toString() ?? 0]
);

export const getBriefElementProductSelected = createSelector(
  getBriefElementSelected,
  (briefElement) => briefElement?.productSelected
);

export const getBriefElementProductSelectedScale = createSelector(
  getBriefElementSelected,
  (briefElement) => briefElement?.scale3D
);

export const getProductVisibilityState = createSelector(
  getBriefElementSelected,
  (briefElement) => briefElement?.productVisibilityState
);

export const getBriefElementStateByPosition = createSelector(
  getPropsPositionOptional,
  (state: RootState) => state.briefElementConfigurator,
  (position: string | undefined, configurator: BriefElementConfiguratorState) =>
    configurator[position ?? 0]
);

export const getBriefElementSectionState = createSelector(
  getBriefElementStateByPosition,
  (configurator) => configurator?.sectionsState
);

export const getProductSelected = createSelector(
  getBriefElementStateByPosition,
  (configurator) => configurator?.productSelected
);

export const getProductCategorySelected = createSelector(
  getBriefElementStateByPosition,
  (configurator) => configurator?.productCategorySelected
);

export const getBriefElementSectionStateByName = createSelector(
  getPropsName,
  getBriefElementSectionState,
  (name, sectionState) =>
    sectionState?.[name] ? sectionState[name] : undefined
);

export const getBriefElementExpansionPanelStatusFromSection = createSelector(
  getPropsName,
  getPropsTouched,
  getPropsErrors,
  getBriefElementSectionState,
  (name, touched, errors, sectionState) => {
    const currentSection = sectionState ? sectionState[name] : undefined;
    const expansionPanelErrors = pickBy(errors, (_, key) =>
      currentSection?.dependentProperties.includes(
        key as ConfiguratorInputNames
      )
    );

    if (!currentSection?.touched) {
      return { status: undefined, errors: expansionPanelErrors };
    }
    if (
      some(
        currentSection.dependentProperties,
        (ptp) =>
          (touched as Record<ConfiguratorInputNames, any>)[ptp] &&
          (errors as Record<ConfiguratorInputNames, any>)[ptp]
      )
    ) {
      return {
        status: MyExpansionPanelStatus.ERROR,
        errors: expansionPanelErrors
      };
    }
    if (
      every(
        errors,
        (_, key) =>
          !currentSection.dependentProperties.includes(
            key as ConfiguratorInputNames
          )
      )
    ) {
      return { status: MyExpansionPanelStatus.SUCCESS };
    }
    return { status: undefined, errors: expansionPanelErrors };
  }
);

export const getIsProductFromUrl = createSelector(
  (state: RootState) => state.briefElementConfigurator,
  (configurator: BriefElementConfiguratorState) =>
    configurator[0]?.productFromUrl
);

export const getBriefElementAllValid = createSelector(
  getBriefElementsConfiguratorState,
  (briefElementState) => every(briefElementState, (be) => be?.isValid === true)
);

/**
 * Returns the input options computed for the brief element.
 * These input options can be from activated inter input options.
 * It specify where or not the base precondition is met for the linked input ids.
 * If input options has formulas, it has been computed into its corresponding property.
 * e.g. options.defaultFormula computed as options.default
 * @param position of the brief element
 * @returns input options for the brief element
 */
export const getInputOptionsComputed = createSelector(
  getPropsPositionOptional,
  getBriefElementStateByPosition,
  (position, sectionState) => {
    if (position === undefined) return {} as ConfiguratorInputOptionsComputed;
    return (
      sectionState?.inputOptionsComputed ||
      ({} as ConfiguratorInputOptionsComputed)
    );
  }
);

/**
 * Used to store the values of the brief element in specific cases:
 * - when the user switches to/create another brief element
 * - during initialisation when product is automatically selected from url
 * - during duplication of a brief
 * Warning: don't use it as source of truth for the brief element
 * during the configuration phase. Use formik state instead.
 * @param position of the brief element
 * @returns values of the brief element
 */
export const getBriefElementStateValues = createSelector(
  getBriefElementStateByPosition,
  (sectionState) => sectionState?.values || {}
);

/**
 * For a given product, it returns an Object including Maps of inputOptions from the product directly and from its operations,
 * and Maps of interInputOptions from the product directly and from its operation
 */
export const getConfiguratorInputOptionsProduct = createSelector(
  getPropsProduct,
  AdapterSelector.selectAll,
  OperationSelector.selectAll,
  (product, options, operations): ProductInputOptions => {
    const operationValid = operations.filter(
      (opts) =>
        product?.operationIds.includes(opts.id) &&
        (opts.internal || opts.external) &&
        opts.enabled
    );

    const iO = {
      fromOperations: new Map<InputUUID, ConfiguratorInputOption>(),
      fromProduct: new Map<InputUUID, ConfiguratorInputOption>()
    };

    const interIO = {
      fromOperations: new Map<InputUUID, Array<ConfiguratorInputOption>>(),
      fromProduct: new Map<InputUUID, Array<ConfiguratorInputOption>>()
    };

    const isFromOperation = (options: ConfiguratorInputOption) =>
      !!options.operationId &&
      operationValid.map((o) => o.id).includes(options.operationId);

    const isFromProduct = (options: ConfiguratorInputOption) =>
      product && !!options.productId && product.id === options.productId;

    for (const opt of options) {
      if (!opt.interInput) {
        if (
          isFromOperation(opt) &&
          !iO.fromOperations.has(opt.configuratorInputId)
        ) {
          //we arbitrary select one inputOption form one of the operations of the product
          iO.fromOperations.set(opt.configuratorInputId, opt);
        } else if (isFromProduct(opt)) {
          iO.fromProduct.set(opt.configuratorInputId, opt);
        }
      } else {
        if (isFromOperation(opt)) {
          const arr = interIO.fromOperations.get(opt.configuratorInputId);

          if (arr) arr.push(opt);
          else interIO.fromOperations.set(opt.configuratorInputId, [opt]);
        } else if (isFromProduct(opt)) {
          const arr = interIO.fromProduct.get(opt.configuratorInputId);

          if (arr) arr.push(opt);
          else interIO.fromProduct.set(opt.configuratorInputId, [opt]);
        }
      }
    }

    //select options from product first and fall back with ones from its operations
    const inputOptions = new Map<InputUUID, ConfiguratorInputOption>([
      ...Array.from(iO.fromOperations.entries()),
      ...Array.from(iO.fromProduct.entries())
    ]);

    const interInputOptions = new Map<string, Array<ConfiguratorInputOption>>([
      ...Array.from(interIO.fromOperations.entries()),
      ...Array.from(interIO.fromProduct.entries())
    ]);

    interInputOptions.forEach((interIO, inputId, list) => {
      if (!inputOptions.has(inputId)) list.delete(inputId);
      else interIO.sort((a, b) => b.priority - a.priority);
    });

    return { iO: inputOptions, iIO: interInputOptions };
  }
);

const inputIsVisibleByUser = (
  userRoles: EachUserRole,
  options?: ConfiguratorInputOption | Partial<ConfiguratorInputOptionComputed>
): boolean => {
  if (!options) return false;
  if (options.visible && options.visibleExcludedRoles) {
    return !options.visibleExcludedRoles.some((role) => userRoles[role]);
  }
  return options.visible ?? false;
};

/**
 * Return list of input option computed for all know inputs for the given product & position in the configurator
 */
export const getConfiguratorInputOptionsResult = createSelector(
  getPropsProduct,
  ConfiguratorInputSelector.selectAll,
  getEachUserStandardRoleStatus,
  getConfiguratorInputOptionsProduct,
  getInputOptionsComputed,
  (product, inputs, userRoles, { iO }, iOComputed) => {
    return inputs.reduce((result, currInput) => {
      const inputOptionBase = iO.get(currInput.id);
      const inputOptionComputed = get(iOComputed, currInput.id);
      const precondition = inputOptionComputed?.precondition ?? false;
      const name = currInput.external
        ? (getExternalInputName(currInput.name) as ConfiguratorInputNames)
        : currInput.name;

      if (!product || !inputOptionBase) {
        result[currInput.name] = {
          linked: false,
          id: currInput.name,
          label: currInput.label,
          external: currInput.external,
          visible: false,
          visibleExcludedRoles: undefined,
          required: false,
          formula: "false",
          jsonLogicFormula: getJsonLogicFormulaAlways(false),
          interInputId: undefined,
          interInputFormula: undefined,
          precondition,
          name
        };
      } else {
        const inputOptionApplied = inputOptionComputed || inputOptionBase;

        //for debug purpose: keep base preconditions and add interInput formula
        const basePrecondition: Pick<
          ConfiguratorInputOptionComputed,
          "formula" | "jsonLogicFormula" | "interInputId" | "interInputFormula"
        > = {
          formula: inputOptionBase.formula,
          jsonLogicFormula:
            inputOptionBase.jsonLogicFormula || getJsonLogicFormulaAlways(false)
        };

        if (inputOptionApplied.id !== inputOptionBase.id) {
          basePrecondition.interInputId = inputOptionComputed?.id;
          basePrecondition.interInputFormula = inputOptionComputed?.formula;
        }

        result[currInput.name] = {
          linked: true,
          id: currInput.name,
          label: inputOptionApplied.label,
          helperText: inputOptionApplied.helperText,
          external: currInput.external,
          visible: inputIsVisibleByUser(userRoles, inputOptionApplied),
          visibleExcludedRoles: inputOptionApplied.visibleExcludedRoles,
          required: inputOptionApplied.required,
          options: inputOptionApplied.options,
          ...basePrecondition,
          precondition,
          name
        };
      }

      return result;
    }, {} as ConfiguratorInputOptionsResult);
  }
);

/**
 * Return the map of interInputOptions for the give product
 */
export const getInterInputOptionsResult = createSelector(
  getConfiguratorInputOptionsProduct,
  getEachUserStandardRoleStatus,
  ConfiguratorInputSelector.selectAll,
  ({ iIO }, userRoles, inputs): BriefElementInterInputOptionsComputed => {
    const map = new Map<InputName, Array<InterInputOptionComputed>>();

    iIO.forEach((interInputOptions, inputId) => {
      const currInput = inputs.find((i) => i.id === inputId);
      if (!currInput) return;

      const optionsComputed = interInputOptions.map((opt) => ({
        ...opt,
        visible: inputIsVisibleByUser(userRoles, opt),
        jsonLogicFormula:
          opt?.jsonLogicFormula || getJsonLogicFormulaAlways(false)
      }));

      map.set(currInput.name, optionsComputed);
    });

    return map;
  }
);

export const getConfiguratorInputOptionWithRelations = createSelector(
  AdapterSelector.selectAll,
  ConfiguratorInputSelector.selectAll,
  OperationSelector.selectAll,
  ProductSelector.selectAll,
  (options, inputs, operations, products) =>
    options.map((option) => {
      const operation = operations.find((o) => option.operationId === o.id);
      const configuratorInput = inputs.find(
        (i) => option.configuratorInputId === i.id
      );
      const product = products.find((p) => option.productId === p.id);
      return {
        ...option,
        operation,
        configuratorInput,
        product
      };
    })
);

export const getOperationAndProductByInputId = createSelector(
  getPropsFactory<string, "inputId">("inputId"),
  getPropsFactory<boolean | undefined, "interInput">("interInput"),
  AdapterSelector.selectAll,
  getOperationsOptions,
  getProductOptions,
  (
    inputId,
    interInput = false,
    configuratorInputOptions,
    operations,
    products
  ) => {
    if (!inputId) {
      return { productOptions: [], operationOptions: [] };
    }
    const inputOptions = configuratorInputOptions.filter(
      (opt) => opt.configuratorInputId === inputId
    );

    const productOptions = products.filter((product) =>
      interInput
        ? products
        : !inputOptions.map((opt) => opt.productId).includes(product.value)
    );
    const operationOptions = operations.filter((operation) =>
      interInput
        ? operation
        : !inputOptions.map((opt) => opt.operationId).includes(operation.value)
    );
    return { productOptions, operationOptions };
  }
);

// We try to get all inputs that's follow a product id or a operation id
// from configurator input options
// Used for create another input option
// Help for avoid no product or operation possible because
// all of them has been already taken
export const getInputsFilteredByConfiguratorInputOptions = createSelector(
  getPropsFactory<string | undefined, "operationId">("operationId"),
  getPropsFactory<string | undefined, "productId">("productId"),
  AdapterSelector.selectAll,
  ConfiguratorInputSelector.selectAll,
  OperationSelector.selectTotal,
  getTotalProduct,
  (
    operationId,
    productId,
    inputOptions,
    inputs,
    operationCount,
    productCount
  ) =>
    inputs
      .filter((input) => {
        const options = inputOptions.filter(
          (io) => io.configuratorInputId === input.id
        );

        if (
          (operationId && options.some((o) => o.operationId === operationId)) ||
          (productId && options.some((o) => o.productId === productId))
        ) {
          return false;
        }
        const operationOptions = options.filter((o) => !!o.operationId);

        const productOptions = options.filter((o) => !!o.productId);
        return !(
          operationOptions.length === operationCount &&
          productOptions.length === productCount
        );
      })
      .map((opt) => ({
        value: opt.id,
        label: opt.name
      }))
);

export const getBriefElementSectionPropsByName = createSelector(
  getPropsName,
  getPropsProduct,
  getBriefElementStateByPosition,
  getBriefElementSectionStateByName,
  getConfiguratorInputOptionsResult,
  (
    name,
    product,
    briefElementState,
    panelState,
    configuratorInputOptionResult
  ) => {
    const foldingBoxWithoutMaterialReference =
      !briefElementState?.productCategorySelected ||
      !briefElementState?.productSelected ||
      !(
        !!briefElementState?.values?.materialReferenceId ||
        !!briefElementState?.values?.corrugatedMaterialId
      );

    const sectionsProps = {
      [SectionNames.PACKAGING_TYPE]: { disabled: false },
      [SectionNames.MATERIALS]: {
        disabled:
          !briefElementState?.productCategorySelected ||
          !briefElementState?.productSelected
      },
      [SectionNames.DECORATIONS]: {
        disabled: foldingBoxWithoutMaterialReference
      },
      [SectionNames.FINISHES]: {
        disabled: foldingBoxWithoutMaterialReference
      },
      [SectionNames.PURCHASE_CONDITION]: {
        disabled: foldingBoxWithoutMaterialReference
      },
      [SectionNames.LOGISTICS]: { disabled: false },
      [SectionNames.DELIVERY]: { disabled: false },
      [SectionNames.ADDITIONAL]: { disabled: false }
    }[name as SectionNames];

    return {
      expanded: panelState?.isExpanded,
      hidden:
        product && panelState
          ? panelState.dependentProperties.filter(
              (depProp: any) =>
                // only based on preconditions
                (configuratorInputOptionResult[depProp]?.visible &&
                  !configuratorInputOptionResult[depProp].interInputId) ||
                ConfiguratorInputMandatory.includes(depProp)
            ).length === 0
          : false,
      ...sectionsProps
    };
  }
);
