import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { OperationActionsTypes } from "./action";
import * as Api from "./api";
import { Operation } from "./entity";

export interface OperationState extends EntityState<Operation> {
  readStatus: FetchingStatus;
  readOneStatus: FetchingStatus;
  updateStatus: FetchingStatus;
}

export const OperationInitialState: OperationState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  readOneStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL
};

export const OperationAdapter = createEntityAdapter<Operation>();

const OperationAdapterState = OperationAdapter.getInitialState(
  OperationInitialState
);

export const read = createMyAsyncThunk<Operation[]>(
  OperationActionsTypes.OPERATION_READ,
  Api.read
);

export const readOne = createMyAsyncThunk(
  OperationActionsTypes.OPERATION_READ_ONE,
  Api.readOneOperation
);

export const update = createMyAsyncThunk(
  OperationActionsTypes.OPERATION_UPDATE,
  Api.update,
  { onSuccessMessage: "saga:update-success" }
);

const OperationSlice = createMySlice({
  name: "operation",
  initialState: OperationAdapterState,
  adapter: OperationAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: OperationAdapter.upsertMany
    },
    {
      action: readOne,
      statusName: "readOneStatus",
      onSuccess: OperationAdapter.upsertOne
    },
    {
      action: update,
      statusName: "updateStatus",
      onSuccess: OperationAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const OperationReducer = OperationSlice.reducer;
export const OperationActions = {
  ...OperationSlice.actions,
  read,
  readOne,
  update
};
