import { AxiosError } from "axios";
import { Address, AddressWithCompany, Company } from "../../entities/company";
import { AppAddSnackbar } from "../../reducers/app/action";
import { store } from "../../reducers/store";
import { apiSlice } from "../../utils/api/api";
import { dynamicUrl } from "../../utils/function/dynamicUrl";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { PageMetaDtoCrm } from "../common/types";
import { transformCompany } from "./transform";
import {
  CompanyContextQuery,
  CompanyManaged,
  CompanyPagination,
  PageMetaDtoCompany
} from "./types";
import { User } from "../../entities/user";
import {
  AddCallDto,
  AddCommentDto,
  AddMeetingDto,
  AddVisioDto,
  Task,
  TaskPagination,
  UpdateCallDto,
  UpdateMeetingDto,
  UpdateVisioDto
} from "../tasks/type";
import { extendedApiSliceEvents } from "../events/events.service";
import { Comment } from "../comments/type";
import {
  setCustomFieldValues,
  transformErpWNestedCustomFields
} from "../common/utils";
import { EntityType } from "../events/type";
import { getInvalidatTagsCompany } from "./company.util";
import { CustomFieldValue } from "../customFields/customFieldValues/types";
import { MetaEntityHipe } from "../customFields/types";
import { Call } from "../call/type";
import { Meeting } from "../meeting/type";
import { Visio } from "../visio/type";
import { roundToFirstFrameOfMonth } from "../events/event.utils";
import { Event } from "@microsoft/microsoft-graph-types";
import { getInvalidatTagsContacts } from "../users/utils";
import { ContactContext } from "../users/types";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Company",
    "CompanyProjects",
    "CompanyHierarchy",
    "MyCompanies",
    "CompanyTasks",
    "CompanyAddresses"
  ]
});

export const companyApiSlice = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CompanyPagination, Partial<PageMetaDtoCrm>>({
      query: ({ page = 1, limit = 25, sort, userId, ...params }) => {
        return dynamicUrl(`/companies/pagination/`, {
          page,
          limit,
          sort,
          ...params
          /*  ...(getLocalEntityFilter(MetaEntityHipe.Company) && {
            filterId: getLocalEntityFilter(MetaEntityHipe.Company)
          }) */
        });
      },
      transformResponse: (responseData: CompanyPagination) => {
        const currentUser = store.getState().authentication.user;
        /* const userState = store.getState().users as UserState;
        const users = userState.users; */
        return transformCompany(responseData, currentUser);
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Company" as const,
                id
              })),
              { type: "Company", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Company", id: "PARTIAL-LIST" }]
    }),
    getMyCompanies: builder.query<CompanyPagination, Partial<PageMetaDtoCrm>>({
      query: ({ page = 1, limit = 25, sort, userId, ...params }) =>
        dynamicUrl(`/users/${userId}/my-companies`, {
          page,
          limit,
          sort,
          ...params
        }),
      transformResponse: (responseData: CompanyPagination) => {
        const currentUser = store.getState().authentication.user;
        /*  const userState = store.getState().users as UserState;
        const users = userState.users; */
        return transformCompany(responseData, currentUser);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "MyCompanies" as const,
                id
              })),
              { type: "MyCompanies", id: "PARTIAL-LIST" }
            ]
          : [{ type: "MyCompanies", id: "PARTIAL-LIST" }]
    }),

    getCompanyHierarchy: builder.query<
      CompanyPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({ page = 1, limit = 25, sort, companyId }) =>
        dynamicUrl(`/companies/${companyId}/hierarchy`, {
          page,
          limit,
          sort
        }),
      transformResponse: (responseData: CompanyPagination) => {
        const currentUser = store.getState().authentication.user;
        /*  const userState = store.getState().users as UserState;
        const users = userState.users; */
        return transformCompany(responseData, currentUser);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "CompanyHierarchy" as const,
                id
              })),
              { type: "CompanyHierarchy", id: "PARTIAL-LIST" }
            ]
          : [{ type: "CompanyHierarchy", id: "PARTIAL-LIST" }]
    }),

    getOneCompany: builder.query<Company, string>({
      query: (id) => `/companies/${id}`,
      providesTags: (result) => [{ type: "Company", id: result?.id }],
      transformResponse: (company: Company) => {
        return {
          ...company,
          ...transformErpWNestedCustomFields(company, EntityType.Companies),
          customFields: setCustomFieldValues(
            company?.customFields as CustomFieldValue[],
            MetaEntityHipe.Company
          ),
          rawCustomFields: company?.customFields as CustomFieldValue[]
        };
      }
    }),

    getCompanyAccounts: builder.query<User[], string>({
      query: (id) => `/companies/${id}/accounts`
    }),
    getRandomCompany: builder.query<Company, void>({
      query: () => `/companies/random`
    }),
    updateCompany: builder.mutation<
      Company,
      Partial<Company> & { context?: CompanyContextQuery }
    >({
      query(data) {
        const { id, context, ...patch } = data;
        return {
          url: `/companies/${id}`,
          method: "PATCH",
          body: { ...patch }
        };
      },
      invalidatesTags: (_, __, arg) => {
        if (!arg?.context)
          return [
            { type: "Company", id: arg.id },
            { type: "CompanyContacts", id: arg.id }
          ];
        return [
          { type: "Company", id: arg.id },
          ...(getInvalidatTagsCompany(arg.context) as any)
        ];
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n
                .t("saga:archived-failed", {
                  name: i18n.t("saga:update-failed")
                })
                .toString()
            )
          );
        }
      }
    }),
    archiveCompany: builder.mutation<Company, string>({
      query(id) {
        return {
          url: `/companies/${id}/archived `,
          method: "DELETE"
        };
      },
      invalidatesTags: () => [{ type: "Company", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(
              i18n
                .t("saga:archived-success", {
                  name: i18n.t("common:project")
                })
                .toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n
                .t("saga:archived-failed", { name: i18n.t("common:project") })
                .toString()
            )
          );
        }
      }
    }),
    addCompany: builder.mutation<
      Company,
      Partial<Company> & { context?: CompanyContextQuery }
    >({
      query({ context, ...data }) {
        return {
          url: `/companies`,
          method: "POST",
          body: { ...data }
        };
      },
      //invalidatesTags: () => [{ type: "Company", id: "PARTIAL-LIST" }],
      invalidatesTags: (_, __, arg) => {
        if (!arg?.context) return [];
        return getInvalidatTagsCompany(arg.context) as any;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const patchResult = dispatch(
          companyApiSlice.util.updateQueryData(
            "getCompanies",
            { page: 1 },
            (draft) => {
              Object.assign(draft, arg.name);
            }
          )
        );

        try {
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (err) {
          patchResult.undo();
        }
      }
    }),
    addManagedCompany: builder.mutation<
      Company,
      Partial<CompanyManaged> & { context?: CompanyContextQuery }
    >({
      query({ userId, context, ...data }) {
        return {
          url: `/users/${userId}/companies/managed`,
          method: "POST",
          body: { ...data }
        };
      },
      //invalidatesTags: () => [{ type: "Company", id: "PARTIAL-LIST" }],
      invalidatesTags: (_, __, arg) => {
        if (!arg?.context) return [];
        return getInvalidatTagsCompany(arg.context) as any;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const patchResult = dispatch(
          companyApiSlice.util.updateQueryData(
            "getCompanies",
            { page: 1 },
            (draft) => {
              Object.assign(draft, arg.name);
            }
          )
        );

        try {
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (err) {
          patchResult.undo();
        }
      }
    }),
    addCompanyComment: builder.mutation<Comment, AddCommentDto>({
      query(dto) {
        const { id, ...post } = dto;
        return {
          url: `/companies/${id}/comments`,
          method: "POST",
          body: post
        };
      },
      invalidatesTags: (comment, _, __) => {
        return [{ type: "Company", id: comment?.companyId as string }];
      },
      /*   invalidatesTags: (_, __, data) => {
        return [{ type: "Company", id: data?.id as string }];
      }, */
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: comment } = await queryFulfilled;
          //Update cache of all events by comments
          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEventsByComments`,
              { id: arg?.id },
              (draft: any) => {
                const update = [
                  {
                    ...comment?.events[0],
                    user: comment?.user,
                    comment: { ...comment },
                    task: comment?.task
                  }
                ];
                draft.data = [...update, ...draft?.data];
              }
            )
          );

          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    addCompanyCall: builder.mutation<Call, AddCallDto>({
      query(dto) {
        const { id, ...post } = dto;
        return {
          url: `/companies/${id}/calls`,
          method: "POST",
          body: post
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: call } = await queryFulfilled;
          //Update cache of all events by comments

          const month = new Date();
          const rounded = roundToFirstFrameOfMonth(month).toISOString();
          const path = `${arg?.id}_${rounded}`;

          const currentData = (store.getState() as any).api.queries[
            `getCompanyEvents("${path}")`
          ] as any;

          const currentMonth = (store.getState() as any).api.queries[
            `getCompanyEventMonths("${arg.id}")`
          ] as any;

          if (currentMonth[0] !== rounded) {
            dispatch(
              extendedApiSliceEvents.util.upsertQueryData(
                `getCompanyEventMonths`,
                { ...currentMonth?.originalArgs },
                [rounded, ...currentMonth.data]
              )
            );
          }

          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEvents`,
              { ...currentData?.originalArgs },
              (draft: any) => {
                const update = [
                  {
                    ...call?.events![0],
                    user: call?.user,
                    call: { ...call, contact: call?.contact }
                  }
                ];
                draft.data = [...update, ...draft?.data];
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    updateCompanyCall: builder.mutation<Call, Partial<UpdateCallDto>>({
      query(dto) {
        const { eventId, companyId, ...patch } = dto;
        return {
          url: `/companies/${companyId}/calls`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: call } = await queryFulfilled;
          //Update cache of all events by comments

          const month = new Date();
          const rounded = roundToFirstFrameOfMonth(month).toISOString();
          const path = `${arg?.companyId}_${rounded}`;
          const currentData = (store.getState() as any).api.queries[
            `getCompanyEvents("${path}")`
          ] as any;

          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEvents`,
              { ...currentData?.originalArgs },
              (draft: any) => {
                const updateCall = draft.data.find(
                  (item: Event) => item.id === arg?.eventId
                );

                updateCall.call = call;
                draft.data = draft.data.map((item: Event) =>
                  item.id === arg?.eventId ? updateCall : item
                );
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    addCompanyVisio: builder.mutation<Visio, AddVisioDto>({
      query(dto) {
        const { id, ...post } = dto;
        return {
          url: `/companies/${id}/visios`,
          method: "POST",
          body: post
        };
      },
      /*   invalidatesTags: (_, __, data) => {
        return [{ type: "Company", id: data?.id as string }];
      }, */
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: visio } = await queryFulfilled;

          const month = new Date();
          const rounded = roundToFirstFrameOfMonth(month).toISOString();
          const path = `${arg?.id}_${rounded}`;
          const currentData = (store.getState() as any).api.queries[
            `getCompanyEvents("${path}")`
          ] as any;

          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEvents`,
              { ...currentData?.originalArgs },
              (draft: any) => {
                const update = [
                  {
                    ...visio?.events![0],
                    user: visio?.user,
                    visio: { ...visio, contact: visio?.contact }
                  }
                ];
                draft.data = [...update, ...draft?.data];
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    updateCompanyVisio: builder.mutation<Visio, Partial<UpdateVisioDto>>({
      query(dto) {
        const { eventId, companyId, ...patch } = dto;
        return {
          url: `/companies/${companyId}/visios`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: visio } = await queryFulfilled;
          //Update cache of all events by comments

          const month = new Date();
          const rounded = roundToFirstFrameOfMonth(month).toISOString();
          const path = `${arg?.companyId}_${rounded}`;
          const currentData = (store.getState() as any).api.queries[
            `getCompanyEvents("${path}")`
          ] as any;

          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEvents`,
              { ...currentData?.originalArgs },
              (draft: any) => {
                const updateVisio = draft.data.find(
                  (item: Event) => item.id === arg?.eventId
                );

                updateVisio.visio = visio;
                draft.data = draft.data.map((item: Event) =>
                  item.id === arg?.eventId ? updateVisio : item
                );
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    addCompanyMeeting: builder.mutation<Meeting, AddMeetingDto>({
      query(dto) {
        const { id, ...post } = dto;
        return {
          url: `/companies/${id}/meetings`,
          method: "POST",
          body: post
        };
      },
      /*   invalidatesTags: (_, __, data) => {
        return [{ type: "Company", id: data?.id as string }];
      }, */
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: meeting } = await queryFulfilled;
          //Update cache of all events by comments
          const month = new Date();
          const rounded = roundToFirstFrameOfMonth(month).toISOString();
          const path = `${arg?.id}_${rounded}`;
          const currentData = (store.getState() as any).api.queries[
            `getCompanyEvents("${path}")`
          ] as any;

          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEvents`,
              { ...currentData?.originalArgs },
              (draft: any) => {
                const update = [
                  {
                    ...meeting?.events![0],
                    user: meeting?.user,
                    meeting: { ...meeting, contact: meeting?.contact }
                  }
                ];
                draft.data = [...update, ...draft?.data];
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    updateCompanyMeeting: builder.mutation<Meeting, Partial<UpdateMeetingDto>>({
      query(dto) {
        const { eventId, companyId, ...patch } = dto;
        return {
          url: `/companies/${companyId}/meetings`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: meeting } = await queryFulfilled;
          //Update cache of all events by comments

          const month = new Date();
          const rounded = roundToFirstFrameOfMonth(month).toISOString();
          const path = `${arg?.companyId}_${rounded}`;
          const currentData = (store.getState() as any).api.queries[
            `getCompanyEvents("${path}")`
          ] as any;

          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getCompanyEvents`,
              { ...currentData?.originalArgs },
              (draft: any) => {
                const updateMeeting = draft.data.find(
                  (item: Event) => item.id === arg?.eventId
                );

                updateMeeting.meeting = meeting;
                draft.data = draft.data.map((item: Event) =>
                  item.id === arg?.eventId ? updateMeeting : item
                );
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    getCompanyCoordinates: builder.query<Company[], string>({
      query: (id) => `/companies/coordinates?id=${id}`
    }),
    getTasksByCompanyId: builder.query<
      TaskPagination,
      Partial<PageMetaDtoCompany>
    >({
      query: ({
        page = 1,
        limit = 25,
        status,
        sort,
        search,
        user,
        taskType,
        date,
        start,
        end,
        companyId,
        filterId
      }) =>
        dynamicUrl(`/companies/${companyId}/tasks`, {
          page,
          limit,
          sort,
          status,
          user,
          taskType,
          date,
          search,
          start,
          end,
          filterId
        }),
      providesTags: (_, __, { companyId }) => [
        { type: "CompanyTasks", id: companyId as string }
      ]
    }),
    createCompanyTask: builder.mutation<Task, Partial<Task>>({
      query(dto) {
        return {
          url: "/tasks",
          method: "POST",
          body: dto
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "CompanyTasks", id: data?.companyId as string },
        { type: "Company", id: data?.companyId as string }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    getCompanyAddress: builder.query<Address[], string>({
      query: (id) => `/companies/${id}/addresses`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "CompanyAddresses" as const,
                id
              })),
              { type: "CompanyAddresses", id: "PARTIAL-LIST" }
            ]
          : [{ type: "CompanyAddresses", id: "PARTIAL-LIST" }]
    }),
    updateCompanyAddress: builder.mutation<
      Address,
      Partial<AddressWithCompany>
    >({
      query(address) {
        return {
          url: `/companies/${address.companyId}/addresses`,
          method: "PATCH",
          body: address
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "CompanyAddresses", id: data?.id as string },
        { type: "CompanyAddresses", id: "PARTIAL-LIST" },
        { type: "Company", id: data?.companyId as string }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    createCompanyAddresses: builder.mutation<
      Address[],
      {
        addresses: Partial<Address>[];
        companyId: string;
      }
    >({
      query({ addresses, companyId }) {
        return {
          url: `/companies/${companyId}/addresses`,
          method: "POST",
          body: addresses
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "CompanyAddresses", id: "PARTIAL-LIST" },
        { type: "Company", id: data?.companyId as string }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    })
  }),

  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetCompanyAccountsQuery,
  useGetMyCompaniesQuery,
  useGetCompanyHierarchyQuery,
  useUpdateCompanyMutation,
  useArchiveCompanyMutation,
  useAddManagedCompanyMutation,
  useAddCompanyMutation,
  usePrefetch: usePrefetchCompanies,
  useAddCompanyCommentMutation,
  useGetCompaniesQuery,
  useGetOneCompanyQuery,
  useLazyGetOneCompanyQuery,
  useLazyGetRandomCompanyQuery,
  useGetCompanyCoordinatesQuery,
  useAddCompanyCallMutation,
  useUpdateCompanyCallMutation,
  useAddCompanyVisioMutation,
  useUpdateCompanyVisioMutation,
  useAddCompanyMeetingMutation,
  useUpdateCompanyMeetingMutation,
  useGetTasksByCompanyIdQuery,
  useCreateCompanyTaskMutation,
  useUpdateCompanyAddressMutation,
  useGetCompanyAddressQuery,
  useCreateCompanyAddressesMutation
} = companyApiSlice;

export const selectCompanies = (): any =>
  companyApiSlice.endpoints.getCompanies.select({ page: 1, limit: 25 });
