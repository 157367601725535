import { createSelector } from "reselect";
import { RootState } from "..";
import { getIsPackitooUser } from "../authentication/selector";

export const getIsDebug = createSelector(
  (state: RootState) => state,
  (state) => state?.appSettings?.setting.features?.isDebug || false
);

export const getIsDebugPackitoo = createSelector(
  getIsDebug,
  getIsPackitooUser,
  (isDebug, isPackitoo) => isDebug && isPackitoo
);

export const getIsCofn = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.features?.isCollectionOfNeeds || false
);

export const getIsConfigurator = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.features?.isConfigurator || false
);

export const getProjectDocumentTab = createSelector(
  getIsCofn,
  getIsConfigurator,
  (isCofn, isConfigurator) =>
    isCofn && isConfigurator
      ? "3"
      : (isCofn && !isConfigurator) || (!isCofn && isConfigurator)
      ? "2"
      : "1"
);

export const getCofnTab = createSelector(
  getIsCofn,
  getIsConfigurator,
  (isCofn, isConfigurator) =>
    isCofn && !isConfigurator ? "1" : isConfigurator && isCofn ? "2" : undefined
);

export const getConfiguratorTab = createSelector(
  getIsCofn,
  getIsConfigurator,
  (isCofn, isConfigurator) => (isConfigurator ? "1" : undefined)
);

export const getIsCustomFields = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.features?.isCustomFields || false
);

export const getLocalisations = createSelector(
  (state: RootState) => state,
  (state) => state.appSettings.setting.localisations || []
);

export const getAllEnabledLocalisations = createSelector(
  getLocalisations,
  (localisations) => localisations.filter((l) => l.enable)
);

export const getDefaultLocalisation = createSelector(
  getLocalisations,
  (localisations) => localisations.find((l) => l.default)
);
