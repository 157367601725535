import { AxiosError } from "axios";
import { AppAddSnackbar } from "../../reducers/app/action";
import { store } from "../../reducers/store";
import { apiSlice } from "../../utils/api/api";
import { dynamicQueryCursorUrlClean } from "../../utils/function/dynamicUrlCursor";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { CursorBasedMetaDto, CursorPagination } from "../common/types";
import { Followers } from "./followers.type";
import { projectApiSlice } from "../project/project.service";

const extendedApiSlice: any = apiSlice
  .enhanceEndpoints({
    addTagTypes: ["Followers"]
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyFollowing: build.query<
        CursorPagination<Followers>,
        Partial<CursorBasedMetaDto>
      >({
        query: ({ limit = 10, cursor }) =>
          dynamicQueryCursorUrlClean(`followers/me`, {
            limit,
            cursor
          }),
        keepUnusedDataFor: 0,
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}("${queryArgs.userId}")`;
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        merge: (currentCache, newItems, args) => {
          if (!args?.arg.cursor) {
            return currentCache;
          }
          currentCache.data.push(...newItems.data);
          currentCache.meta = {
            ...newItems.meta
          };
        },
        providesTags: (_, __, { userId }) => [{ type: "Followers", id: userId }]
      }),
      follow: build.mutation<boolean, Partial<Followers>>({
        query: (post) => ({
          url: `followers/follow`,
          method: "POST",
          body: post
        }),
        invalidatesTags: (_, __, ___) => {
          const currentUser = store.getState().authentication.user;
          return [{ type: "Followers", id: currentUser.id }];
        },
        async onQueryStarted(arg, { dispatch }) {
          try {
            dispatch(
              new AppAddSnackbar(
                i18n.t("saga:update-success").toString(),
                "success"
              )
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed").toString()
              )
            );
          }
        }
      }),
      unFollow: build.mutation<boolean, Partial<Followers>>({
        query: ({ id }) => ({
          url: `followers/${id}/unfollow`,
          method: "DELETE"
        }),
        invalidatesTags: (_, __, ___) => {
          const currentUser = store.getState().authentication.user;
          return [{ type: "Followers", id: currentUser.id }];
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const currentUser = store.getState().authentication.user;

            if (window.location.pathname.includes("projects")) {
              const projectId = window.location.pathname.split("/").pop();
              projectApiSlice.util.invalidateTags([
                "Project",
                { id: projectId } as any
              ]);
              /*  projectApiSlice.util.invalidateTags(
                getInvalidatTagsProjects(
                  ProjectContext.PROJECT,
                  projectId
                ) as any
              ); */
            }

            dispatch(
              extendedApiSlice.util.updateQueryData(
                `getMyFollowing`,
                { userId: currentUser.id },
                (draft: any) => {
                  const index = draft.data.findIndex(
                    (f: Followers) => f.id === arg.id
                  );
                  draft.data.splice(index, 1);
                }
              )
            );

            dispatch(
              new AppAddSnackbar(
                i18n.t("saga:update-success").toString(),
                "success"
              )
            );
          } catch (error) {
            dispatch(
              sendErrorNotification(
                error as AxiosError,
                i18n.t("saga:update-failed").toString()
              )
            );
          }
        }
      })
    }),
    overrideExisting: true
  });

// injectEndpoints to avoid duplicate slice
export const {
  useGetMyFollowingQuery,
  useUnFollowMutation,
  useFollowMutation
} = extendedApiSlice;
