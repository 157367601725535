import * as Sentry from "@sentry/react";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { BriefWithRelations } from "../../entities/brief";
import { PredictionRequest } from "../../entities/predictionRequest";
import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import { updateBriefByIdWithRelations } from "../briefs/saga";
import { getBriefSelected } from "../briefs/selector";
import {
  PredictionRequestActionsTypes,
  PredictionRequestStatus,
  PredictionRequestUpdate,
  PredictionRequestUpdateStatus
} from "./action";
import * as Api from "./api";
import { extendedBriefApiSlice } from "../../services/brief/briefs";

export function* fetchPredictionRequests() {
  yield put(new PredictionRequestStatus(FetchingStatus.PENDING));
  try {
    const { data: predictionRequests } = yield call(
      Api.fetchPredictionRequests
    );
    yield put(
      new PredictionRequestStatus(FetchingStatus.SUCCESS, predictionRequests)
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("predictionRequests:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new PredictionRequestStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("predictionRequests:fetchFailed"))
    );
  }
}

export function* updatePredictionRequest(action: PredictionRequestUpdate) {
  yield put(new PredictionRequestUpdateStatus(FetchingStatus.PENDING));
  try {
    const { data: predictionRequest }: { data: PredictionRequest } = yield call(
      Api.updatePredictionRequest,
      action.predictionRequest
    );
    const briefSelected: BriefWithRelations | undefined = yield select(
      getBriefSelected
    );
    if (briefSelected && briefSelected.id === predictionRequest.briefId) {
      yield call(updateBriefByIdWithRelations, briefSelected.id);
    }
    yield put(
      new PredictionRequestUpdateStatus(
        FetchingStatus.SUCCESS,
        predictionRequest
      )
    );
    yield put(
      extendedBriefApiSlice.util.invalidateTags([
        { type: "Brief", id: predictionRequest?.briefId },
        { type: "BriefDetails", id: predictionRequest?.briefId },
        { type: "BriefValueAnalysis", id: predictionRequest?.briefId }
      ])
    );
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("predictionRequests:updateFailed");
      Sentry.captureException(error);
    });
    yield put(new PredictionRequestUpdateStatus(FetchingStatus.FAILED));
    yield put(
      sendErrorNotification(error, i18n.t("predictionRequests:updateFailed"))
    );
  }
}

export const PredictionRequestSaga = [
  takeLatest(
    PredictionRequestActionsTypes.PREDICTION_REQUEST_FETCH,
    fetchPredictionRequests
  ),
  takeLatest(
    PredictionRequestActionsTypes.PREDICTION_REQUEST_UPDATE,
    updatePredictionRequest
  )
];
