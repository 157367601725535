import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CorrugatedMaterialPriceActionsTypes } from "./action";
import * as Api from "./api";
import {
  CorrugatedMaterialPrice,
  CorrugatedMaterialPriceCreateManyDTO
} from "./entity";

export interface CorrugatedMaterialPriceState
  extends EntityState<CorrugatedMaterialPrice> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
  createManyStatus: FetchingStatus;
}

export const CorrugatedMaterialPriceInitialState: CorrugatedMaterialPriceState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL,
  createManyStatus: FetchingStatus.NULL
};

export const CorrugatedMaterialPriceAdapter = createEntityAdapter<CorrugatedMaterialPrice>();

const CorrugatedMaterialPriceAdapterState = CorrugatedMaterialPriceAdapter.getInitialState(
  CorrugatedMaterialPriceInitialState
);

export const read = createMyAsyncThunk<CorrugatedMaterialPrice[]>(
  CorrugatedMaterialPriceActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  CorrugatedMaterialPriceActionsTypes.CREATE,
  Api.createOne,
  { onSuccessMessage: "saga:create-success" }
);

export const update = createMyAsyncThunk(
  CorrugatedMaterialPriceActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

export const deleteOne = createMyAsyncThunk<CorrugatedMaterialPrice[], string>(
  CorrugatedMaterialPriceActionsTypes.DELETE,
  Api.deleteOne,
  { onSuccessMessage: "saga:delete-success" }
);

export const createMany = createMyAsyncThunk<
  CorrugatedMaterialPrice[],
  CorrugatedMaterialPriceCreateManyDTO
>(CorrugatedMaterialPriceActionsTypes.CREATE_MANY, Api.createMany, {
  onSuccessMessage: "saga:create-success"
});

const CorrugatedMaterialPriceSlice = createMySlice({
  name: "corrugated-material-prices",
  initialState: CorrugatedMaterialPriceAdapterState,
  adapter: CorrugatedMaterialPriceAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: CorrugatedMaterialPriceAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: CorrugatedMaterialPriceAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: CorrugatedMaterialPriceAdapter.upsertOne
    },
    {
      action: deleteOne,
      statusName: "deleteStatus",
      onSuccess: (state, payload) => {
        CorrugatedMaterialPriceAdapter.removeOne(state, payload.meta.arg);
      }
    },
    {
      action: createMany,
      statusName: "createStatus",
      onSuccess: CorrugatedMaterialPriceAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const CorrugatedMaterialPriceReducer =
  CorrugatedMaterialPriceSlice.reducer;
export const CorrugatedMaterialPriceActions = {
  ...CorrugatedMaterialPriceSlice.actions,
  read,
  create,
  update,
  deleteOne,
  createMany
};
