import React from "react";
import { Box, lighten, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

interface MyAlertProps {
  severity: "success" | "warning" | "info" | "error";
  icon?: React.ElementType;
  transparentBackground?: boolean;
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: lighten(theme.palette.success.light, 0.9)
    },
    warning: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9)
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.9)
    },
    info: {
      backgroundColor: lighten(theme.palette.info.light, 0.9)
    },
    transparent: {
      backgroundColor: "transparent"
    }
  })
);

export const MyAlert: React.FC<MyAlertProps> = ({
  severity = "info",
  icon = InfoOutlinedIcon,
  children,
  transparentBackground
}) => {
  const classes = useStyles();
  return (
    <Box
      p={{ xs: 1, md: 2 }}
      borderRadius={8}
      mb={2}
      display="flex"
      alignItems="center"
      flex="1"
      className={
        transparentBackground ? classes.transparent : classes[severity]
      }
    >
      <Box component={icon} mr={2} color={`${severity}.main`} />
      {children}
    </Box>
  );
};
