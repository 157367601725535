import { Menu } from "@material-ui/core";
import { MenuProps } from "@material-ui/core/Menu";
import React, { FunctionComponentElement, ReactNode } from "react";

export interface MyMenuProps extends Omit<MenuProps, "open"> {
  component: FunctionComponentElement<any>;
  id: string;
  children: (props: { onClose: () => void }) => ReactNode;
}

export const MyMenu: React.FC<MyMenuProps> = ({
  component,
  id,
  children,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {React.cloneElement(component, { onClick: handleClick })}
      <Menu
        data-testid="menu"
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...props}
        keepMounted={props.keepMounted ?? true}
      >
        {children({ onClose: handleClose })}
      </Menu>
    </>
  );
};
