import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import i18n from "../../../utils/i18n";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../../utils/request/error_handler";
import { LaminationActionsTypes, LaminationFetchStatus } from "./action";
import * as Api from "./api";

export function* fetchLaminations() {
  yield put(new LaminationFetchStatus(FetchingStatus.PENDING));
  try {
    const { data: laminations } = yield call(Api.fetchLaminations);
    yield put(new LaminationFetchStatus(FetchingStatus.SUCCESS, laminations));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("laminations:fetchFailed");
      Sentry.captureException(error);
    });
    yield put(new LaminationFetchStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("laminations:fetchFailed")));
  }
}

export const LaminationSaga = [
  takeLatest(LaminationActionsTypes.LAMINATION_FETCH, fetchLaminations)
];
