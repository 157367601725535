import * as localesFns from "date-fns/locale";
import * as localesMaterial from "@material-ui/core/locale";
import { dateFormatDictionary } from "../../components/common/form/constant";

/**
 * Returns the date format based on the specified language.
 * @param lang The language identifier.
 * @param withTime Optional parameter to include time format.
 * @returns The formatted date string.
 */
export const getFormatByLang = (lang: string, withTime?: boolean) => {
  const timeFormat = withTime ? "HH:mm" : "";
  const dateFormat = dateFormatDictionary[lang] || "yyyy/MM/dd";
  return `${dateFormat} ${timeFormat}`;
};

export const getLocaleForFns = (lang: string) => {
  switch (lang) {
    case "en-US":
      return localesFns.enUS;
    case "fr-FR":
      return localesFns.fr;
    case "es-ES":
      return localesFns.es;
    case "de-DE":
      return localesFns.de;
    case "it-IT":
      return localesFns.it;
    case "pt-PT":
      return localesFns.pt;
    case "nl-NL":
      return localesFns.nl;
    case "pl-PL":
      return localesFns.pl;
    case "ru-RU":
      return localesFns.ru;
    case "tr-TR":
      return localesFns.tr;
    case "zh-CN":
      return localesFns.zhCN;
    case "ja-JP":
      return localesFns.ja;
    case "ko-KR":
      return localesFns.ko;
    case "ar-SA":
      return localesFns.arSA;
    case "hi-IN":
      return localesFns.hi;
    case "th-TH":
      return localesFns.th;
    case "vi-VN":
      return localesFns.vi;
    case "id-ID":
      return localesFns.id;
    case "ms-MY":
      return localesFns.ms;
    case "hu-HU":
      return localesFns.hu;
    default:
      return localesFns.enUS;
  }
};

export const getLocaleForMaterial = (lang: string) => {
  switch (lang) {
    case "en-US":
      return localesMaterial.enUS;
    case "fr-FR":
      return localesMaterial.frFR;
    case "es-ES":
      return localesMaterial.esES;
    case "de-DE":
      return localesMaterial.deDE;
    case "it-IT":
      return localesMaterial.itIT;
    case "pt-PT":
      return localesMaterial.ptPT;
    case "nl-NL":
      return localesMaterial.nlNL;
    case "pl-PL":
      return localesMaterial.plPL;
    case "ru-RU":
      return localesMaterial.ruRU;
    case "tr-TR":
      return localesMaterial.trTR;
    case "zh-CN":
      return localesMaterial.zhCN;
    case "ja-JP":
      return localesMaterial.jaJP;
    case "ko-KR":
      return localesMaterial.koKR;
    case "hi-IN":
      return localesMaterial.hiIN;
    case "vi-VN":
      return localesMaterial.viVN;
    case "id-ID":
      return localesMaterial.idID;
    case "hu-HU":
      return localesMaterial.huHU;
    default:
      return localesMaterial.enUS;
  }
};
