import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import { DialogActionsProps } from "@material-ui/core/DialogActions";
import { DialogContentProps } from "@material-ui/core/DialogContent";
import { DialogTitleProps } from "@material-ui/core/DialogTitle";
import Grow from "@material-ui/core/Grow";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import React, { forwardRef, ReactNode, useEffect, useState } from "react";

export interface MyDialogProps extends DialogProps {
  titleLabel?: string | ReactNode;
  titleIcon?: ReactNode;
  titleComponent?: ReactNode;
  titleContent?: ReactNode;
  titleProps?: DialogTitleProps;
  content?: ReactNode;
  contentProps?: DialogContentProps;
  contentComponent?: ReactNode;
  actionsContent?: ReactNode;
  actionsProps?: DialogActionsProps;
  actionsComponent?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
        2
      )}px`
    },
    icon: {
      fontSize: 24,
      marginBottom: 4
    },
    closeIcon: {
      padding: 0
    },
    dialogTitle: {
      fontSize: 19,
      fontWeight: "bold",
      paddingBottom: 0,
      paddingTop: 0
    },
    dialogContent: {},
    dialogAction: {
      justifyContent: "space-between"
    }
  })
);

const GrowTransition = forwardRef((props: TransitionProps, ref) => (
  <Grow ref={ref} {...props} />
));
export const MyDialog: React.FC<MyDialogProps> = ({
  open,
  titleLabel,
  titleIcon,
  titleContent,
  titleProps,
  titleComponent,
  content,
  contentComponent,
  contentProps,
  actionsComponent,
  actionsProps,
  actionsContent,
  onClose,
  ...rest
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open !== undefined && open !== openDialog) {
      setOpenDialog(open);
    }
  }, [open, openDialog]);

  const onCloseDialog: DialogProps["onClose"] = (event, reason) => {
    if (onClose) {
      onClose(event, reason);
      setOpenDialog(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={GrowTransition}
      transitionDuration={300}
      fullScreen={fullScreen}
      open={openDialog}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{ classes: { root: classes.dialogPaper } }}
      onClose={onClose}
      {...rest}
    >
      {titleComponent || (
        <DialogTitle
          disableTypography
          className={classes.dialogTitle}
          {...titleProps}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={2}
          >
            <Box display="flex" alignItems="center">
              {titleContent || (
                <>
                  {titleIcon && (
                    <Box clone mr={1} fontSize={24}>
                      {titleIcon}
                    </Box>
                  )}
                  {typeof titleLabel === "string" ? (
                    <Typography color="primary" variant="h6">
                      {titleLabel}
                    </Typography>
                  ) : (
                    <>{titleLabel}</>
                  )}
                </>
              )}
            </Box>
            {onClose && (
              <IconButton
                onClick={(e) => onCloseDialog(e, "escapeKeyDown")}
                className={classes.closeIcon}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
      )}
      {contentComponent || (
        <DialogContent className={classes.dialogContent} {...contentProps}>
          {content}
        </DialogContent>
      )}
      {actionsComponent ||
        (actionsContent && (
          <DialogActions {...actionsProps}>{actionsContent}</DialogActions>
        ))}
    </Dialog>
  );
};
