import { ProductCategory } from "../../entities/productCategory";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";

export enum ProductCategoryActionsTypes {
  PRODUCT_CATEGORY_FETCH = "@@PRODUCT_CATEGORY fetch product category",
  PRODUCT_CATEGORY_FETCH_STATUS = "@@PRODUCT_CATEGORY status product category",
  PRODUCT_CATEGORY_ADD = "@@PRODUCT_CATEGORY add product category"
}

export class ProductCategoryFetch {
  readonly type = ProductCategoryActionsTypes.PRODUCT_CATEGORY_FETCH;
}
export class ProductCategoryFetchStatus {
  readonly type = ProductCategoryActionsTypes.PRODUCT_CATEGORY_FETCH_STATUS;
  constructor(
    public status: FetchingStatus,
    public productsCategory?: ProductCategory[]
  ) {}
}
export class ProductCategoryAdd {
  readonly type = ProductCategoryActionsTypes.PRODUCT_CATEGORY_ADD;
  constructor(public productCategory: ProductCategory) {}
}

export type ProductCategoryActions =
  | ProductCategoryFetch
  | ProductCategoryFetchStatus
  | ProductCategoryAdd;
