import {
  QueryDefinition,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from "@reduxjs/toolkit/dist/query";
import {
  Company,
  CompanyWithProjectCollaboraters
} from "../../entities/company";
import { UseQueryStateDefaultResult } from "../../utils/hooks/useQuery";
import { PageMetaDto } from "../common/types";
import i18n from "../../utils/i18n";

export interface CompanyPagination {
  meta: PageMetaDto;
  data: CompanyWithProjectCollaboraters[];
}

export interface CompanyManaged extends Company {
  userId: string;
}

export interface PageMetaDtoCompany extends PageMetaDto {
  companyId: string;
}

export type resultCompany = UseQueryStateDefaultResult<
  QueryDefinition<
    Partial<PageMetaDto>,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      Record<string, never>,
      FetchBaseQueryMeta
    >,
    "Company",
    CompanyPagination,
    "api"
  >
>;

export interface CompanyCoordinates {
  main: Company[];
  edges: Company[];
}

export enum CompanyContextQuery {
  MY_COMPANIES = "getMyCompanies",
  COMPANIES = "getCompanies",
  COMPANY_HIERARCHY = "getCompanyHierarchy"
}

export enum ParentCompany {
  "WITH_PARENT" = "withParent",
  "WITH_CHILD" = "withChild"
}

export interface CompanyFilter {
  hierarchy?: string;
  filterId?: string;
  createdAt?: string;
  updatedAt?: string;
  manager?: string;
  start?: string | Date;
  end?: string | Date;
  updateStart?: string | Date;
  updateEnd?: string | Date;
}

export enum CompanyActivityEventsTypeEnum {
  CALL = "CALL",
  VISIO = "VISIO",
  MEETING = "MEETING",
  EMAIL = "EMAIL",
  PROJECT_CREATION = "PROJECT_CREATION",
  PROJECT_NAME = "PROJECT_NAME",
  PROJECT_DUE_DATE = "PROJECT_DUE_DATE",
  PROJECT_ERP = "PROJECT_ERP",
  PROJECT_STATUS = "PROJECT_STATUS",
  PROJECT_NOTES = "PROJECT_NOTES",
  PROJECT_MANAGER = "PROJECT_MANAGER",
  PROJECT_PIPELINE = "PROJECT_PIPELINE",
  PROJECT_STEP = "PROJECT_STEP",
  TASK_CREATION = "TASK_CREATION",
  TASK_NAME = "TASK_NAME",
  TASK_DUE_DATE = "TASK_DUE_DATE",
  TASK_STATUS = "TASK_STATUS",
  TASK_NOTES = "TASK_NOTES",
  COMPANY_NAME = "COMPANY_NAME",
  COMPANY_ERP = "COMPANY_ERP",
  COMPANY_CREATION = "COMPANY_CREATION",
  COMPANY_MANAGER = "COMPANY_MANAGER",
  COMPANY_CONTACT = "COMPANY_CONTACT",
  COMPANY_NOTES = "COMPANY_NOTES",
  COMPANY_PROJECTS = "COMPANY_PROJECTS",
  COMPANY_TASKS = "COMPANY_TASKS"
}

export const initialCompanyFilters: CompanyFilter = {
  hierarchy: undefined,
  filterId: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  manager: undefined
};

const projectActivityOptions = (externalProjectLabel?: string) => {
  return (
    [
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_CREATION,
        label: i18n.t(
          "backoffice:companies.activities.filters.project.creation"
        )
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_NAME,
        label: i18n.t("backoffice:companies.activities.filters.project.name")
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_DUE_DATE,
        label: i18n.t("backoffice:companies.activities.filters.project.dueDate")
      },
      /*  {
      value: "project_estimatedValues",
      label: i18n.t(
        "backoffice:companies.activities.filters.project.estimatedValues"
      )
    }, */
      {
        value: externalProjectLabel
          ? CompanyActivityEventsTypeEnum.PROJECT_ERP
          : undefined,
        label: i18n.t("backoffice:companies.activities.filters.project.erp", {
          externalProjectLabel
        })
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_STATUS,
        label: i18n.t("backoffice:companies.activities.filters.project.status")
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_NOTES,
        label: i18n.t("backoffice:companies.activities.filters.project.notes")
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_MANAGER,
        label: i18n.t("backoffice:companies.activities.filters.project.manager")
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_PIPELINE,
        label: i18n.t(
          "backoffice:companies.activities.filters.project.pipeline"
        )
      },
      {
        value: CompanyActivityEventsTypeEnum.PROJECT_STEP,
        label: i18n.t("backoffice:companies.activities.filters.project.step")
      }
    ]
      // filter empty values
      .filter((option) => option?.value)
  );
};

const taskActivityOptions = () => {
  return [
    {
      value: CompanyActivityEventsTypeEnum.TASK_CREATION,
      label: i18n.t("backoffice:companies.activities.filters.task.creation")
    },
    {
      value: CompanyActivityEventsTypeEnum.TASK_NAME,
      label: i18n.t("backoffice:companies.activities.filters.task.name")
    },
    {
      value: CompanyActivityEventsTypeEnum.TASK_DUE_DATE,
      label: i18n.t("backoffice:companies.activities.filters.task.dueDate")
    },
    {
      value: CompanyActivityEventsTypeEnum.TASK_STATUS,
      label: i18n.t("backoffice:companies.activities.filters.task.status")
    },
    {
      value: CompanyActivityEventsTypeEnum.TASK_NOTES,
      label: i18n.t("backoffice:companies.activities.filters.task.notes")
    }
  ];
};

const companyActivityOptions = (externalCompanyLabel?: string) => {
  return [
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_CREATION,
      label: i18n.t("backoffice:companies.activities.filters.company.creation")
    },
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_NAME,
      label: i18n.t("backoffice:companies.activities.filters.company.name")
    },
    {
      value: externalCompanyLabel
        ? CompanyActivityEventsTypeEnum.COMPANY_ERP
        : undefined,
      label: i18n.t("backoffice:companies.activities.filters.company.erp", {
        externalCompanyLabel
      })
    },
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_MANAGER,
      label: i18n.t("backoffice:companies.activities.filters.company.manager")
    },
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_CONTACT,
      label: i18n.t("backoffice:companies.activities.filters.company.contact")
    },
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_NOTES,
      label: i18n.t("backoffice:companies.activities.filters.company.notes")
    },
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_PROJECTS,
      label: i18n.t("backoffice:companies.activities.filters.company.projects")
    },
    {
      value: CompanyActivityEventsTypeEnum.COMPANY_TASKS,
      label: i18n.t("backoffice:companies.activities.filters.company.tasks")
    }
  ].filter((option) => option?.value);
};

const companyScheduleOptions = () => {
  return [
    {
      value: CompanyActivityEventsTypeEnum.EMAIL,
      label: i18n.t("backoffice:companies.activities.filters.actions.email")
    },
    {
      value: CompanyActivityEventsTypeEnum.MEETING,
      label: i18n.t("backoffice:companies.activities.filters.actions.meeting")
    },
    {
      value: CompanyActivityEventsTypeEnum.VISIO,
      label: i18n.t("backoffice:companies.activities.filters.actions.visio")
    },
    {
      value: CompanyActivityEventsTypeEnum.CALL,
      label: i18n.t("backoffice:companies.activities.filters.actions.call")
    }
  ];
};

export const companyGroupedActivityOptions = (
  externalCompanyLabel: string,
  externalProjectLabel: string,
  lang: string
) => {
  return [
    {
      label: i18n.t("backoffice:companies.activities.filters.actions.title"),
      // Has to be functions
      options: companyScheduleOptions()
    },
    {
      label: i18n.t("backoffice:menu.projects"),
      options: projectActivityOptions(externalProjectLabel)
    },
    {
      label: i18n.t("backoffice:menu.tasks"),
      // Has to be functions
      options: taskActivityOptions()
    },
    {
      label: i18n.t("backoffice:menu.companies"),
      options: companyActivityOptions(externalCompanyLabel)
    }
  ];
};

export interface CompanyActivityFilter {
  eventType?: CompanyActivityEventsTypeEnum | CompanyActivityEventsTypeEnum[];
  start?: string | Date;
  end?: string | Date;
}

export const intialCompanyActivityFilters: CompanyActivityFilter = {
  eventType: undefined
};
