import produce from "immer";

import { MaterialReference } from "../../entities/materialReference";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import {
  MaterialReferenceActions,
  MaterialReferenceActionsTypes
} from "./action";
import { ReducerMethods } from "../../utils/reducers/methods";

export interface MaterialReferenceState {
  materialReferences: MaterialReference[];
  createStatus: FetchingStatus;
  readStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
  readOneStatus: FetchingStatus;
}

const initialState: MaterialReferenceState = {
  materialReferences: [],
  createStatus: FetchingStatus.NULL,
  readStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL,
  readOneStatus: FetchingStatus.NULL
};

export const MaterialReferenceReducer = (
  state = initialState,
  action: MaterialReferenceActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_CREATE_STATUS:
        draft.createStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.pushUniqueByMutate(
            draft.materialReferences,
            action.materialReference as MaterialReference
          );
        break;

      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ_STATUS:
        draft.readStatus = action.status;
        draft.materialReferences =
          action.materialReferences || state.materialReferences;
        break;

      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_UPDATE_STATUS:
        draft.updateStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.materialReferences,
            action.materialReference as MaterialReference
          );
        break;

      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_DELETE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.removeByIdMutate(
            draft.materialReferences,
            action.materialReference as MaterialReference
          );
        break;

      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ_ONE_STATUS:
        draft.readStatus = action.status;
        action.status === FetchingStatus.SUCCESS &&
          ReducerMethods.upsertByIdMutate(
            draft.materialReferences,
            action.materialReference as MaterialReference
          );
        break;

      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_ADD:
        ReducerMethods.upsertByIdMutate(
          draft.materialReferences,
          action.materialReference as MaterialReference
        );
        break;

      case MaterialReferenceActionsTypes.MATERIAL_REFERENCE_REMOVE:
        ReducerMethods.removeByIdMutate(
          draft.materialReferences,
          action.materialReference as MaterialReference
        );
        break;

      default:
        return draft;
    }
  });
