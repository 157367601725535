import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CorrugatedCompositionActionsTypes } from "./action";
import * as Api from "./api";
import {
  CorrugatedComposition,
  CorrugatedCompositionCreateManyDTO
} from "./entity";

export interface CorrugatedCompositionState
  extends EntityState<CorrugatedComposition> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  deleteStatus: FetchingStatus;
  createManyStatus: FetchingStatus;
}

export const CorrugatedCompositionInitialState: CorrugatedCompositionState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  deleteStatus: FetchingStatus.NULL,
  createManyStatus: FetchingStatus.NULL
};

export const CorrugatedCompositionAdapter = createEntityAdapter<CorrugatedComposition>();

const CorrugatedCompositionAdapterState = CorrugatedCompositionAdapter.getInitialState(
  CorrugatedCompositionInitialState
);

export const read = createMyAsyncThunk<CorrugatedComposition[]>(
  CorrugatedCompositionActionsTypes.READ,
  Api.read
);

export const create = createMyAsyncThunk(
  CorrugatedCompositionActionsTypes.CREATE,
  Api.createOne,
  { onSuccessMessage: "saga:create-success" }
);

export const update = createMyAsyncThunk(
  CorrugatedCompositionActionsTypes.UPDATE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

export const deleteOne = createMyAsyncThunk<CorrugatedComposition[], string>(
  CorrugatedCompositionActionsTypes.DELETE,
  Api.deleteOne,
  { onSuccessMessage: "saga:delete-success" }
);

export const createMany = createMyAsyncThunk<
  CorrugatedComposition[],
  CorrugatedCompositionCreateManyDTO
>(CorrugatedCompositionActionsTypes.CREATE_MANY, Api.createMany, {
  onSuccessMessage: "saga:create-success"
});

const CorrugatedCompositionSlice = createMySlice({
  name: "corrugated-compositions",
  initialState: CorrugatedCompositionAdapterState,
  adapter: CorrugatedCompositionAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: CorrugatedCompositionAdapter.upsertMany
    },
    {
      action: create,
      statusName: "createStatus",
      onSuccess: CorrugatedCompositionAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: CorrugatedCompositionAdapter.upsertOne
    },
    {
      action: deleteOne,
      statusName: "deleteStatus",
      onSuccess: (state, payload) => {
        CorrugatedCompositionAdapter.removeOne(state, payload.meta.arg);
      }
    },
    {
      action: createMany,
      statusName: "createStatus",
      onSuccess: CorrugatedCompositionAdapter.upsertMany
    }
  ],
  reducers: {}
});

export const CorrugatedCompositionReducer = CorrugatedCompositionSlice.reducer;
export const CorrugatedCompositionActions = {
  ...CorrugatedCompositionSlice.actions,
  read,
  create,
  update,
  deleteOne,
  createMany
};
