import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { CadSourcesActionsTypes } from "./action";
import * as Api from "./api";
import { CadSource } from "./entity";

export interface CadSourceState extends EntityState<CadSource> {
  readStatus: FetchingStatus;
  createStatus: FetchingStatus;
  patchStatus: FetchingStatus;
}

export const CadSourceInitialState: CadSourceState = {
  ids: [],
  entities: {},
  readStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL
};

export const CadSourceAdapter = createEntityAdapter<CadSource>();

const CadSourceAdapterState = CadSourceAdapter.getInitialState(
  CadSourceInitialState
);

export const read = createMyAsyncThunk<CadSource[]>(
  CadSourcesActionsTypes.CAD_SOURCE_READ,
  Api.read
);

export const createOne = createMyAsyncThunk(
  CadSourcesActionsTypes.CREATE_ONE,
  Api.createOne,
  {
    onSuccessMessage: "saga:create-success"
  }
);

export const update = createMyAsyncThunk(
  CadSourcesActionsTypes.PATCH_ONE,
  Api.patchOne,
  { onSuccessMessage: "saga:update-success" }
);

const CadSourceSlice = createMySlice({
  name: "cad-source",
  initialState: CadSourceAdapterState,
  adapter: CadSourceAdapter,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: CadSourceAdapter.upsertMany
    },
    {
      action: createOne,
      statusName: "createStatus",
      onSuccess: CadSourceAdapter.addOne
    },
    {
      action: update,
      statusName: "patchStatus",
      onSuccess: CadSourceAdapter.upsertOne
    }
  ],
  reducers: {}
});

export const CadSourceReducer = CadSourceSlice.reducer;
export const CadSourceActions = {
  ...CadSourceSlice.actions,
  read,
  createOne,
  update
};
