import get from "lodash/get";
import { call, put, takeLatest } from "redux-saga/effects";
import * as Sentry from "@sentry/react";
import i18n from "../../utils/i18n";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { AppAddSnackbar } from "../app/action";
import { MaterialReferenceSheetSizeCreateStatus } from "../materialReferenceSheetSize/action";
import * as ApiMaterialRefSheetSize from "../materialReferenceSheetSize/api";
import { SheetSizeCreateStatus } from "../sheetsizes/action";
import * as ApiSheetSize from "../sheetsizes/api";
import {
  MaterialReferenceActionsTypes,
  MaterialReferenceCreate,
  MaterialReferenceCreateStatus,
  MaterialReferenceDelete,
  MaterialReferenceDeleteStatus,
  MaterialReferenceReadOne,
  MaterialReferenceReadOneStatus,
  MaterialReferenceReadStatus,
  MaterialReferenceUpdate,
  MaterialReferenceUpdateStatus
} from "./action";
import * as Api from "./api";

export function* readMaterialReferences() {
  yield put(new MaterialReferenceReadStatus(FetchingStatus.PENDING));
  try {
    const { data: materialReferences } = yield call(Api.readMaterialReferences);
    yield put(
      new MaterialReferenceReadStatus(
        FetchingStatus.SUCCESS,
        materialReferences
      )
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferences:read-failed");
      Sentry.captureException(error);
    });
    yield put(new MaterialReferenceReadStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-failed")));
  }
}

export function* readOneMaterialReference(action: MaterialReferenceReadOne) {
  yield put(new MaterialReferenceReadOneStatus(FetchingStatus.PENDING));
  try {
    const { data: materialReference } = yield call(
      Api.readOneMaterialReference,
      action.id
    );
    yield put(
      new MaterialReferenceReadOneStatus(
        FetchingStatus.SUCCESS,
        materialReference
      )
    );
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferences:read-one-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MaterialReferenceReadOneStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:read-one-failed")));
  }
}

export function* createMaterialReference(action: MaterialReferenceCreate) {
  yield put(new MaterialReferenceCreateStatus(FetchingStatus.PENDING));
  const {
    materialReferenceSheetSizes,
    ...materialReferenceDto
  } = action.materialReference;
  try {
    const { data: materialReference } = yield call(
      Api.createMaterialReference,
      materialReferenceDto
    );
    // Call to create all sheetsize for this material ref
    if (materialReferenceSheetSizes && materialReferenceSheetSizes.length) {
      for (const mrss of materialReferenceSheetSizes) {
        // Create sheetsize
        const { data: sheetsize } = yield call(ApiSheetSize.createSheetSize, {
          length: mrss.length,
          width: mrss.width
        });
        yield put(new SheetSizeCreateStatus(FetchingStatus.SUCCESS, sheetsize));
        const mrssWithMaterialId = {
          ...mrss,
          length: undefined,
          width: undefined,
          materialReferenceId: materialReference.id,
          sheetsizeId: sheetsize.id
        };

        // Create material ref _ sheetsize
        const { data: materialReferenceSheetSize } = yield call(
          ApiMaterialRefSheetSize.createMaterialReferenceSheetSize,
          mrssWithMaterialId
        );
        yield put(
          new MaterialReferenceSheetSizeCreateStatus(
            FetchingStatus.SUCCESS,
            materialReferenceSheetSize
          )
        );
      }
    }
    yield put(
      new MaterialReferenceCreateStatus(
        FetchingStatus.SUCCESS,
        materialReference
      )
    );
    yield put(new AppAddSnackbar(i18n.t("saga:create-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferences:create-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MaterialReferenceCreateStatus(FetchingStatus.FAILED));
    if (
      get(error, "response.data.message") ===
      "This material has already a sheetsize configuration"
    )
      yield put(
        sendErrorNotification(
          error,
          i18n.t(
            "backoffice:settings.materials.saga.configuration-already-exist"
          )
        )
      );
    else yield put(sendErrorNotification(error, i18n.t("saga:create-failed")));
  }
}

export function* updateMaterialReference(action: MaterialReferenceUpdate) {
  yield put(new MaterialReferenceUpdateStatus(FetchingStatus.PENDING));
  try {
    const { data: materialRef } = yield call(
      Api.updateMaterialReference,
      action.materialReference
    );
    yield put(
      new MaterialReferenceUpdateStatus(FetchingStatus.SUCCESS, materialRef)
    );
    if (action.callback) action.callback();
    yield put(new AppAddSnackbar(i18n.t("saga:update-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferences:update-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MaterialReferenceUpdateStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:update-failed")));
  }
}

export function* deleteMaterialReference(action: MaterialReferenceDelete) {
  yield put(new MaterialReferenceDeleteStatus(FetchingStatus.PENDING));
  try {
    yield call(Api.deleteMaterialReference, action.materialReference.id);
    yield put(new MaterialReferenceDeleteStatus(FetchingStatus.SUCCESS));
    yield put(new AppAddSnackbar(i18n.t("saga:delete-success"), "success"));
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTransactionName("materialReferences:delete-failed");
      scope.setContext("action", { ...action });
      Sentry.captureException(error);
    });
    yield put(new MaterialReferenceDeleteStatus(FetchingStatus.FAILED));
    yield put(sendErrorNotification(error, i18n.t("saga:delete-failed")));
  }
}

export const MaterialReferenceSaga = [
  takeLatest(
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_CREATE,
    createMaterialReference
  ),
  takeLatest(
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ,
    readMaterialReferences
  ),
  takeLatest(
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_READ_ONE,
    readOneMaterialReference
  ),
  takeLatest(
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_UPDATE,
    updateMaterialReference
  ),
  takeLatest(
    MaterialReferenceActionsTypes.MATERIAL_REFERENCE_DELETE,
    deleteMaterialReference
  )
];
