import { Drawer, Hidden, SwipeableDrawer, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppOpenMenu } from "../../reducers/app/action";
import { getOpenMenu } from "../../reducers/app/selector";
import { DRAWER_WIDTH } from "../../utils/constant";
import { BackOfficeMenu } from "./Menu";
import { OnboardingDialog } from "./Onboarding/OnboardingDialog";
import { getEachUserRoleStatus } from "../../reducers/authentication/selector";

interface BackOfficeProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    swipeableDrawer: {
      width: DRAWER_WIDTH
    },
    drawerPaper: {
      [theme.breakpoints.up("md")]: {
        width: DRAWER_WIDTH,
        flexShrink: 0
      }
    },
    toolbar: theme.mixins.toolbar
  })
);
export const BackOffice: React.FC<BackOfficeProps> = () => {
  const userRoles = useSelector(getEachUserRoleStatus);
  const classes = useStyles();
  const openMenu = useSelector(getOpenMenu);
  const dispatch = useDispatch();
  const iOS =
    (process as any).browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleDrawerToggle = (open?: boolean) => {
    dispatch(new AppOpenMenu(open === undefined ? !openMenu : open));
  };

  return (
    <>
      <Hidden mdUp>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openMenu}
          variant="temporary"
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={() => handleDrawerToggle()}
          onOpen={() => handleDrawerToggle(true)}
          classes={{ paper: classes.swipeableDrawer }}
        >
          <BackOfficeMenu />
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          PaperProps={{ elevation: 2 }}
          variant="permanent"
          open
        >
          <BackOfficeMenu />
        </Drawer>
      </Hidden>
      {!userRoles.guest && !userRoles.contact && <OnboardingDialog />}
    </>
  );
};
