import axios, { AxiosResponse } from "axios";

import { Request } from "../../utils/request";
import { User } from "../../entities/user";

export const signin = (
  username: string,
  password: string
): Promise<AxiosResponse<User>> => {
  return Request().post("/sign-in", {
    username,
    password
  });
};

export const signup = (user: Partial<User>) => Request().post("/sign-up", user);

export const changePassword = (
  token: string,
  qPath: string,
  password: string
) => Request().patch(`/${token}/change-password/${qPath}`, { password });

export const RetryConnection = (token: string) =>
  axios.get("/sign-in-by-token", {
    baseURL: process.env.REACT_APP_API || "/api",
    headers: {
      Authorization: "Bearer " + token
    }
  });
