/* eslint-disable @typescript-eslint/ban-types */
import get from "lodash/get";
import {
  boolean,
  BooleanSchema,
  mixed,
  MixedSchema,
  NumberSchema,
  object,
  ObjectSchema,
  ObjectSchemaDefinition,
  setLocale,
  string,
  StringSchema
} from "yup";
import { InputOptionsAdvanced } from "../../reducers/configurator-input-options/entity";
import { ConfiguratorInputOptionsResult } from "../../reducers/configurator-input-options/selector";
import { ConfiguratorInputNames } from "../../reducers/configurator-inputs/constant";
import i18n from "../i18n";
import { jsonTranslator } from "../function/jsonTranslator";
import { CustomError } from "../../components/common/form/common/MyFormHelperText";
import { parsePhoneNumber } from "libphonenumber-js";

export const setYupLocale = () =>
  setLocale({
    mixed: {
      required: () => i18n.t("yup:mixed.required")
    },
    string: {
      email: () => i18n.t("yup:string.email"),
      min: (param) => i18n.t("yup:string.min", param)
    },
    object: {},
    number: {
      min: (param) => i18n.t("yup:number.min", param),
      max: (param) => i18n.t("yup:number.max", param),
      positive: (param) =>
        i18n.t("yup:number.positive", {
          name: param.path,
          min: param.value
        }),
      integer: () => i18n.t("yup:number.integer")
    },
    array: {
      min: (param) => i18n.t("yup:array.min", param),
      max: (param) => i18n.t("yup:array.max", param)
    }
  });

export const mustBeUndefined = <T extends MixedSchema>(
  property: string,
  schema: T,
  optional?: boolean
): MixedSchema =>
  schema
    .nullable()
    .notRequired()
    .test(
      property,
      i18n.t("yup:undefined"),
      (value?: number) =>
        !!optional ||
        !value ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0)
    );

/**
 * Applies the input options logic for the input according to current input options
 * @param property id of the input
 * @param baseSchema yup schema to apply the logic to
 * @returns yup schema for the give input
 */
export const applyInputOptionsLogic = <T extends MixedSchema>(
  property: ConfiguratorInputNames,
  baseSchema: T,
  options?: { nullable?: boolean }
) =>
  mixed().when(
    ["$configuratorInputOptions"],
    (inputOptions: ConfiguratorInputOptionsResult) => {
      const inputOption = get(inputOptions, property);
      if (!inputOption) {
        console.error("Missing input option for input", property);
        return baseSchema;
      }

      const { precondition, required } = inputOption;
      if (precondition === false) {
        // const optional = ConfiguratorInputMetaDataByName[property]?.optional;
        return mustBeUndefined(property, baseSchema, true);
      }

      let schemaResult: T = baseSchema;
      if (required) {
        if (options?.nullable) {
          schemaResult = baseSchema.notOneOf(
            [undefined],
            i18n.t("yup:required")
          );
        } else {
          schemaResult = baseSchema.required() as T;
        }
      } else {
        schemaResult = baseSchema.nullable().notRequired() as T;
      }

      return schemaResult;
    }
  );

/**
 * Applies the input options logic for object inputs according to current input options
 * @param property id of the input
 * @param schemaWithOptions function that returns specific yup schema for the given input options
 * @returns yup schema for the give input
 */
export const applyInputOptionsLogicObject = <T extends MixedSchema>(
  property: ConfiguratorInputNames,
  schemaWithOptions: (required: boolean, options?: InputOptionsAdvanced) => T
) =>
  mixed().when(
    ["$configuratorInputOptions"],
    (inputOptions: ConfiguratorInputOptionsResult) => {
      const inputOption = get(inputOptions, property);
      if (!inputOption) {
        console.error("Missing input option for input", property);
        return schemaWithOptions(false);
      }

      const { precondition, required, options } = inputOption;
      if (precondition === false) {
        // return mustBeUndefined(property, mixed());
        return schemaWithOptions(false);
      }

      let schemaResult: T = schemaWithOptions(required, options || undefined);
      if (required) {
        schemaResult = schemaResult.required() as T;
      } else {
        schemaResult = schemaResult.nullable().notRequired() as T;
      }

      return schemaResult;
    }
  );

/**
 * Applies the input options logic for number inputs according to current input options
 * @param property id of the input
 * @param schema yup schema to apply the logic to
 * @param baseSchema yup schema to apply the logic when precondition is false
 * @returns yup schema for the give input
 */
export const applyInputOptionsLogicNumber = (
  property: ConfiguratorInputNames,
  schema: NumberSchema,
  baseSchema = schema
) =>
  mixed().when(
    ["$configuratorInputOptions"],
    (inputOptions: ConfiguratorInputOptionsResult) => {
      const inputOption = get(inputOptions, property);
      if (!inputOption) {
        console.error("Missing input option for input", property);
        return baseSchema;
      }

      const { precondition, required, options } = inputOption;
      if (precondition === false) {
        // const optional = ConfiguratorInputMetaDataByName[property]?.optional;
        return mustBeUndefined(property, baseSchema, true);
      }

      let schemaResult: NumberSchema<number | null | undefined> = schema;
      if (options?.min) {
        schemaResult = schemaResult.min(options.min, (params): any => {
          const errorMessage = i18n.t("yup:number.min", { min: options.min });
          if (!options?.minMessage) return errorMessage;
          return new CustomError(
            errorMessage,
            jsonTranslator(options.minMessage, i18n.language)
          );
        });
      }
      if (options?.max) {
        schemaResult = schemaResult.max(options.max, (params): any => {
          const errorMessage = i18n.t("yup:number.max", params);
          if (!options?.maxMessage) return errorMessage;
          return new CustomError(
            errorMessage,
            jsonTranslator(options.maxMessage, i18n.language)
          );
        });
      }
      if (required) {
        schemaResult = schemaResult.required();
      } else {
        schemaResult = schemaResult.nullable().notRequired();
      }
      return schemaResult;
    }
  );

/**
 * Applies the input options logic for string inputs according to current input options
 * @param property id of the input
 * @param schema yup schema to apply the logic to
 * @param baseSchema yup schema to apply the logic when precondition is false
 * @returns yup schema for the give input
 */
export const applyInputOptionsLogicString = (
  property: ConfiguratorInputNames,
  schema: StringSchema,
  baseSchema = schema
) =>
  mixed().when(
    ["$configuratorInputOptions"],
    (inputOptions: ConfiguratorInputOptionsResult) => {
      const inputOption = get(inputOptions, property);
      if (!inputOption) {
        console.error("Missing input option for input", property);
        return baseSchema;
      }

      const { precondition, required, options } = inputOption;
      if (precondition === false) {
        // const optional = ConfiguratorInputMetaDataByName[property]?.optional;
        return mustBeUndefined(property, baseSchema, true);
      }

      let schemaResult: StringSchema<string | null | undefined> = baseSchema;
      if (options?.restrictedList) {
        schemaResult = schemaResult.oneOf(
          options.restrictedList,
          i18n.t("yup:restrictedList.notIncluded")
        );
      }
      if (required) {
        schemaResult = schemaResult.required();
      } else {
        schemaResult = schemaResult.nullable().notRequired();
      }

      return schemaResult;
    }
  );

/**
 * Applies the input options logic for boolean inputs according to current input options
 * @param property id of the input
 * @param schema yup schema to apply the logic to
 * @param baseSchema yup schema to apply the logic when precondition is false
 * @returns yup schema for the give input
 */
export const applyInputOptionsLogicBoolean = (
  property: ConfiguratorInputNames,
  schema: BooleanSchema,
  baseSchema = schema
) =>
  mixed().when(
    ["$configuratorInputOptions"],
    (inputOptions: ConfiguratorInputOptionsResult) => {
      const inputOption = get(inputOptions, property);
      if (!inputOption) {
        console.error("Missing input option for input", property);
        return boolean().default(false);
      }

      const { precondition, required } = inputOption;
      if (precondition === false) {
        // const optional = ConfiguratorInputMetaDataByName[property]?.optional;
        return mustBeUndefined(property, baseSchema, true);
      }

      let schemaResult: BooleanSchema = baseSchema;
      if (required) {
        schemaResult = schemaResult.required();
      }

      return schemaResult;
    }
  );

type EntitySchemaCallback<T extends object, U extends string> = (
  baseSchema: Partial<ObjectSchemaDefinition<T>>
) => Record<U, ObjectSchema<Partial<T>>>;

interface EntitySchema {
  <T extends object>(
    baseSchema: Partial<ObjectSchemaDefinition<T>>,
    updateSchema: Partial<ObjectSchemaDefinition<T>>
  ): Record<"CREATE" | "UPDATE", ObjectSchema<Partial<T>>>;
  <T extends object, U extends string>(
    baseSchema: Partial<ObjectSchemaDefinition<T>>,
    updateSchema: Partial<ObjectSchemaDefinition<T>>,
    cb: EntitySchemaCallback<T, U>
  ): Record<
    "CREATE" | "UPDATE" | keyof ReturnType<typeof cb>,
    ObjectSchema<Partial<T>>
  >;
}
export const EntitySchema: EntitySchema = <T extends object, U extends string>(
  baseSchema: Partial<ObjectSchemaDefinition<T>>,
  updateSchema: Partial<ObjectSchemaDefinition<T>>,
  cb?: EntitySchemaCallback<T, U>
) => ({
  CREATE: object(baseSchema as ObjectSchemaDefinition<T>),
  UPDATE: object(
    Object.assign({}, baseSchema, updateSchema) as ObjectSchemaDefinition<T>
  ),
  ...(cb ? cb(baseSchema) : {})
});

export const colorSchema = string().matches(
  /^#([0-9A-F]{3}){1,2}$/i,
  "Must be a valid hex color"
);

export const phoneNumberSchema = (
  name: string,
  options?: { required?: boolean }
): StringSchema<string | null> => {
  const schema = string();
  schema.when(
    ["countryCode", "phoneCount"],
    (countryCode: string, phoneCount: number, schema: StringSchema) =>
      schema.test(
        name,
        i18n.t("backoffice:userProfile.contact.error.format"),
        function test(phoneNumber: string) {
          let valide = false;
          if (!phoneNumber) return options?.required ? false : true;

          if (phoneNumber.length >= 18) {
            return false;
          }

          /*  if (phoneCount !== phoneNumber.length && phoneCount < 16) {
            return this.createError({
              message: i18n.t("backoffice:userProfile.contact.error.length", {
                count: phoneCount
              })
            });
          } */

          if (phoneNumber && countryCode) {
            valide = parsePhoneNumber(
              phoneNumber,
              countryCode.toUpperCase() as any
            ).isValid();
          } else {
            valide = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/.test(
              phoneNumber?.replace(/ /g, "")
            );
          }

          // TODO too restrictive for now if we want only mobile phone
          /*  const correspondingCountry = PHONE_COUNTRIES.find(
          (country) => country.iso2 === countryCode.toUpperCase()
        )?.validation;

        if (correspondingCountry && phoneNumber) {
          valide = new RegExp(correspondingCountry).test(
            phoneNumber?.replace(/ /g, "")
          );
        } else if (phoneNumber) {
          valide = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/.test(
            phoneNumber?.replace(/ /g, "")
          );
        } */
          return valide;
        }
      )
  );
  return schema;
};
