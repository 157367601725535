import { AxiosRequestConfig } from "axios";
import { Request } from "../../utils/request";
import { QuoteSetting } from "./entity";

export const read = () =>
  Request({ withToken: true }).get<QuoteSetting>("/quote-settings");

export type UpdateFileTypeProps = {
  formData: FormData;
  onUploadProgress: AxiosRequestConfig["onUploadProgress"];
};
export const updateFileType = ({
  formData,
  onUploadProgress
}: UpdateFileTypeProps) => {
  return Request({ withToken: true }).post<QuoteSetting>(
    `/quote-settings/term-and-condition`,
    formData,
    { onUploadProgress }
  );
};

export const patch = (quoteSetting: Partial<QuoteSetting>) =>
  Request({ withToken: true }).patch<QuoteSetting>(
    `/quote-settings`,
    quoteSetting
  );
