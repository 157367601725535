import { Box, Grid, Typography } from "@material-ui/core";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { MyPageProps, Page } from "../components/common/MyPage";
import { Ellipsis } from "../components/common/Spinner/Ellipsis";

export interface LoadingPageProps extends MyPageProps {
  title?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = memo(
  ({ title, ...props }) => {
    const { t } = useTranslation("components");
    return (
      <Page center {...props}>
        <Box
          display="flex"
          position="absolute"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={4}
            >
              <Ellipsis />
            </Box>
            <Typography variant="h5" color="textSecondary">
              {title ?? t("dynamicElement.loaderComponent.title")}
            </Typography>
          </Grid>
        </Box>
      </Page>
    );
  }
);
