import produce from "immer";

import { SettingActions, SettingActionTypes } from "./action";

export interface SettingState {
  darkMode: boolean;
}

const initialState: SettingState = {
  darkMode: false
};

export const SettingReducer = (
  state = initialState,
  action: SettingActions
): SettingState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SettingActionTypes.DARK_MODE:
        draft.darkMode = !state.darkMode;
        break;
      default:
        return state;
    }
  });
