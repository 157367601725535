import { Ink } from "../reducers/ink/entity";

export class InkParameter {
  id!: string;
  inkId!: string;
  coverage!: number;
  position!: number;
  ink!: Ink;
}

export type InkParameterDTO = Omit<InkParameter, "id" | "ink">;

export const transformInkParameters = (
  inkParameters: InkParameter[] | undefined
): InkParameterDTO[] | undefined => {
  if (!inkParameters || inkParameters.length === 0) return;
  return inkParameters.map((inkParameter) => ({
    inkId: inkParameter.inkId,
    coverage: inkParameter.coverage,
    position: inkParameter.position
  }));
};
