import produce from "immer";
import { Project } from "../../entities/project";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { ReducerMethods } from "../../utils/reducers/methods";
import { ProjectActions, ProjectActionsTypes } from "./action";

export interface ProjectState {
  projects: Project[];
  fetchStatus: FetchingStatus;
  fetchForUserStatus: FetchingStatus;
  fetchByIdStatus: FetchingStatus;
  updateStatus: FetchingStatus;
  createStatus: FetchingStatus;
  createManagedProjectStatus: FetchingStatus;
  createForCompanyStatus: FetchingStatus;
  deleteProjectStatus: FetchingStatus;
  archivedStatus: FetchingStatus;
  getFilesStatus: FetchingStatus;
  uploadFileStatus: FetchingStatus;
  deleteFileStatus: FetchingStatus;
  contextProjectId?: string;
  selectedProjectId?: string;
  lastCreatedProjectId?: string;
}

const initialState: ProjectState = {
  projects: [],
  fetchStatus: FetchingStatus.NULL,
  fetchForUserStatus: FetchingStatus.NULL,
  fetchByIdStatus: FetchingStatus.NULL,
  updateStatus: FetchingStatus.NULL,
  createStatus: FetchingStatus.NULL,
  createManagedProjectStatus: FetchingStatus.NULL,
  createForCompanyStatus: FetchingStatus.NULL,
  deleteProjectStatus: FetchingStatus.NULL,
  archivedStatus: FetchingStatus.NULL,
  getFilesStatus: FetchingStatus.NULL,
  uploadFileStatus: FetchingStatus.NULL,
  deleteFileStatus: FetchingStatus.NULL,
  contextProjectId: undefined,
  selectedProjectId: undefined,
  lastCreatedProjectId: undefined
};

export const ProjectReducer = (state = initialState, action: ProjectActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ProjectActionsTypes.PROJECT_FETCH_STATUS:
        draft.fetchStatus = action.status;
        ReducerMethods.pushArrayUniqueByMutate(
          draft.projects,
          action.projects || state.projects
        );
        break;
      case ProjectActionsTypes.PROJECT_FOR_USER_FETCH_STATUS:
        draft.fetchForUserStatus = action.status;
        ReducerMethods.pushArrayUniqueByMutate(
          draft.projects,
          action.projects || state.projects
        );
        break;
      case ProjectActionsTypes.PROJECT_UPDATE_STATUS:
        draft.updateStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.project) {
          ReducerMethods.updateByIdMutate(draft.projects, action.project);
        }
        break;
      case ProjectActionsTypes.PROJECT_FETCH_ID_STATUS:
        draft.fetchByIdStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.project) {
          ReducerMethods.upsertByIdMutate(draft.projects, action.project);
        }
        break;
      case ProjectActionsTypes.PROJECT_CREATE_STATUS:
        draft.createStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.project) {
          draft.projects.push(action.project);
          draft.lastCreatedProjectId = action.project.id;
        }
        break;
      case ProjectActionsTypes.PROJECT_CREATE_MANAGED_STATUS:
        draft.createManagedProjectStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.project) {
          draft.projects.push(action.project);
          draft.lastCreatedProjectId = action.project.id;
        }
        break;
      case ProjectActionsTypes.PROJECT_CREATE_FOR_COMPANY_STATUS:
        draft.createForCompanyStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.project) {
          draft.projects.push(action.project);
          draft.lastCreatedProjectId = action.project.id;
        }
        break;
      case ProjectActionsTypes.PROJECT_DELETE_STATUS:
        draft.deleteProjectStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.project) {
          draft.projects = draft.projects.filter(
            (p) => p.id !== action.project?.id
          );
        }
        break;
      case ProjectActionsTypes.PROJECT_ARCHIVED_STATUS:
        draft.archivedStatus = action.status;
        if (action.status === FetchingStatus.SUCCESS && action.projectId)
          ReducerMethods.removeByIdMutate(draft.projects, {
            id: action.projectId
          });
        break;
      case ProjectActionsTypes.PROJECT_UPLOAD_FILE_STATUS:
        draft.uploadFileStatus = action.status;
        break;
      case ProjectActionsTypes.PROJECT_DELETE_FILE_STATUS:
        draft.deleteFileStatus = action.status;
        break;
      case ProjectActionsTypes.PROJECT_GET_FILES_STATUS:
        draft.getFilesStatus = action.status;
        break;
      case ProjectActionsTypes.PROJECT_SET_CONTEXT:
        draft.contextProjectId = action.id;
        break;
      case ProjectActionsTypes.PROJECT_SELECT_ID:
        draft.selectedProjectId = action.id;
        break;
      case ProjectActionsTypes.PROJECT_UNASSIGN_BRIEF:
        ReducerMethods.updateById(
          draft.projects,
          { id: action.projectId } as Project,
          (project) => ({
            ...project,
            briefIds: (project.briefIds || []).filter(
              (b) => b !== action.briefId
            )
          })
        );
        break;
      case ProjectActionsTypes.PROJECT_ASSIGN_BRIEF:
        ReducerMethods.updateById(
          draft.projects,
          { id: action.projectId } as Project,
          (project) => ({
            ...project,
            briefIds: [...(project.briefIds || []), action.briefId]
          })
        );
        break;
      case ProjectActionsTypes.PROJECT_ADD_MANY:
        ReducerMethods.pushArrayUniqueByMutate(draft.projects, action.projects);
        break;
      default:
        return draft;
    }
  });
