import {
  array,
  MixedSchema,
  number,
  NumberSchema,
  string,
  StringSchema
} from "yup";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import {
  applyInputOptionsLogic,
  applyInputOptionsLogicObject,
  applyInputOptionsLogicString,
  setYupLocale
} from "../../../../../../utils/yup";

setYupLocale();

export const requiredByCorrugatedMaterialId = <T extends MixedSchema>(
  schema: T
): T =>
  schema.when(
    "corrugatedMaterialId",
    (corrugatedMaterialId: string, schema: T) =>
      corrugatedMaterialId ? schema.required() : schema
  );

export const MaterialSchemaCorrugated = {
  materialFluteId: applyInputOptionsLogic<StringSchema>(
    ConfiguratorInputNames.MATERIAL_FLUTE_ID,
    string()
  ),
  materialQuality: applyInputOptionsLogic<NumberSchema<number | null>>(
    ConfiguratorInputNames.MATERIAL_QUALITY,
    number().nullable(),
    { nullable: true }
  ),
  materialLinerRectoId: applyInputOptionsLogic<StringSchema<string | null>>(
    ConfiguratorInputNames.MATERIAL_LINER_RECTO_ID,
    string().nullable(),
    { nullable: true }
  ),
  materialLinerVersoId: applyInputOptionsLogic<StringSchema<string | null>>(
    ConfiguratorInputNames.MATERIAL_LINER_VERSO_ID,
    string().nullable(),
    { nullable: true }
  ),
  corrugatedMaterialId: applyInputOptionsLogicString(
    ConfiguratorInputNames.CORRUGATED_MATERIAL_ID,
    string()
  ),
  corrugatedMaterialCompositionIds: applyInputOptionsLogicObject(
    ConfiguratorInputNames.CORRUGATED_MATERIAL_COMPOSITION_IDS,
    (required: boolean) =>
      array()
        .nullable(!required)
        .of(string())
        .min(required ? 1 : 0)
  )
};
