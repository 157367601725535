import { Request } from "../../utils/request";
import { InkCreateDTO, InkPatchDTO } from "./entity";

export const read = () => Request({ withToken: true }).get("/inks");

export const createOne = (configuratorInputOption: InkCreateDTO) =>
  Request({ withToken: true }).post("/inks", configuratorInputOption);

export const patch = ({ id, ...dto }: InkPatchDTO) =>
  Request({ withToken: true }).patch(`/inks/${id}`, dto);
