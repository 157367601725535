// This exception is needed for the current typescript version
// Or the font face class will be not infer from this library
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Features } from "../../services/features/types";
import { createMyAsyncThunk } from "../../utils/reducers/createMyAsyncThunk";
import { createMySlice } from "../../utils/reducers/createMySlice";
import { FetchingStatus } from "../../utils/reducers/fetchingStatus";
import { AppSettingActionsTypes } from "./action";
import * as Api from "./api";
import { AppSetting } from "./entity";
import { Localisations } from "../../entities/localisations";

export interface AppSettingState {
  readStatus: FetchingStatus;
  updateLogoStatus: FetchingStatus;
  patchStatus: FetchingStatus;
  updateFontStatus: FetchingStatus;
  updateFavIconStatus: FetchingStatus;
  updateStyleStatus: FetchingStatus;
  setting: AppSetting;
}

export const AppSettingInitialState: AppSettingState = {
  readStatus: FetchingStatus.NULL,
  patchStatus: FetchingStatus.NULL,
  updateLogoStatus: FetchingStatus.NULL,
  updateFontStatus: FetchingStatus.NULL,
  updateFavIconStatus: FetchingStatus.NULL,
  updateStyleStatus: FetchingStatus.NULL,
  setting: {
    id: "",
    font: null,
    logo: null,
    favIcon: null,
    displayStatuses: true,
    allowSignUp: false,
    allowSignIn: true,
    style: {
      primaryColor: "",
      secondaryColor: "",
      fontSize: 16
    },
    magentoToCart: {
      enabled: false,
      cartPathRedirection: "",
      cartPathApiCall: ""
    },
    woocommerceToCart: {
      enabled: false,
      cartPathRedirection: "",
      cartPathApiCall: ""
    },
    externalCompanyLabel: "",
    externalProjectLabel: "",
    features: null,
    gtm: {
      enabled: false,
      id: ""
    },
    localisations: []
  }
};

export const read = createMyAsyncThunk<AppSetting>(
  AppSettingActionsTypes.GET,
  Api.read,
  {
    onSuccess: (props) => {
      if (props.result.font?.url) {
        new FontFace("Company Font", `url(${props.result.font.url})`)
          .load()
          .then((fontFace) => {
            fontFace.status === "loaded" && document.fonts.add(fontFace);
          });
      }
      return props.result;
    }
  }
);

export const patch = createMyAsyncThunk<AppSetting, Partial<AppSetting>>(
  AppSettingActionsTypes.PATCH,
  Api.patch,
  {
    onSuccessMessage: "saga:update-success"
  }
);

export const updateFont = createMyAsyncThunk(
  AppSettingActionsTypes.UPDATE_FONT,
  Api.updateFileType,
  {
    onSuccess: (props) => {
      if (props.result.font?.url) {
        const font = new FontFace(
          "Company Font",
          `url(${props.result.font?.url})`
        ).load();
        font.then(
          (fontFace) =>
            fontFace.status === "loaded" && document.fonts.add(fontFace)
        );
      }
      return props.result;
    }
  }
);
export const updateLogo = createMyAsyncThunk(
  AppSettingActionsTypes.UPDATE_LOGO,
  Api.updateFileType
);

export const updateFavIcon = createMyAsyncThunk(
  AppSettingActionsTypes.UPDATE_FAVICON,
  Api.updateFileType
);

const AppSettingSlice = createMySlice({
  name: "app-setting",
  initialState: AppSettingInitialState,
  asyncActions: [
    {
      action: read,
      statusName: "readStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: patch,
      statusName: "patchStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: updateLogo,
      statusName: "updateLogoStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: updateFont,
      statusName: "updateFontStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    },
    {
      action: updateFavIcon,
      statusName: "updateFavIconStatus",
      onSuccess: (state, action) => {
        state.setting = action.payload;
      }
    }
  ],
  reducers: {
    updateFeaturesToAppSetting(
      state: any,
      action: { payload: Features } | undefined
    ) {
      state.setting.features = action?.payload;
    },
    updateLocalisationsToAppSetting(
      state: any,
      action: { payload: Localisations[] }
    ) {
      state.setting.localisations = action?.payload;
    }
  }
});

export const AppSettingReducer = AppSettingSlice.reducer;
export const {
  updateFeaturesToAppSetting,
  updateLocalisationsToAppSetting
} = AppSettingSlice.actions;
export const AppSettingActions = {
  ...AppSettingSlice.actions,
  async: { read, patch, updateFont, updateLogo, updateFavIcon }
};
