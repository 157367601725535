import { Request } from "../../utils/request";
import { FluteCreateDTO, FluteUpdateDTO } from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/corrugated-flutes");

export const createOne = (dto: FluteCreateDTO) =>
  Request({ withToken: true }).post("/corrugated-flutes", dto);

export const patchOne = ({ id, ...dto }: FluteUpdateDTO) =>
  Request({ withToken: true }).patch(`/corrugated-flutes/${id}`, dto);
