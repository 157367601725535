import React, { useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { AppSetting } from "../../reducers/appSettings/entity";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

interface InitMetaEntityCustomFieldsProps {
  config: AppSetting["sentry"];
}

export const InitSentry: React.FC<InitMetaEntityCustomFieldsProps> = ({
  config
}) => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (config && !isInitialized.current) {
      Sentry.init({
        dsn: config.dsn,
        environment: config.environment,
        integrations: [
          Sentry.replayIntegration({ maskAllText: false, blockAllMedia: true }),
          Sentry.reactRouterV5BrowserTracingIntegration({
            history
          })
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: `hipe-frontend@${process.env.REACT_APP_LAST_COMMIT}`
      });
      isInitialized.current = true;
    }
  }, [config]);

  return null;
};
