import { Languages } from "../../utils/function/jsonTranslator";
import { EntityType, EntityEventWCode } from "./type";

export function roundToFirstFrameOfDay(date: Date, addDay = false): Date {
  const roundedDate = new Date(date); // Create a new date object based on the provided date
  roundedDate.setUTCHours(0, 0, 0, 0); // Set UTC hours, minutes, seconds, and milliseconds to zero

  // Add one day (in milliseconds) to the roundedDate
  if (addDay) {
    roundedDate.setUTCDate(roundedDate.getUTCDate() + 1);
  }

  return roundedDate; // Return the rounded date
}

export function roundToFirstFrameOfMonth(date: Date, addDay = false): Date {
  const roundedDate = new Date(date); // Create a new date object based on the provided date
  roundedDate.setUTCHours(0, 0, 0, 0); // Set UTC hours, minutes, seconds, and milliseconds to zero

  // If addDay is true, add one day (in milliseconds) to the roundedDate
  if (addDay) {
    roundedDate.setUTCDate(roundedDate.getUTCDate() + 1);
  }

  // Set the date to the first day of the month
  roundedDate.setUTCDate(1);

  return roundedDate; // Return the rounded date
}

export function getMonthsBetweenDates(
  date1: string,
  date2: string,
  lang?: "default" | string
): { [month: string]: Date } {
  // Parse the input strings to Date objects
  const parsedDate1: Date = new Date(date1);
  const parsedDate2: Date = new Date(date2);

  if (
    parsedDate1.getMonth() === parsedDate2.getMonth() &&
    parsedDate1.getFullYear() === parsedDate2.getFullYear()
  ) {
    const monthYear =
      parsedDate1.toLocaleString(lang, { month: "long" }) +
      " " +
      parsedDate1.getFullYear();
    return { [monthYear]: roundToFirstFrameOfMonth(parsedDate1) };
  }

  // Ensure date1 is before date2
  const [start, end] =
    parsedDate1 < parsedDate2
      ? [parsedDate1, parsedDate2]
      : [parsedDate2, parsedDate1];

  // Initialize the array to store months and dates
  const monthsYears: Array<[string, Date]> = [];

  // Iterate through months between the two dates
  const currentDate = new Date(start);
  currentDate.setMonth(currentDate.getMonth() - 1); // set to previous month to ensure current month is included

  while (
    roundToFirstFrameOfMonth(currentDate) < roundToFirstFrameOfMonth(end)
  ) {
    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);

    // Add the current month and date to the array
    const monthYear =
      currentDate.toLocaleString(lang, { month: "long" }) +
      " " +
      currentDate.getFullYear();

    // check if it's the same month as the end date
    /* if (
      roundToFirstFrameOfMonth(currentDate).toISOString() <
      roundToFirstFrameOfMonth(end).toISOString()
    ) {
      continue;
    }*/

    monthsYears.push([
      monthYear,
      roundToFirstFrameOfMonth(new Date(currentDate))
    ]);
  }

  // Sort the array in descending order of date
  monthsYears.sort((a, b) => b[1].getTime() - a[1].getTime());

  const transformToObject = monthsYears.reduce((acc, [monthYear, date]) => {
    acc[monthYear] = date;
    return acc;
  }, {} as any);

  return transformToObject;
}

export function getMonthsFormated(
  months: string[],
  lang?: "default" | Languages
): { [month: string]: Date } {
  return months.reduce((acc, month) => {
    const date = new Date(month);
    const label =
      date.toLocaleString(lang, { month: "long" }) + " " + date.getFullYear();
    acc[label] = roundToFirstFrameOfMonth(date);
    return acc;
  }, {} as any);
}

export const getTranslationKeyAccess = (
  entityType: EntityType
): { translationKey: string; entityKeyAccess: keyof EntityEventWCode } => {
  switch (entityType) {
    case EntityType.Tasks:
      return { translationKey: "tasks", entityKeyAccess: "task" };
    case EntityType.Briefs:
      return { translationKey: "briefs", entityKeyAccess: "brief" };
    case EntityType.Projects:
      return { translationKey: "projects", entityKeyAccess: "project" };
    case EntityType.Companies:
      return { translationKey: "companies", entityKeyAccess: "company" };
    default:
      return { translationKey: "", entityKeyAccess: "task" };
  }
};
